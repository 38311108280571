import React, { useState, useEffect, useRef } from 'react';

import userData from '../../../../Users.json'; // Import the user data
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { MultiSelect } from "primereact/multiselect";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { saveAs } from 'file-saver';
import Baseurl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import { confirmDialog } from 'primereact/confirmdialog';
import CheckAuth from '../../../../APIHelper/CheckAuth';
import { toast } from 'react-toastify';
import LoginLogut from '../../../../CommonComponents/LoginLogut';
import { Loaderr } from '../../../../CommonComponents/Loader';
// import { toast } from 'react-hot-toast';
const BaseUrl = Baseurl;
const CityTable = () =>
{
  const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast } = useLinkManagement();
  const { handleLogout, setLoggedIn, isLoggedInToken, isLoading, setIsLoading } = CheckAuth();
  const [ loading, setLoading ] = useState( true );
  const navigate = useNavigate();
  // const toast = useRef( null );
  const dt = useRef( null );
  const [ globalFilterValue, setGlobalFilterValue ] = useState( '' );
  const [ ciTyData, setciTyData ] = useState( null ); const [ userId, setUserId ] = useState( '' );
  const coloms = [
    { field: "pinCode", header: "Pin Code", },
    { field: "statelabel", header: "State", },
  ];
  const [ visibleColumns, setVisibleColumns ] = useState( coloms );
  const exportColumns = visibleColumns.map( ( col ) => ( { title: col.header, dataKey: col.field } ) );
  exportColumns.unshift( { title: 'Name', dataKey: 'cityName' } );
  const [ filters, setFilters ] = useState( {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    cityName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    pinCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    statelabel: { value: null, matchMode: FilterMatchMode.CONTAINS },
  } );
  useEffect( () =>
  {
    // Check if the user is logged in by looking at localStorage
    const storedLoggedIn = localStorage.getItem( 'loggedIn' );
    const storedUserId = localStorage.getItem( 'userId' );
    if ( storedLoggedIn === 'true' && storedUserId )
    {
      setUserId( storedUserId );
    }
  }, [] );
  // useEffect( () =>
  // {
  //   fetchData();
  // }, [] );
  // const fetchData = async () =>
  // {
  //   try
  //   {
  //     const response = await axios.get( '' + BaseUrl + 'city' );
  //     setciTyData( response.data );
  //     setLoading( false );
  //   } catch ( error )
  //   {
  //     console.error( 'Error:', error );
  //   }
  // };
  useEffect( () =>
  {
    const fetchData = async () =>
    {
      const isLoggedInToken = localStorage.getItem( "token" );

      // Check if the user is not logged in
      if ( !isLoggedInToken )
      {
        handleLogout();
        setLoggedIn( false );
        return;
      }
      setIsLoading( true );
      try
      {
        const response = await axios.get( BaseUrl + 'city', {
          headers: {
            Authorization: `Bearer ${ isLoggedInToken }`,
          },
        } );

        const data = response.data;

        if ( data.status === false )
        {
          setIsLoading( false );
          if ( data.statusCode == '401' )
          {
            // alert(data.message);
            toast.error( data.message );
            handleLogout();
            setLoggedIn( false );
          }
        } else
        {
          setciTyData( response.data );
          setIsLoading( false );
        }

        setLoading( false );
      } catch ( error )
      {
        setIsLoading( false );
        if ( error.response )
        {
          if ( error.response.status === 401 )
          {
            console.log( 'Access to the resource is unauthorized. Please log in.' );
            // You can also redirect the user to a login page or display a modal here.
          } else
          {
            console.log( 'An error occurred:', error.response.data );
          }
        } else if ( error.request )
        {
          console.log( 'No response received from the server.' );
        } else
        {
          console.log( 'An error occurred:', error.message );
        }
      }
    };

    fetchData();
  }, [] ); // Empty dependency array to run the effect only once




  const onGlobalFilterChange = ( e ) =>
  {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters[ 'global' ].value = value;
    setFilters( _filters );
    setGlobalFilterValue( value );
  };
  const onColumnToggle = ( event ) =>
  {
    let selectedColumns = event.value;
    let orderedSelectedColumns = coloms.filter( ( col ) =>
      selectedColumns.some( ( sCol ) => sCol.field === col.field )
    );
    setVisibleColumns( orderedSelectedColumns );
  };
  const exportCSV = ( selectionOnly ) =>
  {
    dt.current.exportCSV( { selectionOnly } );
  };

  const exportPdf = () =>
  {
    const exportFilename = `${ new Date().toLocaleDateString() }`;
    import( 'jspdf' ).then( ( jsPDF ) =>
    {
      import( 'jspdf-autotable' ).then( () =>
      {
        const doc = new jsPDF.default( 0, 0 );
        doc.autoTable( exportColumns, ciTyData );
        doc.save( `Brokaer-${ exportFilename }.pdf` );
      } );
    } );
  };

  const exportExcel = () =>
  {
    import( 'xlsx' ).then( ( xlsx ) =>
    {
      const worksheet = xlsx.utils.json_to_sheet( ciTyData );
      const workbook = { Sheets: { data: worksheet }, SheetNames: [ 'data' ] };
      const excelBuffer = xlsx.write( workbook, {
        bookType: 'xlsx',
        type: 'array'
      } );
      saveAsExcelFile( excelBuffer, 'City' );
    } );
  };

  const saveAsExcelFile = ( buffer, fileName ) =>
  {
    import( 'file-saver' ).then( ( module ) =>
    {
      if ( module && module.default )
      {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob( [ buffer ], { type: EXCEL_TYPE } );
        module.default.saveAs( data, `${ fileName }_export_${ new Date().getTime() }${ EXCEL_EXTENSION }` );
      }
    } );
  };
  const editRow = ( id ) =>
  {
    const idd = id.cityId;

    const fetchData = async () =>
    {
      try
      {
        const response = await axios.get( `${ BaseUrl }checkcity/${ idd }`, {
          headers: { Authorization: `Bearer ${ isLoggedInToken }`, },//Pass Token In Header
        } );
        const editGroupData = response.data;
        if ( editGroupData.status === true )
        {
          addLink( `cityedit,${ idd }` );
          sessionStorage.setItem( 'editcityID', idd );
          navigateelast( idd );
        }
        else if ( editGroupData.status === false )
        { //Server error Or Valid Token 
          if ( editGroupData.statusCode == '401' ) { toast.error( editGroupData.message ); handleLogout(); setLoggedIn( false ); }
          // if ( editGroupData.message === userId ) { addLink( `cityedit,${ idd }` ); navigateelast( idd ); }
          else
          {
            toast.error( 'Entry open by:' + editGroupData.name );
            // toast.current.show( { severity: 'warn', summary: 'Entry open', detail: `by: ${ editGroupData.name }`, life: 2000, } );
          }
        }
      } catch ( error )
      {
        toast.error( 'Error:', error );
        console.error( 'Error:', error );
      }
    };

    fetchData();
  };

  const deletaccept = async ( id ) =>
  {
    const idd = id.cityId;

    try
    {
      const response = await fetch( `${ BaseUrl }city/${ idd }`, { method: 'DELETE', headers: { Authorization: `Bearer ${ isLoggedInToken }` } } );
      if ( response.ok )
      {
        const data = await response.json();
        const { status, message, code, name, statusCode } = data;

        if ( status === false && code === '1' )
        {
          // toast.current.show( { severity: 'warn', summary: 'Already Deleted', detail: message, life: 2000, } );
          toast.error( 'Already Deleted' + message );
          setciTyData( ( prevData ) => prevData.filter( ( row ) => row.cityId !== idd ) );
        } else if ( status === false && code === '2' )
        {
          toast.error( 'Entry open by:' + name );
          // toast.current.show( { severity: 'warn', summary: 'Entry open', detail: `by: ${ name }`, life: 2000, } );
        } else if ( status === true && code === '3' )
        {
          toast.success( 'Delete:' + message );
          // toast.current.show( { severity: 'success', summary: 'Delete', detail: message, life: 2000, } );
          setciTyData( ( prevData ) => prevData.filter( ( row ) => row.cityId !== idd ) );
        }
        else if ( status === false )
        {
          if ( statusCode == '401' )
          {
            toast.error( message );
            //  handleLogout(); setLoggedIn(false);
          }
        }
      } else
      {
        const errorData = await response.json();
        toast.error( 'Cancel:' + errorData.message );
        // toast.current.show( { severity: 'warn',summary: 'Rejected', detail: errorData.message,life: 3000, } );
        console.error( 'Error:', response.status );
      }
    } catch ( error )
    {
      console.error( 'Error:', error );
    }
  };


  const deletereject = () =>
  {
    toast.error( 'Cancel: Delete Request' );
    // toast.current.show( { severity: 'warn', summary: 'Cancel', detail: 'Cancel Delete Request', life: 3000 } );
  };

  const deletconfirm = ( rowData ) => ( event ) =>
  {
    confirmPopup( {
      target: event.currentTarget,
      message: 'Are you sure you want to Delete City ?',
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-danger',
      accepticon: 'pi pi-exclamation-triangle',
      accept: () => deletaccept( rowData ),
      reject: deletereject
    } );
  };

  const actionTemplate = ( rowData ) => (
    <div>
      <Button onClick={ () => editRow( rowData ) } icon="pi pi-pencil p-0 " className="p-0 w-5 me-1" text severity="warning" />
      <Button icon="pi pi-trash p-0 " onClick={ deletconfirm( rowData ) } className="p-0 w-5 ms-1" text severity="danger" />
      <ConfirmPopup />
    </div>
  );


  const renderHeader = () =>
  {
    return (
      <div className="row ">

        <div className="col-6">
          <div className="d-flex  align-items-center">
            <button onClick={ () => { addLink( "maincityadd" ); navigateelast(); } } className='px-2 btn btn-sm btn-primary me-2 text-white' autoFocus >
              Add
            </button>
            {/* <button onClick={addlink} className='px-2 btn btn-sm btn-primary me-2 text-white' autoFocus> Add </button> */ }
            <MultiSelect
              value={ visibleColumns }
              options={ coloms }
              optionLabel="header"
              onChange={ onColumnToggle }
              display="chip"
              className="w-full sm:w-20rem"
            /><span className="px-1"></span>
            <button type="button" className="btn btn-csv text-white btn-sm" onClick={ () => exportCSV( false ) } title="CSV">
              <i className="pi pi-file-excel"></i>
            </button><span className="px-1"></span>
            <button type="button" className="btn btn-success btn-sm" onClick={ exportExcel } title="XLS">
              <i className="pi pi-file-excel"></i>
            </button><span className="px-1"></span>
            <button type="button" className="btn btn-danger btn-sm" onClick={ exportPdf } title="PDF">
              <i className="pi pi-file-pdf"></i>
            </button>
          </div>
        </div>
        <div className="col-6">
          <div className="d-flex justify-content-end ">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText value={ globalFilterValue } style={ { height: '35px' } } type="search" onChange={ onGlobalFilterChange } placeholder="Keyword Search" />
            </span>
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();
  const exportFilename = `City-(${ new Date().toLocaleDateString() })`;
  return (
    <>
      <div className="bg-white p-2 mt-2" style={ { border: "solid #efefef 2px" } } >
        { isLoading ? ( <Loaderr /> ) : ( '' ) }
        <DynamicTitleComponent newTitle={ ` City ` } />
        {/* <Toast ref={ toast } /> */ }
        <DataTable ref={ dt } value={ ciTyData } scrollable resizableColumns showGridlines loading={ loading } exportFilename={ exportFilename } scrollHeight="70vh" style={ { minWidth: '40rem', width: "auto" } } dataKey="id" filters={ filters } header={ header } emptyMessage="No City found." globalFilterFields={ [ 'cityName', 'pinCode', 'statelabel' ] } filterDisplay="row">
          <Column
            header="Action"
            excludeFromExport
            body={ ( rowData ) => actionTemplate( rowData, 'id' ) }
            style={ { minWidth: '90px', width: '60px', maxWidth: '60px' } }
          ></Column>
          <Column
            key='sno'
            field='sno'
            header="Sno"
            style={ { minWidth: '60px', width: '60px', textAlign: "right" } }
          ></Column>
          <Column
            key='cityName'
            field='cityName'
            header="City Name"
            filter
            sortable
            filterPlaceholder={ `Name` }
            style={ { minWidth: '60px' } }
          ></Column>
          { visibleColumns.map( ( col, index ) => (
            <Column key={ index } field={ col.field } header={ col.header } body={ col.body }
              filter
              sortable
              filterPlaceholder={ ` ${ col.header }` }
              filterMatchMode="contains"
              exportable={ col.exportable !== false }
            />
          ) ) }
        </DataTable>
      </div>
    </>
  );
}
export default CityTable;