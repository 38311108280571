import React, { useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
const Navbarr = () =>
{
  const [Name , SetName] = useState('');
  useEffect( () =>
  {
    const uname = localStorage.getItem( 'username' );
    SetName(uname);
  }, [] );
    return (
        <>
            <nav className=" mb-1 rounded-1 px-2 py-1 OpenSans-Regular" style={{backgroundColor:'#0A4B82',color:'#fff'}}>
        <div className="container-fluid p-0 ">
          <span >
          FY 2020-23  ( Uelsoftwares )
          </span>
        <span className='text-center float-end'> {Name} </span>
          {/* <span className='pt-1'>
            <Link to="/user"><span class="material-symbols-outlined">account_circle</span></Link>
          </span> */}
        </div>

      </nav>
     

        </>
    )
}
export default Navbarr;