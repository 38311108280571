import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const StopBrowserBackButton = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Disable the browser's back functionality
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, null, window.location.href);
    };

    return () => {
      // Re-enable the browser's back functionality when the component unmounts
      window.onpopstate = null;
    };
  }, []);

  return (
    <div>
      {/* Your component content goes here */}
    </div>
  );
};

export default StopBrowserBackButton;
