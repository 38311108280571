
import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import Select, { components } from 'react-select';
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { OnlyNumber, SelectCheck, removeLeadingZero, threeDecimal, Pincoode, emtySelect, maxlength, validInput, Star, Editicon } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import customStyles from '../../../../validations/customStyles';
const AddTdsParameter = ( e ) =>
{
    const toast = useRef( null );
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, setFocus ,splitLastFocus } = useLinkManagement();
    const add_Tds_section = '/tds-parameter/add';
    const location = useLocation();
    const currentUrl = location.pathname;
    const navigate = useNavigate();
    const [ Scode, SetSCode ] = useState( '' ); const SCodeRef = useRef(); const [ TdsAcOpt, setTdsAcOpt ] = useState( [] );
    const [ efect_frm, SetEfectFrm ] = useState( '' ); const EfectFrmRef = useRef();
    const [ error, setError ] = useState( '' );
    const [ errors, setErrors ] = useState( '' );
    const [ inputs, setInputs ] = useState( {} );
    const values = { 'P': 'Individual - P', 'C': 'Company - C', 'H': 'Hindu Undivided Family (HUF) - H', 'A': 'Association of Persons (AOP) - A', 'B': 'Body of Individuals (BOI) - B', 'G': 'Government Agency - G', 'J': 'Artificial Juridical Person - J', 'L': 'Local Authority - L', 'F': 'Firm/ Limited Liability Partnership - F', 'T': 'Trust - T', 'W': 'Without Pan - W' }
    //Set Focuse
    useEffect(() => {
        const setFocusFromStorage = JSON.parse(sessionStorage.getItem('focusme'));
        if (setFocusFromStorage && setFocusFromStorage.length > 0) {
          const focusElement = setFocusFromStorage[setFocusFromStorage.length - 1];
          setTimeout(() => {
            if (focusElement === 'SCodeRef') {
                SCodeRef.current.focus();
              splitLastFocus();
            }
          }, 10); // 10 milliseconds delay
        }
      }, []);
    const handleInputChange = ( e, index ) =>
    {
        const { name, value } = e.target;
        var onlyno = OnlyNumber( value ); var removezero = removeLeadingZero( onlyno ); var valuee = validInput( removezero );
        setInputs( ( prevInputs ) => ( {
            ...prevInputs,
            [ index ]: { ...prevInputs[ index ], [ name ]: valuee },
        } ) );
    };
    const TdsParData = () => ( {
        sectioncode: Scode,
        efectfrom: efect_frm.trim(),
        TdsAc: inputs,
    } );
    useEffect( () =>
    {
        const fetsectioncode = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }/sectioncode`, { mode: 'cors' } );
                const data = response.data;
                const mappedOptions = data.map( item => ( { value: item.id, label: item.sectionCode } ) );
                setTdsAcOpt( mappedOptions );
            }
            catch ( error ) { console.log( 'Error fetching Section Code Opt.', error ); }
        };
        fetsectioncode();
    }, [] );
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };
    const validateInputs = () =>
    {
        setError( '' );
        const effrm = maxlength( efect_frm.trim(), 'Effect From' );
        const scode = SelectCheck( Scode, 'Section Code' );
        if ( effrm !== true )
        {
            setError( effrm ); foCoUs( EfectFrmRef ); return false;
        }
        if ( scode !== true )
        {
            setError( scode ); SCodeRef.current.focus(); return false;
        }
        let validRowwd = 0;
        const errorsCopy = {}; // Create a copy of errors to accumulate error messages
        let isValid = 1;
        for ( const [ key, value ] of Object.entries( inputs ) )
        {
            const { tdsstartfrom, tdspres } = value;
            setError( '' );

            if ( tdspres !== null && tdspres !== undefined && tdspres.trim() !== '' )
            {
                validRowwd += 1;
            }

            if ( tdsstartfrom !== undefined || tdsstartfrom !== null || tdsstartfrom.trim() !== '' )
            {
                if ( tdspres === undefined || tdspres === null || tdspres.trim() === '' )
                {
                    // Update errorsCopy with the error message
                    setError( `TDS % is required --- ${ key }` );
                    errorsCopy[ key ] = { ...errorsCopy[ key ], tdsstartfrom: 'TDS % is required' };
                    isValid += 1;
                }
            }
            if ( tdsstartfrom == undefined || tdsstartfrom.trim() === '' )
            {
                if ( tdspres === undefined || tdspres.trim() === '' )
                {
                    // Update errorsCopy with the error message
                    errorsCopy[ key ] = { ...errorsCopy[ key ], tdsstartfrom: '' };
                    isValid = 1;
                }
            }
        }
        // Set errors after the loop is done
        setErrors( errorsCopy );
        if ( validRowwd === 0 )
        {
            setError( 'There should be at least one row with TDS% greater than 1!' );
            return false;
        }
        if ( isValid === 1 ) { return true; }
        else { return false; }
    };
    function SubmitForm( e )
    {
        e.preventDefault();
        if ( validateInputs() )
        {
            saverequest();
            console.log( TdsParData() );
        }
    }
    const saverequest = () =>
    {
        axios
            .post( `${ BaseUrl }tdsparameter`, TdsParData(), { mode: 'cors' } )
            .then( function ( response )
            {
                const data = response.data;
                if ( data.success === true )
                {
                    sessionStorage.removeItem( 'tds_parameters' );
                    const LastLink = getLastLinkInSession();
                    if ( LastLink === 'add_tdsparameter_m' )
                    {
                        setError( '' );
                        SetSCode( '' );
                        SetEfectFrm( '' ); setInputs( [] ); setErrors( [] );
                        EfectFrmRef.current.focus();
                        toast.current.show( { severity: 'success', summary: 'Success', detail: 'Add TDS Section Type Success', life: 2000, } );
                    } else
                    {
                        handleSplitLastLink();
                        navigateelast();
                    }


                }
            } )
            .catch( function ( error )
            {
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors ); foCoUs( EfectFrmRef );
            } );
    }
    const chnageValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'efect_frm' ) { var valuee = validInput( value ); SetEfectFrm( valuee ); removeinvalid( EfectFrmRef ); }
    };
    const Tds_Ac_change = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'TDS Credit A/c empty' ); }
        else { SetSCode( selectedOption ); }
    };
    const accept = () =>
    {
        sessionStorage.removeItem( 'tds_parameters' );
        toast.current.show( { severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000, } );
        handleSplitLastLink();
        navigateelast();
        // if ( currentUrl === add_Tds_section ) { navigate( '/tds-parameter' ); }
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     { if ( event.key === 'Escape' ) { confirm( 'top' ); } };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () => { document.removeEventListener( 'keydown', handleKeyDown ); };
    // }, [] );
    
    // ADD TDS SEction type
    const AddSectionCode = () => 
    {
        sessionStorage.setItem(
            'tds_parameters',
            JSON.stringify( {Scode, efect_frm, inputs } ) );
            setFocus('SCodeRef');
            addLink( "add_tdsST" );
            navigateelast();
        // navigate( '/tds-parameter/add/tds-section-type' );
    };
    const AddSectionCodeBtn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <spna
                    onClick={ AddSectionCode }
                    className="btn btn-xsm  btn-primary btn-block w-100"
                > Add </spna>
            </components.MenuList>
        );
    };
    //Edit TDS Section Typpe
    const editTdsSectionForm = ( value ) =>
    {
        sessionStorage.removeItem( 'tds_parameters' );
        sessionStorage.setItem( 'tds_parameters', JSON.stringify( {efect_frm, inputs, Scode } ) );
        addLink( "edit_tdsST," + value );
        setFocus('SCodeRef');
        navigateelast();

    };
    const EdittdsSection = ( props ) =>
    {
        return (
            <>
                <div
                    onClick={ () => editTdsSectionForm( props.selectProps.value.value ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon/>
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );
    };
    const editTdsSection = ( { children, ...props } ) =>
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EdittdsSection { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };

   


    useEffect( () =>
    {
        const savedData = sessionStorage.getItem( 'tds_parameters' );
        if ( savedData )
        {
            const parsedData = JSON.parse( savedData );
            SetEfectFrm( parsedData.efect_frm );
            SetSCode( parsedData.Scode );
            setInputs( parsedData.inputs );
        }
    }, [] );
   
    return (
        <>
            <div className="p-2 mt-2 main"> <DynamicTitleComponent newTitle={ `Add TDS Parameters ` } />
                <div className="row">
                    <div className="col-md-6">

                        <div className='card'>
                            <div className="card-header"><h4> Add TDS Parameters</h4></div>
                            <form className="form-horizontal" onSubmit={ SubmitForm } autoComplete='off'>
                                <div className="card-body   ">
                                    <div className="row">
                                        {/* left column */ }
                                        <div>
                                            <span className='float-end' style={ { color: 'red' } }> { error }  { errors.mainError && <p>{ errors.mainError }</p> }</span>
                                        </div>
                                        <div className="col-md-12 pb-1 ">
                                            <div className=" row">
                                                <div className="col-md-4 col-sm-4 ">
                                                    <label htmlFor="efect_frm" >
                                                        Effect From
                                                    </label><Star />
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-sm "
                                                        name="efect_frm"
                                                        placeholder="Effect From"
                                                        onChange={ chnageValue }
                                                        value={ efect_frm }
                                                        ref={ EfectFrmRef }
                                                        autoFocus
                                                    />
                                                </div>
                                                <div className="col-md-8 col-sm-8">
                                                    <label htmlFor="section_code" >Section Code</label>
                                                    {/* <EditSection /> */}
                                                    <Select
                                                        options={ TdsAcOpt }
                                                        value={ Scode }
                                                        onChange={ Tds_Ac_change }
                                                        styles={ customStyles }
                                                        components={ { MenuList: AddSectionCodeBtn , IndicatorsContainer: editTdsSection } }
                                                        isClearable
                                                        isSearchable
                                                        ref={ SCodeRef }
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-12 ">
                                                    <table className="table table-bordered table-sm mt-3 table-secondary">

                                                        <thead>
                                                            <tr>
                                                                <th>SN</th>
                                                                <th>PAN Type</th>
                                                                <th>Start From</th>
                                                                <th>TDS %</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { Object.entries( values ).map( ( [ key, value ], index ) => (
                                                                <tr key={ key }>
                                                                    <td>{ index + 1 }</td>
                                                                    <td>{ value }</td>
                                                                    <td className="p-0">
                                                                        <input
                                                                            type="text"
                                                                            name="tdsstartfrom"
                                                                            className="w-100 text-end"
                                                                            key={ key }

                                                                            value={ inputs[ key ]?.tdsstartfrom ?? '' }
                                                                            onChange={ ( e ) => handleInputChange( e, key ) }
                                                                        />
                                                                    </td>
                                                                    <td className="p-0" style={ { width: '100px' } }>
                                                                        <input
                                                                            type="text"
                                                                            name="tdspres"
                                                                            className="w-100 text-end"
                                                                            value={ inputs[ key ]?.tdspres ?? '' }
                                                                            onChange={ ( e ) => handleInputChange( e, key ) }
                                                                        />
                                                                    </td>
                                                                    { errors[ key ]?.tdsstartfrom && (
                                                                        <span className="text-danger p-0">{ errors[ key ].tdsstartfrom }</span>
                                                                    ) }
                                                                </tr>
                                                            ) ) }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='button' onClick={ SubmitForm }>Save</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <Toast ref={ toast } />
                <ConfirmDialog />
            </div>
        </>
    );
}
export default AddTdsParameter;