import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import logo from '../assets/img/logo-pngnew.png';
import 'primeicons/primeicons.css';
import UseLinkManagement from '../Routes/useLinkManagement';
import CheckApi from '../APIHelper/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
const NewSidebar = () =>
{
    const { addLink, clearSessionStorage } = UseLinkManagement();
    const updateUserId = CheckApi();
    const [ sidebarOpen, setSidebarOpen ] = useState( false );
    const [ activeLinks, setActiveLinks ] = useState( {} );
    const [ userId, setUserId ] = useState( '' );
    const toggleSidebar = () => { setSidebarOpen( !sidebarOpen ); };
    const navigate = useNavigate();
    const location = useLocation();
    useEffect( () =>
    {

        const activeLink = sessionStorage.getItem( 'activeLink' ); // Use 'activeLink' as the key
        if ( activeLink )
        {
            setActiveLinks( { [ activeLink ]: true } );
        }
        // Check if the user is logged in by looking at localStorage
        const storedLoggedIn = localStorage.getItem( 'loggedIn' );
        const storedUserId = localStorage.getItem( 'userId' );
        if ( storedLoggedIn === 'true' && storedUserId ) { setUserId( storedUserId ); }
    }, [] );
    const handleLinkClick = ( linkName ) =>
    {
        const urlSegments = location.pathname.split( '/' );
        // Check the length of the URL segments
        if ( urlSegments.length === 2 )
        {
            addLink( linkName, 'parentlink' );
            setActiveLinks( { [ linkName ]: true } );
            navigate( '/' + linkName );
            sessionStorage.setItem( 'activeLink', linkName );
            // console.log( 'only 2 segiment ' +urlSegments);
        } else if ( urlSegments.length >2 )
        {
            // console.log( 'More than 2 segments in the URL'+ urlSegments );
            if ( clearSessionStorage() )
            {
                updateUserId();
                addLink( linkName, 'parentlink' );
                setActiveLinks( { [ linkName ]: true } );
                navigate( '/' + linkName );
                sessionStorage.setItem( 'activeLink', linkName );
            }
        }
       
    };
    const renderLink = ( linkName, label ) => (

        <li key={ linkName }>
            <a href="#"
                className={ activeLinks[ linkName ] ? 'active' : '' }
                onClick={ () => handleLinkClick( linkName ) }
            >
                { label }
            </a>
        </li>
    );
    const downicon = <span style={ { float: 'right', fontSize: '14px' } }> <FontAwesomeIcon icon={ faChevronDown } /> </span>;
    return ( <>
        <nav id="sidebar" className={ ` ${ sidebarOpen ? 'active' : '' }` }>
            <div className=" ">
                <div className="sidebar-logo">
                    <Link to="/" className="">
                        <img src={ logo } width={ '100%' } className='bg-light  p-2' />
                    </Link>
                </div>
                <div className="sidebar-content">
                    <div className="text-end" style={ { bottom: '2%', textAlign: 'end' } }>
                        <Button
                            icon={ `pi ${ sidebarOpen ? 'pi-angle-double-right' : 'pi-angle-double-left' }` }

                            aria-label="Toggle Sidebar"
                            style={ {
                                marginLeft: ` ${ sidebarOpen ? '10px' : '-20px' }`,
                                position: 'absolute', zIndex: 99, marginTop: '-20px', transition: 'all 0.5s', width: '35px !important',
                                paddingLeft: '20px', paddingRight: '20px', borderRadius: '25px', bottom: '2%'
                            } }
                            onClick={ toggleSidebar }
                        />
                    </div>
                    <ul className="list-unstyled components pt-2 px-1">
                        {/* <li className="active">
                            <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"> Masters </a>
                            <ul className="collapse list-unstyled" id="homeSubmenu"> */}
                        {/* <li>
                                    <a
                                        href="#accounts"
                                        data-toggle="collapse"
                                        aria-expanded={ `${ activeLinks[ 'group' ] || activeLinks[ 'city' ] || activeLinks[ 'zone' ] ? 'true' : 'false' }` }
                                        className={ `dropdown-toggle ${ activeLinks[ 'group' ] || activeLinks[ 'city' ] || activeLinks[ 'zone' ] ? 'active' : '' }` }
                                    >
                                        Accounts Related
                                    </a>
                                    <ul className={ `collapse list-unstyled ${ activeLinks[ 'group' ] || activeLinks[ 'city' ] || activeLinks[ 'zone' ] ? 'show' : '' }` } id="accounts">
                                        { renderLink( 'group', 'Group' ) }
                                        { renderLink( 'city', 'City' ) }
                                        { renderLink( 'zone', 'Zone' ) }
                                    </ul>
                                </li> */}

                        {/* <li>{ renderLink( 'accounts', 'Accounts' ) }</li> */ }
                        {/* <li>
                                    <a href="#Inventory" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Inventory Related</a>
                                    <ul className="collapse list-unstyled" id="Inventory">
                                        { renderLink( 'gst', 'GST Type' ) }
                                        { renderLink( 'cess', 'Cess Type' ) }
                                        { renderLink( 'hsn-sac', 'HSN/SAC' ) }
                                        { renderLink( 'packing', 'Packing' ) }
                                        { renderLink( 'manufacturer', 'Manufacturer' ) }
                                        { renderLink( 'stock-group', 'Stock Group' ) }
                                        { renderLink( 'stock-sub-group', ' Stock Sub Group' ) }
                                        { renderLink( 'godown', 'Godown' ) }
                                        { renderLink( 'broker', 'Broker' ) }
                                        { renderLink( 'transport', 'Transport' ) }
                                        { renderLink( 'batchlot', 'Goods Batch/Lot' ) }
                                        { renderLink( 'invoice', 'Invoice Other Detail' ) }
                                        <li> <Link to="/opening-stock">Opening Stock</Link></li>
                                        <li><Link to="/Aadhatiaia-opening">Aadhatiaia Opening Stock</Link></li>
                                    </ul>
                                </li> */}
                        {/* <li>{ renderLink( 'goods', 'Goods' ) }</li>
                                <li>{ renderLink( 'capital-goods-services', 'Capital Goods/Services' ) }</li> */}
                        {/* <li><Link to="/capitalgoodsservices">Capital Goods/Services</Link> </li> */ }
                        {/* <li>
                                    <a href="#tdsrelated" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Inventory Related</a>
                                    <ul className="collapse list-unstyled" id="tdsrelated">
                                        { renderLink( 'tds-section-type', 'TDS Section Type' ) }
                                        { renderLink( 'tds-parameter', 'TDS Parameter' ) }
                                        { renderLink( 'party-wise-tds-percentage', 'Party Wise TDS Percentage' ) }
                                        { renderLink( 'tds-settings', 'TDS Settings' ) }
                                        { renderLink( 'merge', 'Merge TDS' ) }

                                    </ul>
                                </li> */}
                        {/* <li>
                                    <a href="#othermaster" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Other Masters</a>
                                    <ul className="collapse list-unstyled" id="othermaster">
                                        { renderLink( 'document-series', 'Document Series' ) }
                                        { renderLink( 'voucher-narration', 'Voucher Narration' ) }
                                        { renderLink( 'document-cancel', 'Document Cancel' ) }
                                        { renderLink( 'print-profile', 'Print Profile' ) }
                                        { renderLink( 'print-page-size', 'Print Page Size' ) }
                                    </ul>
                                </li> */}
                        {/* <li>
                                    <a href="#AadhatRelated" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Aadhat Work Related</a>
                                    <ul className="collapse list-unstyled" id="AadhatRelated">
                                        { renderLink( 'kacchi-aadhat-working-city', 'Kacchi Aadhat Working City' ) }
                                        { renderLink( 'expenses', 'Expenses' ) }
                                        { renderLink( 'seller-slip-expenses-profile', 'Seller Slip Expenses Profile' ) }
                                        { renderLink( 'print-profile', 'Mandi Dhara Expenses Profile' ) }
                                        { renderLink( 'print-page-size', 'Self Expenses Given In Talpat Profile' ) }
                                        { renderLink( 'print-page-size', 'Kacchi Aadhat Parameter' ) }
                                        { renderLink( 'print-page-size', 'Paty Wise Market Percentage' ) }
                                        { renderLink( 'pacci-aadhat-working-city', 'Pacci Aadhat Working City' ) }
                                    </ul>
                                </li> */}
                        {/* </ul>
                        </li> */}
                        <li id="masterss">
                            <a className="collapsed" data-bs-toggle="collapse" href="#collapseMasters" aria-expanded="false">Masters { downicon }</a>
                            <ul id="collapseMasters" className="collapse list-unstyled" data-bs-parent="#masterss">
                                <li>
                                    <a className="collapsed" data-bs-toggle="collapse" href="#accounts" aria-expanded="false">
                                        Accounts Related { downicon }
                                    </a>
                                    <ul id="accounts" className="collapse list-unstyled" data-bs-parent="#collapseMasters">
                                        { renderLink( 'group', 'Group' ) }
                                        { renderLink( 'city', 'City' ) }
                                        { renderLink( 'zone', 'Zone' ) }
                                    </ul>
                                </li>
                                <li>{ renderLink( 'accounts', 'Accounts' ) }</li>
                                <li>
                                    <a className="collapsed" data-bs-toggle="collapse" href="#Inventory" aria-expanded="false">
                                        Inventory Related { downicon }
                                    </a>
                                    <ul id="Inventory" className="collapse list-unstyled" data-bs-parent="#collapseMasters">
                                        { renderLink( 'gst', 'GST Type' ) }
                                        { renderLink( 'cess', 'Cess Type' ) }
                                        { renderLink( 'hsn-sac', 'HSN/SAC' ) }
                                        { renderLink( 'packing', 'Packing' ) }
                                        { renderLink( 'manufacturer', 'Manufacturer' ) }
                                        { renderLink( 'stock-group', 'Stock Group' ) }
                                        { renderLink( 'stock-sub-group', ' Stock Sub Group' ) }
                                        { renderLink( 'godown', 'Godown' ) }
                                        { renderLink( 'broker', 'Broker' ) }
                                        { renderLink( 'transport', 'Transport' ) }
                                        { renderLink( 'batchlot', 'Goods Batch/Lot' ) }
                                        { renderLink( 'invoice', 'Invoice Other Detail' ) }
                                        { renderLink( 'opening-stock', 'Opening Stock' ) }
                                        {/* <li> <Link to="/opening-stock">Opening Stock</Link></li> */}
                                        <li><Link to="/Aadhatiaia-opening">Aadhatiaia Opening Stock</Link></li>
                                    </ul>
                                </li>
                                <li>{ renderLink( 'goods', 'Goods' ) }</li>
                                <li>{ renderLink( 'capital-goods-services', 'Capital Goods/Services' ) }</li>
                                <li>
                                    <a className="collapsed" data-bs-toggle="collapse" href="#tdsrelated" aria-expanded="false">
                                        TDS Related { downicon }
                                    </a>
                                    <ul id="tdsrelated" className="collapse list-unstyled" data-bs-parent="#collapseMasters">
                                        { renderLink( 'tds-section-type', 'TDS Section Type' ) }
                                        { renderLink( 'tds-parameter', 'TDS Parameter' ) }
                                        { renderLink( 'party-wise-tds-percentage', 'Party Wise TDS Percentage' ) }
                                        { renderLink( 'tds-settings', 'TDS Settings' ) }
                                        { renderLink( 'merge', 'Merge TDS' ) }
                                    </ul>
                                </li>
                                <li>
                                    <a className={ `collapsed ${ activeLinks[ 'document-series' ] ||
                                        activeLinks[ 'voucher-narration' ] ||
                                        activeLinks[ 'document-cancel' ] ||
                                        activeLinks[ 'print-profile' ] ||
                                        activeLinks[ 'print-page-size' ]
                                        ? ''
                                        : 'collapsed'
                                        }` } data-bs-toggle="collapse" href="#collapseTwo" aria-expanded={
                                            activeLinks[ 'document-series' ] ||
                                                activeLinks[ 'voucher-narration' ] ||
                                                activeLinks[ 'document-cancel' ] ||
                                                activeLinks[ 'print-profile' ] ||
                                                activeLinks[ 'print-page-size' ]
                                                ? 'true'
                                                : 'false'
                                        }>
                                        Other Masters { downicon }
                                    </a>
                                    <ul id="collapseTwo"
                                        className={ `collapse list-unstyled ${ activeLinks[ 'document-series' ] ||
                                            activeLinks[ 'voucher-narration' ] ||
                                            activeLinks[ 'document-cancel' ] ||
                                            activeLinks[ 'print-profile' ] ||
                                            activeLinks[ 'print-page-size' ]
                                            ? 'show' : ''
                                            }` } data-bs-parent="#collapseMasters">
                                        { renderLink( 'document-series', 'Document Series' ) }
                                        { renderLink( 'voucher-narration', 'Voucher Narration' ) }
                                        { renderLink( 'document-cancel', 'Document Cancel' ) }
                                        { renderLink( 'print-profile', 'Print Profile' ) }
                                        { renderLink( 'print-page-size', 'Print Page Size' ) }
                                    </ul>
                                </li>
                                <li>
                                    <a className="collapsed" data-bs-toggle="collapse" href="#AadhatRelated" aria-expanded="false">
                                        Aadhat Work Related { downicon }
                                    </a>
                                    <ul id="AadhatRelated" className="collapse list-unstyled" data-bs-parent="#collapseMasters">
                                        { renderLink( 'kacchi-aadhat-working-city', 'Kacchi Aadhat Working City' ) }
                                        { renderLink( 'expenses', 'Expenses' ) }
                                        { renderLink( 'seller-slip-expenses-profile', 'Seller Slip Expenses Profile' ) }
                                        { renderLink( 'mandi-dhara-expenses', 'Mandi Dhara Expenses Profile' ) }
                                        { renderLink( 'selfe-expenses', 'Self Expenses Given In Talpat Profile' ) }
                                        { renderLink( 'kachi-adhat', 'Kacchi Aadhat Parameter' ) }
                                        { renderLink( 'party-wise-market', 'Paty Wise Market Percentage' ) }
                                        { renderLink( 'pacci-aadhat-working-city', 'Pacci Aadhat Working City' ) }
                                    </ul>
                                </li>
                            </ul>
                        </li>



                    </ul>

                </div>

            </div>

        </nav>
    </> );
}
export default NewSidebar;