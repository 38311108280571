import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { BrowserRouter as Router, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { emtySelect, maxlength, validInput, hsn, OnlyNumber, Star } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent';
import { toast } from 'react-toastify';
import CommonFunctions from '../../../../Common/CommonFunction';
const EditHsn = ( e ) =>
{
    const { id } = useParams();
    const { handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, navigatefirstlink } = useLinkManagement();
    const { AddChangeData, EditChangeData, setInWhitchData, splitLastInWitchD, splitLastInWitchF, RemoveHSNSaCType } = CommonFunctions();
    const toastt = useRef( null );
    const [ hsn_sac_sel, set_hsn_sac_sel ] = useState( 'HSN' );
    const [ hsn_sac_code, set_hsn_sac_code ] = useState( '' );
    const [ description, set_description ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const hsn_sel = useRef();
    const hsn_code = useRef();
    const descriptionRef = useRef();

    useEffect( () =>
    {
        const fetchData = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }hsn/${ id }` );
                const edithsnData = response.data;
                set_hsn_sac_code( edithsnData.hsnCode );
                set_description( edithsnData.description );
                set_hsn_sac_sel( edithsnData.type );
            } catch ( error )
            {
                console.error( 'Error:', error );
            }
        };
        fetchData();
    }, [] );
    const HsnData = () => ( {
        hsncode: hsn_sac_code.trim(),
        description: description.trim(),
        type: hsn_sac_sel.trim(),
    } );
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };
    function OnSubmit( e )
    {
        e.preventDefault();
        setError( '' );
        const trimmedtype = emtySelect( hsn_sac_sel, 'HSN/SAC Type' );
        const trimmedCode = hsn( hsn_sac_code.trim(), 'HSN/SAC code', 8 );
        const discription = maxlength( description.trim(), 'description ' );
        const HsnSacType = JSON.parse( sessionStorage.getItem( 'InWhitchHsnSac' ) );
        if ( trimmedtype !== true )
        {
            setError( trimmedtype );
            foCoUs( hsn_sel );
            return false;
        } else if ( trimmedCode !== true )
        {
            setError( trimmedCode );
            foCoUs( hsn_code ); return false;
        } else if ( discription !== true )
        {
            setError( discription );
            foCoUs( descriptionRef ); return false;
        }
        else if ( hsn_sac_sel === 'SAC' && HsnSacType === 'HSN' )
        {
            toast.error( "SAC Entry Not Allowed!" );
            return false;
        }
        else if ( hsn_sac_sel === 'HSN' && HsnSacType === 'SAC' )
        {
            toast.error( "HSN Entry Not Allowed!" );
            return false;
        }
        else 
        {
            // axios
            //     .patch( `${ BaseUrl }hsn/${ id }`, HsnData(), { mode: 'cors' } )
            //     .then( function ( response )
            //     {
            //         const data = response.data;
            //         if ( data.success === true )
            //         {
            //             if ( data.type == 'SAC' ) { toast.success( 'SAC Update Selected' ); }
            //             else { toast.success( 'HSN Update Success' ); }

            //             const LastLink = getLastLinkInSession();    
            //             if ( LastLink === 'edit_hsnsac' )
            //             {
            //                 handleSplitLastLink();
            //                 navigateelast();
            //             } else
            //             {
            //                 if ( data.type == 'SAC' ) { toast.error( 'SAC Not Selected !' ); }
            //                 else
            //                 {
            //                     handleSplitLastLink();
            //                     EditChangeData( hsn_sac_code, data.Newid ); // Change Last Open Popup Data or fild
            //                     navigateelast();
            //                 }
            //             }
            //             // handleSplitLastLink();
            //             // const LastLink = getLastLinkInSession();
            //             // if ( LastLink === 'add_goods_m' || LastLink === 'addgoods' || LastLink === "edit_goods" )
            //             // {
            //             //     const goodsData = sessionStorage.getItem( 'goods_data' );
            //             //     if ( goodsData !== '' || goodsData !== null || goodsData !== undefined )
            //             //     {
            //             //         const d = goodsData ? JSON.parse( goodsData ) : {};
            //             //         const label = hsn_sac_code;
            //             //         const value = d.Hsn.value;
            //             //         if ( data.type == 'SAC' )
            //             //         {
            //             //             toast.error( 'SAC Not Selected !' );
            //             //             d.Hsn = '';
            //             //         sessionStorage.setItem( 'goods_data', JSON.stringify( d ) );
            //             //         }
            //             //        else{
            //             //         d.Hsn = { value, label };
            //             //         sessionStorage.setItem( 'goods_data', JSON.stringify( d ) );
            //             //        }


            //             //     }
            //             // }
            //             // navigateelast();
            //         }
            //     } )
            axios
                .patch( `${ BaseUrl }hsn/${ id }`, HsnData(), { mode: 'cors' } )
                .then( function ( response )
                {
                    const data = response.data;
                    if ( data.success === true )
                    {
                        if ( data.type === 'SAC' )
                        {
                            toast.success( 'SAC Update Selected' );
                        } else
                        {
                            toast.success( 'HSN Update Success' );
                        }

                        const LastLink = getLastLinkInSession();

                        if ( LastLink === 'edit_hsnsac' )
                        {
                            handleSplitLastLink();
                            navigateelast();
                        } else
                        {
                            const HsnSacType = JSON.parse( sessionStorage.getItem( 'InWhitchHsnSac' ) );
                            if ( data.type === 'SAC' && HsnSacType === 'HSN' )
                            {
                                toast.error( "SAC Entry Not Allowed!" );
                                return false;
                            }
                            if ( data.type === 'HSN' && HsnSacType === 'SAC' )
                            {
                                toast.error( "HSN Entry Not Allowed!" );
                                return false;
                            } else
                            {
                                handleSplitLastLink();
                                EditChangeData( hsn_sac_code, data.Newid ); // Change Last Open Popup Data or field
                                RemoveHSNSaCType();
                                navigateelast();
                            }
                        }
                    }
                } )
                .catch( function ( error )
                {
                    var errrr = error.response;
                    const errors = GlobalError( errrr );
                    setError( errors ); foCoUs( hsn_code );
                } );
        }
    }
    const ChangeValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'hsn_sac_sel' ) { var valuee = validInput( value ); set_hsn_sac_sel( valuee ); removeinvalid( hsn_sel ); setError( '' ); }
        if ( name === 'hsn_sac_code' ) { var valuee = OnlyNumber( value ); set_hsn_sac_code( valuee ); removeinvalid( hsn_code ); setError( '' ); }
        if ( name === 'description' ) { var valuee = validInput( value ); set_description( valuee ); removeinvalid( descriptionRef ); setError( '' ); }
    };

    const accept = () =>
    {
        splitLastInWitchD();
        splitLastInWitchF();
        RemoveHSNSaCType();
        handleSplitLastLink();
        navigateelast();
    };

    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };

    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>{if ( event.key === 'Escape' ){confirm( 'top' );}};
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () =>{document.removeEventListener( 'keydown', handleKeyDown );};
    // }, [] );


    return (
        <>
            <div className="p-2 mt-2 main"> <DynamicTitleComponent newTitle={ `Edit HSN-SAC ` } />
                <div className="row">
                    <div className="col-md-6">
                        <div className='card'>
                            <div className="card-header">   <h4>Edit HSN/SAC</h4></div>
                            <form className="form-horizontal" onSubmit={ OnSubmit }>
                                <div className="card-body">
                                    <div className=" row">
                                        <div className="col-md-12 col-sm-12 ">
                                            <div>
                                                <label htmlFor="hsn_sac_sel" className="">
                                                    HSN/SAC Type
                                                </label> <Star /> <span className='float-end' style={ { color: 'red' } }> { error }</span>
                                                <select
                                                    className="form-control form-control-sm"
                                                    name="hsn_sac_sel"
                                                    id="hsn_sac_sel"
                                                    value={ hsn_sac_sel }
                                                    onChange={ ChangeValue }
                                                    ref={ hsn_sel }
                                                    autoFocus
                                                >
                                                    <option value="HSN">HSN</option>
                                                    <option value="SAC">SAC</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12 ">
                                            <div>
                                                <label htmlFor="hsn_sac_code" className=""> HSN/SAC Code </label><Star />
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm "
                                                    id="hsn_sac_code"
                                                    name="hsn_sac_code"
                                                    value={ hsn_sac_code }
                                                    ref={ hsn_code }
                                                    onChange={ ChangeValue }
                                                    placeholder="Code"
                                                    maxLength={ 8 }
                                                    minLength={ 4 }
                                                    required />
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12 ">
                                            <label htmlFor="description" className="">
                                                Description
                                            </label>

                                            <textarea type="text"
                                                className="form-control form-control-sm "
                                                id="description"
                                                name="description"
                                                placeholder="Description"
                                                value={ description }
                                                onChange={ ChangeValue }
                                                ref={ descriptionRef }
                                            >

                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ OnSubmit }>Update</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>

                        </div>
                    </div>

                </div>
                <Toast ref={ toastt } />
                <ConfirmDialog />
            </div>
        </>
    );
}

export default EditHsn;

