import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import Select, { components } from 'react-select';
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { SelectCheck, removeLeadingZero, lemit100, validateDateOrder, Numberordecimal, maxlength, validInput, Star, Editicon } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import customStyles from '../../../../validations/customStyles';

const AddPartywiseTds = ( e ) =>
{
    const toast = useRef( null );
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, setFocus, splitLastFocus } = useLinkManagement();
    const add_party_wise = '/party-wise-tds/add';
    const location = useLocation();
    const currentUrl = location.pathname;
    const navigate = useNavigate();
    const [ efect_frm, SetEfectFrm ] = useState( '' ); const EfectFrmRef = useRef();
    const [ efect_to, SetEfectTo ] = useState( '' ); const EfectTomRef = useRef();
    const [ account, SetAccount ] = useState( '' ); const AccountRef = useRef(); const [ AccountOpt, setAccountOpt ] = useState( [] );
    const [ Scode, SetSCode ] = useState( '' ); const SCodeRef = useRef(); const [ TdsAcOpt, setTdsAcOpt ] = useState( [] );
    const [ reason, SetReasion ] = useState( '' ); const reasonRef = useRef();
    const [ tdspers, SetTdspers ] = useState( '' ); const TdsperRef = useRef();
    const [ error, setError ] = useState( '' );

    useEffect( () => //Fetch Accounts
    {
        const fetchAccounts = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }/vaccountopt`, { mode: 'cors' } );
                const data = response.data;
                const mappedOptions = data.map( item => ( { value: item.id, label: item.accName } ) );
                setAccountOpt( mappedOptions );
            }
            catch ( error ) { console.log( 'Error fetching Accounts.', error ); }
        };
        fetchAccounts();
    }, [] );
    useEffect( () => //fetch section Code's
    {
        const fetsectioncode = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }/sectioncode`, { mode: 'cors' } );
                const data = response.data;
                const mappedOptions = data.map( item => ( { value: item.id, label: item.sectionCode } ) );
                setTdsAcOpt( mappedOptions );
            }
            catch ( error ) { console.log( 'Error fetching Section Code Opt.', error ); }
        };
        fetsectioncode();
    }, [] );
    //Set Focuse
    useEffect( () =>
    {
        const setFocusFromStorage = JSON.parse( sessionStorage.getItem( 'focusme' ) );
        if ( setFocusFromStorage && setFocusFromStorage.length > 0 )
        {
            const focusElement = setFocusFromStorage[ setFocusFromStorage.length - 1 ];
            setTimeout( () =>
            {
                if ( focusElement === 'SCodeRef' )
                {
                    SCodeRef.current.focus();
                    splitLastFocus();
                }
                else if ( focusElement === 'AccountRef' )
                {
                    AccountRef.current.focus();
                    splitLastFocus();
                }
            }, 10 ); // 10 milliseconds delay
        }
    }, [] );
    //get session storage data
    useEffect( () =>
    {
        const savedData = sessionStorage.getItem( 'party_wise_parameters' );
        if ( savedData )
        {
            const parsedData = JSON.parse( savedData );
            SetEfectFrm( parsedData.efect_frm );
            SetSCode( parsedData.Scode );
            SetAccount( parsedData.account );
            SetReasion( parsedData.reason );
            SetTdspers( parsedData.tdspers );
        }
    }, [] );
    const partyWiseData = () => ( {
        reason: reason.trim(),
        tdspers: tdspers.trim(),
        efectfrom: efect_frm.trim(),
        // efectto: efect_to.trim(),
        account: account,
        Scode: Scode,
    } );
    function SubmitForm( e )
    {
        e.preventDefault();
        setError();
        const effrm = maxlength( efect_frm.trim(), 'Effect From date' );
        const scode = SelectCheck( Scode, 'Section Code' );
        const resonn = SelectCheck( reason, 'Reason' );
        const Accountcheck = SelectCheck( account, 'A/c name' );
        const tdsPrs = lemit100( tdspers, 'TDS %' );
        const datecheck = validateDateOrder( efect_frm.trim(), 'Effect From date', efect_to.trim(), 'Effect To date' );
        if ( effrm !== true ) { setError( effrm ); foCoUs( EfectFrmRef ); return false; }
        if ( efect_frm !== '' && efect_to !== '' ) { if ( datecheck !== true ) { setError( datecheck ); foCoUs( EfectTomRef ); return false; } }
        if ( Accountcheck !== true ) { setError( Accountcheck ); AccountRef.current.focus(); return false; }
        if ( scode !== true ) { setError( scode ); SCodeRef.current.focus(); return false; }
        if ( resonn !== true ) { setError( resonn ); foCoUs( reasonRef ); return false; }
        if ( tdsPrs !== true ) { console.log( tdsPrs ); setError( tdsPrs ); foCoUs( TdsperRef ); return false; }

        //   console.log(partyWiseData());
        saverequest();
    }
    const saverequest = () =>
    {
        axios
            .post( `${ BaseUrl }partywisetds`, partyWiseData(), { mode: 'cors' } )
            .then( function ( response )
            {
                const data = response.data;
                if ( data.success === true )
                {
                    sessionStorage.removeItem( 'party_wise_parameters' );
                    setError( '' );
                    SetSCode( '' );
                    SetEfectTo( '' );
                    SetEfectFrm( '' ); SetAccount( '' ); SetReasion( '' ); SetTdspers( '' );
                    EfectFrmRef.current.focus();
                    toast.current.show( {
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Add TDS Section Type Success',
                        life: 2000,
                    } );
                }
            } )
            .catch( function ( error )
            {
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors ); foCoUs( EfectFrmRef );
            } );
    }
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };

    const chnageValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'efect_frm' ) { var valuee = validInput( value ); SetEfectFrm( valuee ); removeinvalid( EfectFrmRef ); }
        if ( name === 'efect_to' ) { var valuee = validInput( value ); SetEfectTo( valuee ); removeinvalid( EfectTomRef ); }
        if ( name === 'reason' ) { var valuee = validInput( value ); SetReasion( valuee ); removeinvalid( reasonRef ); }
        if ( name === 'tdspers' ) { var onlyno = Numberordecimal( value ); var removezero = removeLeadingZero( onlyno ); var valuee = validInput( removezero ); SetTdspers( valuee ); removeinvalid( TdsperRef ); }
    };
    const Ac_change = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'Account empty' ); }
        else { SetAccount( selectedOption ); }
    };
    
    const SectionCode_change = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'Section Code empty' ); }
        else { SetSCode( selectedOption ); }
    };
    //Add Account
    const AddAccount = () => 
    {
        sessionStorage.setItem( 'party_wise_parameters', JSON.stringify( { efect_frm, efect_to, account, Scode, reason, tdspers } ) );
        setFocus( 'AccountRef' );
        addLink( "addaccount" );
        navigateelast();
    };
    const AddAccountBtn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <spna
                    onClick={ AddAccount }
                    className="btn btn-xsm  btn-primary btn-block w-100"
                > Add </spna>
            </components.MenuList>
        );
    };
    //Edit Account 
    const editAccountForm = ( value ) =>
    {
        sessionStorage.removeItem( 'party_wise_parameters' );
        sessionStorage.setItem( 'party_wise_parameters', JSON.stringify( { efect_frm, efect_to, Scode, account, reason, tdspers } ) );
        addLink( "edit_account," + value );
        setFocus( 'AccountRef' );
        navigateelast();

    };
    const EditAccount = ( props ) =>
    {
        return (
            <>
                <div
                    onClick={ () => editAccountForm( props.selectProps.value.value ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );
    };
    const EditAccountBtn = ( { children, ...props } ) =>
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditAccount { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };
    // const EditAccount = () =>
    // {
    //     return (
    //         <>
    //             { account ? (
    //                 <span onClick={ editaccountfrom } className="ed-btn ">
    //                     <i className="pi pi-pencil" style={ { fontSize: '0.8rem' } }></i>
    //                 </span>
    //             ) : null }
    //         </>
    //     );
    // }
    // const editaccountfrom = () =>
    // {
    //     sessionStorage.removeItem( 'party_wise_parameters' );
    //     sessionStorage.setItem('party_wise_parameters', JSON.stringify( { efect_frm, efect_to,Scode,account,reason,tdspers } ) );
    //     const id = account.value
    //     navigate( '/party-wise-tds/add/account/' + id + '' );
    // }

    // ADD TDS SEction type
    const AddSectionCode = () => 
    {
        sessionStorage.setItem( 'party_wise_parameters', JSON.stringify( { efect_frm, efect_to, account, Scode, reason, tdspers } ) );
        setFocus( 'SCodeRef' );
        addLink( "add_tdsST" );
        navigateelast();
        // navigate( '/party-wise-tds/add/tds-section-type' );
    };
    const AddSectionCodeBtn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <spna
                    onClick={ AddSectionCode }
                    className="btn btn-xsm  btn-primary btn-block w-100"
                > Add </spna>
            </components.MenuList>
        );
    };
    //Edit TDS Section Type
    const editTdsSectionForm = ( value ) =>
    {
        sessionStorage.removeItem( 'party_wise_parameters' );
        sessionStorage.setItem( 'party_wise_parameters', JSON.stringify( { efect_frm, efect_to, Scode, account, reason, tdspers } ) );
        addLink( "edit_tdsST," + value );
        setFocus( 'SCodeRef' );
        navigateelast();

    };
    const EdittdsSection = ( props ) =>
    {
        return (
            <>
                <div
                    onClick={ () => editTdsSectionForm( props.selectProps.value.value ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );
    };
    const editTdsSection = ( { children, ...props } ) =>
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EdittdsSection { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };

    // const EditSection = () =>
    // {
    //     return (
    //         <>
    //             { Scode ? (
    //                 <span onClick={ editsecionfrom } className="ed-btn ">
    //                     <i className="pi pi-pencil" style={ { fontSize: '0.8rem' } }></i>
    //                 </span>
    //             ) : null }
    //         </>
    //     );
    // }
    // const editsecionfrom = () =>
    // {
    //     sessionStorage.removeItem( 'party_wise_parameters' );
    //     sessionStorage.setItem('party_wise_parameters', JSON.stringify( { efect_frm, efect_to,Scode,account,reason,tdspers } ) );
    //     const id = Scode.value
    //     navigate( '/party-wise-tds/add/tds-section-type/' + id + '' );
    // }

    const accept = () =>
    {
        sessionStorage.removeItem( 'party_wise_parameters' );
        toast.current.show( { severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000, } );
        handleSplitLastLink();
        navigateelast();
        // if ( currentUrl === add_party_wise ) { navigate( '/party-wise-tds' ); }
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     { if ( event.key === 'Escape' ) { confirm( 'top' ); } };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () => { document.removeEventListener( 'keydown', handleKeyDown ); };
    // }, [] );

    return (
        <>
            <div class=" p-2 mt-2 main" >
                <div className="row">  <DynamicTitleComponent newTitle={ `Add Party Wise TDS Percentage` } />
                    <div className="col-md-6">

                        <div className='card'>
                            <div className="card-header">  <h4> Add Party Wise TDS Percentage</h4>  </div>
                            <form className="form-horizontal" onSubmit={ SubmitForm } autoComplete='off'>
                                <div className="card-body   ">
                                    <div className="row">
                                        {/* left column */ }
                                        <div>
                                            <span className='float-end' style={ { color: 'red' } }> { error }</span>
                                        </div>
                                        <div className="col-md-12 pb-1 ">
                                            <div className=" row">
                                                <div className="col-md-6 col-sm-4 ">
                                                    <label htmlFor="efect_frm" >
                                                        Effect From
                                                    </label><Star />
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-sm "
                                                        name="efect_frm"
                                                        placeholder="Effect From"
                                                        onChange={ chnageValue }
                                                        value={ efect_frm }
                                                        ref={ EfectFrmRef }
                                                        autoFocus
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-4 ">
                                                    <label htmlFor="efect_to" >
                                                        Effect To
                                                    </label><Star />
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-sm "
                                                        name="efect_to"
                                                        placeholder="Effect To"
                                                        onChange={ chnageValue }
                                                        value={ efect_to }
                                                        disabled
                                                        ref={ EfectTomRef }
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-8">
                                                    <label htmlFor="section_code" >
                                                        Account Name
                                                    </label><Star />
                                                    {/* <EditSection/> */ }
                                                    <Select
                                                        options={ AccountOpt }
                                                        value={ account }
                                                        onChange={ Ac_change }
                                                        styles={ customStyles }
                                                        components={ { MenuList: AddAccountBtn, IndicatorsContainer: EditAccountBtn } }
                                                        isClearable
                                                        isSearchable
                                                        ref={ AccountRef }
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-8">
                                                    <label htmlFor="section_code" >
                                                        Section Code
                                                    </label><Star />
                                                    {/* <EditSection/> */ }
                                                    <Select
                                                        options={ TdsAcOpt }
                                                        value={ Scode }
                                                        onChange={ SectionCode_change }
                                                        styles={ customStyles }
                                                        components={ { MenuList: AddSectionCodeBtn, IndicatorsContainer: editTdsSection } }
                                                        isClearable
                                                        isSearchable
                                                        ref={ SCodeRef }
                                                    />
                                                </div>
                                                <div className="col-md-8 col-sm-4 ">
                                                    <label htmlFor="reason" >
                                                        Reason
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        name="reason"
                                                        placeholder="Reason"
                                                        onChange={ chnageValue }
                                                        value={ reason }
                                                        ref={ reasonRef }
                                                    />
                                                </div>
                                                <div className="col-md-4 col-sm-4 ">
                                                    <label htmlFor="tdspers" >
                                                        TDS %
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control form-control-sm "
                                                        name="tdspers"
                                                        placeholder="TDS %"
                                                        onChange={ chnageValue }
                                                        value={ tdspers }
                                                        ref={ TdsperRef }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitForm }>Save</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <Toast ref={ toast } />
                <ConfirmDialog />
            </div>
        </>
    )
}

export default AddPartywiseTds;