import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { BrowserRouter as Router, useNavigate, useLocation ,useParams} from 'react-router-dom';

import { addtwoDecimal, addthreeDecimal, removeLeadingZero, threeDecimal, twoDecimal, emtySelect, maxlength, validInput, Star } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent';
import { toast } from 'react-toastify';
import CommonFunctions from '../../../../Common/CommonFunction';
const EditGst = ( e ) =>
{
   
    const {  handleSplitLastLink,  navigateelast ,getLastLinkInSession} = useLinkManagement();
    const { AddChangeData, EditChangeData, setInWhitchData, splitLastInWitchD, splitLastInWitchF } = CommonFunctions();
    const { id } = useParams();
    const toastt = useRef( null );
    const [ isDisabled, setIsDisabled ] = useState( false );
    const [ gst_type, set_gst_type ] = useState( 'TX' );
    const [ gst_name, set_gst_name ] = useState( '' );
    const [ gst_igst_name, set_gst_igst_name ] = useState( '' );
    const [ gst_igst, set_gst_igst ] = useState( '' );
    const [ gst_igst_decimal, set_gst_igst_decimal ] = useState( '' );//ewewe
    const [ gst_cgst_name, set_gst_cgst_name ] = useState( '' );
    const [ gst_cgst, set_gst_cgst ] = useState( '' );
    const [ gst_cgst_decimal, set_gst_cgst_decimal ] = useState( '' );//ewewe
    const [ sgst_name, set_sgst_name ] = useState( '' );
    const [ gst_sgst, set_gst_sgst ] = useState( '' );
    const [ gst_sgst_decimal, set_gst_sgst_decimal ] = useState( '' ); //ewewe
    const [ error, setError ] = useState( '' );
    const gstType = useRef();
    const gstName = useRef();
    const igstNameRef = useRef(); const igstper = useRef();
    const cgstNameRef = useRef(); const cgstper = useRef();
    const sgstNameRef = useRef(); const sgstper = useRef();
    const [ sysDisable, setsysDisable ] = useState( false );

    useEffect( () =>
    {
        const fetchData = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }gsttype/${ id }` );
                const GstData = response.data;
                set_gst_type( GstData.gstType );
                set_gst_name( GstData.name );
                set_gst_igst_name( GstData.igstName ); set_gst_igst( GstData.igstPer ); set_gst_igst_decimal( GstData.igstPer );
                set_gst_cgst_name( GstData.cgstName ); set_gst_cgst( GstData.cgstPer ); set_gst_cgst_decimal( GstData.cgstPer );
                set_sgst_name( GstData.sgstName ); set_gst_sgst( GstData.sgstPer ); set_gst_sgst_decimal( GstData.sgstPer );
                const sysytem = GstData.isSysDefined;
                if ( sysytem == 1 ) { setsysDisable( true ); setIsDisabled( true );}
                else { setsysDisable( false ); }
                const gstType = GstData.gstType;
                if ( gstType == 'EX' )
                {
                    setIsDisabled( true );
                    set_gst_igst( '' );
                    set_gst_cgst( '' );
                    set_gst_sgst( '' );
                }
                else { setIsDisabled( false ); }
            } catch ( error )
            {
                console.error( 'Error:', error );
            }
        };
        fetchData();
    }, [] );
    const GstData = () => ( {
        gst_type: gst_type.trim(),
        gst_name: gst_name.trim(),
        gst_igst_name: gst_igst_name.trim(),
        gst_igst: gst_igst_decimal.trim(),
        gst_cgst_name: gst_cgst_name.trim(),
        gst_cgst: gst_cgst_decimal.trim(),
        sgst_name: sgst_name.trim(),
        gst_sgst: gst_sgst_decimal.trim(),
    } );
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) => { foc.current.classList.remove( 'is-invalid' ); };
    const removeallinvalid = () =>
    {
        gstName.current.classList.remove( 'is-invalid' );
        igstNameRef.current.classList.remove( 'is-invalid' );
        cgstNameRef.current.classList.remove( 'is-invalid' );
        sgstNameRef.current.classList.remove( 'is-invalid' );
        igstper.current.classList.remove( 'is-invalid' );
        cgstper.current.classList.remove( 'is-invalid' );
        sgstper.current.classList.remove( 'is-invalid' );
    };

    function SubmitForm( e )
    {
        e.preventDefault();
        setError( '' );
        setError( '' );
        const trimmedgst_type = emtySelect( gst_type.trim(), 'GST Category' );
        const trimmedName = maxlength( gst_name.trim(), 'Name', 50 );

        if ( trimmedgst_type !== true ) { setError( trimmedgst_type ); foCoUs( gstType ); return false; }
        else if ( trimmedName !== true ) { setError( trimmedName ); foCoUs( gstName ); return false; }
        else if ( gst_type == 'TX' ) { TX(); }
        else if ( gst_type == 'EX' ) { EX(); }
        else
        {

        }
    }

    const TX = () =>
    {
        const igstName = maxlength( gst_igst_name.trim(), 'IGST Name', 50 );
        const cgstName = maxlength( gst_cgst_name.trim(), 'CGST Name', 50 );
        const sgstName = maxlength( sgst_name.trim(), 'SGST Name', 50 );
        const igstperr = maxlength( gst_igst.trim(), 'IGST%', 50 );
        const cgstperr = maxlength( gst_cgst.trim(), 'CGST%', 50 );
        const sgstperr = maxlength( gst_sgst.trim(), 'SGST%', 50 );

        if ( igstName !== true ){ setError( igstName ); foCoUs( igstNameRef );   return false;} 
        else if ( igstperr !== true ) { setError( igstperr ); foCoUs( igstper ); return false; } 
        else if ( gst_igst.trim() === '0' || gst_igst.trim() === '00' || gst_igst.trim() === '.0' ) { setError( 'Please fill Value should be greater than 0 !' ); foCoUs( igstper ); return false; } 
        else if ( cgstName !== true ) {setError( cgstName ); foCoUs( cgstNameRef );  return false; } 
        else if ( cgstperr !== true ) {setError( cgstperr ); foCoUs( cgstper ); return false; } 
        else if ( gst_cgst.trim() === '0' || gst_cgst.trim() === '000' || gst_cgst.trim() === '.0'  ) {setError( 'Please fill Value should be greater than 0 !' ); foCoUs( cgstper ); return false; } 
        else if ( sgstName !== true ) {setError( sgstName ); foCoUs( sgstNameRef );return false;} 
        else if ( sgstperr !== true ){setError( sgstperr ); foCoUs( sgstper );  return false;}
        else if ( gst_sgst.trim() === '0' || gst_sgst.trim() === '000' || gst_sgst.trim() === '.0'  ) {setError( 'Please fill Value should be greater than 0 !' ); foCoUs( sgstper ); return false; } 
        else { SaveRequest(); }
    };

    const EX = () =>
    {
        const igstName = maxlength( gst_igst_name.trim(), 'IGST Name', 50 );
        const cgstName = maxlength( gst_cgst_name.trim(), 'CGST Name', 50 );
        const sgstName = maxlength( sgst_name.trim(), 'SGST Name', 50 );

        if ( igstName !== true )
        {
            setError( igstName );
            foCoUs( igstNameRef );
            return false;
        } else if ( cgstName !== true )
        {
            setError( cgstName );
            foCoUs( cgstNameRef );
            return false;
        } else if ( sgstName !== true )
        {
            setError( sgstName );
            foCoUs( sgstNameRef );
            return false;
        }
        else { SaveRequest(); }
    }
    const SaveRequest = () =>
    {
        axios
            .patch( `${ BaseUrl }gsttype/${ id }`, GstData(), { mode: 'cors' } )
            .then( function ( response )
            {
                const data = response.data;
                if ( data.success === true )
                {
                    toast.success( 'Update GST Type Success' );
                    const LastLink = getLastLinkInSession();
                    if ( LastLink === 'edit_gst' )
                    {
                        handleSplitLastLink();
                        navigateelast();
                    } else
                    {
                        handleSplitLastLink();
                        EditChangeData( gst_name, data.Newid ); // Change Last Open Popup Data or fild
                        navigateelast();
                    }
                }
            } )
            .catch( function ( error )
            {
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors ); foCoUs( gstName );
            } );
    }
    const inputValue = ( e ) =>
    {

        const { name, value } = e.target;
        if ( name === 'gst_type' )
        {
            const gst_type_value = value;
            set_gst_type( value );
            if ( gst_type_value == 'EX' )
            {
                setIsDisabled( true );
                set_gst_igst( '' );
                set_gst_cgst( '' );
                set_gst_sgst( '' );
            }
            else { setIsDisabled( false ); }
            removeinvalid( gstType );
        }
    }
    const accept = () =>
    {
        splitLastInWitchD();
        splitLastInWitchF();
        handleSplitLastLink();
        navigateelast();
        // if ( currentUrl === '/gst/add' )
        // {
        //     navigate( '/gst' );
        // }
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    const changeValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'gst_name' ) { var valuee = validInput( value ); set_gst_name( valuee ); removeinvalid( gstName ); }
        if ( name === 'gst_igst_name' ) { var valuee = validInput( value ); set_gst_igst_name( valuee ); removeinvalid( igstNameRef ); }
        if ( name === 'gst_cgst_name' ) { var valuee = validInput( value ); set_gst_cgst_name( valuee ); removeinvalid( cgstNameRef ); }
        if ( name === 'gst_sgst_name' ) { var valuee = validInput( value ); set_sgst_name( valuee ); removeinvalid( sgstNameRef ); }
        if ( name === 'gst_igst' )
        {
            const processedValue = removeLeadingZero( value );
            const validationError = twoDecimal( processedValue );
            if ( validationError !== null )
            {
                set_gst_igst( processedValue );
                const formattedValue = addtwoDecimal( processedValue );
                set_gst_igst_decimal( formattedValue );
            } removeinvalid( igstper );
        }
        if ( name === 'gst_cgst' )
        {
            const processedValue = removeLeadingZero( value );
            const validationError = threeDecimal( processedValue );
            if ( validationError != null )
            {
                set_gst_cgst( processedValue ); const formattedValue = addthreeDecimal( processedValue );
                set_gst_cgst_decimal( formattedValue );
            } removeinvalid( cgstper );
        }
        if ( name === 'gst_sgst' )
        {
            const processedValue = removeLeadingZero( value );
            const validationError = threeDecimal( processedValue );
            if ( validationError != null )
            {
                set_gst_sgst( processedValue );
                const formattedValue = addthreeDecimal( processedValue );
                set_gst_sgst_decimal( formattedValue );

            }
            removeinvalid( sgstper );
        }

    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     {
    //         if ( event.key === 'Escape' )
    //         {
    //             confirm( 'top' );
    //         }
    //     };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () =>
    //     {
    //         document.removeEventListener( 'keydown', handleKeyDown );
    //     };
    // }, [] );
    return (
        <>
            <div className="p-2 mt-2 main"> <DynamicTitleComponent newTitle={ `Edit GST Type ` } />
                <div className="row">
                    <div className="col-md-6">
                        <div className='card '>
                            <div className="card-header"> <h4>Edit GST Type</h4> </div>


                            <form className="form-horizontal" onSubmit={ SubmitForm }>
                                <div className="card-body">
                                    <div className=" row">
                                        <>
                                            <div className="col-md-12 col-sm-4">
                                                <label htmlFor="gst_type" className="  ">
                                                    GST Type
                                                    <span className="text-danger"><sup>*</sup></span>
                                                </label><span className='float-end' style={ { color: 'red' } }> { error }</span>
                                                <select
                                                    className="form-control form-control-sm form-select"
                                                    style={ { width: "100%" } }
                                                    name="gst_type"
                                                    id="gst_type"
                                                    placeholder="select"
                                                    autoFocus
                                                    onChange={ inputValue }
                                                    value={ gst_type }
                                                    ref={ gstType }
                                                    required
                                                    disabled={ sysDisable }
                                                >
                                                    <option value={ 'TX' } >Taxable</option>
                                                    <option value={ 'EX' }>Exempted</option>
                                                </select>
                                            </div>
                                            <div className="col-md-12 col-sm-8">
                                                <label htmlFor="gst_name" className="   ">
                                                    GST
                                                    <span className="text-danger"> <sup>*</sup></span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    name="gst_name"
                                                    value={ gst_name }
                                                    onChange={ changeValue }
                                                    placeholder="Name"
                                                    ref={ gstName }

                                                />
                                            </div>
                                            <div className="col-md-8 col-sm-8 ">
                                                <label htmlFor="gst_igst_name" className="    ">
                                                    IGST
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    name="gst_igst_name"
                                                    placeholder="Name"
                                                    value={ gst_igst_name }
                                                    ref={ igstNameRef }
                                                    onChange={ changeValue }
                                                />
                                            </div>
                                            <div className="col-md-4 col-sm-4 ">
                                                <label htmlFor="gst_igst" className="    ">
                                                    IGST %
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="gst_igst"
                                                    disabled={ isDisabled || sysDisable }
                                                    value={ gst_igst }
                                                    ref={ igstper }
                                                    onChange={ changeValue }
                                                />

                                            </div>
                                            <div className="col-md-8 col-sm-8 ">
                                                <label htmlFor="gst_cgst_name" className="    ">
                                                    CGST
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm  "
                                                    id="gst_cgst_name"
                                                    name="gst_cgst_name"
                                                    placeholder="Name"
                                                    value={ gst_cgst_name }
                                                    ref={ cgstNameRef }
                                                    onChange={ changeValue }
                                                />
                                            </div>
                                            <div className="col-md-4 col-sm-4">
                                                <label htmlFor="gst_cgst" className="    ">
                                                    CGST %
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="gst_cgst"
                                                    onChange={ changeValue }
                                                    value={ gst_cgst }
                                                    disabled={ isDisabled || sysDisable }
                                                    ref={ cgstper }
                                                />
                                            </div>
                                            <div className="col-md-8 col-sm-8">
                                                <label htmlFor="sgst_name" className="      ">
                                                    SGST
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm  "
                                                    id="sgst_name"
                                                    name="gst_sgst_name"
                                                    placeholder="Name"
                                                    value={ sgst_name }
                                                    ref={ sgstNameRef }
                                                    onChange={ changeValue }
                                                />
                                            </div>
                                            <div className="col-md-4 col-sm-4">
                                                <label htmlFor="gst_sgst" className="      "> SGST %</label>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    name="gst_sgst"
                                                    onChange={ changeValue }
                                                    value={ gst_sgst }
                                                    ref={ sgstper }
                                                    disabled={ isDisabled || sysDisable }
                                                />
                                            </div>
                                        </>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitForm }>Update</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
                <Toast ref={ toastt } />
                <ConfirmDialog />
            </div>
        </>
    );
}

export default EditGst;

