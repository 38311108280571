import React, { useEffect } from 'react';

const DynamicTitleComponent = ({ newTitle }) => {
  useEffect(() => {
    // Change the title when the component mounts or when newTitle changes
    document.title = newTitle;

    // Optionally, you can reset the title when the component unmounts
    return () => {
      document.title = 'FactBook App';
    };
  }, [newTitle]); // Only re-run the effect if newTitle changes

  return (
    <div>
    
    </div>
  );
};

export default DynamicTitleComponent;
