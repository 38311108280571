// import React from 'react';
// import { Link, useLocation } from 'react-router-dom';

// const Breadcrumb = () => {
//   const location = useLocation();
//   const paths = location.pathname.split('/').filter(path => path !== '');

//   return (
//     <span>
//       <ol className="breadcrumb mb-1 rounded-1 px-2 py-1 OpenSans-Regular" style={{backgroundColor:'#0A4B82',color:'#fff'}}>
//         <li className="breadcrumb-item">
//           <span to="/" className='text-light'>Home</span>
//         </li>
//         {paths.map((path, index) => (
//           <li key={index} className="breadcrumb-item">
//             {index !== paths.length - 1 ? (
//                <span>{path}</span>
//             ) : (
//               <span>{path}</span>
//             )}
//           </li>
//         ))}
//       </ol>
//     </span>
//   );
// };

// export default Breadcrumb;
import React, { useEffect, useState } from 'react';

function Breadcrumbs()
{
  const [ breadcrumbTrail, setBreadcrumbTrail ] = useState( [] );

  useEffect( () =>
  {
    // Step 1: Define a function to retrieve links from session storage
    const retrieveLinksFromStorage = () =>
    {
      const linksFromStorage = sessionStorage.getItem( 'links' );

      if ( linksFromStorage )
      {
        const linksArray = JSON.parse( linksFromStorage );
        const breadcrumbNames = {
          //Edit 
          'cityedit': 'City',

          'sellerslip_edit': 'Seller Slip Expenses Profile',
          'edit_expenses': 'Expenses',
          'edit_account': 'Account',
          'edit_goods': 'Goods',
          'edit_group': 'Group',
          'edit_zone': 'Zone',
          'edit_gst': 'GST Type',
          'edit_cess': 'Cess',
          'edit_hsnsac': 'HSN-SAC',
          'edit_packing': 'Packing',
          'edit_manufacturer': 'Manufacturer',
          'edit_stockgroup': 'Stock Group',
          'edit_stocksubgroup': 'Stock Sub Group',
          'edit_godown': 'Godown',
          'edit_broker': 'Broker',
          'edit_transport': 'Transport',
          'edit_batchlot': 'Batch-lot',
          'edit_invoceother': 'Invoice Other',
          'edit_capitalgoodsservices': 'Capital Goods Services',
          'edit_tdsST': 'TDS Section Type',
          'edit_tdsparameter': 'TDS Parameters',
          'edit_tdspartywise': 'Party Wise TDS Percentage',
          'edit_documentseries': 'Document Series',
          'edit_vouchernarration': 'Voucher Narration',
          'edit_sellerslipexpensesprofile': 'Seller Slip Expenses Profile',
          //Add
          'maincityadd': ' ',
          'maincityedit': 'City',
          'cityadd': 'City',
          //Account
          'addaccount_m': 'Account',
          'addaccount': 'Account',
          'accounts': 'Account',
          //goods
          'add_goods_m': ' ',
          'addgoods': 'Goods',
          'goods': 'Goods',
          //Zone
          'addzone_m': ' ',
          'addzone': 'Zone',
          'zone': 'Zone',
          //Group
          'addgroup_m': ' ',
          'addgroup': 'Group',
          'group': 'Group',
          //seller slip
          'seller-slip-expenses-profile': 'Seller slip expenses profile',
          'add_sellerslipexpensesprofile_m': ' ',
          'add_sellerslipexpensesprofile': 'Seller slip expenses profile',
          //Expenses
          'expenses': 'Expenses',
          'add_expenses_m': ' ',
          'add_expenses': 'Expenses',
          //Section Code
          //GST
          'gst': 'GST Type',
          'add_gst_m': ' ',
          'add_gst': 'GST Type',
          //Cess
          'cess': 'Cess',
          'add_cess_m': '',
          'add_cess': 'Cess',
          //HSN SAC
          'hsn-sac': 'HSN-SAC',
          'add_hsnsac_m': ' ',
          'add_hsnsac': 'HSN-SAC',
          //Packing 
          'packing': 'Packing',
          'add_packing_m': ' ',
          'add_packing': 'packing',
          //manufacturer 
          'manufacturer': 'Manufacturer',
          'add_manufacturer_m': ' ',
          'add_manufacturer': 'Manufacturer',
          //Stock Group 
          'stock-group': 'Stock Group',
          'add_stockgroup_m': ' ',
          'add_stockgroup': 'Stock Group',
          //Stock Sub Group 
          'stock-sub-group': 'Stock Sub Group',
          'add_stocksubgroup_m': ' ',
          'add_stocksubgroup': 'Stock Sub Group',
          //Godown 
          'godown': 'Godown',
          'add_godown_m': ' ',
          'add_godown': 'Godown',
          //Broker 
          'broker': 'Broker',
          'add_broker_m': ' ',
          'add_broker': 'Broker',
          //Transport 
          'transport': 'Transport',
          'add_transport_m': ' ',
          'add_transport': 'Transport',
          //Goods Batch Lot
          'batchlot': 'Batch-lot',
          'add_batchlot_m': ' ',
          'add_batchlot': 'Batch-lot',
          //Invoice Other Detail
          'invoice': 'Invoice Other',
          'add_invoceother_m': ' ',
          'add_invoceother': 'Invoice Other',
          //Capital Goods/Services
          'capital-goods-services': 'Capital Goods Services',
          'add_capitalgoodsservices_m': ' ',
          'add_capitalgoodsservices': 'Capital Goods Services',
          //TDS Section Type
          'tds-section-type': 'TDS Section Type',
          'add_tdsST_m': ' ',
          'add_tdsST': 'TDS Section Type',
          //TDS Section Type
          'tds-parameter': 'TDS Parameters',
          'add_tdsparameter_m': ' ',
          'add_tdsparameter': 'TDS Parameters',
          //TDS Party Wise Persentage 
          'party-wise-tds-percentage': 'Party Wise TDS Percentage',
          'add_tdspartywise_m': ' ',
          'add_tdspartywise': 'Party Wise TDS Percentage',
          //Document Series
          'document-series': 'Document Series',
          'add_documentseries_m': ' ',
          'add_documentseries': 'Document Series',
          //Document Series
          'voucher-narration': 'Voucher Narration',
          'add_vouchernarration_m': ' ',
          'add_vouchernarration': 'Voucher Narration',
        };

        // Split the 'add_sellerslipexpensesprofile' into two parts
        const updatedBreadcrumbNames = { ...breadcrumbNames };
        updatedBreadcrumbNames[ 'add_sellerslipexpensesprofile_m' ] = ''; // You can choose a different name if needed

        const breadcrumbTrailArray = linksArray.map( ( link ) => updatedBreadcrumbNames[ link ] || link );
        setBreadcrumbTrail( breadcrumbTrailArray );
      }
    };

    // Initial retrieval of links when the component mounts
    retrieveLinksFromStorage();

    // Set up an interval to retrieve links every 10 seconds (10000 milliseconds)
    const intervalId = setInterval( retrieveLinksFromStorage, 100);

    // Clean up the interval when the component unmounts
    return () => clearInterval( intervalId );
  }, [] );

  return (
    <div className="breadcrumb mb-1 rounded-1 px-2 py-1 OpenSans-Regular" style={ { backgroundColor: '#0A4B82', color: '#fff' } }>
      <span>Home /</span> { breadcrumbTrail.map( ( link, index ) => (
        <span key={ index }>
          { index > 0 && ' / ' }
          { link }
        </span>
      ) ) }
    </div>
  );
}

export default Breadcrumbs;


