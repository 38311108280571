import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

import { addtwoDecimal, addthreeDecimal, removeLeadingZero, threeDecimal, twoDecimal, emtySelect, maxlength, validInput, Star } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import { toast } from 'react-toastify';
import CommonFunctions from '../../../../Common/CommonFunction';

const AddStockSubGroup = ( e ) =>
{

    const toastt = useRef( null );
    const { handleSplitLastLink, getLastLinkInSession, navigateelast } = useLinkManagement();
    const { AddChangeData, EditChangeData, setInWhitchData, splitLastInWitchD, splitLastInWitchF } = CommonFunctions();
    const [ stck_group_name, set_name ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const namee = useRef();
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };
    const StockGroupData = () => ( {
        name: stck_group_name.trim()
    } );
    function SubmitForm( e )
    {
        e.preventDefault();
        setError( '' );
        const trimmedname = maxlength( stck_group_name.trim(), 'stock Sub group name', 50 );
        if ( trimmedname !== true )
        {
            setError( trimmedname );
            foCoUs( namee );
            return false;
        } else 
        {
            axios
                .post( `${ BaseUrl }stocksubgroup`, StockGroupData(), { mode: 'cors' } )
                .then( function ( response )
                {
                    const data = response.data;
                    if ( data.success === true )
                    {
                        // const LastLink = getLastLinkInSession();
                        // if ( LastLink === 'add_stocksubgroup_m' )
                        // {
                        //     setError( '');
                        //     set_name( '' );
                        //     removeinvalid(namee);
                        // }else{
                        //     handleSplitLastLink();
                        //         const LastLink = getLastLinkInSession();
                        //         if ( LastLink === 'add_goods_m' || LastLink === 'addgoods' || LastLink === "edit_goods" )
                        //         {
                        //             const goodsData = sessionStorage.getItem( 'goods_data' );
                        //             if ( goodsData !== '' || goodsData !== null || goodsData !== undefined )
                        //             {
                        //                 const d = goodsData ? JSON.parse( goodsData ) : {};
                        //                 const value = data.Newid;
                        //                 const label = stck_group_name;
                        //                     d.StoksubGroup = { value, label };
                        //                     sessionStorage.setItem( 'goods_data', JSON.stringify( d ) );
                        //             }
                        //         }
                        //         navigateelast();
                        // }

                        toast.success( 'Add Stock Sub Group Success' );
                        const LastLink = getLastLinkInSession();
                        if ( LastLink === 'add_stocksubgroup_m' ) { setError( '' ); set_name( '' ); removeinvalid( namee ); }
                        else
                        {
                            handleSplitLastLink();
                            AddChangeData( stck_group_name, data.Newid ); // Change Last Open Popup Data or fild
                            navigateelast();
                        }

                    }
                } )
                .catch( function ( error )
                {
                    var errrr = error.response;
                    const errors = GlobalError( errrr );
                    setError( errors ); foCoUs( namee );
                } );
        }
    }
    const changeValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'stck_group_name' ) { var valuee = validInput( value ); set_name( valuee ); removeinvalid( namee ); }
    };
    const accept = () =>
    {
        const LastLink = getLastLinkInSession();
        if ( LastLink === 'add_stocksubgroup_m' )
        {
            splitLastInWitchD( 'f' );
            splitLastInWitchF( 'f' );
        }
        splitLastInWitchD();
        splitLastInWitchF();
        handleSplitLastLink();
        navigateelast();
        // if ( currentUrl === '/stock-group/add' ){navigate( '/stock-group' ); }
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     {
    //         if ( event.key === 'Escape' )  {  confirm( 'top' ); }};
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () => {document.removeEventListener( 'keydown', handleKeyDown ); };
    // }, [] );
    return (
        <>
            <div className="p-2 mt-2 main"> <DynamicTitleComponent newTitle={ `Add Stock Sub Group ` } />
                <div className="row">
                    <div className="col-md-6">

                        <div className='card'>
                            <div className="card-header"><h4>Add Stock Sub Group</h4> </div>
                            <form className="form-horizontal" onSubmit={ SubmitForm } autoComplete='off'>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className=" row">
                                                <div className="col-md-12 col-sm-12">
                                                    <>
                                                        <label htmlFor="stck_group_name" className="">Stock Sub Group </label><Star />
                                                        <span className='float-end' style={ { color: 'red' } }> { error }</span>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-sm "
                                                            name="stck_group_name"
                                                            placeholder="Name"
                                                            value={ stck_group_name }
                                                            ref={ namee }
                                                            autoFocus
                                                            onChange={ changeValue }
                                                        />
                                                    </>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitForm }>Save</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Toast ref={ toastt } />
                <ConfirmDialog />
            </div>
        </>
    );
}

export default AddStockSubGroup;

