import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
// import { Toast } from 'primereact/toast';
import CryptoJS from 'crypto-js';
import { BrowserRouter as Router, Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import Select from 'react-select';
import { emtySelect, maxlength } from '../../../../validations/validations'
import customStyles from '../../../../validations/customStyles';
import Baseurl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import { GlobalArray, GlobalError } from '../../../../validations/globalArray';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent';
import CheckAuth from '../../../../APIHelper/CheckAuth';
import { Loaderr } from '../../../../CommonComponents/Loader';
import { toast } from 'react-toastify';


function EditGroup()
{

    const { handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast } = useLinkManagement();
    const { handleLogout, setLoggedIn, isLoggedInToken, isLoading, setIsLoading } = CheckAuth();
    // const { id } = useParams();
    const [ id, SetId ] = useState();
    const BaseUrl = Baseurl;
    // const toast = useRef( null );
    const location = useLocation();
    const EditUrl = '/group/edit/' + id + '';
    const currentUrl = location.pathname;
    const navigate = useNavigate();
    const gp_nameRef = useRef();
    const primaryRef = useRef();
    const [ isDisabled, setIsDisabled ] = useState( false );
    const [ group_name, set_Group_name ] = useState( '' );
    const [ primary_group, set_Primary_group ] = useState( '' );
    const [ group_effect_type, set_group_effect_type ] = useState( '' );
    const [ edit_group_id, set_Edit_group_id ] = useState( '' );
    const [ error, setError ] = useState( '' );

    // Options 
    const [ Effectoptins, set_Effectoptins ] = useState( '' );

    useEffect( () =>
    {
        
        const fetchEfect = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }primegrouptypes`, { mode: 'cors' } );
                const data = response.data;
                const mappedOptions = data.map( item => ( { value: item.id, label: item.groupName, effectType: item.effectType } ) );
                set_Effectoptins( mappedOptions );
            } catch ( error )
            {
                console.error( 'Error fetching states:', error );
            }
        };

        fetchEfect();
    }, [] );
    const SavegroupValue = ( selected ) =>
    {
        setError( '' );
        set_Primary_group( selected );
        const pgvalue = selected ? selected.effectType : '';
        const group_value = GlobalArray( pgvalue );
        if ( group_value !== null ) { set_group_effect_type( group_value ); }
        else { set_group_effect_type( '' ); }
    };
    useEffect( () =>
    {let editID = sessionStorage.getItem( 'editgroupID' );
    SetId( editID );
        const fetchData = async () =>
        {
            const Token = localStorage.getItem( 'token' );
            setIsLoading( true );
            try
            {
                const response = await axios.get( `${ BaseUrl }group/${ editID }`, { headers: { Authorization: `Bearer ${ Token }` }, mode: 'cors' } );
                const editGroupData = response.data[ 0 ];
                const data = response.data[ 0 ];
                if ( data.status === false )
                {
                    if ( data.statusCode == '401' )
                    {
                        alert( data.message );
                        handleLogout();
                        setLoggedIn( false );
                    }
                    if ( data.statuscode == '406' )
                    {
                        toast.error( data.message );
                    }
                    toast.error( data.message );
                    setIsLoading( false );
                }
                set_Edit_group_id( editGroupData.id );
                const checkid = editGroupData.id;
                if ( checkid === '' || checkid === null ) { navigate( '/group' ); }
                set_Group_name( editGroupData.groupName );
                set_Primary_group( { value: editGroupData.value, label: editGroupData.lable, effectType: editGroupData.effectType } );
                const pgvalue = editGroupData.effectType;
                const group_value = GlobalArray( pgvalue );
                if ( group_value !== null ) { set_group_effect_type( group_value ); }
                else { set_group_effect_type( '' ); }

                if ( currentUrl === '/group/edit/1' || currentUrl === '/accounts/add/group/1' )
                {
                    setIsDisabled( true );
                    set_group_effect_type( 'Balance Sheet' );
                }
                else { setIsDisabled( false ); }
                if ( editID === '1' )
                {
                    setIsDisabled( true );
                    set_group_effect_type( 'Balance Sheet' );
                } else { setIsDisabled( false ); }
                setIsLoading( false );
            }
            catch ( error )
            {
                var errrr = error.response;
                const errors = GlobalError( errrr );
                toast.error( errors );
                setIsLoading( false );
                handleSplitLastLink();
                navigateelast();
            }
        };
        fetchData();
    }, [] );
    const EditData = () =>
    {
        return {
            id: edit_group_id,
            group_name: group_name.replace( /\s{2,}/g, ' ' ).trim(),
            primary_group: primary_group.value,
            group_effect_type: primary_group.effectType,
        };
    };
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const Submitform = () =>
    {
        const primaryopt = emtySelect( primary_group, 'Primary Group' );
        const validation = maxlength( group_name, 'Group Name', );
        if ( currentUrl === '/group/edit/1' || currentUrl === '/accounts/add/group/1' )
        {
            setIsDisabled( true );
            if ( validation !== true )
            {
                setError( validation );
                gp_nameRef.current.focus();
                gp_nameRef.current.classList.add( 'is-invalid' );
                return false;
            }
            else { saveData() }
        } else
        {
            if ( validation !== true ) { setError( validation ); foCoUs( gp_nameRef ); return false; }
            else if ( primaryopt !== true )
            {
                setError( primaryopt );
                primaryRef.current.focus();
                return false;
            }
            else
            {
                saveData()
            }
        }
    };
    const saveData = () =>
    {
        // console.log(EditData());
        const Token = localStorage.getItem( 'token' );
        setIsLoading( true );
        axios
            .patch( `${ BaseUrl }group/${ id }`, EditData(), { headers: { Authorization: `Bearer ${ Token }` }, mode: 'cors' } )
            .then( function ( response )
            {
                const data = response.data;
                if ( data.success === true )
                {
                    sessionStorage.removeItem('editgroupID');
                    toast.success(  data.message );
                    // toast.current.show( { severity: 'success', summary: 'Success', detail: ' toast.error( data.message );', life: 2000, } );
                    const LastLink = getLastLinkInSession();
                    const Account_SS = sessionStorage.getItem( 'account_form' );
                    if ( Account_SS !== null && Account_SS !== '' && Account_SS !== undefined )
                    {
                        const d = Account_SS ? JSON.parse( Account_SS ) : {};
                        const value = d.group_name.value;
                        const label = group_name;
                        d.group_name = { value, label };
                        sessionStorage.setItem( 'account_form', JSON.stringify( d ) );
                    }
                    handleSplitLastLink();
                    navigateelast();
                    setIsLoading( false );
                }
                if( data.status === false){
                    if ( data.statusCode == '401' )
                    {
                        alert( data.message );
                        toast.error( data.message );
                        handleLogout();
                        setLoggedIn( false );
                    }
                }
            } )
            .catch( function ( error )
            {
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors );
                gp_nameRef.current.focus();
                setLoggedIn( false );
            } );

    }
    const ReplaceLabel = () =>
    {
        const storedData = sessionStorage.getItem( 'account_form' );
        const data = storedData ? JSON.parse( storedData ) : {};
        const value = data.ac_group_name.value;
        const label = group_name; // Replace with the desired label value
        data.ac_group_name = { value, label };
        sessionStorage.setItem( 'account_form', JSON.stringify( data ) );
        navigate( '/accounts/add' );
    };
    const cancleUpdate = async () =>
    {
        const Token = localStorage.getItem( 'token' );
        try
        {
            const response = await axios.get( `${ BaseUrl }canclgroup`, { headers: { 'Authorization': `Bearer ${ Token }` } }, { mode: 'cors' } );
            const editGroupData = response.data[ 0 ];
        } catch ( error )
        {
            console.error( 'Error:', error );
            toast.error( error );
        }
    };
    const accept = () =>
    {
        handleSplitLastLink();
        navigateelast();
        cancleUpdate();
        sessionStorage.removeItem('editgroupID');
    }
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to Leaves this Page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept
        } );
    };
    const ChangeValue = ( e ) =>
    {
        const { name, value } = e.target;
        if ( name === 'group_name' ) { set_Group_name( value ); setError( '' ); gp_nameRef.current.classList.remove( 'is-invalid' ); }
    }
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) => { if ( event.key === 'Escape' ) { confirm( 'top' ); } };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () => { document.removeEventListener( 'keydown', handleKeyDown ); };
    // }, [] );
    return (
        <>
            <div >
                <DynamicTitleComponent newTitle={ `Edit Group - ${ group_name }` } />
                { isLoading ? ( <Loaderr/> ) : ( '' ) }
                <ConfirmDialog />
            </div>
            <div className="p-2 mt-2 main">
                <div className="row">
                    <div className="col-md-6">
                        <div className='card '>
                            <div className='pt-2 card-header'><h4>Edit Group </h4></div>
                            <form className="form-horizontal"  >
                                <div className="card-body   ">
                                    <div className="row">
                                        <div className="col-md-12 pb-1 ">
                                            <div className=" row">

                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="group_name" className="modal-label">Group Name</label>
                                                    <span className='float-end' style={ { color: 'red' } }> { error }</span>
                                                    <input type="text" className="form-control form-control-sm  " id="group_name" name="group_name" placeholder="Group Name" value={ group_name } maxLength={ '50' } autoFocus ref={ gp_nameRef } onChange={ ChangeValue } />

                                                </div>
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="primary_group" className=" ">Primary Group</label>
                                                    <Select
                                                        id="primary_group"
                                                        options={ Effectoptins }
                                                        value={ primary_group }
                                                        onChange={ SavegroupValue }
                                                        styles={ customStyles }
                                                        isClearable
                                                        className='custom-disabled-select'
                                                        isDisabled={ isDisabled }
                                                        ref={ primaryRef }
                                                        isSearchable
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="group_effect_type" className=" ">Effect type</label>
                                                    <input type="text" value={ group_effect_type } className="form-control form-control-sm" id="group_effect_type" name="group_effect_type" placeholder="Effect type" disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4 " type='button' onClick={ Submitform }>Update</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>
                        </div>


                    </div>

                </div>
            </div>

        </>
    )

}

export default EditGroup
