import axios from 'axios';
import BaseUrl from '../BaseUrl';
import CheckAuth from './CheckAuth';

const CheckApi = () => {
  const { isLoggedInToken } = CheckAuth();

  async function updateUser() {
    const Token = localStorage.getItem( 'token' );
    try {
      const response = await axios.post(`${BaseUrl}/updateid`, null, {
        headers: {
          'Authorization': `Bearer ${Token}`,
        },
        mode: 'cors',
      });

      const data = response.data;
      if (data.success === true) {
        console.log('success.');
      } else {
        console.log('unsuccess.');
      }
    } catch (error) {
      var errrr = error.response;
      console.error(errrr);
    }
  }

  return updateUser; // Return the function without immediately invoking it
};

export default CheckApi;


