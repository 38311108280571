
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "primereact/resources/primereact.min.css";
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import "primeicons/primeicons.css";
import "./App.css"
import ReactDOM from 'react-dom';
import MasterRoute from './MasterRoute';
import Sidebar from './CommonComponents/Sidebar';
import Navbar from './CommonComponents/Navbar';
import Breadcrumb from './CommonComponents/Breadcrum';
import Alertt from './CommonComponents/Alertt';
import Login from './CommonComponents/Login';
import Popup from './CommonComponents/Popup';
import { updateUserId } from './APIHelper/Common';
import Registration from './CommonComponents/Registration';
import LoginLogout from './CommonComponents/LoginLogut';
import CheckAuth from './APIHelper/CheckAuth';
import { Toaster, toast } from 'react-hot-toast';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFound from './404/NotFound';
import StopBrowserBackButton from './CommonComponents/StopBrowserBackButton';
import Loaderr from './CommonComponents/Loader';
import BaseUrl from './BaseUrl';
import axios from 'axios';
const App = () =>
{
  // const { ,  }=LoginLogout();
  const { handleLogout, loggedIn, setLoggedIn, handleLogin, isLoading, setIsLoading ,updateSession } = CheckAuth();
  const [ isModalOpen, setIsModalOpen ] = useState( false );
  useEffect(() => {
    window.history.forward(); // Attempt to disable the back button
  }, []);
  useEffect( () =>
  {
    const storedLoggedIn = localStorage.getItem( 'loggedIn' ) === 'true';
    const fetchData = async () =>
  {
    setIsLoading( true );
    const Token = localStorage.getItem( "token" );
    try
    {
      const response = await axios.get( '' + BaseUrl + 'user', { headers: { Authorization: `Bearer ${ Token }` } } );
      const data = response.data;
      if ( data.status === false )    // server return -> status false
      {
        if ( data.statusCode == '401' ) // server error unauthorized access or token is invalid
        {
          handleLogout();              // user logged out . Auto
          setLoggedIn( false );        // loader off S.
          setIsLoading( false );       // loader off M.
        }
        toast.error( data.message );
      }
      console.log( response.data );
      setIsLoading( false ); // set is loading created by me
    } catch ( error )
    {
      setIsLoading(false);
       handleLogout(); 
    }
  };

    if ( storedLoggedIn )
    { fetchData();
      setLoggedIn( true );
      setIsModalOpen( false );
    }
    // else { handleLogout(); }
  }, [] );

  const openModal = () => setIsModalOpen( true );
  const closeModal = () => setIsModalOpen( false );
  return (
    <BrowserRouter >
      <Toaster position="top-center" />
      <ToastContainer
        position="top-right"
        // autoClose={ 5000 } // Close the alert after 5 seconds (adjust as needed)
        hideProgressBar={ false }
        newestOnTop={ true }
        closeOnClick
        rtl={ false }
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      { loggedIn ? (
        <div className="wrapper d-flex align-items-stretch">
          <Sidebar />
          <div id="content" className="p-2">
            {/* <button onClick={checkLoggedInUser}>hfgyf</button> */ }
            <button onClick={ openModal } style={ { float: 'right', background: 'transparent', border: '0px', padding: '3px 20px' } }><svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3V12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12V3ZM8.6092 5.8744C9.09211 5.60643 9.26636 4.99771 8.99839 4.5148C8.73042 4.03188 8.12171 3.85763 7.63879 4.1256C4.87453 5.65948 3 8.61014 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 8.66747 19.1882 5.75928 16.5007 4.20465C16.0227 3.92811 15.4109 4.09147 15.1344 4.56953C14.8579 5.04759 15.0212 5.65932 15.4993 5.93586C17.5942 7.14771 19 9.41027 19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 9.3658 6.45462 7.06997 8.6092 5.8744Z" fill="#fff" />
            </svg></button>
            <Popup isOpen={ isModalOpen } onClose={ closeModal } onLogout={ handleLogout } />
            {/* <button className='btn ' onClick={handleLogout}>LogOut</button> */ }
            <Navbar />
            <Breadcrumb />

            { isLoading ? ( <Loaderr/> ) : ( '' ) }
            <MasterRoute />
            <Alertt />
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/" element={ <Login onLogin={ handleLogin } /> } />
          <Route path="/register" element={ <Registration /> } />
          <Route path='*' element={<NotFound /> }/>
          {/* <Route path='*' element={<NotFound/>}/> */}
          {/* <Route path="/*"element={ <Navigate to="/" /> }/> */}
        </Routes>
      ) }
      <StopBrowserBackButton/>
    </BrowserRouter>
  );
};

export default App;


