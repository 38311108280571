import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { toast } from 'react-toastify';
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { addtwoDecimal, addthreeDecimal, removeLeadingZero, threeDecimal, twoDecimal, emtySelect, maxlength, validInput, Star } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import CommonFunctions from '../../../../Common/CommonFunction';
const AddPacking = ( e ) =>
{
    const toastt = useRef( null );
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, navigatefirstlink } = useLinkManagement();
    const { AddChangeData, EditChangeData, setInWhitchData, splitLastInWitchD, splitLastInWitchF } = CommonFunctions();
    const [ packing_name, set_packing_name ] = useState( '' );
    const [ print_name, set_print_name ] = useState( '' );
    const [ qty, set_qty ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const namee = useRef();
    const print = useRef();
    const qtyid = useRef();

    const PackingData = () => ( {
        packing_name: packing_name.trim(),
        packing_print_name: print_name.trim(),
        qty_in_pack: qty.trim(),
    } );
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };
    const removeallinvalid = () =>
    {
        namee.current.classList.remove( 'is-invalid' );
        print.current.classList.remove( 'is-invalid' );
        qtyid.current.classList.remove( 'is-invalid' );
    };
    function SubmitForm( e )
    {
        e.preventDefault();
        setError( '' );
        const trimmedname = maxlength( packing_name.trim(), 'packing name', 50 );
        const trimmedprint = maxlength( print_name.trim(), 'print name', 50 );
        if ( trimmedname !== true )
        {
            setError( trimmedname );
            foCoUs( namee );
            return false;
        }
        else if ( trimmedprint !== true )
        {
            setError( trimmedprint );
            foCoUs( print );
            return false;
        } else 
        {
            axios
                .post( `${ BaseUrl }packing`, PackingData(), { mode: 'cors' } )
                .then( function ( response )
                {
                    const data = response.data;
                    if ( data.success === true )
                    { toast.success( 'Add Packing Success' );
                        const LastLink = getLastLinkInSession();
                        if ( LastLink === 'add_packing_m' )
                        {
                            setError( '' );removeallinvalid(); set_packing_name( '' ); set_print_name( '' ); set_qty( '' ); namee.current.focus();
                            // toastt.current.show( {severity: 'success',summary: 'Success', detail: 'Add Packing Success', life: 2000, } );
                        } else
                        {
                            handleSplitLastLink();
                            const LastLinkk = getLastLinkInSession();
                            if (LastLinkk === 'add_batchlot_m' || LastLinkk === 'add_batchlot' || LastLinkk === "edit_batchlot"){
                                const batchlot = sessionStorage.getItem( 'batch_lot_data' );
                                if ( batchlot !== '' || batchlot !== null || batchlot !== undefined )
                                {
                                    const d = batchlot ? JSON.parse( batchlot ) : {};
                                    const value = data.Newid;
                                    const label = packing_name;
                                    d.batch_packing = { value, label };
                                    sessionStorage.setItem( 'batch_lot_data', JSON.stringify( d ) );
                                }
                            }
                            if (LastLinkk === 'add_opening_stock_m' || LastLinkk === 'add_opening_stock' || LastLinkk === "edit_opening_stock"){
                                const openingstockdata = sessionStorage.getItem('openingstock' );
                                // Parse 'packing index' from session storage (OSPackingfocus) use for pass the new packing selected index
                                const indexid = JSON.parse(sessionStorage.getItem('OSPackingfocus'));
                                if ( openingstockdata !== '' || openingstockdata !== null || openingstockdata !== undefined )
                                {
                                    const d = openingstockdata ? JSON.parse( openingstockdata ) : {};
                                    const expensesArray = d.expenses;
                                    // Define the new name value
                                const newNameValue = { 'label': packing_name, 'value': data.Newid };
                                if (indexid >= 0 && indexid < expensesArray.length) {
                                    // Modify the 'name' property of the selected expense
                                    expensesArray[indexid].packing = newNameValue;
                                }
                                d.expenses = expensesArray;
                                // Store the updated data back in session storage
                                sessionStorage.setItem('openingstock', JSON.stringify(d));
                                }
                            }
                            AddChangeData( packing_name, data.Newid ); // Change Last Open Popup Data or fild
                            navigateelast();
                        }

                        // if ( currentUrl === add_batch_packing ){  navigate( '/batchlot/add' );}
                    }
                } )
                .catch( function ( error )
                {
                    var errrr = error.response;
                    const errors = GlobalError( errrr );
                    setError( errors ); foCoUs( namee );
                } );
        }
    }
    const handleChange = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'packing_name' ) { var valuee = validInput( value ); set_packing_name( valuee ); set_print_name( valuee ); removeinvalid( namee ); }
        if ( name === 'packing_print_name' ) { var valuee = validInput( value ); set_print_name( valuee ); removeinvalid( print ); }
        if ( name === 'qty_in_pack' ) { var valuee = validInput( value ); set_qty( valuee ); removeinvalid( qtyid ); }
    };
    const accept = () =>
    {
        const LastLink = getLastLinkInSession();
        if ( LastLink === 'add_packing_m' ) { splitLastInWitchD( 'f' ); splitLastInWitchF( 'f' ); }
        splitLastInWitchD();
        splitLastInWitchF();
        handleSplitLastLink();
        handleSplitLastLink();
        navigateelast();
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     { if ( event.key === 'Escape' ) {  confirm( 'top' ); }};
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () => {document.removeEventListener( 'keydown', handleKeyDown ); };
    // }, [] );
    return (
        <>
            <div className="px-2 mt-2 main"><DynamicTitleComponent newTitle={ `Add Packing` } />
                <div className="row">
                    <div className="col-md-6">

                        <div className='card'>
                            <div className="card-header"><h4> Add Packing</h4></div>
                            <form className="form-horizontal" onSubmit={ SubmitForm }>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">

                                            <div className=" row">
                                                <div className="col-md-12 col-sm-12">
                                                    <label htmlFor="packing_name" className=" "> Packing Name</label><Star />
                                                    <span className='float-end' style={ { color: 'red' } }> { error }</span>
                                                    <input type="text" className="form-control form-control-sm " id="packing_name"
                                                        name="packing_name"
                                                        placeholder="Packing Name"
                                                        value={ packing_name }
                                                        ref={ namee }
                                                        autoFocus
                                                        onChange={ handleChange }
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="packing_print_name" className="">
                                                        Print Name
                                                        <span className="text-danger">
                                                            <sup>*</sup>
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        id="packing_print_name"
                                                        name="packing_print_name"
                                                        value={ print_name }
                                                        ref={ print }
                                                        onChange={ handleChange }
                                                        placeholder="Print Name"
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <label htmlFor="qty_in_pack" className="">
                                                        Quantity In Pack
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        id="qty_in_pack"
                                                        name="qty_in_pack"
                                                        placeholder="Pack"
                                                        value={ qty }
                                                        ref={ qtyid }
                                                        onChange={ handleChange }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitForm }>Save</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
                <Toast ref={ toastt } />
                <ConfirmDialog />
            </div>
        </>
    );
}

export default AddPacking;

