import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { toast } from 'react-toastify';
import Select, { components } from 'react-select';
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { OnlyNumber, SelectCheck, removeLeadingZero, threeDecimal, Pincoode, emtySelect, maxlength, validInput, Star, Editicon } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import customStyles from '../../../../validations/customStyles';
import CommonFunctions, { fetchOptionsFrom } from '../../../../Common/CommonFunction';
import CheckAuth from '../../../../APIHelper/CheckAuth';
import { Loaderr } from '../../../../CommonComponents/Loader';
const AddGodown = ( e ) =>
{
    const toastt = useRef( null );
    const { isLoggedInToken, handleLogout, setLoggedIn, isLoading, setIsLoading } = CheckAuth();
    const { AddChangeData, EditChangeData, setInWhitchData, setInWhitchFrome, RemoveSSFild, setHSNSaCType, splitLastInWitchD, splitLastInWitchF } = CommonFunctions();
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, setFocus, splitLastFocus } = useLinkManagement();

    const [ godown_name, set_godown_name ] = useState( '' );
    const [ godown_adr1, set_godown_adr1 ] = useState( '' );
    const [ godown_adr2, set_godown_adr2 ] = useState( '' );
    const [ godown_state, set_godown_state ] = useState( '' );
    const [ CityName, set_godown_city ] = useState( '' );
    const [ godown_pincode, set_godown_pincode ] = useState( '' );
    const [ godown_mobile, set_godown_mobile ] = useState( '' );
    const [ godown_phone, set_godown_phone ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const [ states, setStates ] = useState( [] );
    const [ cities, setCities ] = useState( [] );
    const namee = useRef();
    const adr = useRef();
    const stateRef = useRef();
    const cityRef = useRef();
    const pincode = useRef();
    const mobile = useRef();

    const godownData = () => ( {
        godown_name: godown_name.trim(),
        godown_adr1: godown_adr1.trim(),
        godown_adr2: godown_adr2.trim(),
        godown_state: godown_state,
        godown_city: CityName,
        godown_pincode: godown_pincode.trim(),
        godown_mobile: godown_mobile.trim(),
        godown_phone: godown_phone.trim(),
    } );
    useEffect( () =>
    {
        fetchStates();
        const setFocusFromStorage = JSON.parse( sessionStorage.getItem( 'focusme' ) );
        if ( setFocusFromStorage && setFocusFromStorage.length > 0 )
        {
            const focusElement = setFocusFromStorage[ setFocusFromStorage.length - 1 ];
            setTimeout( () =>
            {
                if ( focusElement === 'cityRef' )
                {
                    cityRef.current.focus();
                    splitLastFocus();
                }
            }, 10 ); // 10 milliseconds delay
        }
    }, [] );

    //fetch states Options 
    const fetchStates = async () => { fetchOptionsFrom( 'states', 'id', 'name' ).then( mappedOptions => { setStates( mappedOptions ); } ); };
    //fetch states related citys Options 
    const fetchCity = ( value ) =>
    {
        fetchOptionsFrom( `statecity/${ value }`, 'cityId', 'cityName' ).then( mappedOptions => { setCities( mappedOptions ); } );
    }
    // try
    // {
    //     const response = await axios.get( `${ BaseUrl }statecity/${ value }`, { mode: 'cors' } );
    //     const data = response.data;
    //     if ( data === null )
    //     {
    //         const mappedOptions = { label: 'City Not Found Create New City', color: '#efefef', isDisabled: true, isFixed: true };
    //         setCities( [] );
    //     } else
    //     {
    //         const mappedOptions = data.map( item => ( { value: item.cityId, label: item.cityName } ) );
    //         setCities( mappedOptions );
    //     }
    // } catch ( error )
    // {
    //     console.error( 'Error fetching cities:', error );
    // }


    const StateChange = selectedOption =>
    {
        if ( selectedOption == '' )
        {
            console.log( 'empty state' );
        } else if ( selectedOption == null )
        {
            set_godown_state( selectedOption );
            set_godown_city( '' );
            setCities( [] );
        } else if ( selectedOption != '' )
        {
            set_godown_state( selectedOption );
            set_godown_city( '' );
            const statevalue = selectedOption.value;
            fetchCity( statevalue );
        } else
        {
            console.log( 'state selected' );
        }
    };
    const CityChange = selectedOption =>
    {
        if ( selectedOption == '' )
        {
            console.log( 'City empty' );
        } else if ( selectedOption == null )
        {
            set_godown_city( selectedOption );
        } else if ( selectedOption != '' )
        {
            set_godown_city( selectedOption );
        } else
        {
            console.log( 'City selected' );
        }
    };
    const add_city = () => 
    {
        const chechk_state = godown_state;
        if ( chechk_state === '' || chechk_state === null )
        {
            stateRef.current.focus(); return false;
        } else
        {

            RemoveSSFild( 'godown_data' );
            setInWhitchData( 'godown_data' );
            setInWhitchFrome( 'CityName' );        //In Whitch Value Update on Date
            sessionStorage.setItem( 'godown_data', JSON.stringify( {
                godown_name, godown_adr1, godown_adr2, godown_state, CityName, godown_pincode, godown_mobile, godown_phone
            } ) );
            setFocus( 'cityRef' );
            addLink( "cityadd" );
            navigateelast();
        }

    };
    //Edit City 
    const editCityForm = ( value ) =>
    {
        sessionStorage.removeItem( 'godown_data' );
        sessionStorage.setItem( 'godown_data', JSON.stringify( { godown_name, godown_adr1, godown_adr2, godown_state, CityName, godown_pincode, godown_mobile, godown_phone } ) );
        sessionStorage.setItem( 'editcityID', value );
        addLink( "cityedit," + value );
        setFocus( 'cityRef' );
        navigateelast();

    };
    const EditCity = ( props ) =>
    {
        return (
            <>
                <div
                    onClick={ () => editCityForm( props.selectProps.value.value ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );
    };
    const editCity = ( { children, ...props } ) =>
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditCity { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };
    useEffect( () =>
    {
        const savedData = sessionStorage.getItem( 'godown_data' );
        if ( savedData )
        {
            const parsedData = JSON.parse( savedData );
            set_godown_name( parsedData.godown_name );
            set_godown_adr1( parsedData.godown_adr1 );
            set_godown_adr2( parsedData.godown_adr2 );
            set_godown_state( parsedData.godown_state );
            if ( parsedData.CityName == '' || parsedData.CityName == null )
            {
                set_godown_city( '' )
            } else
            {
                set_godown_city( parsedData.CityName );
            }
            set_godown_pincode( parsedData.godown_pincode );
            set_godown_mobile( parsedData.godown_mobile );
            set_godown_phone( parsedData.godown_phone );
            const statevalue = parsedData.godown_state;
            if ( statevalue != null )
            {
                fetchCity( statevalue.value );
            } else if ( statevalue == null )
            {
                const mappedOptions = { value: 'ocean', label: 'Ocean', color: '#efefef', isDisabled: true };
                setCities( [ mappedOptions ] );
                console.log( 'state value is null' )
            } else
            {
                console.log( 'state value is Empty' );
            }
        }
    }, [] );
    const AddCityButton = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <spna
                    onClick={ add_city }
                    className="btn btn-xsm  btn-primary btn-block w-100"
                > Add City</spna>
            </components.MenuList>
        );
    };
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };
    function SubmitForm( e )
    {
        e.preventDefault();
        setError( '' );
        const trimmedname = maxlength( godown_name.trim(), 'godown Name', 50 );
        const trimmedpincode = Pincoode( godown_pincode.trim(), 'PinCode', 6 );
        const trimmedState = SelectCheck( godown_state, 'state name' );
        const trimmedCit = SelectCheck( CityName, 'city name' );

        if ( trimmedname !== true )
        {
            setError( trimmedname );
            foCoUs( namee );
            return false;
        }
        else if ( trimmedState !== true )
        {
            setError( trimmedState );
            stateRef.current.focus();
            // foCoUs( stateRef );
            return false;
        } else if ( trimmedCit !== true )
        {
            setError( trimmedCit );
            cityRef.current.focus();
            return false;
        }
        else if ( trimmedpincode !== true )
        {
            setError( trimmedpincode );
            foCoUs( pincode );
            return false;
        }
        else 
        {
            axios
                .post( `${ BaseUrl }godown`, godownData(), { mode: 'cors' } )
                .then( function ( response )
                {
                    const data = response.data;
                    if ( data.success === true )
                    {
                        toast.success( 'Add Godown Success' );
                        sessionStorage.removeItem( 'godown_data' );
                        const LastLink = getLastLinkInSession();
                        if ( LastLink === 'add_godown_m' )
                        {
                            setError( '' );
                            set_godown_name( '' ); set_godown_adr1( '' ); set_godown_adr2( '' ); set_godown_state( '' );
                            set_godown_city( '' ); set_godown_pincode( '' ); set_godown_mobile( '' ); set_godown_phone( '' );
                        } else
                        {
                            handleSplitLastLink();
                            AddGodownOnOpenignStockData( godown_name, data.Newid )
                            // const LastLink = getLastLinkInSession();
                            // if ( LastLink === 'add_opening_stock_m' || LastLink === 'add_opening_stock' || LastLink === "edit_opening_stock" )
                            // {
                            //     const openingstockdata = sessionStorage.getItem( 'openingstock' );
                            //     // Parse 'packing index' from session storage (OSPackingfocus) use for pass the new packing selected index
                            //     const indexid = JSON.parse( sessionStorage.getItem( 'OSGodownfocus' ) );
                            //     if ( openingstockdata !== '' || openingstockdata !== null || openingstockdata !== undefined )
                            //     {
                            //         const d = openingstockdata ? JSON.parse( openingstockdata ) : {};
                            //         const osexpensesArray = d.expenses;
                            //         // Define the new name value
                            //         const newNameValue = { 'label': godown_name, 'value': data.Newid };
                            //         if ( indexid >= 0 && indexid < osexpensesArray.length )
                            //         {
                            //             // Modify the 'name' property of the selected expense
                            //             osexpensesArray[ indexid ].godown = newNameValue;
                            //         }
                            //         d.expenses = osexpensesArray;
                            //         // Store the updated data back in session storage
                            //         sessionStorage.setItem( 'openingstock', JSON.stringify( d ) );
                            //     }
                            // }

                            navigateelast();
                        }



                    }
                } )
                .catch( function ( error )
                {
                    var errrr = error.response;
                    const errors = GlobalError( errrr );
                    setError( errors ); foCoUs( namee );
                } );
        }
    }

    const AddGodownOnOpenignStockData = ( Newlabel, newid = null ) =>
    {
        let InWitchFROM;
        let InWitchDATA;
        const getInWitchD = JSON.parse( sessionStorage.getItem( 'InWhitchData' ) );
        if ( getInWitchD && getInWitchD.length > 0 ) { InWitchDATA = getInWitchD[ getInWitchD.length - 1 ]; }
        const getInWitchF = JSON.parse( sessionStorage.getItem( 'InWhitchFrome' ) );
        if ( getInWitchF && getInWitchF.length > 0 ) { InWitchFROM = getInWitchF[ getInWitchF.length - 1 ]; }
        if ( InWitchDATA && InWitchFROM )
        {

            const Data = sessionStorage.getItem( InWitchDATA );
            const indexid = JSON.parse( sessionStorage.getItem( 'OSGodownfocus' ) );
            if ( Data !== '' || Data !== null || Data !== undefined )
            {
                const d = Data ? JSON.parse( Data ) : {};
                try
                {
                    const expensesArray = d.expenses;// Define the new name value
                    const newNameValue = { 'label': Newlabel, 'value': newid };
                    if ( indexid >= 0 && indexid < expensesArray.length ) // Modify the 'name' property of the selected expense
                    {

                        expensesArray[ indexid ].godown = newNameValue;
                    }
                    d.expenses = expensesArray;// Store the updated data back in session storage
                    sessionStorage.setItem( InWitchDATA, JSON.stringify( d ) );
                    splitLastInWitchD();
                    splitLastInWitchF();
                    return true;
                } catch ( error )
                {
                    console.error( "Error:", error );
                    toast.error( error );
                    return false;
                }
            }
            sessionStorage.removeItem( 'OSGodownfocus' );
        }

    }
    const accept = () =>
    {
        sessionStorage.removeItem( 'godown_data' );
        splitLastInWitchD();
        splitLastInWitchF();
        handleSplitLastLink();
        navigateelast();
        // if ( currentUrl === '/godown/add' ) { sessionStorage.removeItem( 'godown_data' ); navigate( '/godown' ); }
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    const changeValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'godown_name' ) { var valuee = validInput( value ); set_godown_name( valuee ); removeinvalid( namee ); }
        if ( name === 'godown_adr1' ) { var valuee = validInput( value ); set_godown_adr1( valuee ); }
        if ( name === 'godown_adr2' ) { var valuee = validInput( value ); set_godown_adr2( valuee ); }
        if ( name === 'godown_pincode' ) { var onlyno = OnlyNumber( value ); var valuee = validInput( onlyno ); set_godown_pincode( valuee ); removeinvalid( pincode ); }
        if ( name === 'godown_mobile' ) { var onlyno = OnlyNumber( value ); var valuee = validInput( onlyno ); set_godown_mobile( valuee ); }
        if ( name === 'godown_phone' ) { var onlyno = OnlyNumber( value ); var valuee = validInput( onlyno ); set_godown_phone( valuee ); }
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) => { if ( event.key === 'Escape' ) { confirm( 'top' ); } };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () => { document.removeEventListener( 'keydown', handleKeyDown ); };
    // }, [] );


    return (
        <>
            <div className="p-2 mt-2 main"> <DynamicTitleComponent newTitle={ `Add Godown ` } />
                <div className="row">
                    <div className="col-md-6">
                        <div className='card'>

                            <div className="card-header"><h4>Add Godown</h4> </div>
                            <form className="form-horizontal" autoComplete="off" onSubmit={ SubmitForm }>
                                <div className="card-body">
                                    <div className="row">

                                        <div className="col-md-12">
                                            <div className=" row">
                                                <div className="col-md-12 col-sm-6 ">
                                                    <label htmlFor="godown_name" className="  "> Name </label><Star />
                                                    <span className='float-end' style={ { color: 'red' } }> { error }</span>
                                                    <input type="text"
                                                        className="form-control form-control-sm" placeholder="Name"
                                                        name='godown_name'
                                                        onChange={ changeValue }
                                                        value={ godown_name }
                                                        ref={ namee }
                                                        maxlength="50"
                                                        autoFocus
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-6 ">
                                                    <label htmlFor="godown_adr1" className="  "> Address</label>
                                                    <textarea name="godown_adr1" maxlength="80" className="form-control form-control-sm " placeholder="Address"
                                                        ref={ adr }
                                                        onChange={ changeValue }
                                                        value={ godown_adr1 } autoComplete="off" >
                                                    </textarea>
                                                </div>
                                                <div className="col-md-6 col-sm-6 ">
                                                    <label htmlFor="godown_adr2" className="  ">  Street</label>
                                                    <textarea name="godown_adr2" className="form-control form-control-sm " placeholder="Street"
                                                        onChange={ changeValue }
                                                        value={ godown_adr2 }
                                                        autoComplete="off"
                                                    ></textarea>
                                                </div>
                                                <div className="col-md-6 col-sm-6 ">
                                                    <label htmlFor="godown_state" className="  ">State</label><Star />
                                                    <Select
                                                        id="godown_state"
                                                        options={ states }
                                                        value={ godown_state }
                                                        onChange={ StateChange }
                                                        styles={ customStyles }
                                                        isClearable
                                                        isSearchable
                                                        ref={ stateRef }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-6 ">
                                                    <label htmlFor="godown_city" className="  "> City </label><Star />
                                                    {/* <EditCity /> */ }
                                                    <Select
                                                        id="godown_city"
                                                        styles={ customStyles }
                                                        options={ cities }
                                                        components={ { MenuList: AddCityButton, IndicatorsContainer: editCity } }
                                                        value={ CityName }
                                                        onChange={ CityChange }
                                                        isClearable
                                                        isSearchable
                                                        ref={ cityRef }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-6 ">
                                                    <label htmlFor="godown_pincode" className="  "> Pin Code </label><Star />
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        name="godown_pincode"
                                                        placeholder="pin code"
                                                        maxLength={ 6 }
                                                        minLength={ 6 }
                                                        onChange={ changeValue }
                                                        value={ godown_pincode }
                                                        ref={ pincode }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-6 ">
                                                    <label htmlFor="godown_mobile" className="  "> Mobile No.</label>
                                                    <input
                                                        type="tel"
                                                        className="form-control form-control-sm "
                                                        name="godown_mobile"
                                                        placeholder="No."
                                                        onChange={ ( e ) => { set_godown_mobile( e.target.value ); setError( '' ); } }
                                                        value={ godown_mobile }
                                                        ref={ mobile }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-6 ">
                                                    <label htmlFor="godown_phone" className="  ">
                                                        Phone No.
                                                    </label>
                                                    <input
                                                        type="tel"
                                                        className="form-control form-control-sm "
                                                        id="godown_phone"
                                                        name="godown_phone"
                                                        onChange={ ( e ) => { set_godown_phone( e.target.value ); setError( '' ); } }
                                                        value={ godown_phone }
                                                        placeholder="No."
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitForm }>Save</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Toast ref={ toastt } />
                <ConfirmDialog />
            </div>
        </>
    );
}

export default AddGodown;

