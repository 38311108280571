export const GlobalArray = (inputValue) => {
    const mapping = {
      BS: 'Balance Sheet',
      PL: 'Profit & Loss A/C',
      TA: 'Trading Account',
    };
    return mapping[inputValue] || null;
  };
export const GlobalError = (errors) => {
  console.log(errors);
  if (errors && errors.data && errors.data.message) {
    return Object.values(errors.data.message).map((error, index) => error);
  }
  return null;
};
export const calculateOnOptions = [ { value: 'A', label: 'Amount' }, { value: 'U', label: 'Unit' }, { value: 'Q', label: 'Qty' } ];
 export const vouterType =[
  {'value': 'CP', 'label': 'Cash/Bank Payment Voucher'},
  {'value': 'CR', 'label': 'Cash/Bank Receipt Voucher'},
  {'value': 'JV', 'label': 'Journal Voucher'},
  {'value': 'SI', 'label': 'Sale Invoice'},
  {'value': 'OB', 'label': 'Order Book'},
  {'value': 'WN', 'label': 'Weight Note'},
  {'value': 'AL', 'label': 'Aadhatia Lagat'},
  {'value': 'AB', 'label': 'Aadhatia Bikri'},
  {'value': 'DCS', 'label': 'Delivery Challan (Supply)'},
  {'value': 'DCL', 'label': 'Delivery Challan (Location)'},
  {'value': 'DCB', 'label': 'Delivery Challan (Branch)'},
  {'value': 'DCJ', 'label': 'Delivery Challan (Job Work)'},
  {'value': 'DCNIRD', 'label': 'Debit/Credit Note Issue (Register Dealer)'},
  {'value': 'DCNIUD', 'label': 'Debit/Credit Note Issue (Unregister Dealer)'},
  {'value': 'DCNIWT', 'label': 'Debit/Credit Note Issue (Without Tax)'},
  {'value': 'RCMIBW', 'label': 'RCM Invoice (Bill Wise)'},
  {'value': 'RCMIDT', 'label': 'RCM Invoice (Daily Total Wise)'},
];
export const VoucherNarrationType = [ { 'value': 'CP', 'label': 'Cash Payment' },
{ 'value': 'CR', 'label': 'Cash Receipt' },
{ 'value': 'BP', 'label': 'Bank Payment' },
{ 'value': 'BR', 'label': 'Bank Receipt' },
{ 'value': 'JV', 'label': 'Journal Voucher' },
{ 'value': 'CNW', 'label': 'Contra Voucher Withdrawal' },
{ 'value': 'CND', 'label': 'Contra Voucher Deposit' },
{ 'value': 'DCNI', 'label': 'Debit/Credit Note Issue' },
{ 'value': 'DCNR', 'label': 'Debit/Credit Note Receive' },]