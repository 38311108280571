import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { addtwoDecimal, addthreeDecimal, removeLeadingZero, threeDecimal, twoDecimal, emtySelect, maxlength, validInput, Star } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
const AddInviceDetail = ( e ) =>
{

    const toast = useRef( null );
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, navigatefirstlink } = useLinkManagement();
    const location = useLocation();
    const currentUrl = location.pathname;
    const navigate = useNavigate();
    const [ name, set_name ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const [ invoiceDetailError, set_invoiceDetailError ] = useState( null );
    const namse = useRef();
    const [ fields, setFields ] = useState( [ '', '' ] );
    const addField = () =>
    {
        if ( fields.length < 5 )
        {
            setFields( [ ...fields, '' ] ); // Add an empty field to the fields array
        }
    };
    const removeField = ( index ) =>
    {
        const updatedFields = [ ...fields ];
        updatedFields.splice( index, 1 ); // Remove the field at the specified index
        setFields( updatedFields );
    };
    const handleChange = ( value, index ) =>
    {
        const updatedFields = [ ...fields ];
        updatedFields[ index ] = value; // Update the value of the field at the specified index
        setFields( updatedFields );
    };
    const nonEmptyFields = fields.filter( value => value.trim() !== '' );
    const trimmedFields = nonEmptyFields.map( ( value ) => value.trim() ); // Trim whitespace from all values in the fields array
    const TransportData = () => ( {
        name: name.trim(),
        value: trimmedFields
    } );
    function SubmitData( e )
    {
        e.preventDefault();
        setError( '' );
        const check_namee = maxlength( name.trim(), 'Name', 50 );
        if ( check_namee !== true ) { setError( check_namee ); namse.current.focus(); return false; }
        else { SaveData(); }
    }
    const SaveData = () =>
    {
        axios
            .post( `${ BaseUrl }invoiceod`, TransportData(), { mode: 'cors' } )
            .then( function ( response )
            {
                const data = response.data;
                if ( data.success === true )
                {
                    const LastLinkk = getLastLinkInSession();
                    if ( LastLinkk === 'add_invoceother_m' )
                    {
                        set_invoiceDetailError( [] );
                        set_name( '' ); setFields( [ '', '' ] );
                        toast.current.show( { severity: 'success', summary: 'Success', detail: 'Add Invoice Other Detail  Success', life: 2000, } );
                        namse.current.focus();
                    }else{
                        handleSplitLastLink();
                        navigateelast();
                    }

                }
            } )
            .catch( function ( error )
            {
                // console.error( error.response );
                // set_invoiceDetailError( error.response.data );
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors ); namse.current.focus();
            } );
    }
    const accept = () =>
    {
        toast.current.show( { severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000, } );
        handleSplitLastLink();
        navigateelast();
        // if ( currentUrl === '/invoice/add' ) { navigate( '/invoice' ); }
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     {
    //         if ( event.key === 'Escape' )
    //         {
    //             confirm( 'top' );
    //         }
    //     };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () =>
    //     {
    //         document.removeEventListener( 'keydown', handleKeyDown );
    //     };
    // }, [] );

    return (
        <>
            <div className="px-2 mt-2 main"><DynamicTitleComponent newTitle={ `Add Invoce other detail` } />
                <div className="row">
                    <div className="col-md-6">

                        <div className='card'>
                            <div className="card-header"> <h4>Add Invoice Other Detail</h4></div>
                            <form className="form-horizontal" onSubmit={ SubmitData } autoComplete='off'>
                                <div className="card-body">
                                    <div className="row">
                                        {/* <div className='text-end'>
                                            { error && <span style={ { color: 'red' } }>{ error }</span> }
                                        </div> */}
                                        
                                            <div className="col-md-12 col-sm-12 ">
                                                <label htmlFor="name" className=" "> Name</label><Star /><span className='float-end' style={ { color: 'red' } }> { error }</span>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    value={ name }
                                                    name="invoice_name"
                                                    required
                                                    placeholder="Invoice"
                                                    onChange={ ( e ) => { set_name( e.target.value ); } }
                                                    ref={ namse }
                                                    autoFocus
                                                />
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <label htmlFor="name" className="">
                                                    Value{ ' ' }</label><Star/> 
                                                { fields.map( ( value, index ) => (
                                                    <div className="row py-1" key={ index }>
                                                        <div className="col-md-11">
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                value={ value }
                                                                placeholder='Value'
                                                                onChange={ ( e ) => handleChange( e.target.value, index ) }
                                                            />
                                                        </div>
                                                        <div className="col-md-1 text-end">
                                                            <button type='button'
                                                                className="btn btn-outline-danger btn-sm "
                                                                onClick={ () => removeField( index ) }> X </button>

                                                        </div>
                                                    </div>
                                                ) ) }
                                            </div>

                                        

                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                { fields.length < 5 && (
                                        <button className="btn px-4 me-2 " type='button' onClick={ addField }>+</button>
                                    ) }
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitData }>Save</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                                {/* <div className="modal-footer py-1">
                                    { fields.length < 5 && (
                                        <span className="btn px-4 btn-primary btn-sm " onClick={ addField }>+</span>
                                    ) }
                                    <button className="btn px-4 btn-sm" type='submit' >Save</button>
                                    <button className="btn  btn-sm" type="reset" onClick={ () => confirm( 'top' ) }>
                                        Back/Cancel
                                    </button>
                                </div> */}
                            </form>


                        </div>
                    </div>
                    <div className="col-md-6"></div>
                </div>
                <Toast ref={ toast } />
                <ConfirmDialog />
            </div>
        </>
    );
}

export default AddInviceDetail;

