import React from 'react';
export const maxlength = ( inputValue, Nane = null, maxno = null ) =>
{
  if ( inputValue.trim() === '' )
  {
    return `Please fill  ${ Nane } !`;
  } else if ( maxno !== null && inputValue.trim().length > maxno )
  {
    return `In ${ Nane } maximum limit of ${ maxno } characters allowed `;
  } else
  {
    return true;
  }
};
export const hsn = ( inputValue, Nane = null, maxno = null ) =>
{
  if ( inputValue.trim() === '' )
  {
    return `Please fill  ${ Nane } !`;
  } else if ( maxno !== null && inputValue.trim().length > maxno )
  {
    return `${ Nane } should be maximum  ${ maxno } digit !`;
  } else if ( maxno !== null && inputValue.trim().length < 4 )
  {
    return `${ Nane } should be minmum  4 digit !`;
  } else
  {
    return true;
  }
};
export const Pincoode = ( inputValue, Nane = null, maxno = null ) =>
{
  if ( inputValue.trim() === '' )
  {
    return `Please fill  ${ Nane } !`;
  } else if ( maxno !== null && inputValue.trim().length > maxno || inputValue.trim().length < maxno )
  {
    return `${ Nane } should be ${ maxno } digit ! `;
  } else
  {
    return true;
  }
};
export const ValidateInput = ( inputValue ) =>
{
  if ( inputValue.trim() === '' || inputValue.trim() === null )
  {
    return 'Input value is required.';
  } else
  {
    return true;
  }
};
export const SelectCheck = ( input, name = null ) =>
{
  if ( input === '' || input === null || input === undefined )
  {
    return `Please Select  ${ name } !`;
  } else
  {
    return true;
  }
}
export const emtySelect = ( inputValue, Nane = null, ) =>
{
  if ( inputValue === '' || inputValue === null || inputValue === undefined )
  {
    return `Please choose  ${ Nane } !`;
  } else
  {
    return true;
  }
};
export const ValidateEmail = ( email ) =>
{
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if ( emailRegex.test( email ) )
  {
    return true;
  } else
  {
    return 'Enter Valid E-mail !';
  }
};
export const OnlyNumber = ( input ) =>
{
  const numericValue = input.replace( /[^0-9]/g, '' );
  return numericValue;
}
export const Numberordecimal = ( input ) =>
{
  const numericValue = input.replace( /[^0-9.]/g, '' );
  return numericValue;
}
export const addtwoDecimal = ( value ) =>
{
  if ( value.includes( '.' ) )
  {
    const decimalIndex = value.indexOf( '.' );
    const beforeDecimal = value.slice( 0, decimalIndex ).padStart( 2, '0' );
    const afterDecimal = value.slice( decimalIndex + 1 ).padEnd( 2, '0' );
    return beforeDecimal + '.' + afterDecimal;
  }

  if ( /^\d+$/.test( value ) )
  {
    return value.padStart( 2, '0' ) + '.00';
  }

  return value;
};

export const removeLeadingZero = ( value ) =>
{
  if ( value.startsWith( '0' ) )
  {
    return value.slice( 1 ); // Remove the leading zero
  }

  return value;
};
export const threeDecimal = ( value ) =>
{
  if ( value.startsWith( '.' ) )
  {
    return value + '0';
  }
  if ( value > 999.999 )
  {
    return null;
  }

  else if ( value.includes( '.' ) && value.split( '.' )[ 1 ].length > 3 )
  {
    return null;
  }

  return '';
};
export const addthreeDecimal = ( value ) =>
{
  if ( value.includes( '.' ) )
  {
    const decimalIndex = value.indexOf( '.' );
    const beforeDecimal = value.slice( 0, decimalIndex ).padStart( 3, '0' );
    const afterDecimal = value.slice( decimalIndex + 1 ).padEnd( 3, '0' );
    return beforeDecimal + '.' + afterDecimal;
  }

  if ( /^\d+$/.test( value ) )
  {
    return value.padStart( 3, '0' ) + '.000';
  }

  return value;
};

export const twoDecimal = ( value ) =>
{


  if ( value > 99.99 )
  {
    return null;
  }

  // if ( value.includes( '.' ) && value.split( '.' )[ 1 ].length > 2 )
  // {
  //   return null;
  // }
  const validNumberPattern = /^\d*[.]?\d{0,2}$/;
  if (validNumberPattern.test(value) || value === '') {
    return value;
  }
  return null; 

  // return '';
};
export const validateDateOrder = ( date1, name1 = null, date2 = null, name2 = null ) =>
{
  if ( name1 === null || name1 === '' || name1 === undefined ) { var name1 = 'Date 1' }
  if ( name2 === null || name2 === '' || name2 === undefined ) { var name2 = 'Date 2' }
  if ( date1 === '' || date1 === null || date1 === undefined )
  {
    return `Please choose ${ name1 }!`;
  }
  if ( date2 === '' || date2 === null || date2 === undefined )
  {
    return `Please choose ${ name2 }!`;
  }
  if ( date2 <= date1 || date1 >= date2 )
  {
    return `${ name1 } should not be greater than ${ name2 }!`;
  }
  return true;
};

export const lemit100 = ( number, name = null ) =>
{
  if ( number === '' || number === null || number === undefined )
  {
    return 'Please fill a valid ' + name + ' !';
  }
  if ( number > 100 )
  {
    return '' + name + ' should not be greater than 100 !';
  }

  if ( number < 0 )
  {
    return '' + name + ' should be a non-negative value !';
  }

  return true;
};


export const validInput = ( value ) =>
{
  let profileNamee = value.split( /[ ]+/ );
  const newText = profileNamee.join( " " );
  const sanitizedValue = newText.replace( /(<([^>]+)>)/gi, '' ); // Remove HTML tags
  const sanitizedValueWithoutScriptTags = sanitizedValue.replace( /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '' ); // Remove JavaScript tags
  const sanitizedValueWithoutPHPTags = sanitizedValueWithoutScriptTags.replace( /<\?php\b[^<]*(?:(?!<\?php)<[^<]*)*<\?php>/gi, '' ); // Remove PHP tags
  const output = sanitizedValueWithoutPHPTags.replace( /<[^>]*>/g, '' ).replace( /&nbsp;/g, '' )
  return ( output );
};

export const prefix = ( inputValue, Name = null ) =>
{
  // console.log(inputValue);
  if ( inputValue.trim() === '' )
  {
    return `Please fill ${ Name }!`;
  } else if ( inputValue.trim().length > 12 )
  {
    return `Total length of series prefix and postfix should be equal to or less than 12 digits!`;
  } else
  {
    return true;
  }
};
export const startno = ( inputValue ) =>
{
  if ( inputValue.trim().length > 16 )
  {
    return `Total length of series prefix, postfix and start no. should be equal to or less than \n16 digits !`;
  } else
  {
    return true;
  }
};

export const Star = () =>
{
  return <span className="text-danger"><sup>*</sup></span>;
};
export const Editicon = () =>
{
  return <svg viewBox="0 0 50 50" width="19px" height="17px" className="css-tj5bde-Svg" aria-hidden="false" focusable="false" >
  <path d="M 43.125 2 C 41.878906 2 40.636719 2.488281 39.6875 3.4375 L 38.875 4.25 L 45.75 11.125 C 45.746094 11.128906 46.5625 10.3125 46.5625 10.3125 C 48.464844 8.410156 48.460938 5.335938 46.5625 3.4375 C 45.609375 2.488281 44.371094 2 43.125 2 Z M 37.34375 6.03125 C 37.117188 6.0625 36.90625 6.175781 36.75 6.34375 L 4.3125 38.8125 C 4.183594 38.929688 4.085938 39.082031 4.03125 39.25 L 2.03125 46.75 C 1.941406 47.09375 2.042969 47.457031 2.292969 47.707031 C 2.542969 47.957031 2.90625 48.058594 3.25 47.96875 L 10.75 45.96875 C 10.917969 45.914063 11.070313 45.816406 11.1875 45.6875 L 43.65625 13.25 C 44.054688 12.863281 44.058594 12.226563 43.671875 11.828125 C 43.285156 11.429688 42.648438 11.425781 42.25 11.8125 L 9.96875 44.09375 L 5.90625 40.03125 L 38.1875 7.75 C 38.488281 7.460938 38.578125 7.011719 38.410156 6.628906 C 38.242188 6.246094 37.855469 6.007813 37.4375 6.03125 C 37.40625 6.03125 37.375 6.03125 37.34375 6.03125 Z"></path>
</svg>;
};

// InputValidation.js (Common Utility Function)

export function AfterDecimalThreeValue(inputValue) {
  // Define a regular expression to match a valid number format
  const validNumberPattern = /^\d*[.]?\d{0,3}$/;
  if (validNumberPattern.test(inputValue) || inputValue === '') {
    return inputValue;
  }
  return null; // Return null for invalid input
}
export function AfterDecimalTowValue(inputValue) {
  // Define a regular expression to match a valid number format
  const validNumberPattern = /^\d*[.]?\d{0,2}$/;
  if (validNumberPattern.test(inputValue) || inputValue === '') {
    return inputValue;
  }
  return null; // Return null for invalid input
}

