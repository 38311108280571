import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import Select from 'react-select';
import { GlobalError } from '../../../../validations/globalArray';
import { emtySelect, maxlength, Pincoode, OnlyNumber } from '../../../../validations/validations'
import customStyles from '../../../../validations/customStyles';
import Baseurl from '../../../BaseUrl';
import axios from 'axios';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import CheckAuth from '../../../../APIHelper/CheckAuth';
import {Loaderr} from '../../../../CommonComponents/Loader';
import CommonFunctions, { fetchOptionsFrom } from '../../../../Common/CommonFunction';
// import { toast } from 'react-hot-toast';
import { toast } from 'react-toastify';
const BaseUrl = Baseurl;

const AddCity = ( e ) =>
{
    const { AddChangeData, EditChangeData, setInWhitchData, setInWhitchFrome, RemoveSSFild, setHSNSaCType, splitLastInWitchD, splitLastInWitchF } = CommonFunctions();
    const { isLoggedInToken, handleLogout, setLoggedIn, isLoading, setIsLoading } = CheckAuth();
    const { userId, handleSplitLastLink, getLastLinkInSession,  navigateelast} = useLinkManagement();
    const [ isDisabled, setIsDisabled ] = useState( false );
    const [ name, set_city_name ] = useState( '' );
    const [ pincode, set_pin_code ] = useState( '' );
    const [ state, set_state_name ] = useState( '' );
    const [ stateopt, setStates ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const cityname = useRef();
    const citypincode = useRef();
    const citystate = useRef(); 
    useEffect( () =>
    {
        fetchStates();
    }, [] );
    const fetchStates = async () =>
    {
        fetchOptionsFrom( 'states', 'id', 'name' ).then( mappedOptions => { setStates( mappedOptions ); } );
        // try
        // { 
        //     const response = await axios.get( `${ BaseUrl }/states`, { mode: 'cors' } );
        //     const data = response.data;
        //     const mappedOptions = data.map( item => ( { value: item.id, label: item.name } ) );
        //     setStates( mappedOptions );
        // } catch ( error )
        // {
        //     console.error( 'Error fetching states:', error );
        // }
    };
    useEffect( () =>
    {
        const cityLastLink = getLastLinkInSession();
        if ( cityLastLink !== 'maincityadd' )
        {
            const savedData = sessionStorage.getItem( 'godown_data' );
            if(savedData){
                const parsedData = JSON.parse( savedData );
                const state = parsedData.godown_state;
                if ( state !== null ) { setIsDisabled( true ); set_state_name( state ); }
                else { console.log( 'state value is null' ); }
                setIsDisabled( true );
            }
            
        } else
        {
            setIsDisabled( false );
        }
        // const savedData = sessionStorage.getItem( 'godown_data' );
        // if ( savedData )
        // {
        //     const godown_url = '/godown/add/city';
        //     if ( currentUrl === godown_url || currentUrl === edit_godown_url )
        //     {
        //         const parsedData = JSON.parse( savedData );
        //         const state = parsedData.godown_state;

        //         if ( state !== null )
        //         {
        //             setIsDisabled( true );
        //             set_state_name( state );
        //         } else
        //         {
        //             console.log( 'state value is null' );
        //         }
        //     }
        // }
    }, [] );
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const CityData = () => ( {
        city_name: name.trim(),
        pin_code: pincode.trim(),
        state_name: state.value,
        uid : userId
    } );

    function OnSubmit( e )
    {

        e.preventDefault();
        setError( '' );
        const VCityName = maxlength( name.trim(), 'City Name' );
        const trimmedPincode = Pincoode( pincode.trim(), 'Pincode ', 6 );
        const trimmedValue = emtySelect( state, 'State' );
        if ( VCityName !== true ) { setError( VCityName ); foCoUs( cityname ); return false; }
        else if ( trimmedPincode !== true ) { setError( trimmedPincode ); foCoUs( citypincode ); return false; }
        else if ( trimmedValue !== true ) { setError( trimmedValue ); citystate.current.focus(); return false; }
        else 
        {
            // const token = localStorage.getItem( 'token' );
            if (!isLoggedInToken){ handleLogout(); return ; };
            setIsLoading( true );
            axios
            .post(`${BaseUrl}city`, CityData(), {
                headers: {'Authorization': `Bearer ${isLoggedInToken}`, // Attach the token to the "Authorization" header
                },
                mode: 'cors',
              })
                .then( function ( response )
                {
                    const data = response.data;
                    if ( data.success === true )
                    {
                        toast.success('Add City Success'  );
                        const cityLastLink = getLastLinkInSession();
                        if ( cityLastLink === 'maincityadd' )
                        {
                            set_city_name( '' );
                            set_pin_code( '' );
                            set_state_name( '' );
                            cityname.current.focus();
                            
                        }
                        else
                        {
                            const Selerslip_SS = sessionStorage.getItem( 'sellerslipexpensesprofile' );
                            const godown_data = sessionStorage.getItem( 'godown_data' );
                            if ( Selerslip_SS !== '' || Selerslip_SS !== null || Selerslip_SS !== undefined )
                            {
                                const d = Selerslip_SS ? JSON.parse( Selerslip_SS ) : {};
                                const value = data.Newid;
                                const label = name;
                                d.CityName = { value, label };
                                sessionStorage.setItem( 'sellerslipexpensesprofile', JSON.stringify( d ) );
                            }
                            // if ( godown_data )
                            // {
                            //     const d = godown_data ? JSON.parse( godown_data ) : {};
                            //     const value = data.Newid;
                            //     const label = name;
                            //     d.CityName = { value, label };
                            //     sessionStorage.setItem( 'godown_data', JSON.stringify( d ) );
                            // }
                            handleSplitLastLink();
                            AddChangeData( name, data.Newid );
                            navigateelast();
                        }
                        setIsLoading( false );
                    }
                    if (data.status === false) {
                        if (data.statusCode == '401') {
                          alert(data.message);
                          handleLogout();
                          setLoggedIn(false);
                        }
                        setIsLoading( false ); // call logout user
                      }
                } )
                .catch( function ( error )
                {
                    var errrr = error.response;
                    const errors = GlobalError( errrr );
                    setError( errors );
                    toast.error( ' '+errors );
                    foCoUs( cityname )
                    setIsLoading( false );
                } );
        }
    }
    const StateChange = selectedOption => { set_state_name( selectedOption ); };
    const accept = () =>
    {
        const LastLink = getLastLinkInSession();
        if ( LastLink === 'maincityadd' ) { splitLastInWitchD( 'f' ); splitLastInWitchF( 'f' ); }
        splitLastInWitchD();
        splitLastInWitchF();
        handleSplitLastLink();
        navigateelast();
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    const ChangeValue = ( e ) =>
    {
        const { name, value } = e.target;
        if ( name === 'city_name' ) { set_city_name( value ); setError( '' ); cityname.current.classList.remove( 'is-invalid' ); }
        if ( name === 'pin_code' ) { var pincodeno = OnlyNumber( value ); set_pin_code( pincodeno ); setError( '' ); citypincode.current.classList.remove( 'is-invalid' ); }
    }
    return (
        <>
        { isLoading ? ( <Loaderr/> ) : ( '' ) }
                <ConfirmDialog />
                <DynamicTitleComponent newTitle={ `Add City ` } />
            <div class=" p-2 mt-2 main" >
                <div className="row">
                    <div className="col-md-6">
                        <div className='card '>
                            <div className="pt-2 card-header">
                                <h4>Add City</h4>
                            </div>
                            <form className="form-horizontal" onSubmit={ OnSubmit } autoComplete="off">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="city_name" className=" modal-label ">City Name</label><span className="text-danger"><sup>*</sup> </span><span className='float-end' style={ { color: 'red' } }> { error }</span>
                                                    <input type="text" className="form-control form-control-sm  " id="city_name" name="city_name" placeholder="City Name" onChange={ ChangeValue } autoFocus value={ name } ref={ cityname } maxLength={ 50 } />
                                                </div>
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="pin_code" className=" form-label ">Pin Code</label> <span className="text-danger" id="pin_code_error" />
                                                    <input type="text" className="form-control form-control-sm  " id="pin_code" name="pin_code" placeholder="Pin Code" maxLength={ 6 } minLength={ 6 } onChange={ ChangeValue } value={ pincode } ref={ citypincode } />
                                                </div>
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="state" className=" form-label ">State Name</label>
                                                    <Select
                                                        id="godown_state"
                                                        options={ stateopt }
                                                        value={ state }
                                                        onChange={ StateChange }
                                                        styles={ customStyles }
                                                        isClearable
                                                        isSearchable
                                                        isDisabled={ isDisabled }
                                                        ref={ citystate }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" card-footer formBtn">
                                    <button className="btn px-4  me-2" type='submit' onClick={ OnSubmit }>Save</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6"></div>
                </div>
            </div>

        </>
    );
}

export default AddCity;

