import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { toast } from 'react-toastify';
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { addtwoDecimal, addthreeDecimal, removeLeadingZero, threeDecimal, twoDecimal, emtySelect, maxlength, validInput, Star } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import CommonFunctions from '../../../../Common/CommonFunction';
import CheckAuth from '../../../../APIHelper/CheckAuth';
import  { Loaderr }  from '../../../../CommonComponents/Loader';
const AddManufacturer = ( e ) =>
{

    const { isLoggedInToken, handleLogout, setLoggedIn, isLoading, setIsLoading } = CheckAuth();
    const { AddChangeData, EditChangeData, setInWhitchData, setInWhitchFrome, RemoveSSFild, setHSNSaCType, splitLastInWitchD, splitLastInWitchF } = CommonFunctions();
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast ,navigatefirstlink} = useLinkManagement();
    
    const [ manufacturer_name, set_name ] = useState( '' );
    const [ manufacturer_print, set_print ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const namee = useRef();
    const print = useRef();
    const ManufacturerData = () => ( {
        manufacturer_name: manufacturer_name.trim(),
        manufacturer_print: manufacturer_print.trim()
    } );
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };
    const removeallinvalid = () =>
    {
        namee.current.classList.remove( 'is-invalid' );
        print.current.classList.remove( 'is-invalid' );
    };
    function SubmitForm( e )
    {
        e.preventDefault();
        setError( '' );
        const trimmedname = maxlength( manufacturer_name.trim(), 'manufacturer name', 50 );
        const trimmedprint = maxlength( manufacturer_print.trim(), 'print name', 50 ); 
        if ( trimmedname !== true )
        {   setError( trimmedname );
            foCoUs( namee );
            return false;
        }  else if ( trimmedprint !== true )
        {
            setError( trimmedprint);
            foCoUs( print );
            return false;
        } else 
        {
            setIsLoading( true );
            axios
                .post( `${ BaseUrl }manufacturer`, ManufacturerData(), { mode: 'cors' } )
                .then( function ( response )
                {
                    const data = response.data;
                    if ( data.success === true )
                    {
                        toast.success( 'Add Manufacturer Success' );
                        const LastLink = getLastLinkInSession();
                        if ( LastLink === 'add_manufacturer_m' )
                        {
                            setError( '' );
                            removeallinvalid();
                            set_name( '' );
                            set_print( '' );
                            namee.current.focus();
                            setIsLoading( false );
                        }
                       else{
                        handleSplitLastLink();
                            // const LastLinkk = getLastLinkInSession();
                            // if (LastLinkk === 'add_batchlot_m' || LastLinkk === 'add_batchlot' || LastLinkk === "edit_batchlot"){
                            //     const batchlot = sessionStorage.getItem( 'batch_lot_data' );
                            //     if ( batchlot !== '' || batchlot !== null || batchlot !== undefined )
                            //     {
                            //         const d = batchlot ? JSON.parse( batchlot ) : {};
                            //         const value = data.Newid;
                            //         const label = manufacturer_name;
                            //         d.batch_manuf = { value, label };
                            //         sessionStorage.setItem( 'batch_lot_data', JSON.stringify( d ) );
                            //     }
                            // }
                            AddChangeData( manufacturer_name, data.Newid ); // Change Last Open Popup Data or fild
                            navigateelast();
                            setIsLoading( false );
                       }
                    }
                } )
                .catch( function ( error )
                {
                    setIsLoading( false );
                    var errrr = error.response;
                    const errors = GlobalError( errrr );
                    setError( errors ); foCoUs( namee );
                } );
        }
    }
    const handleChange = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'manufacturer_name' ) { var valuee = validInput( value ); set_name( valuee ); set_print( valuee ); removeinvalid( namee ); }
        if ( name === 'manufacturer_print' ) { var valuee = validInput( value ); set_print( valuee ); removeinvalid( print ); }
    };
    const accept = () =>
    { const LastLink = getLastLinkInSession();
        if ( LastLink === 'add_manufacturer_m' ) { splitLastInWitchD( 'f' ); splitLastInWitchF( 'f' ); }
        splitLastInWitchD();
        splitLastInWitchF();
        handleSplitLastLink();
        navigateelast();
        // if ( currentUrl === '/manufacturer/add' ) { navigate( '/manufacturer' ); }
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     {
    //         if ( event.key === 'Escape' ) { confirm( 'top' ); }
    //     };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () =>
    //     {
    //         document.removeEventListener( 'keydown', handleKeyDown );
    //     };
    // }, [] );
    return (
        <>
             <div className="p-2 mt-2 main"> <DynamicTitleComponent newTitle={ `Add Manufacturer ` } />
            { isLoading ? ( <Loaderr/> ) : ( '' ) }
                <div className="row">
                    <div className="col-md-6">
                        
                        <div className='card'>
                        <div className="card-header">
                            <h4>Add Manufacturer</h4>
                        </div>
                            <form className="form-horizontal" onSubmit={SubmitForm} autoComplete='off'>
                                <div className="card-body">
                                    <div className=" row">

                                        <div className="col-md-12 col-sm-12 ">
                                            <label htmlFor="manufacturer_name" className="">
                                                Manufacturer Name
                                                <span className="text-danger"><sup>*</sup></span>
                                            </label><span className='float-end' style={ { color: 'red' } }> { error }</span>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm "
                                                id="manufacturer_name"
                                                name="manufacturer_name"
                                                placeholder="Name"
                                                onChange={ handleChange }
                                                ref={ namee }
                                                autoFocus
                                                value={ manufacturer_name }
                                            />
                                        </div>
                                        <div className="col-md-12 col-sm-12 ">
                                            <label htmlFor="manufacturer_print" className="">Print Name <span className="text-danger"><sup>*</sup> </span></label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                name="manufacturer_print"
                                                onChange={ handleChange }
                                                value={ manufacturer_print }
                                                ref={ print }
                                                placeholder="Print"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitForm }>save</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ConfirmDialog />
            </div>
        </>
    );
}

export default AddManufacturer;

