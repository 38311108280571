import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import BaseUrl from '../BaseUrl';
const CommonFunctions = () =>
{
    const InWhitchD = JSON.parse( sessionStorage.getItem( 'InWhitchData' ) ) || [];
    const [ InWhitchData, SetWhitchData ] = useState( InWhitchD );
    const InWhitchF = JSON.parse( sessionStorage.getItem( 'InWhitchFrome' ) ) || [];
    const [ WhitchFrome, SetWhitchFrome ] = useState( InWhitchF );

    // Set In Whitch Data From Update
    const setInWhitchData = ( from ) =>
    {
        if ( from )
        {
            const UpdateInWhitchData = [ ...InWhitchData, from ];
            SetWhitchData( UpdateInWhitchData );
            sessionStorage.setItem( 'InWhitchData', JSON.stringify( UpdateInWhitchData ) );
        }
    }
    // Function to split or remove the last In Witch Data from the array
    const splitLastInWitchD = ( f = null ) =>
    {
        if ( f === 'f' )
        {
            const UpdateInWhitchData = [];
            SetWhitchFrome( UpdateInWhitchData );
            sessionStorage.setItem( 'InWhitchData', JSON.stringify( UpdateInWhitchData ) );
        }
        if ( f === null && InWhitchData.length > 0 )
        {
            const UpdateInWhitchData = [ ...InWhitchData ];
            UpdateInWhitchData.pop();
            SetWhitchData( UpdateInWhitchData );
            sessionStorage.setItem( 'InWhitchData', JSON.stringify( UpdateInWhitchData ) );
        }
    };

    // Set In Whitch From 
    const setInWhitchFrome = ( from ) =>
    {
        if ( from )
        {
            const UpdateInWhitchFrome = [ ...WhitchFrome, from ];
            SetWhitchFrome( UpdateInWhitchFrome );
            sessionStorage.setItem( 'InWhitchFrome', JSON.stringify( UpdateInWhitchFrome ) );
        }
    }
    // Function to split or remove the last In Witch From from the array
    const splitLastInWitchF = ( f = null ) =>
    {
        if ( f === 'f' )
        {
            const UpdateInWhitchData = [];
            SetWhitchFrome( UpdateInWhitchData );
            sessionStorage.setItem( 'InWhitchFrome', JSON.stringify( UpdateInWhitchData ) );
        }
        if ( f === null && WhitchFrome.length > 0 )
        {
            const UpdateInWhitchData = [ ...WhitchFrome ];
            UpdateInWhitchData.pop();
            SetWhitchFrome( UpdateInWhitchData );
            sessionStorage.setItem( 'InWhitchFrome', JSON.stringify( UpdateInWhitchData ) );
        }
    };
    // Change Data On Add New Value
    const AddChangeData = ( Newlabel, newid = null ,extra1 = null, extra2 = null, extra3 = null) =>
    {
        let InWitchFROM;
        let InWitchDATA;
        const getInWitchD = JSON.parse( sessionStorage.getItem( 'InWhitchData' ) );
        if ( getInWitchD && getInWitchD.length > 0 ) { InWitchDATA = getInWitchD[ getInWitchD.length - 1 ]; }
        const getInWitchF = JSON.parse( sessionStorage.getItem( 'InWhitchFrome' ) );
        if ( getInWitchF && getInWitchF.length > 0 ) { InWitchFROM = getInWitchF[ getInWitchF.length - 1 ]; }
        if ( InWitchDATA && InWitchFROM )
        {

            const Data = sessionStorage.getItem( InWitchDATA );
            if ( Data !== '' || Data !== null || Data !== undefined )
            {
                const d = Data ? JSON.parse( Data ) : {};
                try
                {
                    const value = newid;
                    const label = Newlabel;
                    d[ InWitchFROM ] = { value, label };
                    sessionStorage.setItem( InWitchDATA, JSON.stringify( d ) );
                    splitLastInWitchD();
                    splitLastInWitchF();
                    return true;
                } catch ( error )
                {
                    console.error( "Error:", error );
                    toast.error( error );
                    return false;
                }
            }
        }
    }
    //for Opening stock
    const AddOpenignStockData = ( Newlabel, newid = null ) =>
    {
        let InWitchFROM;
        let InWitchDATA;
        const getInWitchD = JSON.parse( sessionStorage.getItem( 'InWhitchData' ) );
        if ( getInWitchD && getInWitchD.length > 0 ) { InWitchDATA = getInWitchD[ getInWitchD.length - 1 ]; }
        const getInWitchF = JSON.parse( sessionStorage.getItem( 'InWhitchFrome' ) );
        if ( getInWitchF && getInWitchF.length > 0 ) { InWitchFROM = getInWitchF[ getInWitchF.length - 1 ]; }
        if ( InWitchDATA && InWitchFROM )
        {

            const Data = sessionStorage.getItem( InWitchDATA );
            const indexid = JSON.parse( sessionStorage.getItem( 'OSBatchLoatNofocus' ) );
            if ( Data !== '' || Data !== null || Data !== undefined )
            {
                const d = Data ? JSON.parse( Data ) : {};
                try
                {
                    const expensesArray = d.expenses;// Define the new name value
                    const newNameValue = { 'label': Newlabel, 'value':newid  };
                    if ( indexid >= 0 && indexid < expensesArray.length ) // Modify the 'name' property of the selected expense
                    {
                       
                        expensesArray[ indexid ].batchlotno = newNameValue;
                    }
                    d.expenses = expensesArray;// Store the updated data back in session storage
                    sessionStorage.setItem( InWitchDATA , JSON.stringify( d ) );
                    splitLastInWitchD();
                    splitLastInWitchF();
                    return true;
                } catch ( error )
                {
                    console.error( "Error:", error );
                    toast.error( error );
                    return false;
                }
            }
        }
     
    }
    //on change data Edit Time pass edit value
    const EditChangeData = ( Newlabel, newid = null ) =>
    {
        let InWitchFROM;
        let InWitchDATA;
        const getInWitchD = JSON.parse( sessionStorage.getItem( 'InWhitchData' ) );
        if ( getInWitchD && getInWitchD.length > 0 ) { InWitchDATA = getInWitchD[ getInWitchD.length - 1 ]; }
        const getInWitchF = JSON.parse( sessionStorage.getItem( 'InWhitchFrome' ) );
        if ( getInWitchF && getInWitchF.length > 0 ) { InWitchFROM = getInWitchF[ getInWitchF.length - 1 ]; }
        if ( InWitchDATA && InWitchFROM )
        {
            const Data = sessionStorage.getItem( InWitchDATA );
            if ( Data !== '' || Data !== null || Data !== undefined )
            {
                const d = Data ? JSON.parse( Data ) : {};
                try
                {
                    const value = d[ InWitchFROM ].value;
                    const label = Newlabel;
                    d[ InWitchFROM ] = { value, label };
                    sessionStorage.setItem( InWitchDATA, JSON.stringify( d ) );
                    splitLastInWitchD();
                    splitLastInWitchF();
                    return true;
                } catch ( error )
                {
                    console.error( "Error:", error );
                    toast.error( error );
                    return false;
                }
            }

        }
    }
    // Remove Array or Data on session storage
    const RemoveSSFild = ( frome ) =>
    {
        sessionStorage.removeItem( frome );
    };
    // const Set Hsn/sac type
    const setHSNSaCType = ( from ) => {if ( from ){ sessionStorage.setItem( 'InWhitchHsnSac', JSON.stringify( from ) );}}
    const RemoveHSNSaCType = (  ) => { sessionStorage.removeItem( 'InWhitchHsnSac');}

    const RCM = [
        { value: "No", label: "No" },
        { value: "YT", label: "Yes (Trading)" },
        { value: "YTSSE", label: "Yes (Trading + Slip Self Entry)" },
        { value: "YTSAE", label: "Yes (Trading + Slip All Entry)" },
        { value: "YTSNSBSE", label: "Yes (Trading + Slip Non Self + Bill Self Entry)" },
        { value: "YTBSE", label: "Yes (Trading + Bill Self Entry)" },
        { value: "YTBPSE", label: "Yes (Trading + Bill + Self Entry)" }
    ];
    
    return {
        AddOpenignStockData, AddChangeData, EditChangeData, setInWhitchData, splitLastInWitchD, setInWhitchFrome, splitLastInWitchF, RemoveSSFild,setHSNSaCType,RemoveHSNSaCType,RCM
    }
}

// Select Options Common Api 

const fetchOptionsFrom = async (fromget, valueId, labelName, extra1 = null, extra2 = null, extra3 = null , extra4 = null) => {
    try {
        // Make a GET request using Axios to the specified URL.
        const response = await axios.get(`${BaseUrl}${fromget}`, { mode: 'cors' });

        // Extract the response data.
        const data = response.data;

        // Map the data into an array of objects with 'value' and 'label' properties.
        const mappedOptions = data.map(item => {
            const option = { value: item[valueId], label: item[labelName] };

            // Check if extra1 and extra2 are provided and not null, then add them to the option.
            if (extra1 !== null) {option[extra1] = item[extra1];}
            // if (extra2 !== null) {option[extra2] = item[extra2];}
            // if (extra3 !== null) {option[extra3] = item[extra3];}
            // if (extra4 !== null) { option[extra4] = item[extra4];}

            return option;
        });

        // Return the mapped options.
        return mappedOptions;
    } catch (error) {
        // Handle errors by logging them to the console.
        console.error('Error fetching data:', error);
    }
};

    //fetch Goods Options with Perameteors
    const fetchGoodsOpt = async () =>
    {
        try
        {
            const response = await axios.get( `${ BaseUrl }goodsopt`, { mode: 'cors' } );
            const data = response.data;
            const mappedOptions = data.map( item => ( { value: item.goodsId, label: item.name, godownmaintain: item.isGodownMaintain, packingmaintain: item.isPackingMaintain, batchmaintain: item.isBatchMaintain } ) );
            return mappedOptions 
        } catch ( error )
        {
            console.error( 'Error fetching states:', error );
        }

    }

export default CommonFunctions;
export {fetchOptionsFrom};
export {fetchGoodsOpt};


