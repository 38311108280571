import React, { useState } from 'react';
import axios from 'axios';
import BaseUrl from '../BaseUrl';
import { useNavigate ,Link } from 'react-router-dom';
import { useToaster } from 'react-hot-toast';
const Registration = () =>
{
  
  const { handlers } = useToaster();
  const navigate = useNavigate();
  const [ formData, setFormData ] = useState( {
    username: '',
    email: '',
    password: '',
    confirm_password: '',
  } );

  const [ errors, setErrors ] = useState( {} );
  const [ error, setError ] = useState();
  const [ isLoading, setIsLoading ] = useState( false );

  const handleChange = ( e ) =>
  {
    
    setError('')
    const { name, value } = e.target;
    setFormData( {
      ...formData,
      [ name ]: value,
    } );
  };

  const handleSubmit = ( e ) =>
  {
    e.preventDefault();
    // Basic form validation
    const validationErrors = {};
    if ( !formData.username )
    {
      validationErrors.username = 'Username is required';
    }
    if ( !formData.email )
    {
      validationErrors.email = 'Email is required';
    }
    if ( !formData.password )
    {
      validationErrors.password = 'Password is required';
    }
    if ( formData.password !== formData.confirm_password )
    {
      validationErrors.confirm_password = 'Passwords do not match';
    }
    if ( Object.keys( validationErrors ).length > 0 )
    {
      setErrors( validationErrors );
      return;
    }

    setIsLoading( true );

    // Make an API request to register the user
    axios
      .post( BaseUrl+'register', formData ,{ mode: 'cors'})
      .then( ( response ) =>
      {
        setIsLoading( false );
        console.log(response.data.status)
        if ( response.data.status === true )
        {
          handlers.showToast('Login successful!', {
            icon: '🚀', // You can customize the icon
            duration: 3000, // Toast duration in milliseconds
          });
          navigate( `/` )
        }
        // 
        // Handle successful registration, e.g., show a success message or redirect to login
      } )
      .catch( ( error ) =>
      {
        setIsLoading( false );
        const de = error.response.data;
        if ( error.response )
        {

          // The request was made, but the server responded with an error status code
          setError( de.messages.error );
        } else if ( error.request )
        {
          // The request was made, but no response was received
          console.error( 'No response received from the server.' );
        } else
        {
          // Something happened in setting up the request that triggered an error
          console.error( 'Error in making the request:', error.message );
        }
      } );
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header text-center"><h2>Registration</h2>  </div>
            
              <form onSubmit={ handleSubmit } autoComplete='off'>
              { error ? <div class="alert alert-danger"> <span className='text-danger'> { error ? error : '' }</span>
                </div> : '' }
                <div className="card-body">
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Username:
                  </label>
                  <input
                    type="text"
                    className={ `form-control ${ errors.username ? 'is-invalid' : '' }` }
                    id="username"
                    name="username"
                    value={ formData.username }
                    onChange={ handleChange }
                  />
                  <div className="invalid-feedback">{ errors.username }</div>
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email:
                  </label>
                  <input
                    type="email"
                    className={ `form-control ${ errors.email ? 'is-invalid' : '' }` }
                    id="email"
                    name="email"
                    value={ formData.email }
                    onChange={ handleChange }
                  />
                  <div className="invalid-feedback">{ errors.email }</div>
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password:
                  </label>
                  <input
                    type="password"
                    className={ `form-control ${ errors.password ? 'is-invalid' : '' }` }
                    id="password"
                    name="password"
                    value={ formData.password }
                    onChange={ handleChange }
                  />
                  <div className="invalid-feedback">{ errors.password }</div>
                </div>
                <div className="mb-3">
                  <label htmlFor="confirm_password" className="form-label">
                    Confirm Password:
                  </label>
                  <input
                    type="password"
                    className={ `form-control ${ errors.confirm_password ? 'is-invalid' : ''
                      }` }
                    id="confirm_password"
                    name="confirm_password"
                    value={ formData.confirm_password }
                    onChange={ handleChange }
                  />
                  <div className="invalid-feedback">{ errors.confirm_password }</div>
                </div>
                
                </div><div className="card-footer text-center">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={ isLoading }
                  >
                    { isLoading ? 'Loading...' : 'Register' }
                  </button> <Link to="/">Login</Link>
                </div>
              </form>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
