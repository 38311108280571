import React, { useState, useEffect, useRef } from "react";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { toast } from 'react-toastify';
import Select, { components } from 'react-select';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Tab, Nav } from 'react-bootstrap';
import axios from 'axios';
import BaseUrl from '../../BaseUrl';
import { GlobalError } from '../../../validations/globalArray';
import { emtySelect, maxlength, Pincoode, OnlyNumber, validInput, Star, Editicon } from '../../../validations/validations'
import customStyles from '../../../validations/customStyles';
import useLinkManagement from '../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../CommonComponents/DynamicTitleComponent';
import CheckAuth from '../../../APIHelper/CheckAuth';
import { Loaderr } from '../../../CommonComponents/Loader';
import CommonFunctions,{fetchOptionsFrom} from "../../../Common/CommonFunction";
const AddGoods = () =>
{

    // comon use 
    const { isLoggedInToken, handleLogout, setLoggedIn,  isLoading, setIsLoading } = CheckAuth();
    const {AddChangeData, EditChangeData, setInWhitchData, setInWhitchFrome, RemoveSSFild, setHSNSaCType , RCM,splitLastInWitchD, splitLastInWitchF } = CommonFunctions();
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, splitLastFocus, setFocus } = useLinkManagement();
    const [ error, setError ] = useState( '' );
    //input filds
    const [ goods_name, Setgoods_name ] = useState( '' ); const GnameRef = useRef();
    const [ print_goods_name, Setprint_goods_name ] = useState( '' ); const PnameRef = useRef();
    const [ Uqc, SetUqc ] = useState( '' );
    const [ Hsn, SetHsn ] = useState( '' ); const HsnRef = useRef();
    const [ StokGroup, SetStokGroup ] = useState( '' ); const StokGroupRef = useRef();
    const [ StoksubGroup, SetStoksubGroup ] = useState( '' ); const StokSubGroupRef = useRef();
    const [ Rate_given, SetRate_given ] = useState( 0 );
    const [ Stock_maintain, SetStock_maintain ] = useState( 0 );
    const [ Godown_maintain, SetGodown_maintain ] = useState( 1 );
    const [ packing_maintain, SetPacking_maintain ] = useState( 1 );
    const [ Stock_register, SetStock_register ] = useState( 1 );
    const [ Lot_maintain, SetLot_maintain ] = useState( 1 );

    //Trading AC 
    const [ group_effect_form, setGroupEffectForm ] = useState( "" ); const groupEffectFormRef = useRef();
    const [ group_trading_ac, setGroupTradingAc, ] = useState( null ); const groupTrading = useRef();
    const [ TacDetail, setTacDetail ] = useState( [] );
    const [ editIndex, setEditIndex ] = useState( -1 );
    const [ disabledRows, setDisabledRows ] = useState( [] );

    
    //Tax Detail 
    const [ tax_effect_form, setTax_effect_form ] = useState( "" ); const tax_effect_formRef = useRef();
    const [ tax_rcm, setTax_rcm, ] = useState( { value: "No", label: "No" } ); const tax_rcmRef = useRef();
    const [ tax_p_tax, setTax_p_tax, ] = useState( null ); const tax_p_taxRef = useRef();
    const [ tax_s_tax, setTax_s_tax, ] = useState( null ); const tax_s_taxRef = useRef();
    const [ TaxDetail, setTaxDetail  ] = useState( [] );
    const [ editTaxIndex, setEditTaxIndex ] = useState( -1 );

    //Cess Detail
    const [ cess_effect_form, setCess_effect_form ] = useState( "" );
    const cess_effect_formRef = useRef();

    const [ advol_yn, setadvol_yn ] = useState( 1 );
    const [ cess_advol, setCess_advol, ] = useState( null ); const cess_advolRef = useRef();
    const [ IsDisabled, setIsDisabled ] = useState( false );

    const [ non_advol_yn, setnon_advol_yn ] = useState( 1 );
    const [ non_advol, setnon_advol ] = useState( null ); const non_advolRef = useRef();
    const [ Dis_non_adv, setDis_non_adv ] = useState( false );

    const [ CessDetail, setCessDetail ] = useState( [] );
    const [ editCessIndex, setEditCess ] = useState( -1 );
    const [ adolOpt, setAdolOpt ] = useState( [] );
    const [ nonadolOpt, setNonadolOpt ] = useState( [] );

    //Stock Packing 
    const [ PackingName, setPackingName ] = useState( "" ); const PackingNameRef = useRef();
    const [ group_paid_rate, setgroup_paid_rate ] = useState( "" );
    const [ group_taxable_rate, setgroup_taxable_rate ] = useState( "" );
    const [ PackingDetail, setPackingDetail  ] = useState( [] );
    const [ editPackingIndex, setEditPackingIndex ] = useState( -1 );
    //Options
    const [ UqcOptions, setUqcOptions ] = useState( '' );
    const [ HsnOptions, setHsnOptions ] = useState( '' );
    const [ StockGroupOptions, setStockGroupOpt ] = useState( '' );
    const [ StocksubGroupOptions, setStocksubGroupOpt ] = useState( '' );
    const [ TradingAcOptions, setTradingAcOptions ] = useState( [] );
    const [ tax_options, setTax_options ] = useState( '' );
    const [ PackingNameOpt, setPackingNameOpt ] = useState( '' );

    const [ activeTab, setActiveTab ] = useState( 'grouptd' );

    const handleTabSelect = ( tab ) => { setActiveTab( tab ); };
    useEffect( () => //Get Session storage and set value
    {
        const savedData = sessionStorage.getItem( 'goods_data' );
        if ( savedData )
        {
            setIsLoading( true );
            const data = JSON.parse( savedData );
            Setgoods_name( data.goods_name );
            Setprint_goods_name( data.print_goods_name );
            SetUqc( data.Uqc );
            SetHsn( data.Hsn );
            SetStokGroup( data.StokGroup );
            SetStoksubGroup( data.StoksubGroup );
            SetRate_given( data.Rate_given );
            SetStock_maintain( data.Stock_maintain );
            SetGodown_maintain( data.Godown_maintain );
            SetPacking_maintain( data.packing_maintain );
            SetStock_register( data.Stock_register );
            SetLot_maintain( data.Lot_maintain );
            setGroupEffectForm( data.group_effect_form );
            setGroupTradingAc( data.group_trading_ac );
            setTacDetail( data.TacDetail );
            setEditIndex( data.editIndex );
            setDisabledRows( data.disabledRows );
            setTax_effect_form( data.tax_effect_form );
            setTax_rcm( data.tax_rcm );
            setTax_p_tax( data.tax_p_tax );
            setTax_s_tax( data.tax_s_tax );
            setTaxDetail( data.TaxDetail );
            setCess_effect_form( data.cess_effect_form );
            setadvol_yn( data.advol_yn );
            setCess_advol( data.cess_advol );
            setIsDisabled( data.IsDisabled );

            setnon_advol_yn( data.non_advol_yn );
            setnon_advol( data.non_advol );
            setDis_non_adv( data.Dis_non_adv );

            setCessDetail( data.CessDetail );
            setEditCess( data.editCessIndex );


            setPackingName( data.PackingName );
            setgroup_paid_rate( data.group_paid_rate );
            setgroup_taxable_rate( data.group_taxable_rate );
            setPackingDetail( data.PackingDetail );
            setEditPackingIndex( data.editPackingIndex );



            setActiveTab( data.activeTab );

        }
        if ( advol_yn === '0' ) { setIsDisabled( true ); setCess_advol( null ); }
        if ( advol_yn === '1' ) { setIsDisabled( false ); }
        if ( non_advol_yn === '0' ) { setDis_non_adv( true ); setnon_advol( null ); }
        if ( non_advol_yn === '1' ) { setDis_non_adv( false ); }
        setIsLoading( false );
    }, [] );
    // Fetch Uqc For DB
    useEffect( () =>
    {
        const fetchUqc = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }/uqc`, { mode: 'cors' } );
                const data = response.data;
                const mappedOptions = data.map( item => ( { value: item.id, label: item.uqcCode + ' (' + item.uqcCodeDetail + ')' } ) );
                setUqcOptions( mappedOptions );
            }
            catch ( error ) { console.error( 'Error fetching UQC:', error ); }
        };
        fetchUqc();
    }, [] );

    useEffect( () =>
    {
        fetchOptionsFrom('hsnonly', 'id', 'hsnCode').then(mappedOptions => {setHsnOptions(mappedOptions); });
        fetchOptionsFrom('stockgroup', 'id', 'name').then(mappedOptions => {setStockGroupOpt(mappedOptions); });
        fetchOptionsFrom('stocksubgroup', 'id', 'name').then(mappedOptions => {setStocksubGroupOpt(mappedOptions); });
        fetchOptionsFrom('vaccountopt', 'id', 'accName').then(mappedOptions => {setTradingAcOptions(mappedOptions); });
        fetchOptionsFrom('gsttype', 'id', 'name').then(mappedOptions => {setTax_options(mappedOptions); });
        fetchOptionsFrom('cess_adol', 'id', 'name').then(mappedOptions => {setAdolOpt(mappedOptions); });
        fetchOptionsFrom('cess_nonadol', 'id', 'name').then(mappedOptions => {setNonadolOpt(mappedOptions); });
        fetchOptionsFrom('packingopt', 'id', 'name').then(mappedOptions => {setPackingNameOpt(mappedOptions); });


        // // Fetch HSN/SAC For DB
        // // fetchOptionsFromApi( 'hsnonly', setHsnOptions, 'id', 'hsnCode' );
        // // Fetch Stock Group For DB
        // fetchOptionsFromApi( 'stockgroup', setStockGroupOpt, 'id', 'name' );
        // // Fetch Stock Sub Group For DB
        // fetchOptionsFromApi( 'stocksubgroup', setStocksubGroupOpt, 'id', 'name' );
        // // Fetch Trading A/c For DB
        // fetchOptionsFromApi( 'vaccountopt', setTradingAcOptions, 'id', 'accName' );
        // // Fetch Gst Type For DB
        // fetchOptionsFromApi( 'gsttype', setTax_options, 'id', 'name' );
        // //Cess Adol
        // fetchOptionsFromApi( 'cess_adol', setAdolOpt, 'id', 'name' );
        // //Cess Adol
        // fetchOptionsFromApi( 'cess_nonadol', setNonadolOpt, 'id', 'name' );
        // //Cess Adol
        // fetchOptionsFromApi( 'packingopt', setPackingNameOpt, 'id', 'name' );
    }, [] );

    // Select Options Common Api 
    const fetchOptionsFromApi = async ( fromget, setFrom, valueId, labelName ) =>
    {
        try
        {
            // Make a GET request using Axios to the specified URL.
            const response = await axios.get( `${ BaseUrl }${ fromget }`, { mode: 'cors' } );

            // Extract the response data.
            const data = response.data;

            // Map the data into an array of objects with 'value' and 'label' properties.
            const mappedOptions = data.map( item => ( { value: item[ valueId ], label: item[ labelName ] } ) );

            // Set the mapped options in your component's state using the provided 'setFrom' function.
            setFrom( mappedOptions );
        } catch ( error )
        {
            // Handle errors by logging them to the console.
            console.error( 'Error fetching data:', error );
        }
    };
    // Common function to set 'goods_data' in sessionStorage
    const setGoodsData = (frome=null) =>
    {
        RemoveSSFild( 'goods_data' );
        setInWhitchData( 'goods_data' ); 
        setInWhitchFrome( frome );        //In Whitch Value Update on Date
        sessionStorage.setItem( 'goods_data', JSON.stringify( {
            goods_name, print_goods_name, Uqc, Hsn, StokGroup, StoksubGroup, Rate_given, Stock_maintain,
            Godown_maintain, packing_maintain, Stock_register, Lot_maintain,
            group_effect_form, group_trading_ac, TacDetail, editIndex, disabledRows,
            tax_effect_form, tax_rcm, tax_p_tax, tax_s_tax, TaxDetail,
            cess_effect_form, advol_yn, IsDisabled, cess_advol, non_advol_yn, non_advol, Dis_non_adv, editCessIndex, CessDetail,
            PackingName,group_paid_rate,group_taxable_rate,PackingDetail,editPackingIndex,
            activeTab,
        } ) );
    };
  
    const GstData = () => ( {
        name: goods_name.trim(),
        pname: print_goods_name.trim(),
        uqc: Uqc.value,
        hsnn: Hsn.value,
        StokGroup: StokGroup.value,
        stocksub: StoksubGroup.value,
        // Manufacturer: Manufacturer.value,
        rate_given: Rate_given,
        Stock_maintain: Stock_maintain,
        Stock_register: Stock_register,
        packing_maintain: packing_maintain,
        godownmaintain: Godown_maintain,
        batchmaintain: Lot_maintain,
        trading_account_data: TacDetail,
        taxdetail: TaxDetail,
        cessdetail: CessDetail,
        packingdetail: PackingDetail
    } );



    ///---------------------------------------------Hsn
    const HsnChange = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'Hsn empty' ); }
        else { SetHsn( selectedOption ); }
    };
    //Add New Hsn Btn
    const AddHsnButton = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <span
                    onClick={ add_HsnForm }
                    className="btn btn-xsm  btn-primary btn-block w-100"
                > Add </span >
            </components.MenuList>
        );
    };
    // Add New Hsn Form
    const add_HsnForm = () => 
    {
        setFocus( 'HsnRef' );           //Set Focus 
        setGoodsData('Hsn');                 //Call Goods Input Field Data
        addLink( `add_hsnsac` );        //Set One New Link For view 
        setHSNSaCType( 'HSN' );
        navigateelast();                //Open Next Page


    };
    //Edit HSN
    const EditHsnForm = ( props ) => //Edit Hsn Form
    {
        const value = props.selectProps.value.value;
        RemoveSSFild( 'goods_data' );                 //Remove Array on Session Storage
        setFocus( 'HsnRef' );                 //Set Focus 
        setGoodsData('Hsn');                             //Call Goods Input Field Data
        addLink( `edit_hsnsac,${ value }` );    //Set One New Link For view
        setHSNSaCType( 'HSN' );
        navigateelast();
    };
    const EditHsn = ( props ) => //Edit Hsn Button
    {
        return (
            <>
                <div
                    onClick={ () => EditHsnForm( props ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const HsnEdit = ( { children, ...props } ) => // Get Selected id then pass the id on Edit Hsn Button
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditHsn { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };
    ///---------------------------------------------Hsn



    ///---------------------------------------------Stock Group
    const StokGrupChange = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'Stock Group empty' ); }
        else { SetStokGroup( selectedOption ); }
    };
    //Add Stock Group Btn
    const AddstkGrupButton = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <span
                    onClick={ add_stkGrup }
                    className="btn btn-xsm  btn-primary btn-block w-100"
                > Add </span >
            </components.MenuList>
        );
    };
    const add_stkGrup = () => 
    {
        setFocus( 'StokGroupRef' );     //Set Focus 
        setGoodsData('StokGroup');                 //Call Goods Input Field Data
        addLink( `add_stockgroup` );    //Set One New Link For view 
        navigateelast();                //Open Next Page

    };
    //Edit Stock Group
    const StockGroupForm = ( props ) => //Edit Stock Group Form
    {
        const value = props.selectProps.value.value;
        RemoveSSFild( 'goods_data' );                 //Remove Array on Session Storage
        setFocus( 'StokGroupRef' );                 //Set Focus 
        setGoodsData('StokGroup' );                             //Call Goods Input Field Data
        addLink( `edit_stockgroup,${ value }` );    //Set One New Link For view 
        navigateelast();                            //Open Next Page
    };
    const EditStockGroup = ( props ) => //Edit Stock Group Button
    {
        return (
            <>
                <div
                    onClick={ () => StockGroupForm( props ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const HsnStockGroup = ( { children, ...props } ) => // Get Selected id then pass the id on Edit Stock Group Button
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditStockGroup { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };

    ///---------------------------------------------Stock Group



    ///--------------------------------------------- Stock Sub Group
    const StoksubGrupChange = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'Stock Sub Group empty' ); }
        else { SetStoksubGroup( selectedOption ); }
    };
    //Add Sub Stock Group Btn
    const AddstksubGrupButton = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <span
                    onClick={ add_stksubGrup }
                    className="btn btn-xsm  btn-primary btn-block w-100"
                > Add </span >
            </components.MenuList>
        );
    };
    const add_stksubGrup = () => 
    {
        setFocus( 'StokSubGroupRef' );     //Set Focus 
        setGoodsData('StoksubGroup');                 //Call Goods Input Field Data
        addLink( `add_stocksubgroup` );    //Set One New Link For view 
        navigateelast();                //Open Next Page
    };
    //Edit Stock Sub Group
    const StockSubGroupForm = ( props ) => //Edit Stock Sub Group Form
    {
        const value = props.selectProps.value.value;
        RemoveSSFild( 'goods_data' );                 //Remove Array on Session Storage
        setFocus( 'StokSubGroupRef' );                 //Set Focus 
        setGoodsData('StoksubGroup' );                             //Call Goods Input Field Data
        addLink( `edit_stocksubgroup,${ value }` );    //Set One New Link For view 
        navigateelast();                            //Open Next Page
    };
    const EditStockSubGroup = ( props ) => //Edit Stock Sub Group Button
    {
        return (
            <>
                <div
                    onClick={ () => StockSubGroupForm( props ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const EditStockSubGroupBtn = ( { children, ...props } ) => // Get Selected id then pass the id on Edit Stock Sub Group Button
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditStockSubGroup { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };
    ///--------------------------------------------- Stock Sub Group




    //--------------------------------------------------Add New Row Trading Data
    //Change Trading Value on Select option
    const TradingAcChange = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'TradingAc empty' ); }
        else { setGroupTradingAc( selectedOption ); }
    };
    const AddTradingAc = () =>
    {
        const effectType = group_effect_form.trim();
        const groupTradingAc = group_trading_ac;

        if ( editIndex !== -1 )
        {
            // Edit Efectfrom or Trading Account
            if ( effectType === '' || effectType === null || effectType === undefined )
            {
                groupEffectFormRef.current.focus();
                return false;
            } else if ( groupTradingAc == null || groupTradingAc === '' )
            {
                groupTrading.current.focus();
                return false;
            } else
            {
                // Check if the entry already exists with the same date or trading account
                let Efd = 0;
                let TAc = 0;

                TacDetail.forEach( ( entry, index ) =>
                {
                    if ( entry.group_effect_form === effectType && index !== editIndex )
                    { Efd++; }
                    if ( entry.group_trading_ac.value === groupTradingAc.value && index !== editIndex )
                    { TAc++; }
                } );

                if ( Efd !== 0 )
                {
                    // alert( 'Effect from is already in use!' );
                    toast.error( 'Effect from is already in use!' );
                    groupEffectFormRef.current.focus();
                    return false;
                }
                if ( TAc !== 0 )
                {
                    toast.error( 'Trading Account is already selected!' );
                    groupTrading.current.focus();
                    return false;
                }

                const updatedTacDetail = [ ...TacDetail ];
                updatedTacDetail[ editIndex ] = {
                    group_effect_form: group_effect_form,
                    group_trading_ac: group_trading_ac,
                };
                setTacDetail( updatedTacDetail );
                setGroupEffectForm( '' );
                setGroupTradingAc( null );
                setEditIndex( -1 );
                groupEffectFormRef.current.focus();
                setDisabledRows( [] );
            }
        } else
        {
            // Add New Row
            if ( effectType === '' || effectType === null || effectType === undefined )
            {
                groupEffectFormRef.current.focus();
                return false;
            } else if ( groupTradingAc == null || groupTradingAc === '' )
            {
                groupTrading.current.focus();
                return false;
            } else
            {
                // Check if the entry already exists with the same date or trading account
                let Efd = 0;
                let TAc = 0;

                TacDetail.forEach( ( entry ) =>
                {
                    if ( entry.group_effect_form === effectType )
                    {
                        Efd++;
                    }
                    if ( entry.group_trading_ac.value === groupTradingAc.value )
                    {
                        TAc++;
                    }
                } );

                if ( Efd !== 0 )
                {
                    // alert( 'Effect from is already in use!' );
                    toast.error( 'Effect from is already in use!' );
                    groupEffectFormRef.current.focus();
                    return false;
                }
                if ( TAc !== 0 )
                {
                    // alert( 'Trading Account is already selected!' );
                    toast.error( 'Trading Account is already selected!' );
                    groupTrading.current.focus();
                    return false;
                }

                // Set Value in Trading Account Table
                const newTacDetail = {
                    group_effect_form: group_effect_form,
                    group_trading_ac: group_trading_ac,
                };
                setTacDetail( [ ...TacDetail, newTacDetail ] );
                setGroupEffectForm( '' );
                setGroupTradingAc( null );
                groupEffectFormRef.current.focus();
            }
        }
    };
    //Remove Row Trading Data 
    const RemoveTradingAc = ( index ) =>
    {
        const updatedTacDetail = [ ...TacDetail ];
        updatedTacDetail.splice( index, 1 );
        if ( editIndex !== -1 )
        {
            setGroupEffectForm( '' );
            setGroupTradingAc( '' );
            setEditIndex( -1 );
        }

        setTacDetail( updatedTacDetail );
    };
    //Trading Data Edit row or set value in input filds
    const EditTradingAcTB = ( index ) =>
    {
        const selectedTacDetail = TacDetail[ index ]; // Rename the parameter to avoid naming conflict
        setGroupEffectForm( selectedTacDetail.group_effect_form );
        setGroupTradingAc( selectedTacDetail.group_trading_ac, );
        setEditIndex( index );
        groupEffectFormRef.current.focus()

    };
    // Trading Data cencle Edit 
    const CancleEditTradingAcTB = ( index ) =>
    {

        setGroupEffectForm( '' );
        setGroupTradingAc( '' );
        setEditIndex( -1 );
        groupEffectFormRef.current.focus()
    };
    //Click Add Trading A/c Button Action
    const AddTradingForm = () => 
    {
        sessionStorage.getItem( 'focuseMe' ); //remove all Batch Loat No foces
        setFocus( 'groupTrading' );
        setGoodsData('group_trading_ac');
        addLink( `addaccount` );
        navigateelast();

    };
    //Trading A/c Add Button
    const AddTradingAc_Btn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <span
                    onClick={ AddTradingForm }
                    className="btn btn-xsm  btn-primary w-100 "
                > Add</span >
            </components.MenuList>
        );
    };
    //Edit Trading Account
    const EditTradingAcForm = ( props ) => //Edit Batch Loat No Form
    {
        const value = props.selectProps.value.value;
        sessionStorage.getItem( 'focusme' ); //remove all Batch Loat No foces
        sessionStorage.setItem( 'editaccountID', value );//Add  Batch Loat No foces
        setFocus( 'groupTrading' );
        sessionStorage.removeItem( 'goods_data' );
        setGoodsData();
        addLink( `edit_account,${ value }` );
        navigateelast();
    };
    const EditTradingAc = ( props ) => //Edit Batch Loat No Button
    {
        return (
            <>
                <div
                    onClick={ () => EditTradingAcForm( props ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    {/* { props.selectProps.value.value ? <Editicon /> : null } */ }
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const TradingAcEdit = ( { children, ...props } ) => // Get Selected id then pass the id on Edit Batch Loat No Button
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditTradingAc { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };
    //---------------------------------------------------------------------- Trading Data

    //-------------------------------------------------------------------------Tax Detail
    // Add New Tax Details Row
    const AddTaxDet = () =>
    {
        const effectFrom = tax_effect_form.trim();
        const tax_rcmm = tax_rcm;
        const tax_p_taxx = tax_p_tax;
        const tax_s_taxx = tax_s_tax;
        if ( effectFrom !== '' )
        {
            if ( tax_rcmm == null || tax_rcmm === '' )
            {
                tax_rcmRef.current.focus();
                return false;
            }
            if ( tax_p_taxx == null || tax_p_taxx === '' )
            {
                tax_p_taxRef.current.focus();
                return false;
            }
            if ( tax_s_taxx == null || tax_s_taxx === '' )
            {
                tax_s_taxRef.current.focus();
                return false;
            }

            if ( editTaxIndex !== -1 )
            {
                let TaxEfd = 0;
                TaxDetail.forEach( ( entry, index ) =>
                {
                    if ( entry.tax_effect_form === effectFrom && index !== editTaxIndex )
                    {
                        TaxEfd++;
                    }
                } );

                if ( TaxEfd !== 0 )
                {
                    toast.error( 'Effect from is already running in this date !' );
                    tax_effect_formRef.current.focus();
                    return false;
                }
                const updatedTaxDetail = [ ...TaxDetail ];
                updatedTaxDetail[ editTaxIndex ] = {
                    tax_effect_form: effectFrom,
                    tax_rcm: tax_rcm,
                    tax_p_tax: tax_p_tax,
                    tax_s_tax: tax_s_tax,
                };
                setTaxDetail( updatedTaxDetail );
                setTax_effect_form( "" );
                setTax_rcm( { value: "No", label: "No" } );
                setTax_p_tax( null );
                setTax_s_tax( null );
                setEditTaxIndex( -1 );
                tax_effect_formRef.current.focus()
            } else
            {
                let TaxEfd = 0;
                TaxDetail.forEach( ( entry ) =>
                {
                    if ( entry.tax_effect_form === effectFrom )
                    {
                        TaxEfd++;
                    }
                } );

                if ( TaxEfd !== 0 )
                {
                    toast.error( 'Effect from is already running in this date !' );
                    tax_effect_formRef.current.focus();
                    return false;
                }
                const newTaxDetail = {
                    tax_effect_form: effectFrom,
                    tax_rcm: tax_rcm,
                    tax_p_tax: tax_p_tax,
                    tax_s_tax: tax_s_tax,
                };
                setTaxDetail( [ ...TaxDetail, newTaxDetail ] );
                setTax_effect_form( "" );
                setTax_rcm( { value: "No", label: "No" } );
                setTax_p_tax( null );
                setTax_s_tax( null );
                tax_effect_formRef.current.focus()
            }
        } else
        {
            tax_effect_formRef.current.focus();
            return false;
        }


    };
    //Remove Tax Detail row
    const RemoveTaxDet = ( index ) =>
    {
        const updatedTaxDetail = [ ...TaxDetail ];
        updatedTaxDetail.splice( index, 1 );
        if ( editTaxIndex !== -1 )
        {
            setTax_effect_form( '' );
            setTax_rcm({ value: "No", label: "No" } );
            setTax_p_tax( '' );
            setTax_s_tax( '' );
            setEditTaxIndex( -1 );
        }
        setTaxDetail( updatedTaxDetail );
    };
    //Tax Detail Edit row or set value in input filds
    const EditTaxDet = ( index ) =>
    {
        const selectedTaxDetail = TaxDetail[ index ]; // Rename the parameter to avoid naming conflict
        setTax_effect_form( selectedTaxDetail.tax_effect_form );
        setTax_rcm( selectedTaxDetail.tax_rcm, );
        setTax_p_tax( selectedTaxDetail.tax_p_tax, );
        setTax_s_tax( selectedTaxDetail.tax_s_tax, );
        setEditTaxIndex( index );
        tax_effect_formRef.current.focus()
    };
    //Cencle Edit Trading Data  
    const CancleEditTaxdetail = ( index ) =>
    {
        setTax_effect_form( '' );
        setTax_rcm({ value: "No", label: "No" } );
        setTax_p_tax( '' );
        setTax_s_tax( '' );
        setEditTaxIndex( -1 );
        tax_effect_formRef.current.focus()
    };
    //Change RCM Value 
    const TaxRcmChange = selectedOption =>
    {
        if ( selectedOption === '' || selectedOption === undefined ) { console.log( 'RCM empty' ); }
        else { setTax_rcm( selectedOption ); }
    };
    //-------------------------------------------Tax Purchase Details
    //Purchase Tax Value Change
    const Tax_p_Tax_Change = selectedOption =>
    {
        if ( selectedOption === '' || selectedOption === undefined ) { console.log( 'Purchase Tax empty' ); }
        else { setTax_p_tax( selectedOption ); }
    };
    //Add New Purchase Tax Button
    const Add_p_Tax_btn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <span
                    onClick={ AddPurchaseTaxForm }
                    className="btn btn-xsm  btn-primary w-100 "
                > Add</span >
            </components.MenuList>
        );
    };
    //Click Add p_Tax Button Action
    const AddPurchaseTaxForm = () => 
    {
        setFocus( 'tax_p_taxRef' );             //Set Focus 
        setGoodsData('tax_p_tax');                         //Call Goods Input Field Data
        addLink( `add_gst` );                   //Set One New Link For view 
        navigateelast();                        //Open Next Page
    };
    //Edit Purchase Tax 
    const PurchanseTaxEditForm = ( props ) => //Edit Purchase Tax Form
    {
        const value = props.selectProps.value.value;
        RemoveSSFild( 'goods_data' );                    //Remove Array on Session Storage
        setFocus( 'tax_p_taxRef' );                      //Set Focus 
        setGoodsData('tax_p_tax');                                  //Call Goods Input Field Data
        addLink( `edit_gst,${ value }` );                //Set One New Link For view 
        navigateelast();                                 //Open Next Page
    };
    const PurchanseTaxEdit = ( props ) => //Edit Purchase Tax Button
    {
        return (
            <>
                <div
                    onClick={ () => PurchanseTaxEditForm( props ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const EditPurchanseTax = ( { children, ...props } ) => // Get Selected id then pass the id on Edit Purchase Tax Button
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <PurchanseTaxEdit { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };
    //-------------------------------------------Tax Sale Details

    //Sales Tax Value Change
    const Tax_s_Tax_Change = selectedOption =>
    {
        if ( selectedOption !== '' || selectedOption !== undefined ) { setTax_s_tax( selectedOption ); }
    };
    //Add New Sales Tax Button
    const Add_S_Tax_btn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <span
                    onClick={ AddSaleTaxForm }
                    className="btn btn-xsm  btn-primary w-100 "
                > Add</span >
            </components.MenuList>
        );
    };
    //Click Add New Sales Button Action
    const AddSaleTaxForm = () => 
    {
        setFocus( 'tax_s_taxRef' );             //Set Focus 
        setGoodsData('tax_s_tax');                         //Call Goods Input Field Data
        addLink( `add_gst` );                   //Set One New Link For view 
        navigateelast();                        //Open Next Page
    };
    //Edit Sale Tax 
    const SaleTaxEditForm = ( props ) => //Edit Purchase Tax Form
    {
        const value = props.selectProps.value.value;
        RemoveSSFild( 'goods_data' );                    //Remove Array on Session Storage
        setFocus( 'tax_s_taxRef' );                      //Set Focus 
        setGoodsData('tax_s_tax');                                  //Call Goods Input Field Data
        addLink( `edit_gst,${ value }` );                //Set One New Link For view 
        navigateelast();                                 //Open Next Page
    };
    const SaleTaxEdit = ( props ) => //Edit Purchase Tax Button
    {
        return (
            <>
                <div
                    onClick={ () => SaleTaxEditForm( props ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const EditSaleTax = ( { children, ...props } ) => // Get Selected id then pass the id on Edit Sale Tax Button
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <SaleTaxEdit { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };
    //-------------------------------------------Tax Sale Details
    //-------------------------------------------------------------------------Tax Detail


    //-------------------------------------------------------------------------Cess Detail
    //-------------------Cess1 Advol

    const advol_ynChange = ( e ) =>
    {
        const { name, value } = e.target;
        if ( name === 'cess_advol_yn' )
        {
            const valuee = value;
            setadvol_yn( valuee );
            if ( valuee == '0' )
            {
                setIsDisabled( true );
                setCess_advol( null )
            }
            else { setIsDisabled( false ); }
        }
    }
    const cess_advolChange = ( selectedOption ) =>
    {
        setCess_advol( selectedOption );
    };
    //Add New Cess1 Advol Button
    const Add_Cess1_Advol_btn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <span
                    onClick={ Add_Cess1_Advol_Form }
                    className="btn btn-xsm  btn-primary w-100 "
                > Add</span >
            </components.MenuList>
        );
    };
    //Click Add New Cess1 Advol Form Button Action
    const Add_Cess1_Advol_Form = () => 
    {
        setFocus( 'cess_advolRef' );             //Set Focus 
        setGoodsData('cess_advol');                         //Call Goods Input Field Data
        addLink( `add_cess` );                   //Set One New Link For view 
        setHSNSaCType( 'Amount' );
        navigateelast();                        //Open Next Page
    };
    //Edit Cess1 Advol 
    const Cess1_AdvolEditForm = ( props ) => //Edit Cess1 Advol Form
    {
        const value = props.selectProps.value.value;
        RemoveSSFild( 'goods_data' );                    //Remove Array on Session Storage
        setFocus( 'cess_advolRef' );                      //Set Focus 
        setGoodsData('cess_advol');                                  //Call Goods Input Field Data
        addLink( `edit_cess,${ value }` );                //Set One New Link For view 
        setHSNSaCType( 'Amount' );
        navigateelast();                                 //Open Next Page
    };
    const Cess1_AdvolEdit = ( props ) => //Edit Cess1 Advol Button
    {
        return (
            <>
                <div
                    onClick={ () => Cess1_AdvolEditForm( props ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const EditCess1_Advol = ( { children, ...props } ) => // Get Selected id then pass the id on Edit Cess1 Advol Button
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <Cess1_AdvolEdit { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };
    const non_advol_ynChange = ( e ) =>
    {
        const { name, value } = e.target;
        if ( name === 'non_advol_yn' )
        {
            const valuee = value;
            setnon_advol_yn( valuee );
            if ( valuee == '0' )
            {
                setDis_non_adv( true );
                setnon_advol( null )
            }
            else { setDis_non_adv( false ); }
        }
    }
    const non_advolChange = ( selectedOption ) =>
    {
        setnon_advol( selectedOption );
    };
    //Add New Cess2 Non Advol Button
    const Add_Cess2_NonAdvol_btn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <span
                    onClick={ Add_Cess2_NonAdvol_Form }
                    className="btn btn-xsm  btn-primary w-100 "
                > Add</span >
            </components.MenuList>
        );
    };
    //Click Add New Cess1 Advol Form Button Action
    const Add_Cess2_NonAdvol_Form = () => 
    {
        setFocus( 'non_advolRef' );             //Set Focus 
        setGoodsData('non_advol');                         //Call Goods Input Field Data
        addLink( `add_cess` );                   //Set One New Link For view 
        setHSNSaCType( 'Qty' );
        navigateelast();                        //Open Next Page
    };
    //Edit Cess1 Advol 
    const Cess2_NonAdvolEditForm = ( props ) => //Edit Cess1 Advol Form
    {
        const value = props.selectProps.value.value;
        RemoveSSFild( 'goods_data' );                    //Remove Array on Session Storage
        setFocus( 'non_advolRef' );                      //Set Focus 
        setGoodsData('non_advol');                                  //Call Goods Input Field Data
        addLink( `edit_cess,${ value }` );                //Set One New Link For view 
        setHSNSaCType( 'Qty' );
        navigateelast();                                 //Open Next Page
    };
    const Cess2_NonAdvolEdit = ( props ) => //Edit Cess1 Advol Button
    {
        return (
            <>
                <div
                    onClick={ () => Cess2_NonAdvolEditForm( props ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const EditCess2_NonAdvol = ( { children, ...props } ) => // Get Selected id then pass the id on Edit Cess1 Advol Button
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <Cess2_NonAdvolEdit { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };

    const addCess = () =>
    {
        const cess_effect_form_val = cess_effect_form.trim();
        const advo_yn_val = advol_yn;
        const cess_advol_val = cess_advol;
        const non_advo_yn_val = non_advol_yn;
        const non_advol_val = non_advol;

        if ( cess_effect_form_val !== '' )
        {
            if ( advo_yn_val == '1' )
            {
                if ( cess_advol_val == null || cess_advol_val === '' )
                {
                    cess_advolRef.current.focus();
                    return false;
                }
            }
            if ( non_advo_yn_val == '1' )
            {
                if ( non_advol_val == null || non_advol_val === '' )
                {
                    non_advolRef.current.focus();
                    return false;
                }
            }

            if ( editCessIndex !== -1 )
            {
                let Efd = 0;
                CessDetail.forEach( ( entry, index ) =>
                {
                    if ( entry.cess_effect_form == cess_effect_form && index !== editCessIndex )
                    { Efd++; }
                } );
                if ( Efd !== 0 )
                {
                    toast.error( 'Effect from is already running in this date !' );
                    cess_effect_formRef.current.focus();
                    return false;
                }
                const updatedCessDetail = [ ...CessDetail ];
                updatedCessDetail[ editCessIndex ] = {
                    cess_effect_form: cess_effect_form,
                    advol_yn: advol_yn,
                    cess_advol: cess_advol,
                    IsDisabled: IsDisabled,
                    non_advol_yn: non_advol_yn,
                    non_advol: non_advol,
                    Dis_non_adv: Dis_non_adv,
                };
                setCessDetail( updatedCessDetail );
                setCess_effect_form( "" );
                setadvol_yn( '1' );
                setCess_advol( null );
                setIsDisabled( false );
                setnon_advol_yn( '1' );
                setnon_advol( null );
                setDis_non_adv( false );
                setEditCess( -1 );
                cess_effect_formRef.current.focus();
            } else
            {
                let Efd = 0;

                CessDetail.forEach( ( entry ) =>
                {
                    if ( entry.cess_effect_form === cess_effect_form )
                    {
                        Efd++;
                    }
                } );

                if ( Efd !== 0 )
                {
                    // alert( 'Effect from is already in use!' );
                    toast.error( 'Effect from is already in use!' );
                    cess_effect_formRef.current.focus();
                    return false;
                }
                const newCessDetail = {
                    cess_effect_form: cess_effect_form,
                    advol_yn: advol_yn,
                    cess_advol: cess_advol,
                    IsDisabled: IsDisabled,
                    non_advol_yn: non_advol_yn,
                    non_advol: non_advol,
                    Dis_non_adv: Dis_non_adv,
                };
                setCessDetail( [ ...CessDetail, newCessDetail ] );
                setCess_effect_form( "" );
                setadvol_yn( '1' );
                setCess_advol( null );
                setIsDisabled( false );
                setnon_advol_yn( '1' );
                setnon_advol( null );
                setDis_non_adv( false );
                cess_effect_formRef.current.focus();
            }
        } else
        {
            cess_effect_formRef.current.focus();
        }
    };
    const CessEditRow = ( index ) =>
    {
        const EdcessData = CessDetail[ index ]; // Rename the parameter to avoid naming conflict
        setCess_effect_form( EdcessData.cess_effect_form );
        setadvol_yn( EdcessData.advol_yn )
        setCess_advol( EdcessData.cess_advol, );
        setIsDisabled( EdcessData.IsDisabled, );
        setnon_advol_yn( EdcessData.non_advol_yn, );
        setnon_advol( EdcessData.non_advol, );
        setDis_non_adv( EdcessData.Dis_non_adv, );
        setEditCess( index );
        cess_effect_formRef.current.focus()
    };
    //Cencle Edit Cess Detail Data  
    const CancleEditCessdetail = ( index ) =>
    {
        setCess_effect_form( '' );
        setadvol_yn( '1' ); setCess_advol( null ); setIsDisabled( false );
        setnon_advol_yn( '1' ); setnon_advol( null ); setDis_non_adv( false );
        setEditCess( -1 );
        cess_effect_formRef.current.focus()
    };
    const CessRemove = ( index ) =>
    {
        const Remove_cess = [ ...CessDetail ];
        Remove_cess.splice( index, 1 );
        if ( editCessIndex !== -1 )
        {
            setCess_effect_form( '' );
            setadvol_yn( '1' ); setCess_advol( '' ); setIsDisabled( false );
            setnon_advol_yn( '1' ); setnon_advol( '' ); setDis_non_adv( false );
            setEditCess( -1 );
        }
        setCessDetail( Remove_cess );
    };
    //-------------------------------------------------------------------------Cess Detail


    //-------------------------------------------------------------------------Stock Packing
    const PackingNameChange = ( selectedOption ) =>
    {
        setPackingName( selectedOption );
    }

      //Add New Cess2 Non Advol Button
      const Add_packing_btn = ( props ) =>
      {
          return (
              <components.MenuList { ...props }>
                  { props.children }
                  <span
                      onClick={ Add_packing_Form }
                      className="btn btn-xsm  btn-primary w-100 "
                  > Add</span >
              </components.MenuList>
          );
      };
      //Click Add New Cess1 Advol Form Button Action
      const Add_packing_Form = () => 
      {
          setFocus( 'PackingNameRef' );             //Set Focus 
          setGoodsData('PackingName');                         //Call Goods Input Field Data
          addLink( `add_packing` );                   //Set One New Link For view 
          navigateelast();                        //Open Next Page
      };
      //Edit Cess1 Advol 
    const packingEditForm = ( props ) => //Edit Cess1 Advol Form
    {
        const value = props.selectProps.value.value;
        setFocus( 'PackingNameRef' );                      //Set Focus 
        setGoodsData('PackingName');                                  //Call Goods Input Field Data
        addLink( `edit_packing,${ value }` );                //Set One New Link For view 
        navigateelast();                                 //Open Next Page
    };
    const PackingEdit = ( props ) => //Edit Cess1 Advol Button
    {
        return (
            <>
                <div
                    onClick={ () => packingEditForm( props ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon/>
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const Editpacking = ( { children, ...props } ) => // Get Selected id then pass the id on Edit Cess1 Advol Button
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <PackingEdit { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };

    const addPacking = () =>
    {
        
        if ( PackingName !== '' && PackingName !== null && PackingName !== undefined )
        {
           

            if ( editPackingIndex !== -1 )
            {
                let Efd = 0;
                PackingDetail.forEach( ( entry, index ) =>
                {
                    if ( entry.packingname.value == PackingName.value && index !== editPackingIndex )
                    { Efd++; }
                } );
                if ( Efd !== 0 )
                {
                    toast.error( 'This PackingName already Selected !' );
                    PackingNameRef.current.focus();
                    return false;
                }
                const UPDPackingDet = [ ...PackingDetail ];
                UPDPackingDet[ editPackingIndex ] = {
                    packingname: PackingName,
                    group_paid_rate: group_paid_rate,
                    group_taxable_rate: group_taxable_rate
                };
                setPackingDetail( UPDPackingDet );
                setPackingName( "" );
                setgroup_paid_rate( "" );
                setgroup_taxable_rate( "" );
                setEditPackingIndex( -1 );
                PackingNameRef.current.focus();
            } else
            {
                let Efd = 0;
                PackingDetail.forEach( ( entry ) =>
                {
                    if ( entry.packingname.value === PackingName.value )
                    {
                        Efd++;
                    }
                } );

                if ( Efd !== 0 )
                {
                    toast.error( 'This PackingName already Selected !' );
                    PackingNameRef.current.focus();
                    return false;
                }
                const newPackingDetail = {
                    packingname: PackingName,
                    group_paid_rate: group_paid_rate,
                    group_taxable_rate: group_taxable_rate
                };
                setPackingDetail( [ ...PackingDetail, newPackingDetail ] );
                setPackingName( "" );
                setgroup_paid_rate( "" );
                setgroup_taxable_rate( "" );
                PackingNameRef.current.focus();
            }
        } else
        {
            PackingNameRef.current.focus();
        }
    };
    const PackingEditRow = ( index ) =>
    {
        const EdPackingData = PackingDetail[ index ]; // Rename the parameter to avoid naming conflict
        setPackingName( EdPackingData.packingname );
        setgroup_paid_rate( EdPackingData.group_paid_rate )
        setgroup_taxable_rate( EdPackingData.group_taxable_rate, );
        setEditPackingIndex( index );
        PackingNameRef.current.focus()
    };
    const CancleEditPacking = ( index ) =>
    {
        setPackingName( '' );
        setgroup_paid_rate( '' ); 
        setgroup_taxable_rate( '' ); 
        setEditPackingIndex( -1 );
        PackingNameRef.current.focus()
    };
    const PackingRemove = ( index ) =>
    {
        const Remove_Packing = [ ...PackingDetail ];
        Remove_Packing.splice( index, 1 );
        if ( editPackingIndex !== -1 )
        {
            setPackingName( '' );
            setgroup_paid_rate( '' ); 
            setgroup_taxable_rate( '' ); 
            setEditPackingIndex( -1 );
        }
        setPackingDetail( Remove_Packing );
    };
    //-------------------------------------------------------------------------Stock Packing


    // Change HSN Option and Set Hsn value
    const UqcChange = selectedOption => { SetUqc( selectedOption ); };

    const foCoUs = ( foc ) => // Add Invalid class or set focus
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>// remove Invalid class 
    {
        foc.current.classList.remove( 'is-invalid' );
    };
    const removeallinvalid = () =>
    {
        GnameRef.current.classList.remove( 'is-invalid' );
        PnameRef.current.classList.remove( 'is-invalid' );
    };
    const changeValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'goods_name' ) { var valuee = validInput( value ); Setgoods_name( valuee ); Setprint_goods_name( valuee ); removeinvalid( GnameRef ); }
        if ( name === 'goods_print_name' ) { var valuee = validInput( value ); Setprint_goods_name( valuee ); removeinvalid( PnameRef ); }
        if ( name === 'group_effect_form' ) { var valuee = validInput( value ); setGroupEffectForm( valuee ); } 
        if ( name === 'group_taxable_rate' ) { var valuee = validInput( value ); setgroup_taxable_rate( valuee ); }
        if ( name === 'group_paid_rate' ) { var valuee = validInput( value ); setgroup_paid_rate( valuee ); }
    };



    //Leave This Page Confirmation 
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    //Page Leave Confirmation Accept
    const accept = () =>
    {
        sessionStorage.removeItem( 'goods_data' );
        const LastLink = getLastLinkInSession();
        if ( LastLink === 'add_goods_m' ) { splitLastInWitchD( 'f' ); splitLastInWitchF( 'f' ); }
        splitLastInWitchD();
        splitLastInWitchF();
        handleSplitLastLink();
        navigateelast();
    };


    const submit = ( e ) =>
    {
        e.preventDefault();
        setError( '' );
        const gname = maxlength( goods_name.trim(), 'Goods name', 50 );
        const pname = maxlength( print_goods_name.trim(), 'Print name', 50 );
        const Hsnnn = emtySelect( Hsn, 'HSN' );
        if ( gname !== true ) { setError( gname ); foCoUs( GnameRef ); return false; }
        else if ( pname !== true ) { setError( pname ); foCoUs( PnameRef ); return false; }
        else if ( Hsnnn !== true ) { setError( Hsnnn ); HsnRef.current.focus(); return false; }
        else
        {
            SaveRequest();
        }
    };
    const SaveRequest = () =>
    {
        setIsLoading( true );
        axios
            .post( `${ BaseUrl }goods`, GstData(), { mode: 'cors' } )
            .then( function ( response )
            {
                const data = response.data;
                if ( data.success === true )
                {
                    toast.success( 'Add Goods Success' );
                    sessionStorage.removeItem( 'goods_data' );
                    const cityLastLink = getLastLinkInSession();
                    if ( cityLastLink === 'add_goods_m' )
                    {
                        setError( '' );
                        removeallinvalid(); Setgoods_name( '' ); Setprint_goods_name( '' ); SetHsn( '' ); SetStokGroup( '' ); SetUqc( '' );
                        SetStoksubGroup( '' ); setTacDetail( [] ); setPackingDetail([]); setCessDetail([]); setTaxDetail([]); 
                        GnameRef.current.focus();
                    } else
                    {
                        handleSplitLastLink();
                        AddChangeData( goods_name, data.Newid ); // Change Last Open Popup Data or fild
                        const LastLinkk = getLastLinkInSession();
                        if ( LastLinkk === 'add_batchlot_m' || LastLinkk === 'add_batchlot' || LastLinkk === "edit_batchlot" )
                        {
                            const batchlot = sessionStorage.getItem( 'batch_lot_data' );
                            if ( batchlot !== '' || batchlot !== null || batchlot !== undefined )
                            {
                                const d = batchlot ? JSON.parse( batchlot ) : {};
                                const value = data.Newid;
                                const label = goods_name;
                                d.batch_goods = { value, label };
                                sessionStorage.setItem( 'batch_lot_data', JSON.stringify( d ) );
                            }
                        }
                        // if ( LastLinkk === 'add_opening_stock_m' || LastLinkk === 'add_opening_stock' || LastLinkk === "edit_opening_stock" )
                        // {
                        //     const openingstockdata = sessionStorage.getItem( 'openingstock' );
                        //     if ( openingstockdata !== '' || openingstockdata !== null || openingstockdata !== undefined )
                        //     {
                        //         const d = openingstockdata ? JSON.parse( openingstockdata ) : {};
                        //         const value = data.Newid;
                        //         const label = goods_name;
                        //         d.Goods = { value, label };
                        //         sessionStorage.setItem( 'openingstock', JSON.stringify( d ) );
                        //     }
                        // }
                        navigateelast();
                    }
                }
                setIsLoading( false );
            } )
            .catch( function ( error )
            {
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors ); foCoUs( GnameRef );
                setIsLoading( false );
            } );
    }

    // // Set Focuse 
    // useEffect( () =>
    // {
    //     const getFocus = JSON.parse( sessionStorage.getItem( 'focusme' ) );
    //     if ( getFocus && getFocus.length > 0 )
    //     {
    //         const setfocus = getFocus[ getFocus.length - 1 ];
    //         setTimeout( () =>
    //         {
    //             if ( setfocus === 'groupTrading' ) { groupTrading.current.focus(); splitLastFocus(); }
    //             if ( setfocus === 'HsnRef' ) { HsnRef.current.focus(); splitLastFocus(); }
    //             if ( setfocus === 'StokGroupRef' ) { StokGroupRef.current.focus(); splitLastFocus(); }
    //             if ( setfocus === 'StokSubGroupRef' ) { StokSubGroupRef.current.focus(); splitLastFocus(); }
    //             if ( setfocus === 'tax_p_taxRef' ) { tax_p_taxRef.current.focus(); splitLastFocus(); }
    //             if ( setfocus === 'tax_s_taxRef' ) { tax_s_taxRef.current.focus(); splitLastFocus(); }
    //             if ( setfocus === 'cess_advolRef' ) { cess_advolRef.current.focus(); splitLastFocus(); }
    //         }, 5 ); // 10 milliseconds delay
    //     }
    //     setIsLoading( false );

    // }, [] );
    useEffect( () =>
    {
        const getFocus = JSON.parse( sessionStorage.getItem( 'focusme' ) );

        if ( getFocus && getFocus.length > 0 )
        {
            const focusMap = {
                groupTrading: groupTrading,
                HsnRef: HsnRef,
                StokGroupRef: StokGroupRef,
                StokSubGroupRef: StokSubGroupRef,
                tax_p_taxRef: tax_p_taxRef,
                tax_s_taxRef: tax_s_taxRef,
                cess_advolRef: cess_advolRef,
                PackingNameRef: PackingNameRef,
            };

            const setfocus = getFocus[ getFocus.length - 1 ];
            const refToFocus = focusMap[ setfocus ];

            if ( refToFocus )
            {
                setTimeout( () =>
                {
                    refToFocus.current.focus();
                    splitLastFocus();
                }, 10 ); // 10 milliseconds delay
            }
        }

        setIsLoading( false );
    }, [] );

    return ( <>
        <div className=" mt-2 main">
            <DynamicTitleComponent newTitle="Add Goods" />
            <ConfirmDialog />
            <div className="card">
            { isLoading ? ( <Loaderr/> ) : ( '' ) }
                <div className="card-header">  <h4 className="mb-0">Add Goods</h4></div>
                <div className="card-body container-fluid">
                    <form autoComplete='off' onSubmit={ submit }>
                        <div className="row ">
                            <span>
                                <span className='float-end' style={ { color: 'red' } }> { error }</span></span>
                            <div className="col-xl-12 col-md-12 col-sm-12 ">

                                <div className="form-group row">
                                    <div className="col-md-3 col-sm-6">
                                        <label htmlFor="goods_name" className="  "> Goods Name</label> <Star />
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            value={ goods_name }
                                            name="goods_name"
                                            ref={ GnameRef }
                                            required
                                            onChange={ changeValue }
                                            placeholder="Name"
                                            autoFocus
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <label htmlFor="goods_print_name" > Print Name </label><Star />
                                        <input
                                            type="text"
                                            className="form-control form-control-sm "
                                            value={ print_goods_name }
                                            ref={ PnameRef }
                                            onChange={ changeValue }
                                            required
                                            name="goods_print_name"
                                            placeholder="Name"
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <label htmlFor="goods_uqc">UQC</label><Star />
                                        <Select
                                            name="goods_uqc"
                                            options={ UqcOptions }
                                            value={ Uqc }
                                            onChange={ UqcChange }
                                            styles={ customStyles }
                                            isClearable
                                            isSearchable
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <label htmlFor="goods_hsn">HSN</label>
                                        <Select
                                            name="goods_hsn"
                                            options={ HsnOptions }
                                            value={ Hsn }
                                            ref={ HsnRef }
                                            onChange={ HsnChange }
                                            components={ { MenuList: AddHsnButton, IndicatorsContainer: HsnEdit } }
                                            styles={ customStyles }
                                            isClearable
                                            isSearchable
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <label htmlFor="goods_stoc_group" >Stock Group</label>
                                        <Select
                                            ref={ StokGroupRef }
                                            options={ StockGroupOptions }
                                            value={ StokGroup }
                                            onChange={ StokGrupChange }
                                            components={ { MenuList: AddstkGrupButton, IndicatorsContainer: HsnStockGroup } }
                                            styles={ customStyles }
                                            isClearable
                                            isSearchable
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <label htmlFor="goods_stock_subgroup">Stock Sub Group</label>
                                        <Select
                                            ref={ StokSubGroupRef }
                                            options={ StocksubGroupOptions }
                                            value={ StoksubGroup }
                                            onChange={ StoksubGrupChange }
                                            components={ { MenuList: AddstksubGrupButton, IndicatorsContainer: EditStockSubGroupBtn } }
                                            styles={ customStyles }
                                            isClearable
                                            isSearchable
                                        />
                                    </div>

                                    <div className="col-md-3 col-sm-4 ">
                                        <label htmlFor="goods_rate_given_in" className="  ">
                                            Rate Given
                                        </label>
                                        <select
                                            className="form-select form-select-sm"
                                            name="goods_rate_given_in"
                                            value={ Rate_given }
                                            onChange={ ( e ) => { SetRate_given( e.target.value ); } }
                                            id="goods_rate_given_in"
                                        >
                                            <option value={ 0 }>Unit</option>
                                            <option value={ 1 }>Qty.</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3 col-sm-4 ">
                                        <label htmlFor="goods_stock_maintain_in" className="  ">
                                            Stock Maintain
                                        </label>
                                        <select
                                            className="form-select form-select-sm"
                                            name="goods_stock_maintain_in"
                                            id="goods_stock_maintain_in"
                                            value={ Stock_maintain }
                                            onChange={ ( e ) => { SetStock_maintain( e.target.value ); } }
                                        >
                                            <option value={ 0 }>Unit</option>
                                            <option value={ 1 }>Qty.</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3 col-sm-4 ">
                                        <label htmlFor="goods_stock_maintain_yn" className="  ">
                                            Godown Maintain
                                        </label>
                                        <select
                                            className="form-select form-select-sm"
                                            name="goods_stock_maintain_yn"
                                            id="goods_stock_maintain_yn"
                                            value={ Godown_maintain }
                                            onChange={ ( e ) => { SetGodown_maintain( e.target.value ); } }
                                        >
                                            <option value={ 1 }>Yes</option>
                                            <option value={ 0 }>No.</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3 col-sm-4 ">
                                        <label htmlFor="goods_stock_packing" className="  ">
                                            Packing Maintain
                                        </label>
                                        <select
                                            className="form-select form-select-sm"
                                            name="goods_stock_packing"
                                            id="goods_stock_packing"
                                            value={ packing_maintain }
                                            onChange={ ( e ) => { SetPacking_maintain( e.target.value ); } }
                                        >
                                            <option value={ 1 }>Yes</option>
                                            <option value={ 0 }>No.</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3 col-sm-4 ">
                                        <label htmlFor="display_stock_register" className="  ">
                                            Display In Stock Register
                                        </label>
                                        <select
                                            className="form-select form-select-sm"
                                            name="display_stock_register"
                                            id="display_stock_register"
                                            value={ Stock_register }
                                            onChange={ ( e ) => { SetStock_register( e.target.value ); } }
                                        >
                                            <option value={ 1 }>Yes</option>
                                            <option value={ 0 }>No.</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3 col-sm-4 ">
                                        <label htmlFor="batch_lot_maintain" className="  ">
                                            Batch/Lot No. Maintain
                                        </label>
                                        <select
                                            className="form-select form-select-sm"
                                            name="batch_lot_maintain"
                                            id="batch_lot_maintain"
                                            value={ Lot_maintain }
                                            onChange={ ( e ) => { SetLot_maintain( e.target.value ); } }
                                        >
                                            <option value={ 1 }>Yes</option>
                                            <option value={ 0 }>No.</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="">
                        <h6 className="text-primary  py-1 ps-3">Goods parameters</h6>
                    </div>
                    <Tab.Container activeKey={ activeTab } onSelect={ handleTabSelect }>
                        <Nav variant="tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="grouptd">Trading A/c</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="gd_menu1">Tax Detail</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="gd_menu2">Cess Detail</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="gd_menu3">Stock Packing</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            {/* ----------Trading ----- Account --------------*/ }
                            <Tab.Pane eventKey="grouptd">
                                <div>
                                    <div>
                                        <div className="form-group row">
                                            <form autoComplete="off">
                                                <div className="row py-1">
                                                    <div className="col-md-2 col-sm-6">
                                                        <label htmlFor="group_effect_form" className="">Effect From</label>
                                                        <input
                                                            type="date"
                                                            className="form-control form-control-sm"
                                                            name="group_effect_form"
                                                            value={ group_effect_form }
                                                            ref={ groupEffectFormRef }
                                                            onChange={ changeValue }
                                                        />
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <label htmlFor="group_trading_ac" className="">Trading A/c</label>
                                                        <Select
                                                            ref={ groupTrading }
                                                            options={ TradingAcOptions }
                                                            value={ group_trading_ac }
                                                            onChange={ TradingAcChange }
                                                            components={ { MenuList: AddTradingAc_Btn, IndicatorsContainer: TradingAcEdit } }
                                                            styles={ customStyles }
                                                            isClearable
                                                            isSearchable
                                                        />
                                                    </div>
                                                    <div className="col-md-1 text-start btn-group">
                                                        <Button type="button" style={ { marginTop: "32px" } } className="btn btn-sm" onClick={ AddTradingAc }>{ editIndex !== -1 ? "Change" : "Add" }</Button>
                                                        {/* Cancle Btn  */ }
                                                        { editIndex !== -1 && (
                                                            <Button type="button" style={ { marginTop: "32px" } } className="btn btn-sm ms-1" onClick={ CancleEditTradingAcTB }>Cancel</Button>
                                                        ) }
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>

                                    <Table striped bordered size="sm">
                                        <thead>
                                            <tr>
                                                <th>Actions</th>
                                                <th>Effect From</th>
                                                <th>Trading A/c</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { TacDetail.map( ( entry, index ) => (
                                                <tr key={ index }>
                                                    <td className="ps-3" style={ { width: "90px" } }>
                                                        <Button
                                                            variant="outline-success"
                                                            className={ `p-0 px-1 me-1 border-0 ${ disabledRows.includes( index ) ? 'disabled' : '' }` }
                                                            size="sm"
                                                            onClick={ () => EditTradingAcTB( index ) }
                                                            disabled={ disabledRows.includes( index ) }
                                                        >
                                                            <i className="pi pi-pencil p-0"></i>
                                                        </Button>{ ' ' }
                                                        <Button
                                                            variant="outline-danger"
                                                            className={ `p-0 px-1 me-1 border-0 ${ disabledRows.includes( index ) ? 'disabled' : '' }` }
                                                            size="sm"
                                                            onClick={ () => RemoveTradingAc( index ) }
                                                            disabled={ disabledRows.includes( index ) }
                                                        >
                                                            <i className="pi pi-trash p-0"></i>
                                                        </Button>{ ' ' }
                                                    </td>
                                                    <td>{ new Date( entry.group_effect_form ).toLocaleDateString( "en-GB" ) }</td>
                                                    <td>{ entry.group_trading_ac && entry.group_trading_ac.label }</td>
                                                </tr>
                                            ) ) }
                                        </tbody>
                                    </Table>
                                </div>
                            </Tab.Pane>
                            {/* ----------Trading ----- Account --------------*/ }
                            {/* ----------Tax --------- Detail -------------- */ }
                            <Tab.Pane eventKey="gd_menu1">
                                <div className="form-group row">
                                    <div className="py-1 row  ">
                                        <div className="col-md-2 col-sm-6 ">
                                            <label htmlFor="tax_effect_form" >Effect From </label>
                                            <input
                                                type="date"
                                                className="form-control form-control-sm"
                                                name="tax_effect_form"
                                                value={ tax_effect_form }
                                                ref={ tax_effect_formRef }
                                                onChange={ ( e ) => setTax_effect_form( e.target.value ) }
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-6 ">
                                            <label htmlFor="tax_rcm" >RCM Apply</label>
                                            <Select
                                                ref={ tax_rcmRef }
                                                options={ RCM }
                                                value={ tax_rcm }
                                                onChange={ TaxRcmChange }
                                                styles={ customStyles }
                                                isSearchable
                                            />

                                        </div>
                                        <div className="col-md-3 col-sm-6 ">
                                            <label htmlFor="tax_p_tax">Purchase Tax</label>
                                            <Select
                                                ref={ tax_p_taxRef }
                                                options={ tax_options }
                                                value={ tax_p_tax }
                                                onChange={ Tax_p_Tax_Change }
                                                components={ { MenuList: Add_p_Tax_btn, IndicatorsContainer: EditPurchanseTax } }
                                                styles={ customStyles }
                                                isClearable
                                                isSearchable
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-6 ">
                                            <label htmlFor="tax_s_tax" >Sale Tax</label>
                                            <Select
                                                ref={ tax_s_taxRef }
                                                options={ tax_options }
                                                value={ tax_s_tax }
                                                onChange={ Tax_s_Tax_Change }
                                                components={ { MenuList: Add_S_Tax_btn, IndicatorsContainer: EditSaleTax } }
                                                styles={ customStyles }
                                                isClearable
                                                isSearchable
                                            />
                                        </div>
                                        {/* <div className="col-md-1 text-start">
                                            <Button type="button" style={ { marginTop: "32px" } } className="btn btn-sm" onClick={ AddTaxDet }>
                                                { editTaxIndex !== -1 ? "Update" : "Add" }
                                            </Button>
                                        </div> */}
                                        <div className="col-md-1 text-start btn-group">
                                            <Button type="button" style={ { marginTop: "32px" } } className="btn btn-sm" onClick={ AddTaxDet }>{ editTaxIndex !== -1 ? "Change" : "Add" }</Button>
                                            {/* Cancle Btn  */ }
                                            { editTaxIndex !== -1 && (
                                                <Button type="button" style={ { marginTop: "32px" } } className="btn btn-sm ms-1" onClick={ CancleEditTaxdetail }>Cancel</Button>
                                            ) }
                                        </div>
                                    </div>

                                </div>
                                <Table striped bordered size="sm">
                                    <thead>
                                        <tr>
                                            <th>Actions</th>
                                            <th>Effect From</th>
                                            <th>RCM Apply</th>
                                            <th>Purchase Tax</th>
                                            <th>Sale Tax</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { TaxDetail.map( ( TaxDetail, index ) => (
                                            <tr key={ index }>
                                                <td className="ps-3" style={ { width: "90px" } }>
                                                    <Button variant="outline-success" className="p-0 px-1 me-1 border-0" size="sm" onClick={ () => EditTaxDet( index ) }>
                                                        <i className="pi pi-pencil p-0 "></i>
                                                    </Button>{ ' ' }
                                                    <Button variant="outline-danger" className="p-0 px-1 me-1 border-0" size="sm" onClick={ () => RemoveTaxDet( index ) }>
                                                        <i className="pi pi-trash p-0 "></i>
                                                    </Button>{ ' ' }
                                                </td>
                                                <td>{ new Date( TaxDetail.tax_effect_form ).toLocaleDateString( "en-GB" ) }</td>
                                                <td>{ TaxDetail.tax_rcm && TaxDetail.tax_rcm.label }</td>
                                                <td>{ TaxDetail.tax_p_tax && TaxDetail.tax_p_tax.label }</td>
                                                <td>{ TaxDetail.tax_s_tax && TaxDetail.tax_s_tax.label }</td>

                                            </tr>
                                        ) ) }
                                    </tbody>
                                </Table>
                            </Tab.Pane>
                            {/* ----------Tax --------- Detail -------------- */ }
                            {/* ----------Cess --------- Detail -------------- */ }
                            <Tab.Pane eventKey="gd_menu2">
                                <div>
                                    <div>
                                        <div className="form-group row">
                                            <div className="row py-1">
                                                <div className="col-md-2 col-sm-6">
                                                    <label htmlFor="cess_effect_form" className="">Effect From</label>
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-sm"
                                                        name="cess_effect_form"
                                                        value={ cess_effect_form }
                                                        ref={ cess_effect_formRef }
                                                        onChange={ ( e ) => setCess_effect_form( e.target.value ) }
                                                    />
                                                </div>
                                                <div className="col-md-4 col-sm-6 ">
                                                    <label htmlFor="cess_advol" className=" ">
                                                        Cess1 Advol
                                                    </label>
                                                    <div className="row">
                                                        <div className="col-sm-3 col-md-3">
                                                            <select
                                                                className="form-control form-control-sm"
                                                                name="cess_advol_yn"
                                                                value={ advol_yn }
                                                                onChange={ advol_ynChange }
                                                            >
                                                                <option value={ '1' }> Yes</option>
                                                                <option value={ '0' }>No</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-sm-9 col-md-9">
                                                            <Select
                                                                ref={ cess_advolRef }
                                                                id="cess_advol"
                                                                options={ adolOpt }
                                                                value={ cess_advol }
                                                                onChange={ cess_advolChange }
                                                                components={ { MenuList: Add_Cess1_Advol_btn, IndicatorsContainer: EditCess1_Advol } }
                                                                styles={ customStyles }
                                                                isClearable
                                                                isSearchable
                                                                isDisabled={ IsDisabled }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-4 col-sm-6 ">
                                                    <label htmlFor="cess_advol" className=" ">
                                                        Cess2 Non Advol
                                                    </label>
                                                    <div className="row">
                                                        <div className="col-sm-3 col-md-3">
                                                            <select
                                                                className="form-control form-control-sm"
                                                                name="non_advol_yn"
                                                                value={ non_advol_yn }
                                                                onChange={ non_advol_ynChange }
                                                            >
                                                                <option value={ '1' }> Yes</option>
                                                                <option value={ '0' }>No</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-sm-9 col-md-9">
                                                            <Select
                                                                ref={ non_advolRef }
                                                                id="non_advol"
                                                                options={ nonadolOpt }
                                                                value={ non_advol }
                                                                onChange={ non_advolChange }
                                                                styles={ customStyles }
                                                                components={ { MenuList: Add_Cess2_NonAdvol_btn, IndicatorsContainer: EditCess2_NonAdvol } }
                                                                isClearable
                                                                isSearchable
                                                                isDisabled={ Dis_non_adv }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-1 text-start btn-group">
                                                    <Button type="button" style={ { marginTop: "32px" } } className="btn btn-sm" onClick={ addCess }>{ editCessIndex !== -1 ? "Change" : "Add" }</Button>
                                                    {/* Cancle Btn  */ }
                                                    { editCessIndex !== -1 && (
                                                        <Button type="button" style={ { marginTop: "32px" } } className="btn btn-sm ms-1" onClick={ CancleEditCessdetail }>Cancel</Button>
                                                    ) }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Table striped bordered size="sm">
                                        <thead>
                                            <tr>
                                                <th>Actions</th>
                                                <th>Effect From</th>
                                                <th>Cess1 Advol</th>
                                                <th>Cess2 Non Advol</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            { CessDetail.map( ( CessDetail, index ) => (
                                                <tr key={ index }>
                                                    <td className="ps-3" style={ { width: "90px" } }>
                                                        <Button variant="outline-success" className="p-0 px-1 me-1 border-0" size="sm" onClick={ () => CessEditRow( index ) }>
                                                            <i className="pi pi-pencil p-0 "></i>
                                                        </Button>{ ' ' }
                                                        <Button variant="outline-danger" className="p-0 px-1 me-1 border-0" size="sm" onClick={ () => CessRemove( index ) }>
                                                            <i className="pi pi-trash p-0 "></i>
                                                        </Button>{ ' ' }
                                                    </td>
                                                    <td>{ new Date( CessDetail.cess_effect_form ).toLocaleDateString( "en-GB" ) }</td>
                                                    <td>{ CessDetail.cess_advol && CessDetail.cess_advol.label }</td>
                                                    <td>{ CessDetail.non_advol && CessDetail.non_advol.label }</td>

                                                </tr>
                                            ) ) }
                                        </tbody>
                                    </Table>


                                </div>
                            </Tab.Pane>
                            {/* ----------Cess --------- Detail -------------- */ }
                            {/* ----------Stock -------- Packing -------------- */ }
                            <Tab.Pane eventKey="gd_menu3">
                                <div className="form-group row">
                                    <div className="py-1 row">
                                        <div className="col-md-4 col-sm-6 ">
                                            <label htmlFor="group_packing_name" > Packing Name</label>
                                            <Select
                                                ref={ PackingNameRef }
                                                options={ PackingNameOpt }
                                                value={ PackingName }
                                                onChange={ PackingNameChange }
                                                components={ { MenuList: Add_packing_btn, IndicatorsContainer: Editpacking } }
                                                styles={ customStyles }
                                                isClearable
                                                isSearchable
                                            />
                                        </div>
                                        <div className="col-md-4 col-sm-6 ">
                                            <label htmlFor="group_taxable_rate">Taxable Rate (Sale)</label>
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                name="group_taxable_rate"
                                                placeholder="Rate"
                                                value={group_taxable_rate}
                                                onChange={ changeValue }
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-6 ">
                                            <label htmlFor="group_paid_rate" > Paid Rate (Sale)</label>
                                            <input
                                                type="number"
                                                className="form-control form-control-sm "
                                                name="group_paid_rate"
                                                placeholder="Rate"
                                                value={group_paid_rate}
                                                onChange={ changeValue }
                                            />
                                        </div>
                                        <div className="col-md-1 text-start btn-group">
                                                        <Button type="button" style={ { marginTop: "32px" } } className="btn btn-sm" onClick={ addPacking }>{ editPackingIndex !== -1 ? "Change" : "Add" }</Button>
                                                        {/* Cancle Btn  */ }
                                                        { editPackingIndex !== -1 && (
                                                            <Button type="button" style={ { marginTop: "32px" } } className="btn btn-sm ms-1" onClick={ CancleEditPacking }>Cancel</Button>
                                                        ) }
                                                    </div>
                                    </div>
                                  
                                </div>
                                <Table striped bordered size="sm">
                                    <thead>
                                        <tr>
                                            <th>Actions</th>
                                            <th>Packing Name</th>
                                            <th>Taxable Rate (Sale)</th>
                                            <th>Paid Rate (Sale)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { PackingDetail.map( ( PackingDetail, index ) => (
                                            <tr key={ index }>
                                                <td className="ps-3" style={ { width: "90px" } }>
                                                    <Button variant="outline-success" className="p-0 px-1 me-1 border-0" size="sm" onClick={ () => PackingEditRow( index ) }>
                                                        <i className="pi pi-pencil p-0 "></i>
                                                    </Button>{ ' ' }
                                                    <Button variant="outline-danger" className="p-0 px-1 me-1 border-0" size="sm" onClick={ () => PackingRemove( index ) }>
                                                        <i className="pi pi-trash p-0 "></i>
                                                    </Button>{ ' ' }
                                                </td>
                                                <td>{ PackingDetail.packingname && PackingDetail.packingname.label }</td>
                                                <td>{ PackingDetail.group_taxable_rate && PackingDetail.group_taxable_rate }</td>
                                                <td>{ PackingDetail.group_paid_rate && PackingDetail.group_paid_rate }</td>
                                            </tr>
                                        ) ) }
                                    </tbody>
                                </Table>
                            </Tab.Pane>
                            {/* ----------Stock -------- Packing -------------- */ }
                        </Tab.Content>
                    </Tab.Container>
                </div>
                <div className="formBtn card-footer">
                    <button className="btn px-4  me-2" type='submit' onClick={ submit }>Save</button>
                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                </div>
            </div>

        </div>
    </> );
}
export default AddGoods