import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { BrowserRouter as Router,useNavigate, useLocation ,useParams} from 'react-router-dom';
import axios from 'axios';
import { addtwoDecimal, addthreeDecimal, removeLeadingZero, threeDecimal, twoDecimal, emtySelect, maxlength, validInput, Star } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
const EditInviceDetail = ( e ) =>
{
    const { id } = useParams();
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, navigatefirstlink } = useLinkManagement();
    const EditUrl = '/invoice/edit/' + id + '';
    const toast = useRef( null );
    const location = useLocation();
    const currentUrl = location.pathname;
    const navigate = useNavigate();
    const [ name, set_name ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const namse = useRef();
    const [ fields, setFields ] = useState( [ '', '' ] );
    useEffect( () =>
    {
        const fetchData = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }invoiceod/${ id }` );
                const InviceData = response.data;
                set_name( InviceData.name );
                const initialValues = InviceData.iodm_values; 
                if( initialValues == null ){
console.log( initialValues);
                }else{
                    const parsedValues = JSON.parse( initialValues );
                    setFields( parsedValues );
                }
               
            } catch ( error )
            {
                console.error( 'Error:', error );
            }
        };
        fetchData();
    }, [] );
    const addField = () =>
    {
        if ( fields.length < 5 )
        {
            setFields( [ ...fields, '' ] ); // Add an empty field to the fields array
        }
    };
    const removeField = ( index ) =>
    {
        const updatedFields = [ ...fields ];
        updatedFields.splice( index, 1 ); // Remove the field at the specified index
        setFields( updatedFields );
    };
    const handleChange = ( value, index ) =>
    {
        const updatedFields = [ ...fields ];
        updatedFields[ index ] = value; // Update the value of the field at the specified index
        setFields( updatedFields );
    };
    const removeTagsAndWhitespace = (value) => {
        return value.trim().replace(/<[^>]*>/g, '').replace(/&nbsp;/g, '');
      }; 
      const nonEmptyFields = fields.filter(value => value.trim() !== '');
      const trimmedFields = nonEmptyFields.map(value => removeTagsAndWhitespace(value));
      const TransportData = () => ({
        name: removeTagsAndWhitespace(name),
        value: trimmedFields
      });
      
    function SubmitData( e )
    {
        e.preventDefault();
        setError( '' );
        const check_namee = maxlength( name.trim(), 'Name', 50 );
        if ( check_namee !== true ) {   setError( check_namee );namse.current.focus(); return false; }
        else { SaveData(); }
    }
    const SaveData = () =>
    {
        axios
            .patch( `${ BaseUrl }invoiceod/${ id }`, TransportData(), { mode: 'cors' } )
            .then( function ( response )
            {
                const data = response.data;
                if ( data.success === true )
                {
                    set_name( '' ); setFields( [ '', '' ] );
                    toast.current.show( {severity: 'success', summary: 'Success',detail: 'Update Invoice Other Detail  Success', life: 2000, } );
                    // if(currentUrl == EditUrl){
                    //     navigate('/invoice')
                    // }
                    handleSplitLastLink();
                    navigateelast();
                }
            } )
            .catch( function ( error )
            {
                // console.error( error.response );
                // set_invoiceDetailError( error.response.data );
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors ); namse.current.focus();
            } );
    }
    const accept = () =>
    {
        toast.current.show( { severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000, } );
        handleSplitLastLink();
        navigateelast();
        // if ( currentUrl === EditUrl )
        // {
        //     navigate( '/invoice' );
        // }
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     {
    //         if ( event.key === 'Escape' )
    //         {
    //             confirm( 'top' );
    //         }
    //     };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () =>
    //     {
    //         document.removeEventListener( 'keydown', handleKeyDown );
    //     };
    // }, [] );
    return (
        <>
            <div className="px-2 mt-2 main"><DynamicTitleComponent newTitle={ `Edit Invoice Other Detail` } />
                <div className="row">
                    <div className="col-md-6">
                        <div className='card'>
                        <div className="card-header"><h4>Edit Invoice Other Detail</h4></div>
                            <form className="form-horizontal" onSubmit={ SubmitData } autoComplete='off'>
                                <div className="card-body">
                                    <div className="row">
                                        {/* <div className='text-end'>
                                            <span className="text-danger">{ errorMessages }</span>
                                            { error && <span style={ { color: 'red' } }>{ error }</span> }
                                        </div> */}
                                        <>
                                            <div className="col-md-12 col-sm-12 ">
                                                <label htmlFor="name" className=" form-label ">
                                                    Name
                                                </label><Star/><span className='float-end' style={ { color: 'red' } }> { error }</span>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    value={ name }
                                                    name="invoice_name"
                                                    required
                                                    placeholder="Invoice"
                                                    onChange={ ( e ) => { set_name( e.target.value ); } }
                                                    ref={ namse }
                                                    autoFocus
                                                />
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <label htmlFor="name" className="form-label">
                                                    Value
                                                </label>
                                                { fields.map( ( value, index ) => (
                                                    <div className="row py-1" key={ index }>
                                                        <div className="col-md-11">
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                value={ value }
                                                                placeholder='Value'
                                                                onChange={ ( e ) => handleChange( e.target.value, index ) }
                                                            />
                                                        </div>
                                                        <div className="col-md-1 text-end">
                                                            <span
                                                                className="btn btn-outline-danger btn-sm "
                                                                onClick={ () => removeField( index ) }> X </span>
                                                        </div>
                                                    </div>
                                                ) ) }
                                            </div>

                                        </>

                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                { fields.length < 5 && (
                                        <button className="btn px-4 me-2 " type='button' onClick={ addField }>+</button>
                                    ) }
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitData }>Update</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
                <Toast ref={ toast } />
                <ConfirmDialog />
            </div>
        </>
    );
}

export default EditInviceDetail;

