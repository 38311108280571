import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { OnlyNumber,maxlength, validInput ,ValidateEmail, emtySelect,  Star } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'

const AddBroker = ( e ) =>
{
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, setFocus ,splitLastFocus } = useLinkManagement();
    const addUrl = '/broker/add';
    const toast = useRef( null );
    const location = useLocation();
    const currentUrl = location.pathname;
    const navigate = useNavigate();
    const [ name, set_name ] = useState( '' );
    const [ contact, set_contact ] = useState( '' );
    const [ email, set_email ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const namse = useRef();
    const emaile = useRef();
    const BrokerData = () => ( {
        name: name.trim(),
        contact: contact.trim(),
        email: email.trim(),
    } );
    function OnSubmit( e )
    {
        e.preventDefault();
        setError( '' );
        const check_name = maxlength( name.trim(), 'broker name ' );
        const checkemail = ValidateEmail( email );
        if ( check_name  !== true) {
            setError( check_name );
                foCoUs( namse );
                return false;  }
        else if ( email != '' )
        {
            if ( checkemail !== true )
            {
                setError( checkemail );
                foCoUs( emaile );
                return false;
            } else { SaveData(); }
        }
        else { SaveData(); }
    }
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };
    const SaveData = () =>
    {
        axios
            .post( `${ BaseUrl }broker`, BrokerData(), { mode: 'cors' } )
            .then( function ( response )
            {
                const data = response.data;
                if ( data.success === true )
                {
                    const LastLink = getLastLinkInSession();
                    if ( LastLink === 'add_broker_m' )
                    {}
                    setError( '' ); set_name( '' ); set_contact( '' ); set_email( '' );
                    toast.current.show( {  severity: 'success',  summary: 'Success', detail: 'Add Broker Success', life: 2000 } );
                    namse.current.focus();
                }else
                {
                    handleSplitLastLink();
                    navigateelast();
                }
            } )
            .catch( function ( error )
            {
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors ); foCoUs( namse );
            } );
    }
    const accept = () =>
    {
        toast.current.show({ severity: 'info', summary: 'Confirmed',  detail: 'You have accepted',  life: 3000, } );
        handleSplitLastLink();
        navigateelast();
        // if ( currentUrl === addUrl ) { navigate( '/broker' ); }
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    const ChangeValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'name' ) { var valuee = validInput( value ); set_name( valuee ); removeinvalid( namse ); }
        if ( name === 'contact' ) { var valuee = OnlyNumber( value ); set_contact( valuee ); }
        if ( name === 'email' ) { var valuee = validInput( value ); set_email( valuee ); removeinvalid( emaile ); }
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     {
    //         if ( event.key === 'Escape' ) { confirm( 'top' ); }
    //     };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () => { document.removeEventListener( 'keydown', handleKeyDown ); };
    // }, [] );

    return (
        <>
             <div className="p-2 mt-2 main"> <DynamicTitleComponent newTitle={ `Add Broker ` } />
                <div className="row">
                    <div className="col-md-6">
                       
                        <div className='card'>
                        <div className="card-header"><h4>Add Broker</h4></div>
                            <form className="form-horizontal" onSubmit={ OnSubmit }>
                                <div className="card-body">
                                    <div className="row">
                                        <>
                                            <div className="col-md-12 col-sm-12 ">
                                                <label htmlFor="name" className="  "> Broker Name </label><Star/>
                                                <span className='float-end' style={ { color: 'red' } }> { error }</span>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm  "
                                                    value={ name }
                                                    name="name"
                                                    required
                                                    autoFocus
                                                    placeholder="Broker Name"
                                                    onChange={ ChangeValue }
                                                    ref={ namse }
                                                />
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <label htmlFor="contact" className="    ">
                                                    Contact No.
                                                </label>
                                                <input
                                                    type="tel"
                                                    className="form-control form-control-sm  "
                                                    maxLength={ 12 }
                                                    minLength={ 10 }
                                                    value={ contact }
                                                    name="contact"
                                                    placeholder="Contact "
                                                    onChange={ ChangeValue }
                                                />
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <label htmlFor="email" className="   ">
                                                    Email
                                                </label>
                                                <input
                                                    type="email"
                                                    className="form-control form-control-sm  "
                                                    id="email"
                                                    name="email"
                                                    value={ email }
                                                    ref={ emaile }
                                                    required
                                                    placeholder="Broker Email"
                                                    onChange={ ChangeValue }
                                                />
                                            </div>
                                        </>

                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ OnSubmit }>save</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
                <Toast ref={ toast } />
                <ConfirmDialog />
            </div>
        </>
    );
}

export default AddBroker;

