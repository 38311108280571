import React from 'react';
import Modal from 'react-modal';

// Modal.setAppElement('#root'); // Set the root element for screen reader accessibility

const customStyles = {
  overlay: {
    zIndex: '9999',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background behind the modal
  },
  content: {
    width: '300px',
    margin: 'auto',
    height: '190px',
    padding: '20px',
    borderRadius: '5px', // Rounded corners
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Drop shadow
    backgroundColor: 'white', // White background
  },
};

const popupContainerStyle = {
  textAlign: 'center',
};

const h2Style = {
  fontSize: '1.5rem',
  marginBottom: '20px',
};

const pStyle = {
  marginBottom: '20px',
};

const buttonStyle = {
  padding: '10px 20px',
  marginRight: '10px',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '5px',
  fontWeight: 'bold',
};

const logoutButtonStyle = {
  backgroundColor: '#ff6347', // Red color for logout button
  color: 'white',
};

const logoutButtonHoverStyle = {
  backgroundColor: '#ff0000', // Darker red color on hover
};

const cancelButtonStyle = {
  backgroundColor: '#ccc', // Light gray color for cancel button
  color: 'black',
};

const cancelButtonHoverStyle = {
  backgroundColor: '#999', // Darker gray color on hover
};

const Popup = ({ isOpen, onClose, onLogout }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Logout Modal"
      style={customStyles}
    >
      <div style={popupContainerStyle}>
        <h2 style={h2Style}>Logout Confirmation</h2>
        <p style={pStyle}>Are you sure you want to log out?</p>
        <button
          style={{ ...buttonStyle, ...logoutButtonStyle }}
          onClick={onLogout}autoFocus
        >
          Logout
        </button>
        <button
          style={{ ...buttonStyle, ...cancelButtonStyle }}
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default Popup;
