import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import Select, { components } from 'react-select';
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { GlobalError, calculateOnOptions } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import customStyles, { customStyless } from '../../../../validations/customStyles';
import { OnlyNumber, SelectCheck, Numberordecimal, removeLeadingZero, maxlength, validInput, Star, Editicon } from '../../../../validations/validations';
const AddSellerslipExpenses = ( e ) =>
{
    const toast = useRef( null );
    // const { addLink, setNewLink } = useLinkManagement();
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, splitLastFocus, setFocus } = useLinkManagement();
    const AddSellerSlipUrl = '/seller-slip-expenses-profile/add';
    const location = useLocation();
    const currentUrl = location.pathname;
    const navigate = useNavigate();
    const [ profilelist, setprofilelist ] = useState( '' ); const [ ProfileOpt, setProfileOpt ] = useState( [] ); const [ selectedValues, setSelectedValues ] = useState( [] );
    const [ profileName, SetprofilName ] = useState( '' ); const profileNameRef = useRef();
    const [ CityName, SetCityName ] = useState( '' ); const CityRef = useRef(); const [ CityOpt, setCityOpt ] = useState( [] );
    const [ error, setError ] = useState( '' );
    const [ expenseOptions, setExpenseOptions ] = useState( [] );
    const filteredOptions = expenseOptions.filter( expenseOptions => !selectedValues.includes( expenseOptions.value ) );
    const refs1 = useRef( [] );
    const refs2 = useRef( [] );
    const refs = useRef( [] );
    const [ expenses, setExpenses ] = useState( [
        { sn: '1', name: '', calculateOn: '', percentage: '' },
    ] );
    useEffect( () =>
    {
        // Initialize with 10 default rows
        const defaultRows = Array.from( { length: 5 }, ( _, index ) => ( { sn: ( index + 1 ).toString(), name: '', calculateOn: '', percentage: '', } ) );
        setExpenses( defaultRows );
    }, [] );
    //Set Focuse
    useEffect( () =>
    {
        const setFocusFromStorage = JSON.parse( sessionStorage.getItem( 'focusme' ) );
        if ( setFocusFromStorage && setFocusFromStorage.length > 0 )
        {
            const focusElement = setFocusFromStorage[ setFocusFromStorage.length - 1 ];
            setTimeout( () =>
            {
                if ( focusElement === 'CityRef' )
                {
                    CityRef.current.focus();
                    splitLastFocus();
                }
            }, 10 ); // 10 milliseconds delay
        }
    }, [] );
    const handleAddRow = () =>
    {
        const newSN = ( expenses.length + 1 ).toString();
        setExpenses( [ ...expenses, { sn: newSN, name: '', calculateOn: '', percentage: '' } ] );
    };
    const handleDeleteRow = ( index ) =>
    {
        const updatedExpenses = [ ...expenses ];
        updatedExpenses.splice( index, 1 );
        setExpenses( updatedExpenses );
        getSelectedExpenseValues( updatedExpenses );

    };
    const handleExpenseNameChange = ( index, selectedOption ) =>
    {
        const updatedExpenses = [ ...expenses ];
        updatedExpenses[ index ].name = selectedOption;
        setExpenses( updatedExpenses );
        getSelectedExpenseValues( updatedExpenses );
    };

    const getSelectedExpenseValues = expenses =>
    {
        var NewOpt = expenses
            .filter( expense => expense.name !== null )
            .map( expense => expense.name.value );
        setSelectedValues( NewOpt );
    };

    const handleCalculateOnChange = ( index, selectedOption ) =>
    {
        const updatedExpenses = [ ...expenses ];
        updatedExpenses[ index ].calculateOn = selectedOption;
        setExpenses( updatedExpenses );
    };
    const handleInputChange = ( index, field, value ) =>
    {
        const updatedExpenses = [ ...expenses ]; // Replace with your actual functions
        const onlyno = Numberordecimal( value );
        const removezero = removeLeadingZero( onlyno );
        const valuee = validInput( removezero );
        updatedExpenses[ index ][ field ] = valuee;
        setExpenses( updatedExpenses );
    };

    const validateInputs = () =>
    {
        setError();
        const City = SelectCheck( CityName, 'City Name' );
        const effrm = maxlength( profileName.trim(), 'Effect From date' );
        if ( City !== true ) { setError( City ); CityRef.current.focus(); return false; }
        if ( effrm !== true ) { setError( effrm ); foCoUs( profileNameRef ); return false; }
        let validRowwd = 0;
        let isvalid = 0;
        const errorsCopy = {};
        for ( const [ key, value ] of Object.entries( expenses ) )
        {
            setError( '' );
            const sn = value.sn;
            const expencesName = value.name;
            const expencescalculateOn = value.calculateOn;
            const expencespercentage = value.percentage;
            if ( expencespercentage !== undefined && expencespercentage.trim() !== '' )
            {
                validRowwd += 1;
                if ( expencespercentage !== '' || expencespercentage !== null )
                {
                    if ( expencesName === '' || expencesName === null )
                    {
                        errorsCopy[ sn ] = { ...errorsCopy[ sn ], calculateOn: 'Please select expense name on ' + sn + '' }
                        isvalid += 1;
                    }
                }
                if ( expencespercentage !== '' || expencespercentage !== null )
                {
                    if ( expencescalculateOn === '' || expencescalculateOn === null )
                    {
                        errorsCopy[ sn ] = { ...errorsCopy[ sn ], calculateOn: 'Please select calculate on on ' + sn + '' }
                        isvalid += 1;
                    }
                }
            }
            if ( expencesName !== '' )
            {
                if ( expencesName === null ) { setError( '' ); }
                else
                {
                    if ( expencescalculateOn === '' || expencescalculateOn === null )
                    {
                        errorsCopy[ sn ] = { ...errorsCopy[ sn ], calculateOn: 'Please select calculate on ' + sn + '' }
                        isvalid += 1;
                    }
                    else if ( expencespercentage === '' )
                    {
                        errorsCopy[ sn ] = { ...errorsCopy[ sn ], calculateOn: 'Please fill % on ' + sn + '' }
                        isvalid += 1;
                    }
                }
            }
            if ( expencescalculateOn !== '' )
            {
                if ( expencescalculateOn === null ) { setError( '' ); }
                else
                {
                    if ( expencesName === '' || expencesName === null )
                    {
                        errorsCopy[ sn ] = { ...errorsCopy[ sn ], calculateOn: 'Please select expense name on ' + sn + '' }
                        isvalid += 1;
                    }
                    else if ( expencespercentage === '' )
                    {
                        setError( `Please fill %--- ${ key }` );
                        errorsCopy[ sn ] = { ...errorsCopy[ sn ], calculateOn: 'Please fill % on ' + sn + '' }
                        isvalid += 1;
                    }
                }
            }
        }
        const displayErrors = ( errors ) =>
        {
            for ( const sn in errors )
            {
                const errorMessages = errors[ sn ];
                for ( const key in errorMessages )
                {
                    const errorMessage = errorMessages[ key ];
                    setError( ` ${ errorMessage }` );
                }
            }
        };
        displayErrors( errorsCopy );
        if ( validRowwd === 0 )
        {
            setError( 'There should be at least one expense row with %!' );
            refs2.current[ 0 ].focus();
            return false;
        }
        if ( isvalid === 0 )
        {
            const nonEmptyExpenses = expenses.filter( ( expense ) =>
            {
                return (
                    ( expense.name !== '' && expense.name !== null && expense.name !== undefined ) ||
                    ( expense.calculateOn !== '' && expense.calculateOn !== null && expense.calculateOn !== undefined ) ||
                    ( expense.percentage !== '' && expense.percentage !== null && expense.percentage !== undefined )
                );
            } );
            partyWiseData( nonEmptyExpenses );
            return true;
        }
        else { return false; }
    };
    const partyWiseData = ( nonEmptyExpenses ) => ( {
        city: CityName,
        profileName: profileName,
        expenses: nonEmptyExpenses,
    } );
    function SubmitForm( e )
    {
        e.preventDefault();
        if ( validateInputs() )
        {
            const nonEmptyExpenses = expenses.filter( ( expense ) =>
            {
                return (
                    ( expense.name !== '' && expense.name !== null && expense.name !== undefined ) ||
                    ( expense.calculateOn !== '' && expense.calculateOn !== null && expense.calculateOn !== undefined ) ||
                    ( expense.percentage !== '' && expense.percentage !== null && expense.percentage !== undefined )
                );
            } );

            const data = partyWiseData( nonEmptyExpenses );
            saverequest( data );
            console.log( data );
        }
        // saverequest();
    }
    useEffect( () => //Fetch Expenses
    {
        const fetch = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }/expensesoptions`, { mode: 'cors' } );
                const data = response.data;
                const Options = data.map( item => ( { value: item.id, label: item.name } ) );
                setExpenseOptions( Options );
            }
            catch ( error ) { console.log( 'Error fetching ExpenseOptions.', error ); }
        };
        fetch();
    }, [] );

    useEffect( () => //fetch Citys
    {
        const fetch = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }/allcity`, { mode: 'cors' } );
                const data = response.data;
                const mappedOptions = data.map( item => ( { value: item.cityId, label: item.cityName } ) );
                setCityOpt( mappedOptions );
            }
            catch ( error ) { console.log( 'Error fetching City Opt.', error ); }
        };
        fetch();
    }, [] );

    const saverequest = ( data ) =>
    {
        axios
            .post( `${ BaseUrl }sellerslipexpensesprofile`, data, { mode: 'cors' } )
            .then( function ( response )
            {
                const data = response.data;
                if ( data.success === true )
                {
                    sessionStorage.removeItem( 'sellerslipexpensesprofile' );
                    const LastLink = getLastLinkInSession();
                    if ( LastLink === 'add_sellerslipexpensesprofile_m' )
                    {
                        setError( '' );
                        SetprofilName( '' );
                        SetCityName( '' );
                        setExpenses( [] );
                        setProfileOpt( [] ); setprofilelist( '' );
                        const defaultRows = Array.from( { length: 5 }, ( _, index ) => ( { name: '', calculateOn: '', percentage: '', } ) );
                        setExpenses( defaultRows );
                        CityRef.current.focus();
                        toast.current.show( { severity: 'success', summary: 'Success', detail: 'Add Seller Slip Expenses Profile Success', life: 2000, } );
                    }
                    else
                    {
                        handleSplitLastLink();
                        navigateelast();
                    }

                }
            } )
            .catch( function ( error )
            {
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors ); CityRef.current.focus();
            } );
    }
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };

    const chnageValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'profilName' ) { var valuee = validInput( value ); SetprofilName( valuee ); removeinvalid( profileNameRef ); }
    };
    const fetchProfiles = ( idd = null ) =>
    {
        const fetch = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }showprofileListbycity/${ idd }`, { mode: 'cors' } );
                const data = response.data;
                if ( data === null ) { setProfileOpt( [] ); }
                else
                {
                    const mappedOptions = data.map( item => ( { value: item.id, label: item.profileName } ) );
                    setProfileOpt( mappedOptions );
                }
            }
            catch ( error ) { setProfileOpt( [] ); }
        };
        fetch();
    }
    const fetchExpenses = ( idd ) =>
    {
        const fetch = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }expensebycity/${ idd }` );
                const da = response.data;
                setExpenses( da.expenses );
                getSelectedExpenseValues( da.expenses );
            } catch ( error )
            {
                console.error( 'Error:', error );
                navigate( '/' );
            }
        };
        fetch();
    }
    const profilechange = selectedOption => // on change Profile Copy From
    {
        if ( selectedOption !== '' )
        {
            setprofilelist( selectedOption );
            if ( selectedOption !== null )
            {
                fetchExpenses( selectedOption.value );
            }
        }
    };
    const CityName_change = selectedOption => // on change City
    {
        if ( selectedOption !== '' )
        {
            SetCityName( selectedOption );
            if ( selectedOption === null )
            {
                setProfileOpt( [] );
            } else
            {
                fetchProfiles( selectedOption.value );
            }
        }

    };

    const CityForm = () => 
    {
        sessionStorage.setItem( 'sellerslipexpensesprofile', JSON.stringify( { profileName, expenses, profilelist, ProfileOpt } ) );
        addLink( "cityadd" );
        setFocus( 'CityRef' );
        navigateelast();
    };
    const CityNameBtn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <spna
                    onClick={ () => { CityForm() } }
                    className="btn btn-xsm  btn-primary  w-100 "
                > Add </spna>
            </components.MenuList>
        );
    };
    const EditCityForm = ( value ) =>
    {
        sessionStorage.removeItem( 'sellerslipexpensesprofile' );
        sessionStorage.setItem( 'sellerslipexpensesprofile', JSON.stringify( { CityName, profileName, expenses, profilelist, ProfileOpt } ) );
        addLink( `cityedit,${ value }` );
        setFocus( 'CityRef' );
        navigateelast();
    };
    const Getcityid = ( props ) =>
    {
        return (
            <>
                <div
                    onClick={ () => EditCityForm( props.selectProps.value.value ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const cityEdit = ( { children, ...props } ) =>
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <Getcityid { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };
    const ExpenseForm = ( props ) => 
    {
        const { index } = props;
        sessionStorage.setItem( 'Expensesfocus', JSON.stringify( index ) );
        sessionStorage.setItem( 'sellerslipexpensesprofile', JSON.stringify( { CityName, profileName, expenses, profilelist, ProfileOpt } ) );
        addLink( "add_expenses" );
        navigateelast();
    };
    const ExpenseBtn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <spna
                    onClick={ () => { ExpenseForm( props ) } }
                    className="btn btn-xsm  btn-primary  w-100 "
                > Add </spna>
            </components.MenuList>
        );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     { if ( event.key === 'Escape' ) { confirm( 'top' ); } };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () => { document.removeEventListener( 'keydown', handleKeyDown ); };
    // }, [] );
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    const accept = () =>
    {
        sessionStorage.removeItem( 'sellerslipexpensesprofile' );
        handleSplitLastLink();
        navigateelast();
        toast.current.show( { severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000, } );
        // if ( currentUrl === AddSellerSlipUrl ) { navigate( '/seller-slip-expenses-profile' ); }
    };
    useEffect( () =>
    {
        const setFocusFromStorage = JSON.parse( sessionStorage.getItem( 'Expensesfocus' ) );
        if ( setFocusFromStorage === 0 ) { refs.current[ 0 ].focus(); }
        setTimeout( () =>
        {
            if ( setFocusFromStorage ) { refs.current[ setFocusFromStorage ].focus(); }
            sessionStorage.removeItem( 'Expensesfocus' ); // Removes the 'Expensesfocus' item from sessionStorage
        }, 10 );
        const savedData = sessionStorage.getItem( 'sellerslipexpensesprofile' );
        if ( savedData )
        {
            const data = JSON.parse( savedData );
            SetprofilName( data.profileName );
            SetCityName( data.CityName );
            setExpenses( data.expenses );
            setProfileOpt( data.ProfileOpt );
            setprofilelist( data.profilelist );
            getSelectedExpenseValues( data.expenses );
        }
    }, [] );
    const editAccountForm = ( props ) =>
    {
        const value = props.selectProps.value.value;
        const { index } = props;
        sessionStorage.getItem( 'Expensesfocus' ); //remove all Expenses foces
        sessionStorage.setItem( 'Expensesfocus', JSON.stringify( index ) );//Add  Expenses foces
        sessionStorage.removeItem( 'sellerslipexpensesprofile' );
        sessionStorage.setItem( 'sellerslipexpensesprofile', JSON.stringify( { CityName, profileName, expenses, profilelist, ProfileOpt } ) );
        addLink( `edit_expenses,${ value }` );
        navigateelast();
    };
    const EditIndicator = ( props ) =>
    {
        return (
            <>
                <div
                    onClick={ () => editAccountForm( props ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const IndicatorsContainer = ( { children, ...props } ) =>
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditIndicator { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };
    return (
        <>
            <div className="p-2 mt-2 main"> <DynamicTitleComponent newTitle={ `Add Seller Slip Expenses Profile` } />
                <div className="row">
                    <div className="col-md-6">
                        <div className='card'>
                            <div className="card-header">
                                <h4>Add Seller Slip Expenses Profile</h4>
                            </div>
                            <form className="form-horizontal" onSubmit={ SubmitForm } autoComplete='off'>
                                <div className="row card-body">
                                    <div>
                                        <span className='float-end' style={ { color: 'red' } }> { error }</span>
                                    </div>
                                    <div className="col-md-12 pb-1 ">
                                        <div className=" row">
                                            <div className="col-md-12 col-sm-8">
                                                <label htmlFor="CityName" >
                                                    City Name
                                                </label><Star />
                                                <Select
                                                    options={ CityOpt }
                                                    value={ CityName }
                                                    onChange={ CityName_change }
                                                    styles={ customStyles }
                                                    components={ { MenuList: CityNameBtn, IndicatorsContainer: cityEdit } }
                                                    isClearable
                                                    isSearchable
                                                    autoFocus
                                                    ref={ CityRef }
                                                />
                                            </div>
                                            <div className="col-md-8 col-sm-6 ">
                                                <label htmlFor="profilName" >Profile Name </label><Star />
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm "
                                                    name="profilName"
                                                    placeholder="Name"
                                                    onChange={ chnageValue }
                                                    value={ profileName }
                                                    ref={ profileNameRef }

                                                />
                                            </div>
                                            <div className="col-md-3 col-sm-8">
                                                <label  > Profile Copy From</label>
                                                <Select
                                                    options={ ProfileOpt }
                                                    value={ profilelist }
                                                    onChange={ profilechange }
                                                    styles={ customStyles }
                                                    isClearable
                                                    isSearchable
                                                />
                                            </div>
                                            <div className="col-md-1 mt-4 col-sm-6  text-end ">
                                                <button onClick={ handleAddRow } type='button' className='btn btn-sm btn-dark mt-2 ms-1' > Add</button>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div classList="col-md-12">
                                                <table className='table table-bordered table-sm table-secondary'>
                                                    <thead className='pb-2'>
                                                        <tr >
                                                            <th className='text-center' style={ { width: "45px" } }>SN</th>
                                                            <th style={ { width: "300px" } }>Expense Name</th>
                                                            <th style={ { width: "250px" } } className='mx-1'>Calculate On</th>
                                                            <th style={ { width: "140px" } } className='text-end pe-3'>%</th>
                                                            <th></th> {/* Column for Delete button */ }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { expenses.map( ( expense, index ) => (
                                                            <tr key={ index } className="p-0 m-0">
                                                                <td className='text-center p-0 m-0'>{ index + 1 }</td>
                                                                <td className='p-0 m-0'>
                                                                    <Select
                                                                        options={ filteredOptions }
                                                                        value={ expense.name }
                                                                        styles={ customStyles }
                                                                        components={ { MenuList: ( props ) => <ExpenseBtn { ...props } index={ index } />, IndicatorsContainer: ( props ) => <IndicatorsContainer { ...props } index={ index } /> } }
                                                                        isClearable
                                                                        placeholder={ 'Name..' }
                                                                        isSearchable
                                                                        ref={ ( el ) => ( refs.current[ index ] = el ) }
                                                                        onChange={ ( selectedOption ) => handleExpenseNameChange( index, selectedOption ) }
                                                                    />
                                                                </td>
                                                                <td className='p-0 m-0 '>
                                                                    <Select
                                                                        options={ calculateOnOptions }
                                                                        value={ expense.calculateOn }
                                                                        styles={ customStyless }
                                                                        placeholder={ 'On..' }
                                                                        isClearable
                                                                        isSearchable
                                                                        ref={ ( el ) => ( refs1.current[ index ] = el ) }
                                                                        onChange={ ( selectedOption ) => handleCalculateOnChange( index, selectedOption ) }
                                                                    />
                                                                </td>
                                                                <td className='p-0 m-0'>
                                                                    <input
                                                                        type='number'
                                                                        style={ { width: '100%' } }
                                                                        className='secondary p-0 text-end custom-input'
                                                                        value={ expense.percentage }
                                                                        ref={ ( el ) => ( refs2.current[ index ] = el ) }
                                                                        onChange={ ( e ) => handleInputChange( index, 'percentage', e.target.value ) }
                                                                    />
                                                                </td>
                                                                <td className='text-center p-0 m-0'>
                                                                    <button type='button' className='btn text-danger btn-sm' onClick={ () => handleDeleteRow( index ) }>X</button>
                                                                </td>

                                                            </tr>
                                                        ) ) }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitForm }>Save</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Toast ref={ toast } />
                <ConfirmDialog />
            </div>
        </>
    )
}

export default AddSellerslipExpenses;