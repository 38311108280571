// Loader.js
import React from 'react';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loader = () => {
  return (
    <div className="loader">
      <ClipLoader css={override} size={50} color={'#123abc'} loading={true} />
    </div>
  );
};

export const Loaderr = ()=>{
  return(
    <div id="loader-background">
              <div id="loader-content">
                <div class="loader">
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                </div>
              </div>
            </div>
  );
}

export default Loader;
