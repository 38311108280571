import React, { useRef, useEffect } from "react";
import { Toast } from 'primereact/toast';

const Alertt = (props) => {
    const toast = useRef(null);
    const { alert, showMessage } = props;

    useEffect(() => {
        if (alert) {
            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: showMessage,
                life: 2000,
            });
        }
    }, [alert, showMessage]);

    return (
        <Toast ref={toast} />
    );
}

export default Alertt;
