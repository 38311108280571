
import React, { useState, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import Select from 'react-select';
import axios from 'axios';
import { SelectCheck,  maxlength, validInput, Star } from '../../../../validations/validations';
import { GlobalError ,VoucherNarrationType} from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import customStyles from '../../../../validations/customStyles';
const AddVoucherNarration = ( e ) =>
{
    const toast = useRef( null );
    const { handleSplitLastLink, getLastLinkInSession, navigateelast } = useLinkManagement();
    const [ vou_nar_type, SetNarType ] = useState( '' ); const NarTypeRef = useRef();
    const [ vou_nar, SetVouNar ] = useState( '' ); const VouNarRef = useRef();
    const [ error, setError ] = useState( '' );

    const vnarrationData = () => ( {
        voc_type: vou_nar_type,
        voc_narration: vou_nar.trim(),
    } );
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };
    const vouterType = VoucherNarrationType ;
    function SubmitForm( e )
    {
        e.preventDefault();
        setError( '' );
        const doc_ty = SelectCheck( vou_nar_type, 'Voucher type' );
        const doc_name = maxlength( vou_nar.trim(), 'Voucher narration', 50 );
        if ( doc_ty !== true ) { setError( doc_ty ); NarTypeRef.current.focus(); return false; }
        if ( doc_name !== true ) { setError( doc_name ); foCoUs( VouNarRef ); return false; }
        saverequest();
    }
    const saverequest = () =>
    {
        axios
            .post( `${ BaseUrl }vouchernarration`, vnarrationData(), { mode: 'cors' } )
            .then( function ( response )
            {
                const data = response.data;
                if ( data.success === true )
                {
                    
                    const LastLink = getLastLinkInSession();
                    if ( LastLink === 'add_vouchernarration_m' )
                    {
                        setError( '' );
                        SetNarType( '' );
                        SetVouNar( '' );
                        NarTypeRef.current.focus();
                        toast.current.show( { severity: 'success', summary: 'Success', detail: 'Add Voucher Narration Success', life: 2000, } );
                    }
                    else
                    {
                        handleSplitLastLink();
                        navigateelast();
                    }
                }
            } )
            .catch( function ( error )
            {
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors ); NarTypeRef.current.focus();
            } );
    }
    const chnageValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'vou_nar' ) { var valuee = validInput( value ); SetVouNar( valuee ); removeinvalid( VouNarRef ); }
    };
    const voc_type_change = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'Voucher Type empty' ); }
        else { SetNarType( selectedOption ); }
    };
    const accept = () =>
    {
        toast.current.show( { severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000, } );
        handleSplitLastLink();
        navigateelast();
        // if ( currentUrl === add_Vouchernarration ) { navigate( '/vouchernarration' ); }
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     { if ( event.key === 'Escape' ) { confirm( 'top' ); } };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () => { document.removeEventListener( 'keydown', handleKeyDown ); };
    // }, [] );
    return (
        <>
            <div className="p-2 mt-2 main"> <DynamicTitleComponent newTitle={ `Add Voucher Narration` } />
                <div className="row">
                    <div className="col-md-6">

                        <div className='card'>
                            <div className="card-header">
                                <h4>Add Voucher Narration</h4>
                            </div>
                            <form className="form-horizontal" onSubmit={ SubmitForm } autoComplete='off'>
                                <div className="card-body   ">
                                    <div className="row">
                                        {/* left column */ }
                                        <div>
                                            <span className='float-end' style={ { color: 'red' } }> { error }</span>
                                        </div>
                                        <div className="col-md-12 pb-1 ">
                                            <div className=" row">
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="vou_nar_type" >
                                                        Voucher Type
                                                    </label><Star />
                                                    <Select
                                                        options={ vouterType }
                                                        value={ vou_nar_type }
                                                        onChange={ voc_type_change }
                                                        styles={ customStyles }
                                                        isClearable
                                                        autoFocus
                                                        isSearchable
                                                        ref={ NarTypeRef }
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="vou_nar" >
                                                        Voucher Narration
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        id="vou_nar"
                                                        name="vou_nar"
                                                        placeholder="Narration"
                                                        onChange={ chnageValue }
                                                        value={ vou_nar }
                                                        ref={ VouNarRef }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitForm }>Save</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <Toast ref={ toast } />
                <ConfirmDialog />
            </div>
        </>
    );
}

export default AddVoucherNarration;


