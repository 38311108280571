
import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import Select, { components } from 'react-select';
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { OnlyNumber, SelectCheck, removeLeadingZero, threeDecimal, Pincoode, emtySelect, maxlength, validInput, Star, Editicon } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import customStyles from '../../../../validations/customStyles';
import EditAccount from '../../Accounts/EditAccount';
const AddTdsSectionType = ( e ) =>
{
    const toast = useRef( null );
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, setFocus, splitLastFocus } = useLinkManagement();
    const AddSellerSlip = '/seller-slip-expenses-profile/add/expenses/add/section-type';          // add on /seller-slip-expenses-profile Add
    const add_Tds_section = '/tds-section-type/add';
    const add_frm_tds_parameters = '/tds-parameter/add/tds-section-type'; //Add time add url
    const add_frm_edit_tds_parameters = '/tds-parameter/edit/tds-section-type'; // edit time add url
    // const add_party_wise = '/party-wise-tds/add/tds-section-type'; // edit time add url
    // const add_edit_party_wise = '/party-wise-tds/edit/tds-section-type'; // edit time add url
    const location = useLocation();
    const currentUrl = location.pathname;
    const navigate = useNavigate();
    const [ Scode, SetSCode ] = useState( '' ); const SCodeRef = useRef(); const [ TdsAcOpt, setTdsAcOpt ] = useState( [] );
    const [ NPay, SetNPay ] = useState( '' ); const NPayRef = useRef();
    const [ TDSCA, SetTDSCA ] = useState( '' ); const TDSCARef = useRef();
    const [ error, setError ] = useState( '' );

    var id = 'E';
    // var partyId = 'T';
    const savedData = sessionStorage.getItem( 'tds_parameters' );
    if ( savedData )
    {
        const parsedData = JSON.parse( savedData );
        const pidcheck = parsedData.id;
        if ( pidcheck === '' || pidcheck === undefined || pidcheck === null ) { var id = '' }
        var id = pidcheck;
    }
    // Set Focus
    useEffect( () =>
    {
        const setFocusFromStorage = JSON.parse( sessionStorage.getItem( 'focusme' ) );
        if ( setFocusFromStorage && setFocusFromStorage.length > 0 )
        {
            const focusElement = setFocusFromStorage[ setFocusFromStorage.length - 1 ];
            setTimeout( () =>
            {
                if ( focusElement === 'TDSCARef' )
                {
                    TDSCARef.current.focus();
                    splitLastFocus();
                }
            }, 10 ); // 10 milliseconds delay
        }
    }, [] );
    const TdsSecData = () => ( {
        sectioncode: Scode.trim(),
        nationPay: NPay.trim(),
        TdsAc: TDSCA,
    } );
    useEffect( () =>
    {
        const fetchUqc = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }/vaccountopt`, { mode: 'cors' } );
                const data = response.data;
                const mappedOptions = data.map( item => ( { value: item.id, label: item.accName } ) );
                setTdsAcOpt( mappedOptions );
            }
            catch ( error ) { console.error( 'Error fetching Voucherac Opt.', error ); }
        };
        fetchUqc();
    }, [] );
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };
    function SubmitForm( e )
    {
        e.preventDefault();
        setError( '' );
        const doc_name = Scode.trim();
        if ( doc_name === '' ) { setError( 'Section Code not valid, check it.' ); foCoUs( SCodeRef ); return false; }
        saverequest();
    }
    const saverequest = () =>
    {
        axios
            .post( `${ BaseUrl }tdssectiontype`, TdsSecData(), { mode: 'cors' } )
            .then( function ( response )
            {
                const data = response.data;
                if ( data.success === true )
                {
                    sessionStorage.removeItem( 'tdssectioncode' );
                    const LastLink = getLastLinkInSession();
                    if ( LastLink === 'add_tdsST_m' )
                    {
                        setError( '' );
                        SetSCode( '' );
                        SetNPay( '' ); SetTDSCA( '' );
                        SCodeRef.current.focus();
                        toast.current.show( { severity: 'success', summary: 'Success', detail: 'Add TDS Section Type Success', life: 2000, } );
                    } else
                    {
                        handleSplitLastLink();
                        const LastLinkk = getLastLinkInSession();
                        let lasttlinkk = LastLinkk;
                        const linkParts = LastLinkk.split( ',' );
                        if ( linkParts.length === 2 )
                        {
                            const link = linkParts[ 0 ].trim();
                            const editId = linkParts[ 1 ].trim();
                            lasttlinkk = link;
                        }
                        if ( lasttlinkk === 'add_tdsparameter_m' || lasttlinkk === 'add_tdsparameter' || lasttlinkk === "edit_tdsparameter" )
                        {
                            const TdsParameter = sessionStorage.getItem( 'tds_parameters' );
                            if ( TdsParameter !== '' || TdsParameter !== null || TdsParameter !== undefined )
                            {
                                const d = TdsParameter ? JSON.parse( TdsParameter ) : {};
                                    const value = data.Newid;
                                    const label = Scode;
                                    d.Scode = { value, label };
                                    sessionStorage.setItem( 'tds_parameters', JSON.stringify( d ) );
                                
                            }
                        }
                        else if ( lasttlinkk === 'add_expenses_m' || lasttlinkk === 'add_expenses' || lasttlinkk === "edit_expenses" )
                        {
                            const TdsParameter = sessionStorage.getItem( 'dbexpenses' );
                            if ( TdsParameter !== '' || TdsParameter !== null || TdsParameter !== undefined )
                            {
                                const d = TdsParameter ? JSON.parse( TdsParameter ) : {};
                                    const value = data.Newid;
                                    const label = Scode;
                                    d.code = { value, label };
                                    sessionStorage.setItem( 'dbexpenses', JSON.stringify( d ) );
                                
                            }
                        }
                        navigateelast();
                    }



                    // if ( currentUrl === add_frm_tds_parameters ) { navigate( '/tds-parameter/add' ); }
                    // if ( currentUrl === '/expenses/add/section-type' ) { navigate( '/expenses/add' ); }
                    // if ( currentUrl === '/expenses/edit/section-type' )
                    // {
                    //     const s = sessionStorage.getItem( 'dbexpenses' );
                    //     const d = s ? JSON.parse( s ) : {};
                    //     const rid = d.id;
                    //     navigate( '/expenses/edit/' + rid + '' );
                    // }
                    // if ( currentUrl === add_frm_edit_tds_parameters ) { navigate( `/tds-parameter/edit/${ id }` ); }
                    // if ( currentUrl === AddSellerSlip )
                    // {
                    //     const s = sessionStorage.getItem( 'dbexpenses' );
                    //     const d = s ? JSON.parse( s ) : {};
                    //     const value = data.Newid;
                    //     const label = Scode;
                    //     d.code = { value, label };
                    //     sessionStorage.setItem( 'dbexpenses', JSON.stringify( d ) );
                    //     navigate( '/seller-slip-expenses-profile/add/expenses' );
                    // }
                    // Party Wise  TDS %
                    // if(currentUrl === add_party_wise){navigate('/party-wise-tds/add'); }
                    // if(currentUrl === add_edit_party_wise){navigate(`/party-wise-tds/edit/${partyId}`); }
                }
            } )
            .catch( function ( error )
            {
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors ); foCoUs( SCodeRef );
            } );
    }
    const chnageValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'sec_code' ) { var valuee = validInput( value ); SetSCode( valuee ); removeinvalid( SCodeRef ); }
        if ( name === 'npay' ) { var valuee = validInput( value ); SetNPay( valuee ); removeinvalid( NPayRef ); }
    };
    const Tds_Ac_change = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'TDS Credit A/c empty' ); }
        else { SetTDSCA( selectedOption ); }
    };
    const accept = () =>
    {
        sessionStorage.removeItem( 'tdssectioncode' );
        toast.current.show( { severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000, } );
        handleSplitLastLink();
        navigateelast();
        // if ( currentUrl === add_Tds_section ) { navigate( '/tds-section-type' ); }
        // if ( currentUrl === add_frm_tds_parameters ) { navigate( '/tds-parameter/add' ); }
        // if ( currentUrl === '/expenses/add/section-type' ) { navigate( '/expenses/add' ); }
        // if ( currentUrl === '/expenses/edit/section-type' )
        // {
        //     const s = sessionStorage.getItem( 'dbexpenses' );
        //     const d = s ? JSON.parse( s ) : {};
        //     const rid = d.id;
        //     navigate( '/expenses/edit/' + rid + '' );
        // }
        // if(currentUrl === AddSellerSlip){
        //     navigate( '/seller-slip-expenses-profile/add/expenses' );
        // }
        // if ( currentUrl === add_frm_edit_tds_parameters ) { navigate( `/tds-parameter/edit/${ id }` ); }
        // Party Wise  TDS %
        // if(currentUrl === add_party_wise){navigate('/party-wise-tds/add'); }
        // if(currentUrl === add_edit_party_wise){navigate(`/party-wise-tds/edit/${partyId}`); }
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     { if ( event.key === 'Escape' ) { confirm( 'top' ); } };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () => { document.removeEventListener( 'keydown', handleKeyDown ); };
    // }, [] );
    useEffect( () =>
    {
        const savedData = sessionStorage.getItem( 'tdssectioncode' );
        if ( savedData )
        {
            const data = JSON.parse( savedData );
            SetSCode( data.Scode );
            SetNPay( data.NPay );
            SetTDSCA( data.TDSCA );
        }
    }, [] );
    const editAccountForm = ( value ) =>
    {
        sessionStorage.setItem( 'tdssectioncode', JSON.stringify( { Scode, NPay, TDSCA } ) );
        addLink( "edit_account," + value );
        setFocus( 'TDSCARef' );
        navigateelast();
        // if ( currentUrl === AddSellerSlip )
        // {
        //     navigate( '/seller-slip-expenses-profile/add/expenses/add/section-type/add/tda-credit-ac/' + value + '' );
        // } else
        // {
        //     navigate( '/expenses/add/posting-ac/' + value + '' );
        // }
    };
    const EditIndicator = ( props ) =>
    {
        return (
            <>
                <div
                    onClick={ () => editAccountForm( props.selectProps.value.value ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const IndicatorsContainer = ( { children, ...props } ) =>
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditIndicator { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };
    const addAccountform = () =>
    {
        sessionStorage.setItem( 'tdssectioncode',JSON.stringify( { Scode, NPay, TDSCA } ) );
        addLink( "addaccount");
        setFocus( 'TDSCARef' );
        navigateelast();
        // if ( currentUrl === AddSellerSlip )
        // {
        //     navigate( '/seller-slip-expenses-profile/add/expenses/add/section-type/add/tda-credit-ac' );
        // } else
        // {
        //     navigate( '/expenses/add/posting-ac' );
        // }

    }
    const accountBtn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <spna
                    onClick={ addAccountform }
                    className="btn btn-xsm  btn-primary btn-block w-100"
                > Add </spna>
            </components.MenuList>
        );
    };
    return (
        <>
            <div className="p-2 mt-2 main"> <DynamicTitleComponent newTitle={ `Add | TDS Section Type ` } />
                <div className="row">
                    <div className="col-md-6">

                        <div className='card'>
                            <div className="card-header"> <h4>Add TDS Section Type</h4> </div>
                            <form className="form-horizontal" onSubmit={ SubmitForm }>
                                <div className="card-body   ">
                                    <div className="row">
                                        {/* left column */ }
                                        <div>
                                            {/* <span className='float-end' style={ { color: 'red' } }> { error }</span> */ }
                                        </div>
                                        <div className="col-md-12 pb-1 ">
                                            <div className=" row">
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="sec_code" >
                                                        Section Code
                                                    </label><Star /><span className='float-end' style={ { color: 'red' } }> { error }</span>
                                                    <input type="text" className="form-control form-control-sm " id="sec_code" name="sec_code"
                                                        placeholder="Section Code" onChange={ chnageValue } value={ Scode } ref={ SCodeRef } autoFocus
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="npay" >
                                                        Nature of Payment
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        id="npay"
                                                        name="npay"
                                                        placeholder="Nature of Payment"
                                                        onChange={ chnageValue }
                                                        value={ NPay }
                                                        ref={ NPayRef }
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="tds_cr" >
                                                        TDS Credit A/c
                                                    </label>
                                                    <Select
                                                        id="tds_cr"
                                                        options={ TdsAcOpt }
                                                        value={ TDSCA }
                                                        components={ { MenuList: accountBtn, IndicatorsContainer: IndicatorsContainer } }
                                                        onChange={ Tds_Ac_change }
                                                        styles={ customStyles }
                                                        isClearable
                                                        isSearchable
                                                        ref={ TDSCARef }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitForm }>Save</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <Toast ref={ toast } />
                <ConfirmDialog />
            </div>
        </>
    );
}

export default AddTdsSectionType;


    