import React, { useState, useEffect, useRef } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Column } from 'primereact/column';
import { MultiSelect } from "primereact/multiselect";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import BaseUrl from '../../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent';
import CheckAuth from '../../../../APIHelper/CheckAuth';
import { Loaderr} from '../../../../CommonComponents/Loader';
const ZoneTable = () =>
{
  const [ loading, setLoading ] = useState( true );
  const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast } = useLinkManagement();
  const { handleLogout, setLoggedIn, isLoggedInToken, isLoading, setIsLoading } = CheckAuth();
  const navigate = useNavigate();
  const dt = useRef( null );
  const coloms = [
    // { field: "groupType", header: "Group Type", },
  ];
  const [ visibleColumns, setVisibleColumns ] = useState( coloms );
  const [ globalFilterValue, setGlobalFilterValue ] = useState( '' );
  const [ zoneData, setZoneData ] = useState( null );
  const exportColumns = visibleColumns.map( ( col ) => ( { title: col.header, dataKey: col.field } ) );
  exportColumns.unshift( { title: 'Zone Name', dataKey: 'zoneName' } );

  const [ filters, setFilters ] = useState( {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    zonename: { value: null, matchMode: FilterMatchMode.CONTAINS },
  } );

  useEffect( () =>
  {// Check if the user is logged in by looking at localStorage
    const storedLoggedIn = localStorage.getItem( 'loggedIn' );
    const logintoken = localStorage.getItem( 'token' );
    if ( storedLoggedIn === 'true' && logintoken ) { fetchData(); }
  }, [] );


  const fetchData = async () =>
  {
    setIsLoading( true );
    const Token = localStorage.getItem( "token" );
    if ( !Token ) { handleLogout(); setLoggedIn( false ); return; }
    try
    {
      const response = await axios.get( BaseUrl + '/zone', { headers: { Authorization: `Bearer ${ Token }` } } ); //Get Api. Get Zone Data
      const data = response.data;
      if ( data.status === false )    // server return -> status false
      {
        if ( data.statusCode == '401' ) // server error unauthorized access or token is invalid
        {
          alert( data.message );       // show error message in alert
          toast.error( data.message ); // show error message in toast
          handleLogout();              // user logged out . Auto
          setLoggedIn( false );        // loader off S.
          setIsLoading( false );       // loader off M.
        }
      } 
      else                          // server return -> status true
      {
        setZoneData( response.data );  // set zone data
        setLoading( false ); // set loading created by datatable
        setIsLoading(false); // set is loading created by me
      }
    } 
    catch ( error ) // server return Errors
    {
      if (error.response) {
        setIsLoading(false);
        if (error.response.status === 401) {
          toast.error('Access to the resource is unauthorized. Please log in.');
          // You can also redirect the user to a login page or display a modal here.
        } 
        // else {
        //   toast.error('An error occurred:', error.response.data.error.message);
        // }
      } else if (error.request) {
        toast.error('No response received from the server.');
      } else {
        toast.error('An error occurred:', error.message);
      }
    }
  };

  const onGlobalFilterChange = ( e ) =>
  {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters[ 'global' ].value = value;
    setFilters( _filters );
    setGlobalFilterValue( value );
  };



  const onColumnToggle = ( event ) =>
  {
    let selectedColumns = event.value;
    let orderedSelectedColumns = coloms.filter( ( col ) =>
      selectedColumns.some( ( sCol ) => sCol.field === col.field )
    );
    setVisibleColumns( orderedSelectedColumns );
  };



  const exportCSV = ( selectionOnly ) =>
  {
    dt.current.exportCSV( { selectionOnly } );
  };


  const exportPdf = () =>
  {
    const exportFilename = `${ new Date().toLocaleDateString() }`;
    import( 'jspdf' ).then( ( jsPDF ) =>
    {
      import( 'jspdf-autotable' ).then( () =>
      {
        const doc = new jsPDF.default( 0, 0 );
        doc.autoTable( exportColumns, zoneData );
        doc.save( `ZoneData_${ exportFilename }.pdf` );
      } );
    } );
  };


  const exportExcel = () =>
  {
    import( 'xlsx' ).then( ( xlsx ) =>
    {
      const worksheet = xlsx.utils.json_to_sheet( zoneData );
      const workbook = { Sheets: { data: worksheet }, SheetNames: [ 'data' ] };
      const excelBuffer = xlsx.write( workbook, {
        bookType: 'xlsx',
        type: 'array'
      } );
      saveAsExcelFile( excelBuffer, 'Zone' );
    } );
  };




  const saveAsExcelFile = ( buffer, fileName ) =>
  {
    import( 'file-saver' ).then( ( module ) =>
    {
      if ( module && module.default )
      {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob( [ buffer ], { type: EXCEL_TYPE } );
        module.default.saveAs( data, `${ fileName }_export_${ new Date().getTime() }${ EXCEL_EXTENSION }` );
      }
    } );
  };




  const editRow = ( id ) =>
  {
    const idd = id.id;
    const Token = localStorage.getItem( 'token' );
    const checkeditid = async()=>{
      setIsLoading(true);
    try{
      const response = await axios.get(`${BaseUrl}checkzone/${idd}`,{headers: {Authorization:`Bearer ${Token}`},
      mode: 'cors'});
      const retun = response.data;
      if(retun.status === true){
        addLink( 'edit_zone,' + idd + '' );
        navigateelast();
        sessionStorage.setItem('editzoneID',idd);
        setIsLoading(false);
      }
     if(retun.status === false){
      setIsLoading(false);
        if(retun.statusCode =='401'){toast.error(retun.message ); handleLogout(); setLoggedIn(false);}
        else{toast.error(retun.message );}
      }
    }
    catch( error ){
      if (error.response) {
        if (error.response.status === 401) {
          toast.error('Access to the resource is unauthorized. Please log in.');
        } else {
          toast.error('An error occurred:', error.response.data);
        }
      } else if (error.request) {
        toast.error('No response received from the server.');
      } else {
        toast.error('An error occurred:', error.message);
      }
    }
  }

  checkeditid();
  };

  

  const deletaccept = async ( id ) =>
  {
    const idd = id.id;
    const Token = localStorage.getItem( 'token' );
    try
    {
      const response = await axios.delete(`${BaseUrl}zone/${idd}`,{headers: {Authorization:`Bearer ${Token}`},
      mode: 'cors'});
      const retun = response.data;
      if(retun.status ===true){
        toast.success(retun.message );
        setZoneData( prevData => prevData.filter( row => row.id !== idd ) );
      }
      if(retun.status === false){
        if(retun.statusCode =='401'){toast.error(retun.message ); handleLogout(); setLoggedIn(false);}
        else{toast.error(retun.message );}
      }
    }
    catch ( error )
    {
      console.log( 'Error:', error );
    }
      // const response = await fetch( `${ BaseUrl }zone/${ idd }`,{headers: { method: 'DELETE',Authorization:`Bearer ${Token}`},
      // mode: 'cors'});

    //   if ( response.ok )
    //   {
    //     const data = await response.json();
    //     toast.current.show( { severity: 'success', summary: 'Delete', detail: data.message, life: 2000 } );
    //     setZoneData( prevData => prevData.filter( row => row.id !== idd ) );
    //   } else
    //   {
    //     const errorData = await response.json();
    //     toast.current.show( { severity: 'warn', summary: 'Rejected', detail: errorData.message, life: 3000 } );
    //     console.error( 'Error:', response.status );
    //   }
    // } catch ( error )
    // {
    //   console.error( 'Error:', error );
    // }
  };



  const deletereject = () =>
  {
    toast.error('Cancel Delete Request');
    // toast.current.show( { severity: 'warn', summary: 'Cancel', detail: 'Cancel Delete Request', life: 3000 } );
  };



  const deletconfirm = ( rowData ) => ( event ) =>
  {
    confirmPopup( {
      target: event.currentTarget,
      message: 'Are you sure you want to Delete Zone?',
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-danger',
      accepticon: 'pi pi-exclamation-triangle',
      accept: () => deletaccept( rowData ),
      reject: deletereject
    } );
  };



  const actionTemplate = ( rowData ) => (
    <div>
      <Button onClick={ () => editRow( rowData ) } icon="pi pi-pencil p-0 " className="p-0 w-5 me-2" text severity="warning" />
      <Button icon="pi pi-trash p-0 " onClick={ deletconfirm( rowData ) } className="p-0 w-5" text severity="danger" />
      <ConfirmPopup />
    </div>
  );



  const renderHeader = () =>
  {
    return (
      <div className="row ">

        <div className="col-6">
          <div className="d-flex  align-items-center">
            <button onClick={ () => { addLink( "addzone_m" ); navigateelast(); } } className='px-2 btn btn-sm btn-primary me-2 text-white' autoFocus >
              Add
            </button>
            <MultiSelect
              value={ visibleColumns }
              options={ coloms }
              optionLabel="header"
              onChange={ onColumnToggle }
              display="chip"
              className="w-full sm:w-20rem"
            /><span className="px-1"></span>
            <button type="button" className="btn btn-csv text-white btn-sm" onClick={ () => exportCSV( false ) } title="CSV">
              <i className="pi pi-file-excel"></i>
            </button><span className="px-1"></span>
            <button type="button" className="btn btn-success btn-sm" onClick={ exportExcel } title="XLS">
              <i className="pi pi-file-excel"></i>
            </button><span className="px-1"></span>
            <button type="button" className="btn btn-danger btn-sm" onClick={ exportPdf } title="PDF">
              <i className="pi pi-file-pdf"></i>
            </button>
          </div>
        </div>
        <div className="col-6">
          <div className="d-flex justify-content-end ">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText value={ globalFilterValue } style={ { height: '35px' } } type="search" onChange={ onGlobalFilterChange } placeholder="Keyword Search" />
            </span>
          </div>
        </div>
      </div>

    );
  };


  const header = renderHeader();
  const exportFilename = `ZoneData - Date(${ new Date().toLocaleDateString() })`;

  return (
    <div className="bg-white p-2 mt-2" style={ { border: "solid #efefef 2px" } }><DynamicTitleComponent newTitle={ `Zone ` } />
      {/* <Toast ref={ toast } /> */}
      { isLoading ? ( <Loaderr/> ) : ( '' ) }
      <DataTable ref={ dt } value={ zoneData } scrollable resizableColumns showGridlines const
        loading={ loading } exportFilename={ exportFilename } scrollHeight="70vh" style={ { minWidth: '50rem' } } dataKey="id" filters={ filters } header={ header } emptyMessage="No Zone found." globalFilterFields={ [ 'zonename' ] }>
        <Column
          header="Action"
          excludeFromExport
          body={ ( rowData ) => actionTemplate( rowData, 'id' ) }
          style={ { minWidth: '60px', width: '60px' } }
        ></Column>
         <Column
                    key='sno'
                    field='sno'
                    header="Sno"
                    style={ { minWidth: '60px' ,width: '60px', textAlign:"right"} }
                ></Column>
        <Column
          key='zonename'
          field='zoneName'
          header="Zone Name"
          sortable
          filterPlaceholder={ `Search by Zone Name` }
          style={ { minWidth: '60px' } }
        ></Column>
      </DataTable>
    </div>
  );
}
export default ZoneTable;
