import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { toast } from 'react-toastify';
import { BrowserRouter as Router, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { maxlength, validInput, Star } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent';
import CommonFunctions from '../../../../Common/CommonFunction';

const EditPacking = ( e ) =>
{
    const { id } = useParams();
    const {  handleSplitLastLink, getLastLinkInSession,  navigateelast,  } = useLinkManagement();
    const {  EditChangeData,  splitLastInWitchD, splitLastInWitchF } = CommonFunctions();
    const toastt = useRef( null );
    const navigate = useNavigate();
    const [ packing_name, set_packing_name ] = useState( '' );
    const [ print_name, set_print_name ] = useState( '' );
    const [ qty, set_qty ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const namee = useRef();
    const print = useRef();
    const qtyid = useRef();

    useEffect( () =>
    {
        const fetchData = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }packing/${ id }` );
                const packingData = response.data;
                set_packing_name( packingData.name );
                set_print_name( packingData.printName );
                set_qty( packingData.qty );
            } catch ( error )
            {
                console.error( 'Error:', error );
                // alert( 'Error:', error );
                navigate( '/' );
            }
        };
        fetchData();
    }, [] );
    const PackingData = () => ( {
        packing_name: packing_name.trim(),
        packing_print_name: print_name.trim(),
        qty_in_pack: qty.trim(),
    } );
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };
    function SubmitForm( e )
    {
        e.preventDefault();
        setError( '' );
        const trimmedname = maxlength( packing_name.trim(), 'packing name', 50 );
        const trimmedprint = maxlength( print_name.trim(), 'print name', 50 );
        if ( trimmedname !== true )
        {
            setError( trimmedname );
            foCoUs( namee );
            return false;
        }
        else if ( trimmedprint !== true )
        {
            setError( trimmedprint );
            foCoUs( print );
            return false;
        } else 
        {
            axios
                .patch( `${ BaseUrl }packing/${ id }`, PackingData(), { mode: 'cors' } )
                .then( function ( response )
                {
                    const data = response.data;
                    if ( data.success === true )
                    {
                        toast.success( 'Update Packing Success' );

                        const LastLinkk = getLastLinkInSession();
                        if ( LastLinkk === 'edit_packing' )
                        {
                            handleSplitLastLink();
                            navigateelast();
                        } else
                        {
                            handleSplitLastLink();
                            if ( LastLinkk === 'add_batchlot_m' || LastLinkk === 'add_batchlot' || LastLinkk === "edit_batchlot" )
                            {
                                const batchlot = sessionStorage.getItem( 'batch_lot_data' );
                                if ( batchlot !== '' || batchlot !== null || batchlot !== undefined )
                                {
                                    const d = batchlot ? JSON.parse( batchlot ) : {};
                                    const value = d.batch_packing.value;
                                    const label = packing_name;
                                    d.batch_packing = { value, label };
                                    sessionStorage.setItem( 'batch_lot_data', JSON.stringify( d ) );
                                }
                            }
                            if ( LastLinkk === 'add_opening_stock_m' || LastLinkk === 'add_opening_stock' || LastLinkk === "edit_opening_stock" )
                            {
                                const openingstockdata = sessionStorage.getItem( 'openingstock' );
                                // Parse 'packing index' from session storage (OSPackingfocus) use for pass the new packing selected index
                                const indexid = JSON.parse( sessionStorage.getItem( 'OSPackingfocus' ) );
                                if ( openingstockdata !== '' || openingstockdata !== null || openingstockdata !== undefined )
                                {
                                    const d = openingstockdata ? JSON.parse( openingstockdata ) : {};
                                    const expensesArray = d.expenses;
                                    // Define the new name value
                                    for ( const item of expensesArray )
                                    {
                                        if ( item.packing.value === id )
                                        {   // Check the name.value of the current item
                                            item.packing.label = packing_name;          // Update the label property
                                        }
                                    }
                                    d.expenses = expensesArray;
                                    // Store the updated data back in session storage
                                    sessionStorage.setItem( 'openingstock', JSON.stringify( d ) );
                                }
                            }
                            EditChangeData( packing_name, data.Newid ); // Change Last Open Popup Data or fild
                            navigateelast();
                        }
                    }
                } )
                .catch( function ( error )
                {
                    var errrr = error.response;
                    const errors = GlobalError( errrr );
                    setError( errors ); foCoUs( namee );
                } );
        }
    }
    const handleChange = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'packing_name' ) { var valuee = validInput( value ); set_packing_name( valuee ); removeinvalid( namee ); }
        if ( name === 'packing_print_name' ) { var valuee = validInput( value ); set_print_name( valuee ); removeinvalid( print ); }
        if ( name === 'qty_in_pack' ) { var valuee = validInput( value ); set_qty( valuee ); removeinvalid( qtyid ); }
    };
    const accept = () =>
    {
        splitLastInWitchD();
        splitLastInWitchF();
        handleSplitLastLink();
        navigateelast();
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    return (
        <>
            <div className="px-2 mt-2 main"><DynamicTitleComponent newTitle={ `Edit Packing` } />
                <div className="row">
                    <div className="col-md-6">

                        <div className='card'>
                            <div className="pt-2 card-header">
                                <h4>Edit Packing</h4>
                            </div>
                            <form className="form-horizontal" onSubmit={ SubmitForm }>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">

                                            <div className=" row">
                                                <div className="col-md-12 col-sm-12">
                                                    <label htmlFor="packing_name" className=" "> Packing Name</label><Star />
                                                    <span className='float-end' style={ { color: 'red' } }> { error }</span>
                                                    <input type="text" className="form-control form-control-sm " id="packing_name"
                                                        name="packing_name"
                                                        placeholder="Packing Name"
                                                        value={ packing_name }
                                                        ref={ namee }
                                                        autoFocus
                                                        onChange={ handleChange }
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="packing_print_name" className="">Print Name </label> <Star />
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        id="packing_print_name"
                                                        name="packing_print_name"
                                                        value={ print_name }
                                                        ref={ print }
                                                        onChange={ handleChange }
                                                        placeholder="Print Name"
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <label htmlFor="qty_in_pack" className="">
                                                        Quantity In Pack

                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        id="qty_in_pack"
                                                        name="qty_in_pack"
                                                        placeholder="Pack"
                                                        value={ qty }
                                                        ref={ qtyid }
                                                        onChange={ handleChange }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitForm }>Update</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
                <Toast ref={ toastt } />
                <ConfirmDialog />
            </div>
        </>
    );
}

export default EditPacking;

