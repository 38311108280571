import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import Select, { components } from 'react-select';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { GlobalError } from '../../../validations/globalArray';
import customStyles from '../../../validations/customStyles'
import axios from 'axios';
import useLinkManagement from '../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../CommonComponents/DynamicTitleComponent'
import { Editicon } from '../../../validations/validations';
import { Loaderr } from '../../../CommonComponents/Loader';
import CheckAuth from '../../../APIHelper/CheckAuth';
import { toast } from 'react-toastify';
function EditAccount( props )
{
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast } = useLinkManagement();
    const { handleLogout, setLoggedIn, isLoggedInToken, isLoading, setIsLoading } = CheckAuth();
    // const { id } = useParams();
    const [ id, SetId ] = useState();
    // const toast = useRef( null );
    const location = useLocation();
    const currentUrl = location.pathname;
    const [ group_name, setAc_group_name ] = useState( '' ); const AccountNameRef = useRef();
    const [ ac_account_name, setAc_account_name ] = useState( '' );
    const [ ac_bank_short_name, setAc_bank_short_name ] = useState( '' );
    const [ GroupOptions, setGroupOptions ] = useState( [] );
    const [ error, setError ] = useState( '' );
    const navigate = useNavigate();

    useEffect( () =>
    {
        let editID = sessionStorage.getItem( 'editaccountID' );
    SetId( editID );
        const savedData = sessionStorage.getItem( 'account_form' );
        if ( savedData )
        {
            const parsedData = JSON.parse( savedData );
            const pidcheck = parsedData.id;
            if ( pidcheck === '' || pidcheck === undefined || pidcheck === null ) { Apia() }
            else { sessionstor(); }
        }
        else { Apia() }
    }, [] );
    const Apia = () =>//get data by api
    {
        const fetchData = async () =>
        {let editID = sessionStorage.getItem( 'editaccountID' );
            const Token = localStorage.getItem( 'token' );
            setIsLoading( true );
            try
            {
                const response = await axios.get( `${ BaseUrl }account/${ editID }` , { headers: { Authorization: `Bearer ${ Token }` }, mode: 'cors' });
                const d = response.data[ 0 ];
                const data = response.data[ 0 ];
                if ( data.status === false )
                {

                    if ( data.statusCode == '401' )
                    {
                        alert( data.message );
                        handleLogout();
                        setLoggedIn( false );
                    }
                    if ( data.statuscode == '406' )
                    {
                        toast.error( data.message );
                    }
                    toast.error( data.message );
                    setIsLoading( false );
                }
                setAc_group_name( { value: d.groupId, label: d.groupName, systemid: d.system } );
                setAc_account_name( d.accName );
                setIsLoading( false );
            } catch ( error )
            {
                var errrr = error.response;
                const errors = GlobalError( errrr );
                toast.error( errors );
                setIsLoading( false );
                // console.error( 'Error:', error );
                // navigate( '/' );
            }
        };

        fetchData();
    };
    const sessionstor = () => //get data on session storage
    {
        const savedData = sessionStorage.getItem( 'account_form' );
        if ( savedData )
        {
            const data = JSON.parse( savedData );
            setAc_group_name( data.group_name );
            setAc_account_name( data.ac_account_name );
            setAc_bank_short_name( data.ac_bank_short_name );
        }
    };
    const handleChange = ( e ) =>
    {
        const { name, value } = e.target;
        if ( name === 'ac_account_name' ) { setAc_account_name( value ); }
        else if ( name === 'ac_bank_short_name' ) { setAc_bank_short_name( value ); }
    };
    useEffect( () =>
    {
        // Fetch Group options from the API endpoint
        const Token = localStorage.getItem( "token" );
        fetch( BaseUrl + 'group',{ headers: { Authorization: `Bearer ${ Token }` } } )
            .then( ( response ) => response.json() )
            .then( ( data ) =>
            {
                if ( data.status === false )    // server return -> status false
                {
                    if ( data.statusCode == '401' ) // server error unauthorized access or token is invalid
                    {
                        toast.error( data.message ); // show error message in toast
                        handleLogout();              // user logged out . Auto
                    }
                    toast.error( data.message );
                }
                const mappedOptions = data.map( ( item ) => ( {
                    value: item.id,
                    label: item.groupName,
                    systemid: item.system,
                } ) );
                setGroupOptions( mappedOptions );
            } )
            .catch( ( error ) =>
            {
                console.log( 'Error fetching options:', error );
            } );
    }, [] );
    const add_group = () =>
    {
        sessionStorage.setItem( 'account_form', JSON.stringify( { id, ac_account_name, group_name, ac_bank_short_name } ) );
        addLink( "addgroup" );
        navigateelast();

        // if ( currentUrl === AddSellerSlip )
        // {
        //     navigate( '/seller-slip-expenses-profile/add/expenses/add/posting-ac/edit/group' );
        // }
        // else if ( currentUrl === AddSellerSlipEditTdsCreditAc )
        // {

        //     navigate( '/seller-slip-expenses-profile/add/expenses/add/section-type/add/tda-credit-ac/edit/group' );
        // }
        // else if ( currentUrl === AddSellerSlipEditAc )
        // {
        //     navigate( '/seller-slip-expenses-profile/add/expenses/add/section-type/edit/tda-credit-ac/edit/group' );
        // }



    };
    const SelectMenuButton = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <span
                    // to={ '/accounts/add-accounts/group' }
                    onClick={ add_group }
                    className="btn btn-xsm  btn-primary btn-block w-100"
                >
                    Add
                </span>
            </components.MenuList>
        );
    };
    const SavegroupValue = ( selected ) => { setAc_group_name( selected ); };
    const AccountData = () => ( {
        group_name: group_name,
        account_name: ac_account_name,
        bank_short_name: ac_bank_short_name
    } );
    const SubmitForm = () => { saverequest(); }
    const saverequest = () =>
    {setIsLoading( true );
        const Token = localStorage.getItem( "token" );
        axios
            .patch( `${ BaseUrl }account/${ id }`, AccountData(), {
                headers: {'Authorization': `Bearer ${ Token }`,   }, mode: 'cors',
            } )
            .then( function ( response )
            {
                const data = response.data;
                if ( data.success === true )
                {setIsLoading( false );
                    // sessionStorage.removeItem( 'account_form' );
                    sessionStorage.removeItem( 'account_form' );
                    sessionStorage.removeItem( 'editaccountID' );
                    handleSplitLastLink();
                    const LastLinkk = getLastLinkInSession();
                    let lasttlinkk = LastLinkk;
                    const linkParts = LastLinkk.split( ',' );

                    if ( linkParts.length === 2 )
                    {
                        const link = linkParts[ 0 ].trim();
                        const editId = linkParts[ 1 ].trim();
                        lasttlinkk = link;
                    }
                    if ( lasttlinkk === 'add_tdsST_m' || lasttlinkk === 'add_tdsST' || lasttlinkk === "edit_tdsST" )
                    {
                        const TdsSC1 = sessionStorage.getItem( 'tdssectioncode' );
                        if ( TdsSC1 !== '' || TdsSC1 !== null || TdsSC1 !== undefined )
                        {
                            const d = TdsSC1 ? JSON.parse( TdsSC1 ) : {};
                            if ( group_name.value === '6' || group_name.value === '7' || group_name.value === '16' )
                            {
                                d.TDSCA = '';
                                sessionStorage.setItem( 'tdssectioncode', JSON.stringify( d ) );
                            } else
                            {
                                const value = d.TDSCA.value;
                                const label = ac_account_name;
                                d.TDSCA = { value, label };
                                sessionStorage.setItem( 'tdssectioncode', JSON.stringify( d ) );
                            }
                        }
                    }
                     if( lasttlinkk === 'add_tdspartywise_m' || lasttlinkk === 'add_tdspartywise' || lasttlinkk === "edit_tdspartywise"){
                        const TdsSC2 = sessionStorage.getItem( 'party_wise_parameters' );
                        if ( TdsSC2 !== '' || TdsSC2 !== null || TdsSC2 !== undefined )
                        {
                            const d2 = TdsSC2 ? JSON.parse( TdsSC2 ) : {};
                            if ( group_name.value === '6' || group_name.value === '7' || group_name.value === '16' )
                            {
                                d2.account = '';
                                sessionStorage.setItem( 'party_wise_parameters', JSON.stringify( d2 ) );
                            }else{
                                const value = d2.account.value;
                                const label = ac_account_name;
                                d2.account = { value, label };
                                sessionStorage.setItem( 'party_wise_parameters', JSON.stringify( d2 ) );
                            }

                        }
                    }
                     if( lasttlinkk === 'add_expenses_m' || lasttlinkk === 'add_expenses' || lasttlinkk === "edit_expenses"){
                        const TdsSC3 = sessionStorage.getItem( 'dbexpenses' );
                        if ( TdsSC3 !== '' || TdsSC3 !== null || TdsSC3 !== undefined )
                        {
                            const d3 = TdsSC3 ? JSON.parse( TdsSC3 ) : {};
                            if ( group_name.value === '6' || group_name.value === '7' || group_name.value === '16' )
                            {
                                d3.account = '';
                                sessionStorage.setItem( 'dbexpenses', JSON.stringify( d3 ) );
                            }else{
                                const value = d3.account.value;
                                const label = ac_account_name;
                                d3.account = { value, label };
                                sessionStorage.setItem( 'dbexpenses', JSON.stringify( d3 ) );
                            }

                        }
                    }
                    if (LastLinkk === 'add_opening_stock_m' || LastLinkk === 'add_opening_stock' || LastLinkk === "edit_opening_stock"){
                        const openingstockdata = sessionStorage.getItem('openingstock' );
                        if ( openingstockdata !== '' || openingstockdata !== null || openingstockdata !== undefined )
                        {
                            const d = openingstockdata ? JSON.parse( openingstockdata ) : {};
                            if ( group_name.value === '6' || group_name.value === '7' || group_name.value === '16' )
                            { toast.error( 'Group name : Bank Accounts, Cash In Hand, And Bank OCC/AC not use ' );
                                d.Account = '';
                                sessionStorage.setItem( 'openingstock', JSON.stringify( d ) );
                            } else
                            {
                                const value = d.Account.value;
                                const label = ac_account_name;
                                d.Account = { value, label };
                                sessionStorage.setItem( 'openingstock', JSON.stringify( d ) );
                            }

                        }
                    }
                    if (LastLinkk === 'add_goods_m' || LastLinkk === 'addgoods' || LastLinkk === "edit_goods"){
                        const goodsData = sessionStorage.getItem('goods_data' );
                        if ( goodsData !== '' || goodsData !== null || goodsData !== undefined )
                        {
                            const d = goodsData ? JSON.parse( goodsData ) : {};
                            if ( group_name.value === '6' || group_name.value === '7' || group_name.value === '16' )
                            { toast.error( 'Group name : Bank Accounts, Cash In Hand, And Bank OCC/AC not use ' );
                                d.group_trading_ac = '';
                                sessionStorage.setItem( 'goods_data', JSON.stringify( d ) );
                            } else
                            {
                                const value = d.group_trading_ac.value;
                                const label = ac_account_name;
                                d.group_trading_ac = { value, label };
                                sessionStorage.setItem( 'goods_data', JSON.stringify( d ) );
                            }

                        }
                    }
                    navigateelast();
             
                }
                if ( data.status === false )
                {
                    if ( data.statusCode == '401' )
                    {
                        //   alert(data.message);
                        toast.error( data.message );
                        handleLogout();
                        setLoggedIn( false );
                    }
                    setIsLoading( false ); // call logout user
                }
            } )
            .catch( function ( error )
            {
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors ); AccountNameRef.current.focus();
            } );
    }

    const editGroupForm = ( value, systemid = null ) =>
    {
        const Token = localStorage.getItem( 'token' );
        const checkeditid = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }checkgroup/${ value }`, {
                    headers: { Authorization: `Bearer ${ Token }` },
                    mode: 'cors'
                } );
                const returndata = response.data;
                if ( returndata.status === false )
                {
                    if ( returndata.statusCode === '401' )
                    {
                        toast.error( returndata.message );
                        handleLogout();
                        setLoggedIn( false );
                    }
                    if ( returndata.code == '1' )
                    {
                        toast.error( returndata.message );
                        setGroupOptions( prevData => prevData.filter( row => row.id !== value ) );
                    }
                    else { toast.error( returndata.message ); }
                }
                if ( returndata.status === true )
                {
                    if ( systemid === '1' && value === '1' )
                    {
                        sessionStorage.removeItem( 'account_form' );
                        sessionStorage.setItem( 'account_form', JSON.stringify( { id, ac_account_name, group_name, ac_bank_short_name } ) );
                        addLink( "edit_group," + value );
                        sessionStorage.setItem( 'editgroupID', value );
                        navigateelast();
                    } else
                    {
                        sessionStorage.removeItem( 'account_form' );
                        sessionStorage.setItem( 'account_form', JSON.stringify( { id, ac_account_name, group_name, ac_bank_short_name } ) );
                        addLink( "edit_group," + value );
                        sessionStorage.setItem( 'editgroupID', value );
                        navigateelast();

                    }
                }
            }
            catch ( error )
            {
                toast.error( 'Error:', error );
                console.error( 'Error:', error );
            }
        }
        if (value !== '1' && systemid === '1' )
        {
            toast.error( `Entry can't be Update due to entry is system defined.` );
            return false;
        } else
        {
            checkeditid();
        }

        // if ( systemid === '1' && value === '1' )
        // {
        //     sessionStorage.removeItem( 'account_form' );
        //     sessionStorage.setItem( 'account_form', JSON.stringify( { id, ac_account_name, group_name, ac_bank_short_name } ) );
        //     addLink( "edit_group," + value );
        //     navigateelast();
        // }
        // else if ( systemid === '1' )
        // {
        //     toast.current.show( { severity: 'error', summary: 'Rejected', detail: 'This Group System Defined', life: 3000 } );
        //     return false;
        // }
        // else 
        // {
        //     sessionStorage.removeItem( 'account_form' );
        //     sessionStorage.setItem( 'account_form', JSON.stringify( { id, ac_account_name, group_name, ac_bank_short_name } ) );
        //     addLink( "edit_group," + value );
        //     navigateelast();
        // }

    };
    const EditGroup = ( props ) =>
    {
        return (
            <>
                <div
                    onClick={ () => editGroupForm( props.selectProps.value.value, props.selectProps.value.systemid ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const editGroup = ( { children, ...props } ) =>
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditGroup { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };
    const accept = () =>
    {
        // toast.current.show( { severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000, } );
        sessionStorage.removeItem( 'account_form ' ); //// empty  Account session Storage 
        sessionStorage.removeItem( 'editaccountID' ); //// empty  Account session Storage 
        handleSplitLastLink();
        navigateelast();
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    useEffect( () =>
    {
        const handleKeyDown = ( event ) =>
        { if ( event.key === 'Escape' ) { confirm( 'top' ); } };
        document.addEventListener( 'keydown', handleKeyDown );
        return () => { document.removeEventListener( 'keydown', handleKeyDown ); };
    }, [] );
    return (
        <>
            <div className="p-2 mt-2 main">
            { isLoading ? ( <Loaderr /> ) : ( '' ) }
                <ConfirmDialog />
                <DynamicTitleComponent newTitle="Edit Accounts" />
                <div className='card '>
                    <div className="card-header">
                        <h4 className="pt-2">Edit Account</h4>
                    </div>
                    <form  >
                        <div className="row card-body">
                            <div>
                                <span className='float-end' style={ { color: 'red' } }> { error }  </span>
                            </div>

                            <div className="col-xl-12 col-md-12 col-sm-12">

                                <div className="form-group row">

                                    <div className="col-md-4 col-sm-6 ">
                                        <label htmlFor="group_name" className=" ">Group<span className="text-danger"><sup>*</sup></span></label>
                                        {/* <EditGroup /> */ }
                                        <Select
                                            options={ GroupOptions }
                                            components={ { MenuList: SelectMenuButton, IndicatorsContainer: editGroup } }
                                            styles={ customStyles }
                                            isClearable
                                            autoFocus
                                            isSearchable
                                            name="group_name"
                                            value={ group_name }
                                            onChange={ SavegroupValue }
                                            ref={ AccountNameRef }
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-6 ">
                                        <label htmlFor="ac_account_name" className=" ">Account Name<span className="text-danger"><sup>*</sup></span></label>
                                        <input type="text" className="form-control form-control-sm  " id="ac_account_name" name="ac_account_name" placeholder="Account Name" value={ ac_account_name } onChange={ handleChange } />
                                    </div>
                                    <div className="col-md-4 col-sm-6 ">
                                        <label htmlFor="ac_bank_short_name" className="">Bank Short Name</label>
                                        <input type="text" className="form-control form-control-sm  " id="ac_bank_short_name" name="ac_bank_short_name" placeholder="Short Name" value={ ac_bank_short_name } onChange={ handleChange } />
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="formBtn card-footer">
                            <button className="btn px-4  me-2" type='button' onClick={ SubmitForm }>Update</button>
                            <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                        </div>
                    </form>
                </div>



            </div>
            {/* <Toast ref={ toast } />
            <ConfirmDialog /> */}
        </>
    );
}
export default EditAccount