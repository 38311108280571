
import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import Select, { components } from 'react-select';
import { BrowserRouter as Router, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { OnlyNumber, SelectCheck, removeLeadingZero, threeDecimal, Pincoode, emtySelect, maxlength, validInput, Star, Editicon } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import customStyles from '../../../../validations/customStyles';
import EditAccount from '../../Accounts/EditAccount';
const EditSectionType = ( e ) =>
{

    const toast = useRef( null );
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, setFocus, splitLastFocus } = useLinkManagement();
    const { id } = useParams();
    const AddSellerSlip = '/seller-slip-expenses-profile/add/expenses/add/section-type/' + id + '';          // add on /seller-slip-expenses-profile Add
    const edit_Tds_section = '/tds-section-type/edit/' + id + '';
    const edit_add_Tds_by_parameter = '/tds-parameter/add/tds-section-type/' + id + ''; // Add Time Edit 
    const edit_edit_Tds_by_parameter = '/tds-parameter/edit/tds-section-type/' + id + ''; // Add Time Edit 
    const Edit_expenses = '/expenses/edit/section-type/' + id + ''; // Edit Time Edit 
    const Add_expenses = '/expenses/add/section-type/' + id + ''; // Add Time Edit 

    const location = useLocation();
    const currentUrl = location.pathname;
    const navigate = useNavigate();
    const [ Scode, SetSCode ] = useState( '' ); const SCodeRef = useRef(); const [ TdsAcOpt, setTdsAcOpt ] = useState( [] );
    const [ NPay, SetNPay ] = useState( '' ); const NPayRef = useRef();
    const [ TDSCA, SetTDSCA ] = useState( '' ); const TDSCARef = useRef();
    const [ error, setError ] = useState( '' );


    useEffect( () =>
    {
        const savedData = sessionStorage.getItem( 'tdssectioncode' );
        if ( savedData )
        {
            const parsedData = JSON.parse( savedData );
            const pidcheck = parsedData.id;
            if ( pidcheck === '' || pidcheck === undefined || pidcheck === null ) { Apia() }
            else { sessionstor(); }
        }
        else { Apia() }
    }, [] );

    const Apia = () =>//get data by api
    {
        const fetchData = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }tdssectiontype/${ id }` );
                const item = response.data[ 0 ];
                console.log( item );
                SetSCode( item.sectionCode );
                SetNPay( item.sectionName );
                if ( item.tdsCreditAccId === '0' || item.tdsCreditAccId === undefined || item.tdsCreditAccId === null )
                {
                    SetTDSCA( '' );
                } else
                {
                    SetTDSCA( { value: item.tdsCreditAccId, label: item.accName } );
                }


            } catch ( error )
            {
                console.log( 'Error:', error );
            } finally
            {
                //  setLoading(false);
                // setLoading(false);
            }
        };
        fetchData();
    };
    const sessionstor = () => //get data on session storage
    {

        const savedData = sessionStorage.getItem( 'tdssectioncode' );
        if ( savedData )
        {
            const data = JSON.parse( savedData );
            SetSCode( data.Scode );
            SetNPay( data.NPay );
            SetTDSCA( data.TDSCA );
        }
    };
    //Set focus
    useEffect( () =>
    {
        const setFocusFromStorage = JSON.parse( sessionStorage.getItem( 'focusme' ) );
        if ( setFocusFromStorage && setFocusFromStorage.length > 0 )
        {
            const focusElement = setFocusFromStorage[ setFocusFromStorage.length - 1 ];
            setTimeout( () =>
            {
                if ( focusElement === 'TDSCARef' )
                {
                    TDSCARef.current.focus();
                    splitLastFocus();
                }
            }, 10 ); // 10 milliseconds delay
        }
    }, [] );
    const TdsSecData = () => ( {
        sectioncode: Scode.trim(),
        nationPay: NPay.trim(),
        TdsAc: TDSCA,
    } );
    useEffect( () =>
    {
        const fetchUqc = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }/vaccountopt`, { mode: 'cors' } );
                const data = response.data;
                const mappedOptions = data.map( item => ( { value: item.id, label: item.accName } ) );
                setTdsAcOpt( mappedOptions );
            }
            catch ( error ) { console.error( 'Error fetching Voucherac Opt.', error ); }
        };
        fetchUqc();
    }, [] );
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };
    function SubmitForm( e )
    {
        e.preventDefault();
        setError( '' );
        const doc_name = Scode.trim();
        if ( doc_name === '' ) { setError( 'Section Code not valid, check it.' ); foCoUs( SCodeRef ); return false; }
        saverequest();
    }
    const saverequest = () =>
    {
        axios
            .patch( `${ BaseUrl }tdssectiontype/${ id }`, TdsSecData(), { mode: 'cors' } )
            .then( function ( response )
            {
                const data = response.data;
                if ( data.success === true )
                {
                    sessionStorage.removeItem( 'tdssectioncode' );
                    toast.current.show( { severity: 'success', summary: 'Success', detail: 'Update TDS Section Type Success', life: 2000, } );
                    
                    handleSplitLastLink();
                        const LastLinkk = getLastLinkInSession();
                        let lasttlinkk = LastLinkk;
                        const linkParts = LastLinkk.split( ',' );
                        if ( linkParts.length === 2 )
                        {
                            const link = linkParts[ 0 ].trim();
                            const editId = linkParts[ 1 ].trim();
                            lasttlinkk = link;
                        }
                        if ( lasttlinkk === 'add_tdsparameter_m' || lasttlinkk === 'add_tdsparameter' || lasttlinkk === "edit_tdsparameter" )
                        {
                            const TdsParameter = sessionStorage.getItem( 'tds_parameters' );
                            if ( TdsParameter !== '' || TdsParameter !== null || TdsParameter !== undefined )
                            {
                                const d = TdsParameter ? JSON.parse( TdsParameter ) : {};
                                    const value = d.Scode.value;
                                    const label = Scode;
                                    d.Scode = { value, label };
                                    sessionStorage.setItem( 'tds_parameters', JSON.stringify( d ) );
                                
                            }
                        }
                        if ( lasttlinkk === 'add_tdspartywise_m' || lasttlinkk === 'add_tdspartywise' || lasttlinkk === "edit_tdspartywise" )
                        {
                            const TdsParameter = sessionStorage.getItem( 'party_wise_parameters' );
                            if ( TdsParameter !== '' || TdsParameter !== null || TdsParameter !== undefined )
                            {
                                const d = TdsParameter ? JSON.parse( TdsParameter ) : {};
                                    const value = d.Scode.value;
                                    const label = Scode;
                                    d.Scode = { value, label };
                                    sessionStorage.setItem( 'party_wise_parameters', JSON.stringify( d ) );
                                
                            }
                        }
                        else if ( lasttlinkk === 'add_expenses_m' || lasttlinkk === 'add_expenses' || lasttlinkk === "edit_expenses" )
                        {
                            const TdsParameter = sessionStorage.getItem( 'dbexpenses' );
                            if ( TdsParameter !== '' || TdsParameter !== null || TdsParameter !== undefined )
                            {
                                const d = TdsParameter ? JSON.parse( TdsParameter ) : {};
                                    const value = d.code.value;
                                    const label = Scode;
                                    d.code = { value, label };
                                    sessionStorage.setItem( 'dbexpenses', JSON.stringify( d ) );
                                
                            }
                        }
                        navigateelast();
                    // if ( currentUrl === Add_expenses ) {
                    //     const storedData = sessionStorage.getItem( 'dbexpenses' );
                    //     const d = storedData ? JSON.parse( storedData ) : {};
                    //     const value = d.code.value;
                    //     const label = Scode;
                    //     d.code = { value, label };
                    //     sessionStorage.setItem( 'dbexpenses', JSON.stringify( d ) );
                    //      navigate( '/expenses/add' ); }///Add Expenses
                    // if ( currentUrl === Edit_expenses )///Edit Expenses
                    // {

                    //     const storedData = sessionStorage.getItem( 'dbexpenses' );
                    //     const d = storedData ? JSON.parse( storedData ) : {};
                    //     const value = d.code.value;
                    //     const label = Scode;
                    //     const rid = d.id;
                    //     d.code = { value, label };
                    //     console.log(d);
                    //     sessionStorage.setItem( 'dbexpenses', JSON.stringify( d ) );
                    //      navigate( '/expenses/edit/' + rid + '' );
                    // }
                    // if ( currentUrl === edit_Tds_section ) { navigate( '/tds-section-type' ); }
                    // if ( currentUrl === edit_add_Tds_by_parameter ) { changeSectioncodeLabel( 'add' ); }
                    // if ( currentUrl === edit_edit_Tds_by_parameter )
                    // {
                    //     const savedData = sessionStorage.getItem( 'tds_parameters' );
                    //     const parsedData = JSON.parse( savedData );
                    //     const idd = parsedData.id;
                    //     changeSectioncodeLabel( `edit/${ idd }` );
                    // }
                    // if ( currentUrl === AddSellerSlip ) { const storedData = sessionStorage.getItem( 'dbexpenses' );
                    //     const d = storedData ? JSON.parse( storedData ) : {};
                    //     const value = d.code.value;
                    //     const label = Scode;
                    //     d.code = { value, label };
                    //     sessionStorage.setItem( 'dbexpenses', JSON.stringify( d ) );
                    //     navigate( '/seller-slip-expenses-profile/add/expenses' );
                    // } // Seller slip

                }
            } )
            .catch( function ( error )
            {
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors ); foCoUs( SCodeRef );
            } );
    }
    const changeSectioncodeLabel = ( from ) =>
    {
        const storedData = sessionStorage.getItem( 'tds_parameters' );
        const data = storedData ? JSON.parse( storedData ) : {};
        const value = data.Scode.value;
        const label = Scode;
        data.Scode = { value, label };
        sessionStorage.setItem( 'tds_parameters', JSON.stringify( data ) );
        navigate( `/tds-parameter/${ from }` );
    };
    const chnageValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'sec_code' ) { var valuee = validInput( value ); SetSCode( valuee ); removeinvalid( SCodeRef ); }
        if ( name === 'npay' ) { var valuee = validInput( value ); SetNPay( valuee ); removeinvalid( NPayRef ); }
    };
    const Tds_Ac_change = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'TDS Credit A/c empty' ); }
        else { SetTDSCA( selectedOption ); }
    };
    const accept = () =>
    {
        sessionStorage.removeItem( 'tdssectioncode' );
        toast.current.show( { severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000, } );
        handleSplitLastLink();
        navigateelast();
        // const savedData = sessionStorage.getItem( 'tds_parameters' );
        // if ( savedData )
        // {
        //     const parsedData = JSON.parse( savedData );
        //     const idd = parsedData.id;
        // } else
        // {
        //     var idd = '';
        // }

        // if ( currentUrl === edit_Tds_section ) { navigate( '/tds-section-type' ); }
        // if ( currentUrl === edit_add_Tds_by_parameter ) { navigate( '/tds-parameter/add' ); }
        // if ( currentUrl === Add_expenses ) { navigate( '/expenses/add' ); }///Add Expenses
        // if ( currentUrl === Edit_expenses )///Edit Expenses
        // {
        //     const s = sessionStorage.getItem( 'dbexpenses' );
        //     const d = s ? JSON.parse( s ) : {};
        //     const rid = d.id;
        //     navigate( '/expenses/edit/' + rid + '' );
        // }
        // if ( currentUrl === edit_edit_Tds_by_parameter ) { navigate( `/tds-parameter/edit/${ idd }` ); }
        // if ( currentUrl === AddSellerSlip ) { navigate( '/seller-slip-expenses-profile/add/expenses' ); } // Seller slip
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     { if ( event.key === 'Escape' ) { confirm( 'top' ); } };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () => { document.removeEventListener( 'keydown', handleKeyDown ); };
    // }, [] );

    const editAccountForm = ( value ) =>
    {
        sessionStorage.setItem( 'tdssectioncode', JSON.stringify( { id, Scode, NPay, TDSCA } ) );
        addLink( "edit_account," + value );
        setFocus( 'TDSCARef' );
        navigateelast();
        // if ( currentUrl === AddSellerSlip )
        // {
        //     navigate( '/seller-slip-expenses-profile/add/expenses/add/section-type/edit/tda-credit-ac/' + value + '' );
        // } else
        // {
        //     navigate( '/expenses/add/posting-ac/' + value + '' );
        // }
    };
    const EditIndicator = ( props ) =>
    {
        return (
            <>
                <div
                    onClick={ () => editAccountForm( props.selectProps.value.value ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const IndicatorsContainer = ( { children, ...props } ) =>
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditIndicator { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };
    const addAccountform = () =>
    {
        sessionStorage.setItem( 'tdssectioncode', JSON.stringify( { id, Scode, NPay, TDSCA } ) );
        addLink( "addaccount" );
        setFocus( 'TDSCARef' );
        navigateelast();
        // if ( currentUrl === AddSellerSlip )
        // {
        //     navigate( '/seller-slip-expenses-profile/add/expenses/add/section-type/edit/tda-credit-ac' );
        // } else
        // {
        //     navigate( '/expenses/add/posting-ac' );
        // }

    }
    const accountBtn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <spna
                    onClick={ addAccountform }
                    className="btn btn-xsm  btn-primary btn-block w-100"
                > Add </spna>
            </components.MenuList>
        );
    };
    return (
        <>
            <div className="p-2 mt-2 main"> <DynamicTitleComponent newTitle={ `Edit | TDS Section Type ` } />
                <div className="row">

                    <div className="col-md-6">

                        <div className='card'>
                            <div className="card-header"> <h4>Edit TDS Section Type</h4>  </div>
                            <form className="form-horizontal" onSubmit={ SubmitForm } autoComplete='off'>
                                <div className="card-body   ">
                                    <div className="row">
                                        {/* left column */ }
                                        <div>
                                            <span className='float-end' style={ { color: 'red' } }> { error }</span>
                                        </div>
                                        <div className="col-md-12 pb-1 ">
                                            <div className=" row">
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="sec_code" >
                                                        Section Code
                                                    </label><Star />
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        id="sec_code"
                                                        name="sec_code"
                                                        placeholder="Section Code"
                                                        onChange={ chnageValue }
                                                        value={ Scode }
                                                        ref={ SCodeRef } autoFocus
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="npay" >
                                                        Nature of Payment
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        id="npay"
                                                        name="npay"
                                                        placeholder="Nature of Payment"
                                                        onChange={ chnageValue }
                                                        value={ NPay }
                                                        ref={ NPayRef }
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="tds_cr" >
                                                        TDS Credit A/c
                                                    </label>
                                                    <Select
                                                        id="tds_cr"
                                                        options={ TdsAcOpt }
                                                        value={ TDSCA }
                                                        onChange={ Tds_Ac_change }
                                                        components={ { MenuList: accountBtn, IndicatorsContainer: IndicatorsContainer } }
                                                        styles={ customStyles }
                                                        isClearable
                                                        isSearchable
                                                        ref={ TDSCARef }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitForm }>Update</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <Toast ref={ toast } />
                <ConfirmDialog />
            </div>
        </>
    );
}

export default EditSectionType;


