// import React, { useState, useEffect, useRef } from 'react';
// import { Link } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
// import axios from 'axios';
// import customStyles from '../../../../validations/customStyles';
// import Baseurl from '../../../BaseUrl';
// import useLinkManagement from '../../../../Routes/useLinkManagement';
// import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent';
// import { emtySelect, maxlength, validInput, Star } from '../../../../validations/validations'
// import { GlobalArray, GlobalError } from '../../../../validations/globalArray';
// import CheckAuth from '../../../../APIHelper/CheckAuth';
// import { Loaderr } from '../../../../CommonComponents/Loader';
// import Select from 'react-select';

// const OpeningStockTable = () =>
// {
//     const { isLoggedInToken, handleLogout, setLoggedIn, isLoading, setIsLoading } = CheckAuth();
//     const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast } = useLinkManagement();
//     return ( <><div className="row">
//         <h2 className='text-center'>Opening Stock Table</h2>
//         <span className=' btn-group-sm'>
//             {/* <Link to='add' className='link btn btn-primary px-3'>Add</Link>  */}
//             <button onClick={ () => { sessionStorage.removeItem( 'openingstock' ); addLink( "add_opening_stock_m" ); navigateelast(); } } className='px-2 btn btn-sm btn-primary me-2 text-white' autoFocus >
//               Add
//             </button>
//             <Link to='edit/1' className='link btn btn-info px-3 ms-2'>edit</Link>
//         </span>

//     </div>
//     </> )
// }

// export default OpeningStockTable;


import React, { useState, useEffect, useRef } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { toast } from 'react-toastify';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Column } from 'primereact/column';
import { MultiSelect } from "primereact/multiselect";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import { saveAs } from 'file-saver';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent';
import BaseUrl from '../../../BaseUrl';
import CheckAuth from '../../../../APIHelper/CheckAuth';
import CommonFunctions from '../../../../Common/CommonFunction';
const ManufacturerTable = () =>
{
    const [ loading, setLoading ] = useState( true );
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast } = useLinkManagement();
    const { isLoggedInToken, handleLogout, setLoggedIn, isLoading, setIsLoading } = CheckAuth();
    const {RemoveSSFild } = CommonFunctions();
    const dt = useRef( null );
    const [ globalFilterValue, setGlobalFilterValue ] = useState( '' );
    const [ Data, setData ] = useState( null );
    const coloms = [
        { field: "unit", header: "Unit", },
        { field: "netQty", header: "Qty.", },
        { field: "netAmt", header: "Amount", },
        { field: "username", header: "Entery By User", }
    ];
    const [ visibleColumns, setVisibleColumns ] = useState( coloms );
    const exportColumns = visibleColumns.map( ( col ) => ( { title: col.header, dataKey: col.field } ) );
    exportColumns.unshift( { title: 'Goods', dataKey: 'goodsname' } );
    const [ filters, setFilters ] = useState( {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        qty: { value: null, matchMode: FilterMatchMode.CONTAINS },
        amount: { value: null, matchMode: FilterMatchMode.CONTAINS },
        username: { value: null, matchMode: FilterMatchMode.CONTAINS },
    } );
    useEffect( () =>
    {
        fetchData();
    }, [] );
    const fetchData = async () =>
    {
        const Token = localStorage.getItem( "token" );
        try
        {
            const response = await axios.get( '' + BaseUrl + 'openingstock', { headers: { Authorization: `Bearer ${ Token }` } } );
            setData( response.data );
            setLoading( false );
        } catch ( error )
        {
            console.error( 'Error:', error );
        }
    };
    const onGlobalFilterChange = ( e ) =>
    {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters[ 'global' ].value = value;
        setFilters( _filters );
        setGlobalFilterValue( value );
    };
    const onColumnToggle = ( event ) =>
    {
        let selectedColumns = event.value;
        let orderedSelectedColumns = coloms.filter( ( col ) =>
            selectedColumns.some( ( sCol ) => sCol.field === col.field )
        );
        setVisibleColumns( orderedSelectedColumns );
    };
    const exportCSV = ( selectionOnly ) =>
    {
        dt.current.exportCSV( { selectionOnly } );
    };

    const exportPdf = () =>
    {
        const exportFilename = `${ new Date().toLocaleDateString() }`;
        import( 'jspdf' ).then( ( jsPDF ) =>
        {
            import( 'jspdf-autotable' ).then( () =>
            {
                const doc = new jsPDF.default( 0, 0 );
                doc.autoTable( exportColumns, Data );
                doc.save( `Opening-Stock-${ exportFilename }.pdf` );
            } );
        } );
    };
    
    const exportExcel = () =>
    {
        import( 'xlsx' ).then( ( xlsx ) =>
        {
            const worksheet = xlsx.utils.json_to_sheet( Data );
            const workbook = { Sheets: { data: worksheet }, SheetNames: [ 'data' ] };
            const excelBuffer = xlsx.write( workbook, {
                bookType: 'xlsx',
                type: 'array'
            } );
            saveAsExcelFile( excelBuffer, 'Opening Stock' );
        } );
    };
    const saveAsExcelFile = ( buffer, fileName ) =>
    {
        import( 'file-saver' ).then( ( module ) =>
        {
            if ( module && module.default )
            {
                const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const EXCEL_EXTENSION = '.xlsx';
                const data = new Blob( [ buffer ], { type: EXCEL_TYPE } );
                module.default.saveAs( data, `${ fileName }_export_${ new Date().getTime() }${ EXCEL_EXTENSION }` );
            }
        } );
    };

    const editRow = ( id ) =>
    {
        const idd = id.transactionMasterId;
        // addLink( 'edit_opening_stock,' + idd + '' );
        // navigateelast();
        // navigate( `/manufacturer/edit/${ idd }` );

        const Token = localStorage.getItem( "token" );
        const fetchData = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }checkopeningstock/${ idd }`, {
                    headers: { Authorization: `Bearer ${ Token }`, },//Pass Token In Header
                } );
                const editGroupData = response.data;
                if ( editGroupData.status === true )
                {
                    addLink( `edit_opening_stock,${ idd }` );
                    sessionStorage.setItem( 'OpeningStockeditID', idd );
                    navigateelast( idd );
                }
                else if ( editGroupData.status === false )
                { //Server error Or Valid Token 
                    if ( editGroupData.statusCode == '401' ) { toast.error( editGroupData.message ); handleLogout(); setLoggedIn( false ); }
                    else
                    {
                        toast.error( editGroupData.message );
                    }
                }
            } catch ( error )
            {
                toast.error( 'Error:', error );
                console.error( 'Error:', error );
            }
        };

        fetchData();
    };
    const deletaccept = async ( id ) =>
    {
        const idd = id.transactionMasterId;
        const Token = localStorage.getItem( "token" );
        try
        {
            const response = await fetch( `${ BaseUrl }openingstock/${ idd }`, {
                method: 'DELETE', headers: { Authorization: `Bearer ${ Token }` }
            } );

            if ( response.ok )
            {
                const data = await response.json();
                toast.success( data.message );
                setData( prevData => prevData.filter( row => row.transactionMasterId !== idd ) );
            } else
            {
                const errorData = await response.json();
                toast.error( errorData.message );
                console.error( 'Error:', response.status );
            }
        } catch ( error )
        {
            console.error( 'Error:', error );
        }
    };



    const deletconfirm = ( rowData ) => ( event ) =>
    {
        confirmPopup( {
            target: event.currentTarget,
            message: 'Are you sure you want to Delete Opening Stock ?',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            accepticon: 'pi pi-exclamation-triangle',
            accept: () => deletaccept( rowData ),
            // reject: deletereject
        } );
    };

    const actionTemplate = ( rowData ) => (
        <div>
            <Button onClick={ () => editRow( rowData ) } icon="pi pi-pencil p-0 " className="p-0 w-5 me-1" text severity="warning" />
            <Button icon="pi pi-trash p-0 " onClick={ deletconfirm( rowData ) } className="p-0 w-5 ms-1" text severity="danger" />
            <ConfirmPopup />
        </div>
    );
    const renderHeader = () =>
    {
        return (
            <div className="row ">
                <div className="col-6">
                    <div className="d-flex  align-items-center">
                        <button onClick={ () => { RemoveSSFild( 'openingstock' ); addLink( "add_opening_stock_m" ); navigateelast(); } } className='px-2 btn btn-sm btn-primary me-2 text-white' autoFocus >
                            Add
                        </button>
                        <MultiSelect
                            value={ visibleColumns }
                            options={ coloms }
                            optionLabel="header"
                            onChange={ onColumnToggle }
                            display="chip"
                            className="w-full sm:w-20rem"
                        /><span className="px-1"></span>
                        <button type="button" className="btn btn-csv text-white btn-sm" onClick={ () => exportCSV( false ) } title="CSV">
                            <i className="pi pi-file-excel"></i>
                        </button><span className="px-1"></span>
                        <button type="button" className="btn btn-success btn-sm" onClick={ exportExcel } title="XLS">
                            <i className="pi pi-file-excel"></i>
                        </button><span className="px-1"></span>
                        <button type="button" className="btn btn-danger btn-sm" onClick={ exportPdf } title="PDF">
                            <i className="pi pi-file-pdf"></i>
                        </button>
                    </div>
                </div>
                <div className="col-6">
                    <div className="d-flex justify-content-end ">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={ globalFilterValue } style={ { height: '35px' } } type="search" onChange={ onGlobalFilterChange } placeholder="Keyword Search" />
                        </span>
                    </div>
                </div>
            </div>



        );
    };

    const header = renderHeader();
    const exportFilename = `Opening-Stock(${ new Date().toLocaleDateString() })`;
    return (
        <div className="bg-white p-2 mt-2 " ><DynamicTitleComponent newTitle={ ` Opening Stock ` } />
            {/* <Toast ref={ toast } /> */}
            <DataTable ref={ dt } value={ Data } loading={ loading } scrollable resizableColumns showGridlines exportFilename={ exportFilename } scrollHeight="70vh" style={ { minWidth: '50rem' } } dataKey="id" filters={ filters } header={ header } emptyMessage="No Opening Stock found." globalFilterFields={ [ 'name', 'unit', 'qty', 'amount', 'username' ] } filterDisplay="row">
                <Column
                    header="Action"
                    excludeFromExport
                    body={ ( rowData ) => actionTemplate( rowData, 'id' ) }
                    style={ { minWidth: '60px', width: '60px' } }
                ></Column>
                <Column
                    key='sno'
                    field='sno'
                    header="Sno"
                    style={ { minWidth: '60px', width: '60px', textAlign: "right" } }
                ></Column>
                <Column
                    key='transactionMasterId'
                    field='goodsname'
                    header="Goods"
                    filter
                    sortable
                    filterPlaceholder={ `Goods Name` }
                    style={ { minWidth: '60px' } }
                ></Column>
                { visibleColumns.map( ( col, index ) => (
                    <Column key={ index } field={ col.field } header={ col.header } body={ col.body }
                        filter
                        sortable
                        filterPlaceholder={ ` ${ col.header }` }
                        filterMatchMode="contains"
                        exportable={ col.exportable !== false }
                    />
                ) ) }
            </DataTable>
        </div>
    );
}
export default ManufacturerTable;