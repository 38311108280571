import React, { useState, useEffect, useRef } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Column } from 'primereact/column';
import { MultiSelect } from "primereact/multiselect";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent';
import BaseUrl from '../../../BaseUrl';
const VoucherNarationTable = () =>
{
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage,navigateelast } = useLinkManagement();
    const [ loading, setLoading ] = useState( true );
    const navigate = useNavigate();
    const toast = useRef( null );
    const dt = useRef( null );
    const [ globalFilterValue, setGlobalFilterValue ] = useState( '' );
    const [ InvoiceOtherDetailData, setInvoiceOtherDetailData ] = useState( null );
    const coloms = [
        { field: "narration", header: "Voucher Narration", },
    ];
    const [ visibleColumns, setVisibleColumns ] = useState( coloms );
    const exportColumns = visibleColumns.map( ( col ) => ( { title: col.header, dataKey: col.field } ) );
    exportColumns.unshift( { title: 'Voucher Type', dataKey: 'voucherType' } );
    const [ filters, setFilters ] = useState( {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        voucherType: { value: null, matchMode: FilterMatchMode.CONTAINS },
        narration: { value: null, matchMode: FilterMatchMode.CONTAINS },
    } );
    useEffect( () =>
    {
        fetchData();
    }, [] );
    const fetchData = async () =>
    {
        try
        {
            const response = await axios.get( '' + BaseUrl + 'vouchernarration' );
            setInvoiceOtherDetailData( response.data );
            setLoading( false );
        } catch ( error )
        {
            console.error( 'Error:', error );
        }
    };

    const onGlobalFilterChange = ( e ) =>
    {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters[ 'global' ].value = value;
        setFilters( _filters );
        setGlobalFilterValue( value );
    };
    const onColumnToggle = ( event ) =>
    {
        let selectedColumns = event.value;
        let orderedSelectedColumns = coloms.filter( ( col ) =>
            selectedColumns.some( ( sCol ) => sCol.field === col.field )
        );
        setVisibleColumns( orderedSelectedColumns );
    };
    const exportCSV = ( selectionOnly ) =>
    {
        dt.current.exportCSV( { selectionOnly } );
    };

    const exportPdf = () =>
    {
        const exportFilename = `${ new Date().toLocaleDateString() }`;
        import( 'jspdf' ).then( ( jsPDF ) =>
        {
            import( 'jspdf-autotable' ).then( () =>
            {
                const doc = new jsPDF.default( 0, 0 );
                doc.autoTable( exportColumns, InvoiceOtherDetailData );
                doc.save( `Voucher-Narration-${ exportFilename }.pdf` );
            } );
        } );
    };

    const exportExcel = () =>
    {
        import( 'xlsx' ).then( ( xlsx ) =>
        {
            const worksheet = xlsx.utils.json_to_sheet( InvoiceOtherDetailData );
            const workbook = { Sheets: { data: worksheet }, SheetNames: [ 'data' ] };
            const excelBuffer = xlsx.write( workbook, {
                bookType: 'xlsx',
                type: 'array'
            } );
            saveAsExcelFile( excelBuffer, 'Voucher-Narration' );
        } );
    };

    const saveAsExcelFile = ( buffer, fileName ) =>
    {
        import( 'file-saver' ).then( ( module ) =>
        {
            if ( module && module.default )
            {
                const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const EXCEL_EXTENSION = '.xlsx';
                const data = new Blob( [ buffer ], { type: EXCEL_TYPE } );
                module.default.saveAs( data, `${ fileName }_export_${ new Date().getTime() }${ EXCEL_EXTENSION }` );
            }
        } );
    };

    const editRow = ( id ) =>
    {
        const idd = id.id;
        addLink( 'edit_vouchernarration,' + idd + '' );
        navigateelast();
        // navigate( `/vouchernarration/edit/${ idd }` );
    };
    const deletaccept = async ( id ) =>
    {
        const idd = id.id;
        console.log( 'Delete row:', idd );
        try
        {
            const response = await fetch( `${ BaseUrl }vouchernarration/${ idd }`, {
                method: 'DELETE',
            } );

            if ( response.ok )
            {
                const data = await response.json();
                toast.current.show( { severity: 'success', summary: 'Delete', detail: data.message, life: 2000 } );
                setInvoiceOtherDetailData( prevData => prevData.filter( row => row.id !== idd ) );
            } else
            {
                const errorData = await response.json();
                toast.current.show( { severity: 'warn', summary: 'Rejected', detail: errorData.message, life: 3000 } );
                console.error( 'Error:', response.status );
            }
        } catch ( error )
        {
            console.error( 'Error:', error );
        }
    };

    const deletereject = () =>
    {
        toast.current.show( { severity: 'error', summary: 'Cancel', detail: 'Cancel Delete Request', life: 3000 } );
    };

    const deletconfirm = ( rowData ) => ( event ) =>
    {
        confirmPopup( {
            target: event.currentTarget,
            message: 'Are you sure you want to Delete Voucher Narration ?',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            accepticon: 'pi pi-exclamation-triangle',
            accept: () => deletaccept( rowData ),
            reject: deletereject
        } );
    };

    const actionTemplate = ( rowData ) => (
        <div>
            <Button onClick={ () => editRow( rowData ) } icon="pi pi-pencil p-0 " className="p-0 w-5 me-1" text severity="warning" />
            <Button icon="pi pi-trash p-0 " onClick={ deletconfirm( rowData ) } className="p-0 w-5 ms-1" text severity="danger" />
            <ConfirmPopup />
        </div>
    );
    const addlink = () =>
    {
        navigate( '/vouchernarration/add' );
    }
    const renderHeader = () =>
    {
        return (
            <div className="row ">

                <div className="col-6">
                    <div className="d-flex  align-items-center">
                        <button onClick={ () => { addLink( "add_vouchernarration_m" ); navigateelast(); } } className='px-2 btn btn-sm btn-primary me-2 text-white' autoFocus >Add </button>
                        {/* <button onClick={ addlink } className='px-2 btn btn-sm btn-primary me-2 text-white' autoFocus> Add </button> */ }
                        <MultiSelect
                            value={ visibleColumns }
                            options={ coloms }
                            optionLabel="header"
                            onChange={ onColumnToggle }
                            display="chip"
                            className="w-full sm:w-20rem"
                        /><span className="px-1"></span>
                        <button type="button" className="btn btn-csv text-white btn-sm" onClick={ () => exportCSV( false ) } title="CSV">
                            <i className="pi pi-file-excel"></i>
                        </button><span className="px-1"></span>
                        <button type="button" className="btn btn-success btn-sm" onClick={ exportExcel } title="XLS">
                            <i className="pi pi-file-excel"></i>
                        </button><span className="px-1"></span>
                        <button type="button" className="btn btn-danger btn-sm" onClick={ exportPdf } title="PDF">
                            <i className="pi pi-file-pdf"></i>
                        </button>
                    </div>
                </div>
                <div className="col-6">
                    <div className="d-flex justify-content-end ">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={ globalFilterValue } style={ { height: '35px' } } type="search" onChange={ onGlobalFilterChange } placeholder="Keyword Search" />
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const header = renderHeader();
    const exportFilename = `Voucher-Narration-(${ new Date().toLocaleDateString() })`;
    return (
        <div className="bg-white p-2 mt-2 " ><DynamicTitleComponent newTitle={ `Voucher Narration` } />
            <Toast ref={ toast } />
            <DataTable ref={ dt } value={ InvoiceOtherDetailData } const
                loading={ loading } scrollable resizableColumns showGridlines exportFilename={ exportFilename } scrollHeight="70vh" style={ { minWidth: '50rem' } } dataKey="id" filters={ filters } header={ header } emptyMessage="No Voucher Narration found." globalFilterFields={ [ 'voucherType', 'narration' ] } filterDisplay="row">
                <Column
                    header="Action"
                    excludeFromExport
                    body={ ( rowData ) => actionTemplate( rowData, 'id' ) }
                    style={ { minWidth: '60px', width: '60px' } }
                ></Column>
                <Column
                    key='voucherType'
                    field='voucherType'
                    header="Voucher Type"
                    filter
                    sortable
                    filterPlaceholder={ `Name` }
                    style={ { minWidth: '60px' } }
                ></Column>
                { visibleColumns.map( ( col, index ) => (
                    <Column key={ index } field={ col.field } header={ col.header } body={ col.body }
                        filter
                        sortable
                        filterPlaceholder={ ` ${ col.header }` }
                        filterMatchMode="contains"
                        exportable={ col.exportable !== false }
                    />
                ) ) }
            </DataTable>
        </div>
    );
}
export default VoucherNarationTable;