import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

import { addtwoDecimal, addthreeDecimal, removeLeadingZero, AfterDecimalTowValue, twoDecimal, emtySelect, maxlength, validInput, Star } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import { toast } from 'react-toastify';
import CommonFunctions from '../../../../Common/CommonFunction';
const AddCess = ( e ) =>
{

    const toastt = useRef( null );
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, navigatefirstlink } = useLinkManagement();
    const { AddChangeData, EditChangeData, setInWhitchData, splitLastInWitchD, splitLastInWitchF, RemoveHSNSaCType } = CommonFunctions();
    const [ cessName, setCessName ] = useState( '' );
    const [ cessApplyOn, setCessApplyOn ] = useState( 'Amount' ); const [ disabledType, setdisabledType ] = useState( false );
    const [ cessValue, setCessValue ] = useState( '' );
    const [ cess_dec_val, set_dec_val ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const cessNameRef = useRef();
    const cessApplyOnRef = useRef();
    const cessValueRef = useRef();

    const CessData = () => ( {
        name: cessName.trim(),
        applyon: cessApplyOn.trim(),
        value: cess_dec_val.trim(),
    } );

    useEffect( () =>
    {
        const Type = JSON.parse( sessionStorage.getItem( 'InWhitchHsnSac' ) );
        if ( Type === 'Amount' )
        {
            setCessApplyOn( 'Amount' );
            setdisabledType( true );
        } else if ( Type === 'Qty' )
        {
            setCessApplyOn( 'Qty' );
            setdisabledType( true );
        } else
        {
            setdisabledType( false );
        }
    } )
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };
    const removeallinvalid = () =>
    {
        cessNameRef.current.classList.remove( 'is-invalid' );
        cessApplyOnRef.current.classList.remove( 'is-invalid' );
        cessValueRef.current.classList.remove( 'is-invalid' );
    };

    function SubmitForm( e )
    {
        e.preventDefault();
        setError( '' );
        const trimmedName = maxlength( cessName.trim(), 'cess name', 50 );
        const trimmedApplyOn = emtySelect( cessApplyOn.trim(), 'apply on' );
        const trimmedValue = maxlength( cessValue.trim(), 'Value should be greater than 0 ' );

        if ( trimmedName !== true ) { setError( trimmedName ); foCoUs( cessNameRef ); return false; }
        if ( trimmedApplyOn !== true ) { setError( trimmedApplyOn ); foCoUs( cessApplyOnRef ); return false; }
        if ( trimmedValue !== true ) { setError( trimmedValue ); foCoUs( cessValueRef ); return false; }
        if ( cessValue.trim() === '0' || cessValue.trim() === '00' || cessValue.trim() === '.0' ) { setError( 'Value should be greater than 0' ); foCoUs( cessValueRef ); return false; }
        else
        {
            axios
                .post( `${ BaseUrl }cess`, CessData(), { mode: 'cors' } )
                .then( function ( response )
                {
                    const data = response.data;
                    if ( data.success === true )
                    {
                        toast.success( 'Add Cess Success' );
                        const LastLink = getLastLinkInSession();
                        if ( LastLink === 'add_cess_m' )
                        {
                            setCessName( '' );
                            setCessValue( '' );
                            foCoUs( cessNameRef );
                            removeallinvalid();
                        }
                        else
                        {
                            const Type = JSON.parse( sessionStorage.getItem( 'InWhitchHsnSac' ) );
                            if ( data.type === 'Amount' && Type === 'Qty' )
                            {
                                toast.error( "Amount Entry Not Allowed!" );
                                return false;
                            }
                            if ( data.type === 'Qty' && Type === 'Amount' )
                            {
                                toast.error( "Qty Entry Not Allowed!" );
                                return false;
                            }
                            if ( data.type === Type )
                            {
                                handleSplitLastLink();
                                AddChangeData( cessName, data.Newid );
                                RemoveHSNSaCType();
                                navigateelast();
                            }

                        }

                    }
                } )
                .catch( function ( error )
                {
                    var errrr = error.response;
                    const errors = GlobalError( errrr );
                    setError( errors ); foCoUs( cessNameRef );
                } );
        }
    }

    const changeValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'cess_name' ) { var valuee = validInput( value ); setCessName( valuee ); removeinvalid( cessNameRef ); }
        if ( name === 'cess_apply_on' ) { var valuee = validInput( value ); setCessApplyOn( valuee ); removeinvalid( cessApplyOnRef ); }
        if ( name === 'cess_value' )
        {
            const processedValue = removeLeadingZero( value );
            const validationError = twoDecimal( processedValue );
            if ( validationError !== null )
            {
                setCessValue( validationError );
                const twoValue = addtwoDecimal( validationError );
                set_dec_val( twoValue );
            }
            removeinvalid( cessValueRef );
        }
    };

    const accept = () =>
    {
        const LastLink = getLastLinkInSession();
        if ( LastLink === 'add_stockgroup_m' ) { splitLastInWitchD( 'f' ); splitLastInWitchF( 'f' ); }
        splitLastInWitchD();
        splitLastInWitchF();
        RemoveHSNSaCType();
        handleSplitLastLink();
        navigateelast();
        // if ( currentUrl === '/cess/add' )
        // {
        //     navigate( '/cess' );
        // } else if ( currentUrl === '/group/add/cess' )
        // {
        //     navigate( '/group/add' );
        // }
    };

    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };

    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     {
    //         if ( event.key === 'Escape' )
    //         {
    //             confirm( 'top' );
    //         }
    //     };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () =>
    //     {
    //         document.removeEventListener( 'keydown', handleKeyDown );
    //     };
    // }, [] );

    return (
        <>
            <div class=" p-2 mt-2 main"><DynamicTitleComponent newTitle={ `Add Cess` } />
                <div className="row">
                    <div className="col-md-6">
                        <div className='card '>
                            <div className="pt-2 card-header"> <h4>Add Cess</h4></div>
                            <form className="form-horizontal">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <label htmlFor="cess_name" className="modal-label"> Cess Name </label><Star />
                                                    <span className='float-end' style={ { color: 'red' } }> { error }</span>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        id="cess_name"
                                                        name="cess_name"
                                                        placeholder="Cess Name"
                                                        ref={ cessNameRef }
                                                        value={ cessName }
                                                        autoFocus
                                                        onChange={ changeValue }
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <label htmlFor="cess_apply_on" > Apply on</label><Star />
                                                    <select
                                                        className="form-control form-control-sm"
                                                        style={ { width: '100%' } }
                                                        aria-invalid="false"
                                                        name="cess_apply_on"
                                                        id="cess_apply_on"
                                                        value={ cessApplyOn }
                                                        onChange={ changeValue }
                                                        ref={ cessApplyOnRef }
                                                        disabled={ disabledType }
                                                    >
                                                        <option value="Amount">
                                                            Advol (Amount)
                                                        </option>
                                                        <option value="Qty">
                                                            Non Advol (Qty.)
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <label htmlFor="cess_value" className="">
                                                        Value{ ' ' }
                                                    </label><Star />
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        id="cess_value"
                                                        name="cess_value"
                                                        placeholder="Value"
                                                        ref={ cessValueRef }
                                                        value={ cessValue }
                                                        onChange={ changeValue }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitForm }>Save</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>

                        </div>


                    </div>
                </div>
                <Toast ref={ toastt } />
                <ConfirmDialog />
            </div>
        </>
    );
}

export default AddCess;

