
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
const useLinkManagement = () =>
{
  const navigate = useNavigate();
  const [ links, setLinks ] = useState( [] );
  const [ splitLast, setSplitLast ] = useState( false );
  const initialLinks = JSON.parse(sessionStorage.getItem('focusme')) || [];
  const [linksf, setLinksf] = useState(initialLinks);
  

  const [ userId, setUserId ] = useState( '' );

  useEffect( () =>{ getLinksInSession(); }, [] );
  useEffect(() => { sessionStorage.setItem('focusme', JSON.stringify(linksf)); }, [linksf]);
  const getLinksInSession = () =>
  {
    const storedLinks = sessionStorage.getItem( "links" );
    if ( storedLinks )
    {
      setLinks( JSON.parse( storedLinks ) );
    } else
    {
      setLinks( [] );
    }
  };
  // Add New Link in sessionstorage
  const addLink = ( link, sidebar = null ) =>
  {
    if ( sidebar !== '' && sidebar !== null && sidebar === 'parentlink' )
    {
      sessionStorage.removeItem( 'links' );
      sessionStorage.setItem( 'links', JSON.stringify( [ link ] ) );
      setLinks( [ link ] );
    } else
    {
      const newLinkToAdd = link.trim();
      const linkParts = link.split( ',' );
      if ( linkParts.length === 2 )
      {
        const link = linkParts[ 0 ].trim();
        const id = linkParts[ 1 ].trim();
        const updatedLinks = [ ...links, link, id ];
        setLinks( updatedLinks );
        sessionStorage.setItem( 'links', JSON.stringify( updatedLinks ) );
      } else
      {
        const updatedLinks = [ ...links, newLinkToAdd ];
        setLinks( updatedLinks );
        sessionStorage.setItem( 'links', JSON.stringify( updatedLinks ) );
      }
    }
  };
  // Split or Check Add link or Edit link
  const handleSplitLastLink = () =>
  {
    if ( links.length === 0 )
    {
      return; // No links to split/remove
    }
    let newLinks;
    const lastLink = links[ links.length - 1 ];
    if ( !isNaN( lastLink ) )
    {
      if ( links.length >= 2 )
      {
        newLinks = links.slice( 0, links.length - 2 );
      }
    } else
    {
      if ( links.length >= 1 )
      {
        newLinks = links.slice( 0, links.length - 1 );
      }
    }
    setLinks( newLinks );
    sessionStorage.setItem( "links", JSON.stringify( newLinks ) );
    setSplitLast( !splitLast );
  };

  //Get Last Link 
  const getLastLinkInSession = () =>
  {
    const storedLinks = sessionStorage.getItem( "links" );
    if ( storedLinks )
    {
      const parsedLinks = JSON.parse( storedLinks );
      const lastLink = parsedLinks[ parsedLinks.length - 1 ];
      if ( !isNaN( lastLink ) )
      {
        if ( parsedLinks.length >= 2 )
        {
          const secondLastLink = parsedLinks[ parsedLinks.length - 2 ];
          return `${ secondLastLink }, ${ lastLink }`;
        }
      } else
      {
        return lastLink;
      }
    }
    return "";
  };
  //Get Last Link 
  const get2ndLastLinkInSession = () =>
  {
    const storedLinks = sessionStorage.getItem( "links" );
    if ( storedLinks )
    {
      const parsedLinks = JSON.parse( storedLinks );
      const lastLink = parsedLinks[ parsedLinks.length - 2 ];
      if ( !isNaN( lastLink ) )
      {
        if ( parsedLinks.length >= 2 )
        {
          const secondLastLink = parsedLinks[ parsedLinks.length - 2 ];
          return `${ secondLastLink }, ${ lastLink }`;
        }
      } else
      {
        return lastLink;
      }
    }
    return "";
  };
  // conferm by use leave this page and clear sesionstorage
  const clearSessionStorage = () =>
  {
    const shouldClear = window.confirm( 'Are you sure you want to leave the page?' );
    if ( shouldClear )
    {
      sessionStorage.removeItem( 'links' );
      sessionStorage.clear();
      setLinks( [] );
      return true;
    }
  };
  const firstLink = () =>
  {
    const storedLinks = sessionStorage.getItem( "links" );
    if ( storedLinks )
    {
      const parsedLinks = JSON.parse( storedLinks );
      const firstLink = parsedLinks[ 0 ];
      return firstLink;
    }
  }
  const navigatefirstlink = () =>
  {
    const lastLink = firstLink();
    alert( lastLink )
    console.log( lastLink );
    navigate( lastLink );
  }

  
  const setFocus = (foc) => {
    if (foc) {
      const updatedLinks = [...linksf, foc];
      setLinksf(updatedLinks);
      sessionStorage.setItem('focusme', JSON.stringify(updatedLinks));
    }
  };


 

  // Function to split or remove the last link from the array
  const splitLastFocus = () => {
    if (linksf.length > 0) {
      const updatedLinks = [...linksf];
      updatedLinks.pop(); // Remove the last link
      setLinksf(updatedLinks);
      sessionStorage.setItem('focusme', JSON.stringify(updatedLinks));
    }
  };
  useEffect( () =>
  {
    // Check if the user is logged in by looking at localStorage
    const storedLoggedIn = localStorage.getItem( 'loggedIn' );
    const storedUserId = localStorage.getItem( 'userId' );
    if ( storedLoggedIn === 'true' && storedUserId )
    {
      setUserId( storedUserId );
    }
  }, [] );


  const navigateelast = ( id ) =>
  {
    // Get the last link from the session
    const lastLink = getLastLinkInSession();
    if ( lastLink === null || lastLink === '' || lastLink === undefined )
    {
      navigate( `/` );
    } else
    {
      // Split the last link into parts
      const linkParts = lastLink.split( ',' );
      if ( linkParts.length === 2 )
      {
        // If there are two parts, extract the link and editId
        const link = linkParts[ 0 ].trim();
        const editId = linkParts[ 1 ].trim();
        // Encode the encrypted ID to make it URL-safe
        if ( link === null || link === '' ) { navigate( `/` );}
        // Check the link and navigate accordingly
        if ( link === 'cityedit' ) { navigate( `/city/edit/${ editId }` ); }
        else if ( link === 'maincityedit' ) { navigate( `/city/edit/${ editId }` ); }
        else if ( link === 'sellerslip_edit' ) { navigate( `/seller-slip-expenses-profile/edit/${ editId }` ); }
        else if ( link === 'edit_expenses' ) { navigate( `/expenses/edit/${ editId }` ); }
        else if ( link === 'edit_account' ) { navigate( `/accounts/edit/${ editId }` ); }
        else if ( link === 'edit_goods' ) { navigate( `/goods/edit/${ editId }` ); }
        else if ( link === 'edit_goods_m' ) { navigate( `/goods/edit/${ editId }` ); }
        else if ( link === 'edit_group' ) { navigate( `/group/edit/${ editId }` ); }
        // else if ( link === 'edit_sectioncode' ) { navigate( `/tds-section-type/edit/${ editId }` ); }
        else if ( link === 'edit_zone' ) { navigate( `/zone/edit/${ editId }` ); }
        else if ( link === 'edit_gst' ) { navigate( `/gst/edit/${ editId }` ); }
        else if ( link === 'edit_cess' ) { navigate( `/cess/edit/${ editId }` ); }
        else if ( link === 'edit_hsnsac' ) { navigate( `/hsn-sac/edit/${ editId }` ); }
        else if ( link === 'edit_packing' ) { navigate( `/packing/edit/${ editId }` ); }
        else if ( link === 'edit_manufacturer' ) { navigate( `/manufacturer/edit/${ editId }` ); }
        else if ( link === 'edit_manufacturer_m' ) { navigate( `/manufacturer/edit/${ editId }` ); }
        else if ( link === 'edit_stockgroup' ) { navigate( `/stock-group/edit/${ editId }` ); }
        else if ( link === 'edit_stocksubgroup' ) { navigate( `/stock-sub-group/edit/${ editId }` ); }
        else if ( link === 'edit_godown' ) { navigate( `/godown/edit/${ editId }` ); }
        else if ( link === 'edit_broker' ) { navigate( `/broker/edit/${ editId }` ); }
        else if ( link === 'edit_transport' ) { navigate( `/transport/edit/${ editId }` ); }
        else if ( link === 'edit_batchlot' ) { navigate( `/batchlot/edit/${ editId }` ); }
        else if ( link === 'edit_batchlot_m' ) { navigate( `/batchlot/edit/${ editId }` ); }
        else if ( link === 'edit_invoceother' ) { navigate( `/invoice/edit/${ editId }` ); }
        else if ( link === 'edit_capitalgoodsservices' ) { navigate( `/capital-goods-services/edit/${ editId }` ); }
        else if ( link === 'edit_tdsST' ) { navigate( `/tds-section-type/edit/${ editId }` ); }
        else if ( link === 'edit_tdsparameter' ) { navigate( `/tds-parameter/edit/${ editId }` ); }
        else if ( link === 'edit_tdspartywise' ) { navigate( `/party-wise-tds-percentage/edit/${ editId }` ); }
        else if ( link === 'edit_documentseries' ) { navigate( `/document-series/edit/${ editId }` ); }
        else if ( link === 'edit_vouchernarration' ) { navigate( `/voucher-narration/edit/${ editId }` ); }
        else if ( link === 'edit_sellerslipexpensesprofile' ) { navigate( `/seller-slip-expenses-profile/edit/${ editId }` ); }
        else if ( link === 'edit_opening_stock_m' ) { navigate( `/opening-stock/edit/${ editId }` ); }
        else if ( link === 'edit_opening_stock' ) { navigate( `/opening-stock/edit/${ editId }` ); }
      }
      else
      {
        // If there's only one part in the last link
        if ( lastLink.trim() !== '' )
        {
          // Check the last link and navigate accordingly
          if ( lastLink === 'city' ) { navigate( '/city' ); }
          else if ( lastLink === 'maincityadd' ) { navigate( '/city/add' ); }
          // else if ( lastLink === 'maincityedit' ) { navigate( `/city/edit/${ id }` ); }
          else if ( lastLink === 'cityadd' ) { navigate( '/city/add' ); }
          //Account
          else if ( lastLink === 'addaccount_m' ) { navigate( '/accounts/add' ); }
          else if ( lastLink === 'addaccount' ) { navigate( '/accounts/add' ); }
          else if ( lastLink === 'accounts' ) { navigate( '/accounts' ); }
          //goods
          else if ( lastLink === 'add_goods_m' ) { navigate( '/goods/add' ); }
          else if ( lastLink === 'addgoods' ) { navigate( '/goods/add' ); }
          else if ( lastLink === 'goods' ) { navigate( '/goods' ); }
          //Zone
          else if ( lastLink === 'addzone_m' ) { navigate( '/zone/add' ); }
          else if ( lastLink === 'addzone' ) { navigate( '/zone/add' ); }
          else if ( lastLink === 'zone' ) { navigate( '/zone' ); }
          //Group
          else if ( lastLink === 'addgroup_m' ) { navigate( '/group/add' ); }
          else if ( lastLink === 'addgroup' ) { navigate( '/group/add' ); }
          else if ( lastLink === 'group' ) { navigate( '/group' ); }
          //seller slip
          else if ( lastLink === 'seller-slip-expenses-profile' ) { navigate( '/seller-slip-expenses-profile' ); }
          else if ( lastLink === 'add_sellerslipexpensesprofile_m' ) { navigate( '/seller-slip-expenses-profile/add' ); }
          else if ( lastLink === 'add_sellerslipexpensesprofile' ) { navigate( '/seller-slip-expenses-profile/add' ); }
          //Expenses
          else if ( lastLink === 'expenses' ) { navigate( '/expenses' ); }
          else if ( lastLink === 'add_expenses_m' ) { navigate( '/expenses/add' ); }
          else if ( lastLink === 'add_expenses' ) { navigate( '/expenses/add' ); }
          //GST
          else if ( lastLink === 'gst' ) { navigate( '/gst' ); }
          else if ( lastLink === 'add_gst_m' ) { navigate( '/gst/add' ); }
          else if ( lastLink === 'add_gst' ) { navigate( '/gst/add' ); }
          //Cess
          else if ( lastLink === 'cess' ) { navigate( '/cess' ); }
          else if ( lastLink === 'add_cess_m' ) { navigate( '/cess/add' ); }
          else if ( lastLink === 'add_cess' ) { navigate( '/cess/add' ); }
          //HSN SAC
          // else if ( lastLink === 'hsnsac' ) { navigate( '/hsn-sac' ); }
          else if ( lastLink === 'hsn-sac' ) { navigate( '/hsn-sac' ); }
          else if ( lastLink === 'add_hsnsac_m' ) { navigate( '/hsn-sac/add' ); }
          else if ( lastLink === 'add_hsnsac' ) { navigate( '/hsn-sac/add' ); }
          //Packing 
          else if ( lastLink === 'packing' ) { navigate( '/packing' ); }
          else if ( lastLink === 'add_packing_m' ) { navigate( '/packing/add' ); }
          else if ( lastLink === 'add_packing' ) { navigate( '/packing/add' ); }
          //manufacturer 
          else if ( lastLink === 'manufacturer' ) { navigate( '/manufacturer' ); }
          else if ( lastLink === 'add_manufacturer_m' ) { navigate( '/manufacturer/add' ); }
          else if ( lastLink === 'add_manufacturer' ) { navigate( '/manufacturer/add' ); }
          //Stock Group 
          // else if ( lastLink === 'stockgroup' ) { navigate( '/stock-group' ); }
          else if ( lastLink === 'stock-group' ) { navigate( '/stock-group' ); }
          else if ( lastLink === 'add_stockgroup_m' ) { navigate( '/stock-group/add' ); }
          else if ( lastLink === 'add_stockgroup' ) { navigate( '/stock-group/add' ); }
          //Stock Sub Group 
          // else if ( lastLink === 'stocksubgroup' ) { navigate( '/stock-sub-group' ); }
          else if ( lastLink === 'stock-sub-group' ) { navigate( '/stock-sub-group' ); }
          else if ( lastLink === 'add_stocksubgroup_m' ) { navigate( '/stock-sub-group/add' ); }
          else if ( lastLink === 'add_stocksubgroup' ) { navigate( '/stock-sub-group/add' ); }
          //Godown 
          else if ( lastLink === 'godown' ) { navigate( '/godown' ); }
          else if ( lastLink === 'add_godown_m' ) { navigate( '/godown/add' ); }
          else if ( lastLink === 'add_godown' ) { navigate( '/godown/add' ); }
          //Broker 
          else if ( lastLink === 'broker' ) { navigate( '/broker' ); }
          else if ( lastLink === 'add_broker_m' ) { navigate( '/broker/add' ); }
          else if ( lastLink === 'add_broker' ) { navigate( '/broker/add' ); }
          //Transport 
          else if ( lastLink === 'transport' ) { navigate( '/transport' ); }
          else if ( lastLink === 'add_transport_m' ) { navigate( '/transport/add' ); }
          else if ( lastLink === 'add_transport' ) { navigate( '/transport/add' ); }
          //Goods Batch Lot
          else if ( lastLink === 'batchlot' ) { navigate( '/batchlot' ); }
          else if ( lastLink === 'add_batchlot_m' ) { navigate( '/batchlot/add' ); }
          else if ( lastLink === 'add_batchlot' ) { navigate( '/batchlot/add' ); }
          //Invoice Other Detail
          else if ( lastLink === 'invoice' ) { navigate( '/invoice' ); }
          else if ( lastLink === 'add_invoceother_m' ) { navigate( '/invoice/add' ); }
          else if ( lastLink === 'add_invoceother' ) { navigate( '/invoice/add' ); }
          //Capital Goods/Services
          else if ( lastLink === 'capital-goods-services' ) { navigate( '/capital-goods-services' ); }
          else if ( lastLink === 'add_capitalgoodsservices_m' ) { navigate( '/capital-goods-services/add' ); }
          else if ( lastLink === 'add_capitalgoodsservices' ) { navigate( '/capital-goods-services/add' ); }
          //TDS Section Type
          else if ( lastLink === 'tds-section-type' ) { navigate( '/tds-section-type' ); }
          else if ( lastLink === 'add_tdsST_m' ) { navigate( '/tds-section-type/add' ); }
          else if ( lastLink === 'add_tdsST' ) { navigate( '/tds-section-type/add' ); }
          //TDS Section Type
          else if ( lastLink === 'tds-parameter' ) { navigate( '/tds-parameter' ); }
          else if ( lastLink === 'add_tdsparameter_m' ) { navigate( '/tds-parameter/add' ); }
          else if ( lastLink === 'add_tdsparameter' ) { navigate( '/tds-parameter/add' ); }
          //TDS Party Wise Persentage 
          else if ( lastLink === 'party-wise-tds-percentage' ) { navigate( '/party-wise-tds-percentage' ); }
          else if ( lastLink === 'add_tdspartywise_m' ) { navigate( '/party-wise-tds-percentage/add' ); }
          else if ( lastLink === 'add_tdspartywise' ) { navigate( '/party-wise-tds-percentage/add' ); }
          //Document Series
          else if ( lastLink === 'document-series' ) { navigate( '/document-series' ); }
          else if ( lastLink === 'add_documentseries_m' ) { navigate( '/document-series/add' ); }
          else if ( lastLink === 'add_documentseries' ) { navigate( '/document-series/add' ); }
          //Document Series
          else if ( lastLink === 'voucher-narration' ) { navigate( '/voucher-narration' ); }
          else if ( lastLink === 'add_vouchernarration_m' ) { navigate( '/voucher-narration/add' ); }
          else if ( lastLink === 'add_vouchernarration' ) { navigate( '/voucher-narration/add' ); }
          //Document Series
          else if ( lastLink === 'opening-stock' ) { navigate( '/opening-stock' ); }
          else if ( lastLink === 'add_opening_stock_m' ) { navigate( '/opening-stock/add' ); }
          else if ( lastLink === 'add_opening_stock' ) { navigate( '/opening-stock/add' ); }
          
        }
      }
    }

  };
  return {
    links,
    splitLast,
    userId,
    addLink,
    handleSplitLastLink,
    getLastLinkInSession,
    clearSessionStorage,
    navigateelast, firstLink, navigatefirstlink, setFocus, splitLastFocus,get2ndLastLinkInSession
  };
};
export default useLinkManagement;
