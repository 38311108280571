import React from 'react';
import { Link} from 'react-router-dom';
import Img from '../404/8030430_3828535.svg'; 
const NotFound = () => {
  const containerStyle = {
    // backgroundColor: '#0a4a85',
    height: '80vh',
    fontFamily: 'Arial, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    textAlign: 'center',
  };

  const headingStyle = {
    fontSize: '4rem',
    marginBottom: '20px',
  };

  const messageStyle = {
    fontSize: '1.5rem',
    marginBottom: '20px',
  };

  const imageStyle = {
    maxWidth: '10%',
    height: 'auto',
  };

  const iconStyle = {
    fontSize: '2rem',
    marginBottom: '10px',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>404</h1>
      <p style={messageStyle}>Oops! The page you're looking for doesn't exist.</p>
      {/* <img src={Img} alt="404" style={imageStyle} /> */}
    
        {/* <Img/> */}
      
      <p>Let's get you back to the <a href="/" style={{ color: 'blue', textDecoration: 'underline' }}>home page</a>.</p>
      <div style={iconStyle}>
        {/* Use an icon library like Font Awesome or another library of your choice */}
        {/* Example with Font Awesome: */}
        <i className="fas fa-exclamation-circle"></i>
      </div>
    </div>
  );
};

export default NotFound;
