import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { toast } from 'react-toastify';
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import Select, { components } from 'react-select';
import axios from 'axios';
import { OnlyNumber, SelectCheck, removeLeadingZero, threeDecimal, Pincoode, emtySelect, maxlength, validInput, Star, Editicon } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import customStyles from '../../../../validations/customStyles';
import CommonFunctions, { fetchOptionsFrom, fetchGoodsOpt } from '../../../../Common/CommonFunction';
import { Loaderr } from '../../../../CommonComponents/Loader';
import CheckAuth from '../../../../APIHelper/CheckAuth';
const Addbatchlot = ( e ) =>
{

    const { addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, setFocus, splitLastFocus, get2ndLastLinkInSession } = useLinkManagement();
    const { AddOpenignStockData, EditChangeData, setInWhitchData, setInWhitchFrome, RemoveSSFild, setHSNSaCType, splitLastInWitchD, splitLastInWitchF } = CommonFunctions();
    const { isLoggedInToken, handleLogout, setLoggedIn, isLoading, setIsLoading } = CheckAuth();
    const toastt = useRef( null );

    const [ batch_goods, set_batch_goods ] = useState( '' ); const b_g_Ref = useRef(); const [ b_g_opt, setb_g_opt ] = useState( [] );
    const [ goodsDisable, setGoodsDisable ] = useState( false );

    const [ batch_packing, set_batch_packing ] = useState( '' ); const b_p_Ref = useRef(); const [ b_p_opt, setb_p_opt ] = useState( [] ); const [ b_p_disable, setb_p_disable ] = useState( false );

    const [ batch_manuf, set_batch_manuf ] = useState( '' ); const b_m_Ref = useRef(); const [ b_m_opt, setb_m_opt ] = useState( [] );
    const [ batch_lot, set_batch_lot ] = useState( '' ); const b_l_Ref = useRef();
    const [ batch_MD_YN, set_batch_MD_YN ] = useState( '1' ); const [ md_disable, setmd_disable ] = useState( false );
    const [ mfgr_date, set_mfgr_date ] = useState( '' ); const b_m_d_Ref = useRef();
    const [ batch_EX_YN, set_batch_EX_YN ] = useState( '1' ); const [ Ex_disable, setEx_disable ] = useState( false );
    const [ Ex_date, set_Ex_date ] = useState( '' ); const b_e_d_Ref = useRef();
    const [ error, setError ] = useState( '' );

    const BatchData = () => ( {
        batch_goods: batch_goods.value,
        batch_packing: batch_packing,
        batch_manuf: batch_manuf,
        batch_lot: batch_lot.trim(),
        batch_MD_YN: batch_MD_YN,
        mfgr_date: mfgr_date,
        batch_EX_YN: batch_EX_YN,
        Ex_date: Ex_date
    } );
    const setBatchLotData = ( frome = null ) =>
    {
        RemoveSSFild( 'batch_lot_data' );
        setInWhitchData( 'batch_lot_data' );
        setInWhitchFrome( frome );        //In Whitch Value Update on Date
        sessionStorage.setItem( 'batch_lot_data', JSON.stringify( {
            batch_goods, batch_packing, batch_manuf, batch_lot, batch_MD_YN, mfgr_date, batch_EX_YN, Ex_date
        } ) );
    };
    useEffect( () =>
    {
        fetchOptionsFrom( 'manufacturer', 'id', 'name' ).then( mappedOptions => { setb_m_opt( mappedOptions ); } );
        fetchpacking();
        fetchAndSetGoodsOptions();
        setgodsid();

    }, [] );
    const fetchAndSetGoodsOptions = async ( checkid = null ) =>
    {
        try
        {
            const groupOpt = await fetchGoodsOpt();
            setb_g_opt( groupOpt );
            if ( checkid !== null || checkid !== undefined )
            {
                groupOpt.map( ( item ) =>
                {
                    if ( checkid == item.value )
                    {
                        set_batch_goods( item );
                        if ( item.packingmaintain == 0 )
                        {
                            set_batch_packing( '' );
                            setb_p_disable( true );
                        }
                        else if ( item.packingmaintain == 1 )
                        {
                            setb_p_disable( false );
                        }

                    }
                } );

            }
        } catch ( error )
        {
            console.error( 'Failed to fetch goods options:', error );
        }
    };
    const fetchpacking = () =>
    {
        fetchOptionsFrom( 'packing', 'id', 'name' ).then( mappedOptions => { setb_p_opt( mappedOptions ); } );
    }
    const setgodsid = () =>
    {
        const link = getLastLinkInSession();
        if ( link === 'add_batchlot' )
        {
            setGoodsDisable( true );
            setb_p_disable( true );
            const packing = sessionStorage.getItem( 'batchlotpackingid' );
            if(packing){
             const data =  JSON.parse( packing );
             setTimeout( () => { set_batch_packing( data );sessionStorage.removeItem( 'batchlotpackingid'); }, 5 );
            }
            const goods = JSON.parse( sessionStorage.getItem( 'batchlotgoodsid' ) );
            setTimeout( () => { set_batch_goods( goods );sessionStorage.removeItem( 'batchlotgoodsid'); }, 5 );
        }
    }
    useEffect( () =>
    {
        const savedData = sessionStorage.getItem( 'batch_lot_data' );
        if ( savedData )
        {
            const parsedData = JSON.parse( savedData );
            set_batch_goods( parsedData.batch_goods );
            set_batch_packing( parsedData.batch_packing );
            set_batch_manuf( parsedData.batch_manuf );
            set_batch_lot( parsedData.batch_lot );
            set_batch_MD_YN( parsedData.batch_MD_YN ); set_mfgr_date( parsedData.mfgr_date );
            set_batch_EX_YN( parsedData.batch_EX_YN ); set_Ex_date( parsedData.Ex_date );
            if ( parsedData.batch_goods !== null || parsedData.batch_goods !== '' || parsedData.batch_goods !== undefined )
            {
                fetchAndSetGoodsOptions( parsedData.batch_goods.value );
            }
            if ( parsedData.batch_MD_YN === '0' ) { setmd_disable( true ); }
            if ( parsedData.batch_EX_YN === '0' ) { setEx_disable( true ); }
        }
    }, [] );
    //Set Focus 
    useEffect( () =>
    {
        const setFocusFromStorage = JSON.parse( sessionStorage.getItem( 'focusme' ) );
        if ( setFocusFromStorage && setFocusFromStorage.length > 0 )
        {
            const focusElement = setFocusFromStorage[ setFocusFromStorage.length - 1 ];
            setTimeout( () =>
            {
                if ( focusElement === 'b_p_Ref' ) { b_p_Ref.current.focus(); splitLastFocus(); }
                if ( focusElement === 'b_m_Ref' ) { b_m_Ref.current.focus(); splitLastFocus(); }
            }, 1 ); // 10 milliseconds delay
        }
    }, [] );
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) => { foc.current.classList.remove( 'is-invalid' ); };
    const acceptt = () => handleSaveRequest();
    const reject = () => { b_p_Ref.current.focus(); return false; };
    const confirmm = ( position, messagee ) =>
    {
        confirmDialog( {
            message: messagee,
            header: 'Save Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept: acceptt,
            reject,
        } );
    };
    function SubmitForm( e )
    {
        e.preventDefault();
        setError( '' );

        const group = SelectCheck( batch_goods, 'goods name' );
        const packing = SelectCheck( batch_packing, 'packing name' );
        const lotno = maxlength( batch_lot.trim(), 'batch/lot no.', 50 );

        if ( group !== true )
        {
            setError( group );
            b_g_Ref.current.focus();
            return false;
        }

        if ( lotno !== true ) { setError( lotno ); foCoUs( b_l_Ref ); return false; }

        if ( batch_MD_YN === '1' && ( mfgr_date === '' || mfgr_date === '0000-00-00' ) )
        {
            setError( 'Please fill Mfg. date!' );
            foCoUs( b_m_d_Ref );
            return false;
        }

        if ( batch_EX_YN === '1' && ( Ex_date === '' || Ex_date === '0000-00-00' ) )
        {
            setError( 'Please fill Exp. date!' );
            foCoUs( b_e_d_Ref );
            return false;
        }

        if ( batch_MD_YN === '1' && batch_EX_YN === '1' )
        {
            // Additional validation for mfgr_date and Ex_date
            const mfgrDate = new Date( mfgr_date );
            const expDate = new Date( Ex_date );

            if ( mfgrDate >= expDate )
            {
                setError( 'Mfg. date should be before Exp. date!' );
                foCoUs( b_e_d_Ref );
                return false;
            }
        }

        if ( batch_goods.packingmaintain === '1' && packing !== true )
        {
            confirmm( 'center', 'Are you sure? Without entering Packing, data will be saved' );
            return false;
        }

        if ( batch_goods.packingmaintain === '0' && packing == true )
        {
            confirmm( 'top', 'Goods packing is not maintained, and you want to add packing. Are you sure?' );
            return false;
        }
        if ( batch_goods.packingmaintain === '0' && packing !== true )
        {
            handleSaveRequest();
            return true;
        }
        handleSaveRequest();

        // If all validations passed, continue with the save request

    }
    const handleSaveRequest = () =>
    {

        axios
            .post( `${ BaseUrl }batchlot`, BatchData(), { mode: 'cors' } )
            .then( function ( response )
            {
                const data = response.data;
                if ( data.success === true )
                {
                    toast.success( 'Add Goods Batch/Lot Success' );
                    sessionStorage.removeItem( 'batch_lot_data' );
                    const LastLink = getLastLinkInSession();
                    if ( LastLink === 'add_batchlot_m' )
                    {
                        setError( '' ); set_batch_goods( '' ); set_batch_packing( '' ); set_batch_manuf( '' ); set_batch_lot( '' ); set_mfgr_date( '' ); set_Ex_date( '' ); b_g_Ref.current.focus();
                    } else
                    {
                        handleSplitLastLink();
                        //Only on Goods Oening Stock
                        AddOpenignStockData( batch_lot, data.Newid ); // Change Last Open Popup Data or fild
                        navigateelast();
                    }

                }
            } )
            .catch( function ( error )
            {
                console.log( error );
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors ); b_g_Ref.current.focus();
            } );
    };

    //Add Nested Form 
    //Add Goods
    const AddGoodsBtn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <spna
                    onClick={ AddGoods }
                    className="btn btn-xsm  btn-primary w-100"
                > Add</spna>
            </components.MenuList>
        );
    }
    const AddGoods = () => 
    {
        set_batch_goods( '' );
        set_batch_packing( '' );
        setBatchLotData( 'batch_goods' ); //Batch Lot Input Field Data set in session storage
        addLink( "addgoods" );
        navigateelast();
    };
    //Edit Goods 
    const EditGoodsForm = ( value ) =>
    {
        setBatchLotData( 'batch_goods' ); //Batch Lot Input Field Data set in session storage
        addLink( "edit_goods," + value );
        navigateelast();

    };
    const EditGoods = ( props ) =>
    {
        return (
            <>
                <div
                    onClick={ () => EditGoodsForm( props.selectProps.value.value ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );
    };
    const EditGoodsbtn = ( { children, ...props } ) =>
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditGoods { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };
    // Add Packing
    const AddPackingBtn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <spna
                    onClick={ add_packing }
                    className="btn btn-xsm  btn-primary w-100"
                > Add</spna>
            </components.MenuList>
        );
    };
    const add_packing = () => 
    {
        setBatchLotData( 'batch_packing' ); //Batch Lot Input Field Data set in session storage
        setFocus( 'b_p_Ref' );
        addLink( "add_packing" );
        navigateelast();
    };
    //Edit Packing 
    const EditPackingForm = ( value ) =>
    {
        setBatchLotData( 'batch_packing' ); //Batch Lot Input Field Data set in session storage
        addLink( "edit_packing," + value );
        setFocus( 'b_p_Ref' );
        navigateelast();
    };
    const EditPacking = ( props ) =>
    {
        return (
            <>
                <div
                    onClick={ () => EditPackingForm( props.selectProps.value.value ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );
    };
    const EditPackingbtn = ( { children, ...props } ) =>
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditPacking { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };
    // Add Manufacturer
    const AddManufacturerBtn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <spna
                    onClick={ addManufacturer }
                    className="btn btn-xsm  btn-primary w-100"
                > Add</spna>
            </components.MenuList>
        );
    };
    const addManufacturer = () => 
    {
        setBatchLotData( 'batch_manuf' ); //Batch Lot Input Field Data set in session storage
        setFocus( 'b_m_Ref' );
        addLink( "add_manufacturer" );
        navigateelast();
    };
    //Edit Manufacturer 
    const EditManufacturerForm = ( value ) =>
    {
        setBatchLotData( 'batch_manuf' ); //Batch Lot Input Field Data set in session storage
        addLink( "edit_manufacturer," + value );
        setFocus( 'b_m_Ref' );
        navigateelast();
    };
    const EditManufacturer = ( props ) =>
    {
        return (
            <>
                <div
                    onClick={ () => EditManufacturerForm( props.selectProps.value.value ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );
    };
    const EditManufacturerbtn = ( { children, ...props } ) =>
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditManufacturer { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };


    const b_g_change = ( selectedOption ) =>
    {
        setError( '' ); // Clear any previous errors.

        if ( selectedOption !== null && selectedOption !== undefined )
        {
            set_batch_goods( selectedOption );
            const packingmaintain = selectedOption.packingmaintain;

            if ( packingmaintain === '0' )
            {
                set_batch_packing( '' );
                setb_p_disable( true );
            } else if ( packingmaintain === '1' )
            {
                setb_p_disable( false );
            }
        } else
        {// Handle the case where selectedOption is empty, null, or undefined.
            set_batch_packing( '' );
            //setb_p_opt( [] ); // Assuming this is used to clear options.
        }
        if ( selectedOption === null )
        {
            set_batch_goods( selectedOption );
        }
    };

    const b_p_change = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'Packing empty' ); }
        else { set_batch_packing( selectedOption ); }
    }
    const b_m_change = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'manufacturer empty' ); }
        else { set_batch_manuf( selectedOption ); }
    }
    const ChangeValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'batch_lot_no' ) { var valuee = validInput( value ); set_batch_lot( valuee ); removeinvalid( b_l_Ref ); }
        if ( name === 'mf_YN' ) { var valuee = validInput( value ); set_batch_MD_YN( valuee ); if ( valuee === '0' ) { setmd_disable( true ); set_mfgr_date( '' ); } else { setmd_disable( false ) } removeinvalid( b_m_d_Ref ); }
        if ( name === 'mfgr_date' ) { var valuee = validInput( value ); set_mfgr_date( valuee ); removeinvalid( b_m_d_Ref ); }
        if ( name === 'exp_YN' ) { var valuee = validInput( value ); set_batch_EX_YN( valuee ); if ( valuee === '0' ) { setEx_disable( true ); set_Ex_date( '' ); } else { setEx_disable( false ) } removeinvalid( b_e_d_Ref ); }
        if ( name === 'exp_date' ) { var valuee = validInput( value ); set_Ex_date( valuee ); removeinvalid( b_e_d_Ref ); }
    }
    const accept = () =>
    {
        sessionStorage.removeItem( 'batch_lot_data' );
        const LastLink = getLastLinkInSession();
        if ( LastLink === 'add_batchlot_m' ) { splitLastInWitchD( 'f' ); splitLastInWitchF( 'f' ); }
        splitLastInWitchD();
        splitLastInWitchF();
        handleSplitLastLink();
        navigateelast();
        // if ( currentUrl === '/batchlot/add' ) { navigate( '/batchlot' ); }
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     {
    //         if ( event.key === 'Escape' ) { confirm( 'top' ); }
    //     };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () =>
    //     {
    //         document.removeEventListener( 'keydown', handleKeyDown );
    //     };
    // }, [] );
    return (
        <>
            <div className="p-2 mt-2 main">
                <DynamicTitleComponent newTitle={ `Add Goods Batch-Lot ` } />
                <Toast ref={ toastt } />
                { isLoading ? ( <Loaderr /> ) : ( '' ) }
                <ConfirmDialog />
                <div className="row ">
                    <div className="col-md-6">
                        <div className='card'>
                            <div className="card-header">  <h4> Add Goods Batch/Lot No.</h4></div>
                            <form className="form-horizontal" onSubmit={ SubmitForm }>
                                <div className="card-body">
                                    <div className="row">
                                        {/* <span className='float-end' style={ { color: 'red' } }> { error }</span> */ }
                                        <div> <span className='float-end' style={ { color: 'red' } }> { error }</span></div>
                                        <div className="col-md-12">
                                            <div className=" row">
                                                <div className="col-md-6 col-sm-12 ">
                                                    <label htmlFor="batch_goods_name">Goods Name </label><Star />
                                                    <Select
                                                        id="b_g_name"
                                                        options={ b_g_opt }
                                                        value={ batch_goods }
                                                        onChange={ b_g_change }
                                                        styles={ customStyles }
                                                        components={ { MenuList: AddGoodsBtn, IndicatorsContainer: EditGoodsbtn } }
                                                        isClearable
                                                        autoFocus
                                                        isSearchable
                                                        isDisabled={ goodsDisable }
                                                        ref={ b_g_Ref }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-12 ">
                                                    <label htmlFor="batch_packing_name" >Packing Name </label><Star />
                                                    {/* <Editpacking/>*/ }
                                                    <Select
                                                        id="b_g_name"
                                                        options={ b_p_opt }
                                                        value={ batch_packing }
                                                        onChange={ b_p_change }
                                                        components={ { MenuList: AddPackingBtn, IndicatorsContainer: EditPackingbtn } }
                                                        styles={ customStyles }
                                                        isClearable
                                                        isSearchable
                                                        isDisabled={ b_p_disable }
                                                        ref={ b_p_Ref }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-12 ">
                                                    <label htmlFor="batch_mfgr_name" className=""> Manufacturer Name</label>
                                                    <Select
                                                        id="b_g_name"
                                                        options={ b_m_opt }
                                                        value={ batch_manuf }
                                                        onChange={ b_m_change }
                                                        styles={ customStyles }
                                                        components={ { MenuList: AddManufacturerBtn, IndicatorsContainer: EditManufacturerbtn } }
                                                        isClearable
                                                        isSearchable
                                                        ref={ b_m_Ref }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-12 ">
                                                    <label htmlFor="batch_lot_no" className="">Batch/Lot No.</label><Star />
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        name="batch_lot_no"
                                                        value={ batch_lot }
                                                        onChange={ ChangeValue }
                                                        ref={ b_l_Ref }
                                                        placeholder="Batch/Lot"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-12 ">
                                                    <label htmlFor="mf_YN" > Manufacturer Date</label>
                                                    <div className="row">
                                                        <div className="col-md-4 col-sm-4">
                                                            <select
                                                                className="form-select form-select-sm "
                                                                name="mf_YN"
                                                                id="mf_YN"
                                                                value={ batch_MD_YN }
                                                                onChange={ ChangeValue }
                                                            >
                                                                <option value={ 1 }>Yes</option>
                                                                <option value={ 0 }>No</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-8 col-sm-8">
                                                            <input
                                                                type="date"
                                                                className="form-control form-control-sm "
                                                                name="mfgr_date"
                                                                value={ mfgr_date }
                                                                onChange={ ChangeValue }
                                                                disabled={ md_disable }
                                                                ref={ b_m_d_Ref }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12 ">
                                                    <label htmlFor="exp_YN" >Expiry Date</label>
                                                    <div className="row">
                                                        <div className="col-md-4 col-sm-4">
                                                            <select
                                                                className="form-select form-select-sm"
                                                                name="exp_YN"
                                                                id="exp_YN"
                                                                value={ batch_EX_YN }
                                                                onChange={ ChangeValue }
                                                            >
                                                                <option value={ 1 }>Yes</option>
                                                                <option value={ 0 }>No</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-8 col-sm-8">
                                                            <input
                                                                type="date"
                                                                className="form-control form-control-sm "
                                                                id="exp_date"
                                                                name="exp_date"
                                                                value={ Ex_date }
                                                                onChange={ ChangeValue }
                                                                disabled={ Ex_disable }
                                                                ref={ b_e_d_Ref }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitForm }>Save</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default Addbatchlot;

