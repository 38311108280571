import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
// import { Toast } from 'primereact/toast';
import { emtySelect, maxlength, validInput, Star } from '../../../../validations/validations'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { GlobalArray, GlobalError } from '../../../../validations/globalArray';
import Confermalert from '../../../../CommonComponents/Alertt';
import axios from 'axios';
import customStyles from '../../../../validations/customStyles';
import Baseurl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent';
import CheckAuth from '../../../../APIHelper/CheckAuth';
import { Loaderr } from '../../../../CommonComponents/Loader';
import { toast } from 'react-toastify';
const AddGroup = () =>
{
  // const toast = useRef( null );
  const { isLoggedInToken, handleLogout, setLoggedIn, isLoading, setIsLoading } = CheckAuth();
  const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast } = useLinkManagement();
  const BaseUrl = Baseurl;
  const gp_nameRef = useRef();
  const primaryRef = useRef();
  const [ group_name, set_Group_name ] = useState( '' );
  const [ primary_group, set_Primary_group ] = useState( '' );
  const [ group_effect_type, set_group_effect_type ] = useState( '' );
  const [ error, setError ] = useState( '' );


  // Options 
  const [ Effectoptins, set_Effectoptins ] = useState( '' );

  const AddGroupData = () =>
  {
    return {
      group_name: group_name.trim(),
      primary_group: primary_group.value,
      group_effect_type: primary_group.efecttype
    };
  }
  const foCoUs = ( foc ) =>
  {
    foc.current.focus();
    foc.current.classList.add( 'is-invalid' );
  };
  const submitform = () =>
  {
    const primaryopt = emtySelect( primary_group, 'Primary Group' );
    const validation = maxlength( group_name, 'Group Name', );
    if ( validation !== true ) { setError( validation ); foCoUs( gp_nameRef ); return false; }
    else if ( primaryopt !== true )
    {
      setError( primaryopt );
      primaryRef.current.focus();
      return false;
    }
    else
    {
      setError( '' );
      const Token = localStorage.getItem( 'token' );
      setIsLoading( true );
      axios
        .post( `${ BaseUrl }group`, AddGroupData(), {
          headers: {
            'Authorization': `Bearer ${ Token }`, // Attach the token to the "Authorization" header
          },
          mode: 'cors',
        } )
        .then( function ( response )
        {
          const data = response.data;
          if ( data.success === true )
          {
            const LastLink = getLastLinkInSession();
            toast.success( 'Success: Add Group Success' );
            if ( LastLink === 'addgroup_m' )
            {
              set_Group_name( '' );
              set_Primary_group( '' );
              set_group_effect_type( '' );
              gp_nameRef.current.focus();
              // toast.current.show( {severity: 'success',summary: 'Success', detail: 'Add Group Success', life: 2000,} );
            }
            else
            {
              const Account_SS = sessionStorage.getItem( 'account_form' );
              if ( Account_SS !== null && Account_SS !== '' && Account_SS !== undefined )
              {
                const d = Account_SS ? JSON.parse( Account_SS ) : {};
                const value = data.Newid;
                const label = group_name;
                d.group_name = { value, label };
                sessionStorage.setItem( 'account_form', JSON.stringify( d ) );
              }
              handleSplitLastLink();
              navigateelast();
            }
            setIsLoading( false );
          }
          if ( data.status === false )
          {
            if ( data.statusCode == '401' )
            {
              alert( data.message );
              handleLogout();
              setLoggedIn( false );
            }
            setIsLoading( false ); // call logout user
          }
        } )
        .catch( function ( error )
        {
          var errrr = error.response;
          const errors = GlobalError( errrr );
          setError( errors );
          toast.error( ' ' + errors );
          gp_nameRef.current.focus();
        } );
    }

  }
  const ChangeValue = ( e ) =>
  {
    const { name, value } = e.target;
    if ( name === 'group_name' ) { var val = validInput( value ); set_Group_name( val ); setError( '' ); }
  }

  useEffect( () =>
  {
    const fetchEfect = async () =>
    {
      try
      {
        const response = await axios.get( `${ BaseUrl }primegrouptypes`, { mode: 'cors' } );
        const data = response.data;
        const mappedOptions = data.map( item => ( { value: item.id, label: item.groupName, efecttype: item.effectType } ) );
        set_Effectoptins( mappedOptions );
      } catch ( error )
      {
        console.error( 'Error fetching states:', error );
      }
    };

    fetchEfect();
  }, [] );
  const confirm = ( position ) =>
  {
    confirmDialog( {
      message: 'Are you sure you want to Leaves this Page?',
      header: 'Leave Confirmation',
      icon: 'pi pi-info-circle',
      position,
      accept
    } );
  };
  const accept = () =>
  {
    // toast.current.show( { severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 } );
    handleSplitLastLink();
    navigateelast();
  }
  // useEffect( () =>
  // {
  //   const handleKeyDown = ( event ) => { if ( event.key === 'Escape' ) { confirm( 'top' ); } };
  //   document.addEventListener( 'keydown', handleKeyDown );
  //   return () => { document.removeEventListener( 'keydown', handleKeyDown ); };
  // }, [] );
  const SavegroupValue = ( selected ) =>
  {
    setError( '' );
    set_Primary_group( selected );
    const pgvalue = selected ? selected.efecttype : '';
    const group_value = GlobalArray( pgvalue );
    if ( group_value !== null )
    {
      set_group_effect_type( group_value );
    } else
    {
      set_group_effect_type( '' );
    }
  };

  return ( <>
    { isLoading ? ( <Loaderr /> ) : ( '' ) }
    <DynamicTitleComponent newTitle={ `Add Group` } />

    {/* <Toast ref={ toast } /> */ }
    <ConfirmDialog />
    <div className="px-2 mt-2 main">
      <div className="row">
        <div className="col-md-6">
          <div className='card '>
            <div className='pt-2 card-header'><h4>Add Group</h4></div>
            <form className="form-horizontal" autoComplete="off" >
              <div className="card-body   ">
                <div className=" row">
                  <div className="col-md-12 col-sm-12 ">
                    <label htmlFor="group_name" >Group Name</label><Star /><span className='float-end' style={ { color: 'red' } }> { error }</span>
                    <input type="text" className="form-control form-control-sm " name="group_name" placeholder="Group Name" autoFocus value={ group_name } onChange={ ChangeValue } ref={ gp_nameRef } />
                  </div>
                  <div className="col-md-12 col-sm-12 ">
                    <label htmlFor="primary_group" >Primary Group</label><Star />
                    <Select
                      id="primary_group"
                      options={ Effectoptins }
                      value={ primary_group }
                      onChange={ SavegroupValue }
                      styles={ customStyles }
                      isClearable
                      ref={ primaryRef }
                      isSearchable
                      rules={ { required: 'Please select an option' } }
                    />

                  </div>
                  <div className="col-md-12 col-sm-12 ">
                    <label htmlFor="group_effect_type" className="">Effect type</label>
                    <input type="text" value={ group_effect_type } className="form-control form-control-sm" id="group_effect_type" name="group_effect_type" placeholder="Effect type" disabled />
                  </div>
                </div>
              </div>
              <div className=" card-footer formBtn">
                {/* <button className="btn px-4  me-2" type='submit' onClick={ submitform }>Add</button> */ }
                <button className="btn px-4  me-2" type='button' onClick={ submitform }>Save</button>
                <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>

  </> );
}
export default AddGroup;