import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { BrowserRouter as Router, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { OnlyNumber, ValidateEmail, maxlength, validInput, Star } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primereact/progressspinner';
import 'primeicons/primeicons.css';
const EditTransport = ( e ) =>
{
    const { id } = useParams();
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, setFocus, splitLastFocus } = useLinkManagement();
    const editUrl = '/transport/edit/' + id + '';
    const [ loading, setLoading ] = useState( false );
    const toast = useRef( null );
    const location = useLocation();
    const currentUrl = location.pathname;
    const navigate = useNavigate();
    const [ name, set_name ] = useState( '' );
    const [ contact, set_contact ] = useState( '' );
    const [ email, set_email ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const namse = useRef();
    const emaile = useRef();
    const TransportData = () => ( {
        name: name.trim(),
        contact: contact.trim(),
        email: email.trim(),
    } );
    useEffect( () =>
    {
        const fetchData = async () =>
        {
            try
            {
                setLoading( true );
                const response = await axios.get( `${ BaseUrl }transport/${ id }` );
                const TransportData = response.data;
                set_name( TransportData.name );
                set_email( TransportData.email );
                set_contact( TransportData.contact1 );
            } catch ( error )
            {
                console.error( 'Error:', error );
            } finally
            {
                setLoading( false )
            }
        };
        fetchData();
    }, [] );
    const foCoUs = ( foc ) => //Add invalid class or set focus 
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) => //remove invalid class 
    {
        foc.current.classList.remove( 'is-invalid' );
    };
    function OnSubmit( e )// click submit button
    {
        e.preventDefault();
        setError( '' );
        const check_name = maxlength( name.trim(), 'broker name ' ); // check valid data
        const checkemail = ValidateEmail( email );
        if ( check_name !== true )
        {
            setError( check_name );
            foCoUs( namse );
            return false;
        }
        else if ( email != '' )
        {
            if ( checkemail !== true )
            {
                setError( checkemail );
                foCoUs( emaile );
                return false;
            } else { SaveData(); }
        }
        else { SaveData(); }
    }
    const SaveData = () =>
    {
        axios
            .patch( `${ BaseUrl }transport/${ id }`, TransportData(), { mode: 'cors' } )
            .then( function ( response )
            {
                const data = response.data;
                if ( data.success === true )
                    toast.current.show( { severity: 'success', summary: 'Success', detail: 'Update Transport Success', life: 2000, } );
                handleSplitLastLink();
                navigateelast();
            } )
            .catch( function ( error )
            {
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors ); foCoUs( namse );
            } );
    }
    const accept = () =>
    {
        toast.current.show( { severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000, } );
        handleSplitLastLink();
        navigateelast();
        // if ( currentUrl === editUrl )
        // {
        //     navigate( '/transport' );
        // }
    };
    const ChangeValue = ( e ) =>//on change input value
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'name' ) { var valuee = validInput( value ); set_name( valuee ); removeinvalid( namse ); }
        if ( name === 'contact' ) { var valuee = OnlyNumber( value ); set_contact( valuee ); }
        if ( name === 'email' ) { var valuee = validInput( value ); set_email( valuee ); removeinvalid( emaile ); }
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     {
    //         if ( event.key === 'Escape' ) { confirm( 'top' ); }
    //     };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () => { document.removeEventListener( 'keydown', handleKeyDown ); };
    // }, [] );

    return (
        <>
            { loading ? (
                <div className='text-center' style={{bottom:'50%'}}>
                    <ProgressSpinner style={ { width: '50px', height: '50px'  } } strokeWidth="4" animationDuration=".5s" />
                </div>
                
            ) : (
                <div className="p-2 mt-2 main"> <DynamicTitleComponent newTitle={ `Edit Transport ` } />
                    <div className="row">
                        <div className="col-md-6">
                            <div className='card'>
                            <div className="card-header"><h4>Edit Transport</h4></div>
                                <form className="form-horizontal" onSubmit={ OnSubmit }>
                                    <div className="card-body">
                                        <div className="row">
                                            <>
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="name" className="  "> Transport Name</label><Star/>
                                                    <span className='float-end' style={ { color: 'red' } }> { error }</span>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm  "
                                                        value={ name }
                                                        name="name"
                                                        required
                                                        placeholder="Transport Name"
                                                        onChange={ ChangeValue }
                                                        ref={ namse }
                                                        autoFocus
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <label htmlFor="contact" className="    ">
                                                        Contact No.
                                                    </label>
                                                    <input
                                                        type="tel"
                                                        className="form-control form-control-sm  "
                                                        maxLength={ 12 }
                                                        minLength={ 10 }
                                                        value={ contact }
                                                        name="contact"
                                                        placeholder="Contact "
                                                        onChange={ ChangeValue }
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <label htmlFor="email" className="   ">
                                                        Email
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control form-control-sm  "
                                                        id="email"
                                                        name="email"
                                                        value={ email }
                                                        ref={ emaile }
                                                        required
                                                        placeholder="Transport Email"
                                                        onChange={ ChangeValue }
                                                    />
                                                </div>
                                            </>

                                        </div>
                                    </div>
                                    <div className="formBtn card-footer">
                                        <button className="btn px-4  me-2" type='submit' onClick={ OnSubmit }>Update</button>
                                        <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                    </div>
                                </form>


                            </div>
                        </div>
                    </div>
                    <Toast ref={ toast } />
                    <ConfirmDialog />
                </div>
            ) }
        </>
    );
}

export default EditTransport;

