
import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import Select from 'react-select';
import { BrowserRouter as Router, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

import customStyles from '../../../validations/customStyles';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../CommonComponents/DynamicTitleComponent'
import { emtySelect, maxlength, validInput, Star, SelectCheck } from '../../../validations/validations'
import { GlobalArray, GlobalError } from '../../../validations/globalArray';
const EditCapitalGoods = ( e ) =>
{
    const toast = useRef( null );
    const { handleSplitLastLink, navigateelast } = useLinkManagement();
    const { id } = useParams();
    const Edit_Capitalgoodsservices = '/capitalgoodsservices/edit/' + id + '';
    const location = useLocation();
    const currentUrl = location.pathname;
    const navigate = useNavigate();

    const [ entery_type, SetEnteryType ] = useState( '' ); const EnteryTypeRef = useRef();
    const [ name, SetName ] = useState( '' ); const nameRef = useRef();
    const [ pname, SetPName ] = useState( '' ); const pnameRef = useRef();
    const [ voucherac, SetVoucherac ] = useState( '' ); const VoucheracRef = useRef(); const [ vouchOpt, setvouchOpt ] = useState( [] );
    const [ hsn_sac, SetHsnSac ] = useState( '' ); const HsnsacRef = useRef(); const [ HsnOpt, setHsnOpt ] = useState( [] );
    const [ uqc, SetUqc ] = useState( '' ); const UqcRef = useRef(); const [ UqcOptions, setUqcOptions ] = useState( [] );
    const [ tds_aply, SetTdsAply ] = useState( '1' ); const [ tdsDis, settdsDis ] = useState( false );
    const [ code, SetCode ] = useState( '' ); const codeRef = useRef(); const [ sectioncodeotp, setseccodeopt ] = useState( [] );
    const [ error, setError ] = useState( '' );

    useEffect( () =>
    {
        const fetchData = async () =>
        {
            try
            {
                // setLoading(true);
                const response = await axios.get( `${ BaseUrl }capitalgoods/${ id }` );
                const item = response.data[ 0 ];
                console.log( item );
                SetEnteryType( { value: item.type, label: item.type } );
                SetName( item.name );
                SetPName( item.printName );
                SetTdsAply( item.isTdsApply );
                if ( item.isTdsApply === '0' )
                {
                    SetCode( '' );
                    settdsDis( true );
                } else
                {
                    SetCode( { value: item.tdsSectionId, label: item.sectionCode } );
                }
                SetVoucherac( { value: item.postingAccId, label: item.accName } );
                SetHsnSac( { value: item.value, label: item.hsncode } );
                SetUqc( { value: item.uqcId, label: item.uqcdescription } );

            } catch ( error )
            {
                console.error( 'Error:', error );
            } finally
            {
                // setLoading(false);
            }
        };
        fetchData();
    }, [] );
    const capitalgoodData = () => ( {
        entery_type: entery_type,
        name: name.trim(),
        pname: pname.trim(),
        voucherac: voucherac,
        hsn_sac: hsn_sac,
        uqc: uqc,
        tds_aply: tds_aply,
        sectioncode: code,
    } );
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };
    const vouterType = [
        { 'value': 'Capital Goods', 'label': 'Capital Goods', 'type': 'HSN' },
        { 'value': 'Service', 'label': 'Service', 'type': 'SAC' }
    ]
    function SubmitForm( e )
    {
        e.preventDefault();
        setError( '' );
        const ent_ty = SelectCheck( entery_type, 'entery type' );
        const namee = maxlength( name.trim(), 'name', 50 );
        const pnamee = maxlength( pname.trim(), 'print name', 50 );
        const vac_ty = SelectCheck( voucherac, 'Voucher A/c' );
        var hsnsacc = '';
        if ( entery_type === null || entery_type === '' || entery_type === undefined )
        {
            var hsnsacc = 'HSN/SAC';
        } else
        {
            var hsnsacc = entery_type.type;
        }
        const hsn_ty = SelectCheck( hsn_sac, hsnsacc );
        const uqc_ty = SelectCheck( uqc, 'UQC' );
        const code_ty = SelectCheck( code, 'section code' );
        if ( ent_ty !== true ) { setError( ent_ty ); EnteryTypeRef.current.focus(); return false; }
        if ( namee !== true ) { setError( namee ); foCoUs( nameRef ); return false; }
        if ( pnamee !== true ) { setError( pnamee ); foCoUs( pnameRef ); return false; }
        if ( vac_ty !== true ) { setError( vac_ty ); VoucheracRef.current.focus(); return false; }
        if ( hsn_ty !== true ) { setError( hsn_ty ); HsnsacRef.current.focus(); return false; }
        if ( uqc_ty !== true ) { setError( uqc_ty ); UqcRef.current.focus(); return false; }
        if ( tds_aply === '1' && code_ty !== true ) { setError( uqc_ty ); codeRef.current.focus(); return false; }
        saverequest();
    }
    const saverequest = () =>
    {
        axios
            .patch( `${ BaseUrl }capitalgoods/${ id }`, capitalgoodData(), { mode: 'cors' } )
            .then( function ( response )
            {
                const data = response.data;
                if ( data.success === true )
                {
                        handleSplitLastLink();
                        navigateelast();

                    // if ( currentUrl === Edit_Capitalgoodsservices ) { navigate( '/capitalgoodsservices' ); }

                }
            } )
            .catch( function ( error )
            {
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors ); EnteryTypeRef.current.focus();
            } );
    }
    const chnageValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        // if ( name === 'entery_type' ) { SetEnteryType( value );}
        if ( name === 'name' ) { var valuee = validInput( value ); SetName( valuee ); SetPName( valuee ); removeinvalid( nameRef ); }
        if ( name === 'pname' ) { var valuee = validInput( value ); SetPName( valuee ); removeinvalid( pnameRef ); }
        if ( name === 'tds_apply' ) { SetTdsAply( value ); if ( value === '0' ) { settdsDis( true ); } else { settdsDis( false ); } }
    };
    useEffect( () =>
    {
        const fetchUqc = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }/uqc`, { mode: 'cors' } );
                const data = response.data;
                const mappedOptions = data.map( item => ( { value: item.id, label: item.uqcCode + ' (' + item.uqcCodeDetail + ')' } ) );
                setUqcOptions( mappedOptions );
            }
            catch ( error ) { console.error( 'Error fetching UQC:', error ); }
        };
        fetchUqc();
    }, [] );
    useEffect( () =>
    {
        const fetchUqc = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }/sectioncode`, { mode: 'cors' } );
                const data = response.data;
                const mappedOptions = data.map( item => ( { value: item.id, label: item.sectionCode } ) );
                setseccodeopt( mappedOptions );
            }
            catch ( error ) { console.error( 'Error fetching sectionCode:', error ); }
        };
        fetchUqc();
    }, [] );

    const fetchhsnsac = ( value ) =>
    {
        const fetchhsn = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }hsnorsa/${ value }`, { mode: 'cors' } );
                const data = response.data;
                const mappedOptions = data.map( item => ( { value: item.id, label: item.hsnCode } ) );
                setHsnOpt( mappedOptions );

            } catch ( error )
            {
                console.error( 'Error fetching HSN/SAC:', error );
            }
        };
        fetchhsn();
    }
    useEffect( () =>
    {
        const fetchUqc = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }/vaccountopt`, { mode: 'cors' } );
                const data = response.data;
                const mappedOptions = data.map( item => ( { value: item.id, label: item.accName } ) );
                setvouchOpt( mappedOptions );
            }
            catch ( error ) { console.error( 'Error fetching Voucherac Opt.', error ); }
        };
        fetchUqc();
    }, [] );
    const voc_type_change = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'Voucher Type empty' ); }
        else { SetVoucherac( selectedOption ); }
    };
    const entery_type_change = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'Entery Type empty' ); }
        else
        {
            SetEnteryType( selectedOption );
            if ( selectedOption === null || selectedOption === undefined )
            {
                SetHsnSac( '' ); setHsnOpt( [] );
            } else
            {
                fetchhsnsac( selectedOption.type ); SetHsnSac( '' );
            }

        }
    };
    const uqc_change = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'UQC Type empty' ); }
        else { SetUqc( selectedOption ); }
    };
    const code_change = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'Code Type empty' ); }
        else { SetCode( selectedOption ); }
    };
    const hsn_change = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'HSN/SAC Type empty' ); }
        else { SetHsnSac( selectedOption ); }
    };
    const accept = () =>
    {
        toast.current.show( { severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 } );
        handleSplitLastLink();
        navigateelast();
        // if ( currentUrl === Edit_Capitalgoodsservices ) { navigate( '/capitalgoodsservices' ); }
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     { if ( event.key === 'Escape' ) { confirm( 'top' ); } };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () => { document.removeEventListener( 'keydown', handleKeyDown ); };
    // }, [] );
    return (
        <>
            <div className="px-2 mt-2 main"><DynamicTitleComponent newTitle={ `Edit | Capital Group-Services` } />
                <div className="row">
                    <div className="col-md-6">
                        <div className='card'>
                            <div className="card-header"> <h4>Edit Capital Goods/Service</h4></div>
                            <form className="form-horizontal" onSubmit={ SubmitForm }>
                                <div className="card-body   ">
                                    <div className="row">
                                        {/* left column */ }
                                        <div>
                                            <span className='float-end' style={ { color: 'red' } }> { error }</span>
                                        </div>
                                        <div className="col-md-12 pb-1 ">
                                            <div className=" row">
                                                <div className="col-md-6 col-sm-12 ">
                                                    <label htmlFor="entery_type" > Entery Type </label><Star />
                                                    <Select
                                                        options={ vouterType }
                                                        value={ entery_type }
                                                        onChange={ entery_type_change }
                                                        styles={ customStyles }
                                                        isClearable
                                                        isSearchable
                                                        autoFocus
                                                        ref={ EnteryTypeRef }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-12 ">
                                                    <label htmlFor="name" >Name </label><Star />
                                                    <input type="text" placeholder='Name' className="form-control form-control-sm " name="name" onChange={ chnageValue } value={ name } ref={ nameRef }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-12 ">
                                                    <label htmlFor="pname" >Print Name </label><Star />
                                                    <input type="text" className="form-control form-control-sm " placeholder='Print' name="pname" onChange={ chnageValue } value={ pname } ref={ pnameRef }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-12 ">
                                                    <label htmlFor="voucherac" >Voucher A/c </label><Star />
                                                    <Select
                                                        options={ vouchOpt }
                                                        value={ voucherac }
                                                        onChange={ voc_type_change }
                                                        styles={ customStyles }
                                                        isClearable
                                                        isSearchable
                                                        ref={ VoucheracRef }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-12 ">
                                                    <label htmlFor="hsn_sac" > HSN/SAC </label><Star />
                                                    <Select
                                                        options={ HsnOpt }
                                                        value={ hsn_sac }
                                                        onChange={ hsn_change }
                                                        styles={ customStyles }
                                                        isClearable
                                                        isSearchable
                                                        ref={ HsnsacRef }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-12 ">
                                                    <label htmlFor="uqc" > UQC </label><Star />
                                                    <Select
                                                        options={ UqcOptions }
                                                        value={ uqc }
                                                        onChange={ uqc_change }
                                                        styles={ customStyles }
                                                        isClearable
                                                        isSearchable
                                                        ref={ UqcRef }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-12 ">
                                                    <label htmlFor="tds_aply" > TDS Apply </label>
                                                    <select className='form-control form-control-sm' name='tds_apply' onChange={ chnageValue } value={ tds_aply }>
                                                        <option value={ '1' }>Yes</option>
                                                        <option value={ '0' }>No</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 col-sm-12 ">
                                                    <label htmlFor="sectioncode" > Section Code </label>
                                                    {/* <input className='form-control form-control-sm' type='text' placeholder='Section Code' name='sectioncode' value={ code } onChange={ chnageValue } ref={ codeRef } disabled={ tdsDis } /> */ }
                                                    <Select
                                                        options={ sectioncodeotp }
                                                        value={ code }
                                                        onChange={ code_change }
                                                        styles={ customStyles }
                                                        isClearable
                                                        isSearchable
                                                        ref={ codeRef }
                                                        isDisabled={ tdsDis }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitForm }>Update</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <Toast ref={ toast } />
                <ConfirmDialog />
            </div>
        </>
    );
}

export default EditCapitalGoods;


