
import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import Select, { components } from 'react-select';
import { BrowserRouter as Router, useNavigate, useLocation ,useParams} from 'react-router-dom';
import axios from 'axios';
import {maxlength, validInput, Star, startno, prefix, SelectCheck, OnlyNumber, removeLeadingZero } from '../../../../validations/validations';
import { GlobalError ,vouterType} from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import customStyles from '../../../../validations/customStyles';
const EditDocumentSeries = ( e ) =>
{
    const toast = useRef( null );
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, setFocus ,splitLastFocus } = useLinkManagement();
    const { id } = useParams();
    const EditUrl = '/documentseries/edit/'+id+'';
    const location = useLocation();
    const currentUrl = location.pathname;
    const navigate = useNavigate();
    const [doc_type , SetDocType] = useState('');  const DocRef = useRef();
    const [doc_sec_name , SetDocSecName] =useState(''); const DocSecRef = useRef();
    const [doc_sec_prefix , SetDocSecPrefix] =useState(''); const DocPrefixRef = useRef();
    const [doc_sec_postfix , SetDocSecPostfix] =useState(''); const DocPostfixRef = useRef();
    const [doc_sec_no , SetDocSecNo] =useState(''); const DocSecNoRef = useRef();
    const [ error , setError ] = useState( '' );

    const seriesData = () => ( {
        doc_type:doc_type,
        seriesname:doc_sec_name,
        prefix:doc_sec_prefix,
        postfix:doc_sec_postfix,
        startno:doc_sec_no,
        fyid:1
    } );
    useEffect( () =>
    {
        const fetchData = async () =>
        {
            try
            {
                // setLoading(true);
                const response = await axios.get( `${ BaseUrl }documentseries/${ id }` );
                const item = response.data[0];
                console.log( item );
                SetDocSecName(item.name);
                SetDocType({ value: item.voucherTypeid, label: item.voucherType});
                SetDocSecPrefix(item.prefix);
                SetDocSecPostfix(item.postfix);
                SetDocSecNo(item.startVoucherNo);
            } catch ( error )
            {
                console.error( 'Error:', error );
            } finally
            {
                // setLoading(false);
            }
        };
        fetchData();
    }, [] );
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };
    // const vouterType =[
    //     {'value': 'CP', 'label': 'Cash/Bank Payment Voucher'},
    //     {'value': 'CR', 'label': 'Cash/Bank Receipt Voucher'},
    //     {'value': 'JV', 'label': 'Journal Voucher'},
    //     {'value': 'SI', 'label': 'Sale Invoice'},
    //     {'value': 'OB', 'label': 'Order Book'},
    //     {'value': 'WN', 'label': 'Weight Note'},
    //     {'value': 'AL', 'label': 'Aadhatia Lagat'},
    //     {'value': 'AB', 'label': 'Aadhatia Bikri'},
    //     {'value': 'DCS', 'label': 'Delivery Challan (Supply)'},
    //     {'value': 'DCL', 'label': 'Delivery Challan (Location)'},
    //     {'value': 'DCB', 'label': 'Delivery Challan (Branch)'},
    //     {'value': 'DCJ', 'label': 'Delivery Challan (Job Work)'},
    //     {'value': 'DCNIRD', 'label': 'Debit/Credit Note Issue (Register Dealer)'},
    //     {'value': 'DCNIUD', 'label': 'Debit/Credit Note Issue (Unregister Dealer)'},
    //     {'value': 'DCNIWT', 'label': 'Debit/Credit Note Issue (Without Tax)'},
    //     {'value': 'RCMIBW', 'label': 'RCM Invoice (Bill Wise)'},
    //     {'value': 'RCMIDT', 'label': 'RCM Invoice (Daily Total Wise)'},
    // ]
    function SubmitForm( e )
    {
        e.preventDefault();
        setError( '' );
        const doc_ty = SelectCheck( doc_type, 'document type' );
        const doc_name = maxlength( doc_sec_name.trim(), 'series name', 50 );
        const prepost = prefix( doc_sec_prefix.trim()+doc_sec_postfix.trim(), 'prefix and postfix');
        const startn = startno( doc_sec_prefix.trim()+doc_sec_postfix.trim()+doc_sec_no.trim());
        if ( doc_ty !== true ) {setError( doc_ty ); DocRef.current.focus(); return false; }
        if ( doc_name !== true ) {setError( doc_name ); foCoUs( DocSecRef ); return false; }
        if ( doc_sec_prefix.trim() !== '' || doc_sec_postfix.trim() !== '' )
        {
            if(prepost !== true){
                setError(prepost);
                foCoUs( DocPrefixRef ); return false;
            }
            else if (startn !== true){
                setError(startn);
                foCoUs( DocSecNoRef ); return false;
            }
        }
        saverequest();
    }
    const saverequest =()=>{
        axios
                .patch( `${ BaseUrl }documentseries/${id}`, seriesData(), { mode: 'cors' } )
                .then( function ( response )
                {
                    const data = response.data;
                    if ( data.success === true )
                    {
                        toast.current.show( { severity: 'success', summary: 'Success', detail: 'Update Document Series Success', life: 2000, } );
                        handleSplitLastLink();
                        navigateelast();
                        // if ( currentUrl === EditUrl ){ navigate( '/documentseries' ); }
                    }
                } )
                .catch( function ( error )
                {
                    var errrr = error.response;
                    const errors = GlobalError( errrr );
                    setError( errors ); DocRef.current.focus();
                } );
    }
    const chnageValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'doc_sec_name' ) { var valuee = validInput( value ); SetDocSecName( valuee ); removeinvalid( DocSecRef );}
        if ( name === 'doc_sec_prefix' ) { var valuee = validInput( value ); SetDocSecPrefix( valuee ); removeinvalid( DocPrefixRef ); }
        if ( name === 'doc_sec_postfix' ) { var valuee = validInput( value ); SetDocSecPostfix( valuee ); removeinvalid( DocPostfixRef ); }
        if ( name === 'doc_sec_no' ) { var onno = OnlyNumber(value); var removez= removeLeadingZero( onno ) ;var valuee = validInput( removez ); SetDocSecNo( valuee ); removeinvalid( DocSecNoRef ); }
    };
    const doc_type_change = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'Document Type empty' ); }
        else { SetDocType( selectedOption ); }
    };
    const accept = () =>
    {
        toast.current.show( { severity: 'info', summary: 'Confirmed', detail: 'You have accepted',  life: 3000,} );
        handleSplitLastLink();
        navigateelast();
        // if ( currentUrl === EditUrl ) { navigate( '/documentseries' ); }
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     { if ( event.key === 'Escape' ) { confirm( 'top' ); } };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () => { document.removeEventListener( 'keydown', handleKeyDown ); };
    // }, [] );
     return (
        <>
            <div className="p-2 mt-2 main"> <DynamicTitleComponent newTitle={ `Edit Document Series ` } />
                <div className="row">
                    <div className="col-md-6">
                        <div className='card'>
                        <div className="card-header"><h4>Edit Document Series</h4></div>
                            <form className="form-horizontal" onSubmit={SubmitForm} autoComplete='off'>
                                <div className="card-body   ">
                                    <div className="row"><div><span className='float-end' style={ { color: 'red' } }> { error }</span></div>
                                        {/* left column */ }
                                        <div className="col-md-12 pb-1 ">
                                            
                                            <div className=" row">
                                                <span className="text-danger" id="doc_error" />
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="doc_type">Document Type</label><Star/> 
                                                    <Select
                                                        id="b_g_name"
                                                        options={ vouterType }
                                                        value={ doc_type }
                                                        onChange={ doc_type_change }
                                                        styles={ customStyles }
                                                        isClearable
                                                        autoFocus
                                                        isSearchable
                                                        ref={ DocRef }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-6 ">
                                                    <label htmlFor="doc_sec_name">Series Name</label><Star/>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        id="doc_sec_name"
                                                        name="doc_sec_name"
                                                        onChange={chnageValue}
                                                        ref={DocSecRef}
                                                        value={doc_sec_name}
                                                        placeholder="Name"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-6 ">
                                                    <label htmlFor="doc_sec_prefix">Series Prefix</label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        id="doc_sec_prefix"
                                                        name="doc_sec_prefix"
                                                        placeholder="Prefix"
                                                        onChange={chnageValue}
                                                        ref={DocPrefixRef}
                                                        value={doc_sec_prefix}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-6 ">
                                                    <label htmlFor="doc_sec_postfix">Series Postfix</label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        id="doc_sec_postfix"
                                                        name="doc_sec_postfix"
                                                        placeholder="Postfix"
                                                        onChange={chnageValue}
                                                        ref={DocPostfixRef}
                                                        value={doc_sec_postfix}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-6 ">
                                                    <label htmlFor="doc_sec_no">Start No.</label>
                                                    <input
                                                        type="text"
                                                        maxLength={6}
                                                        className="form-control form-control-sm "
                                                        id="doc_sec_no"
                                                        name="doc_sec_no"
                                                        placeholder="Number"
                                                        onChange={chnageValue}
                                                        ref={DocSecNoRef}
                                                        value={doc_sec_no}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitForm }>Update</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Toast ref={ toast } />
                <ConfirmDialog />
            </div>
        </>
    );
}

export default EditDocumentSeries;


