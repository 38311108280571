import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
//City
import CityTable from './Components/Masters/AccountsRelated/City/City';
import AddCity from './Components/Masters/AccountsRelated/City/AddCity';
import Editcity from './Components/Masters/AccountsRelated/City/EditCity';
//Group
import GroupTable from './Components/Masters/AccountsRelated/Group/Group';
import AddGroup from './Components/Masters/AccountsRelated/Group/AddGroup';
import EditGroup from './Components/Masters/AccountsRelated/Group/EditGroup';
//HomePage
import HomePage from './CommonComponents/Dashboard';
//Zone
import ZoneTable from './Components/Masters/AccountsRelated/Zone/ZoneTable';
import Addzone from './Components/Masters/AccountsRelated/Zone/AddZone';
import Editzone from './Components/Masters/AccountsRelated/Zone/EditZone';
//Account
import AccountTable from './Components/Masters/Accounts/AccountTable';
import AddAccounts from './Components/Masters/Accounts/Addaccount';
import EditAccount from './Components/Masters/Accounts/EditAccount';
//GST
import GstTable from './Components/Masters/InventoryRelated/GstType/GstTable';
import AddGst from './Components/Masters/InventoryRelated/GstType/AddGst';
import EditGst from './Components/Masters/InventoryRelated/GstType/EditGst';
//Cess
import CessTable from './Components/Masters/InventoryRelated/CessType/CessTable';
import AddCess from './Components/Masters/InventoryRelated/CessType/AddCess';
import EditCess from './Components/Masters/InventoryRelated/CessType/EditCess';
// HSN-SAC
import HsnTable from './Components/Masters/InventoryRelated/HSN_SAC/HsnTable';
import AddHsn from './Components/Masters/InventoryRelated/HSN_SAC/AddHsn';
import EditHsn from './Components/Masters/InventoryRelated/HSN_SAC/EditHsn';
//Packing
import PackingTable from './Components/Masters/InventoryRelated/Packing/PackingTable';
import AddPacking from './Components/Masters/InventoryRelated/Packing/AddPacking';
import EditPacking from './Components/Masters/InventoryRelated/Packing/EditPacking';
//Manufacturer
import ManufacturerTable from './Components/Masters/InventoryRelated/Manufacturer/ManufacturerTable';
import AddManufacturer from './Components/Masters/InventoryRelated/Manufacturer/AddManufacturer';
import EditManufacturer from './Components/Masters/InventoryRelated/Manufacturer/EditManufacturer';
//StockGroup
import StockGroupTable from './Components/Masters/InventoryRelated/StockGroup/StockGroupTable';
import AddStockGroup from './Components/Masters/InventoryRelated/StockGroup/AddStockGroup';
import EditStockGroup from './Components/Masters/InventoryRelated/StockGroup/EditStockGroup';
//Stock Sub Group
import StockSubGroupTable from './Components/Masters/InventoryRelated/StockSubGroup/StockSubGroupTable';
import AddStockSubGroup from './Components/Masters/InventoryRelated/StockSubGroup/AddStockSubGroup';
import EditStockSubGroup from './Components/Masters/InventoryRelated/StockSubGroup/EditStockSubGroup';
//Godown
import GodownTable from './Components/Masters/InventoryRelated/Godown/GodownTable';
import AddGodown from './Components/Masters/InventoryRelated/Godown/AddGodown';
import EditGodown from './Components/Masters/InventoryRelated/Godown/EditGodown';
//Broker
import BrokerTable from './Components/Masters/InventoryRelated/Broker/BrokerTable';
import AddBroker from './Components/Masters/InventoryRelated/Broker/AddBroker';
import EditBroker from './Components/Masters/InventoryRelated/Broker/EditBroker';
//Transport
import TransportTable from './Components/Masters/InventoryRelated/Transport/TransportTable';
import AddTransport from './Components/Masters/InventoryRelated/Transport/AddTransport';
import EditTransport from './Components/Masters/InventoryRelated/Transport/EditTransport';
//GoodsBatchLotTable
import GoodsBatchLotTable from './Components/Masters/InventoryRelated/Goods_BatchLot/GoodsBatchLotTable';
import Addbatchlot from './Components/Masters/InventoryRelated/Goods_BatchLot/AddGoodsBatchLot';
import EditGoodsBatchLot from './Components/Masters/InventoryRelated/Goods_BatchLot/EditGoodsBatchLot';
// Invoce Other Detail
import InvoiceTable from './Components/Masters/InventoryRelated/InvoiceOtherDetail/InvoiceOtherDetailTable';
import AddInviceDetail from './Components/Masters/InventoryRelated/InvoiceOtherDetail/AddInvoiceOtherDetail';
import EditInviceDetail from './Components/Masters/InventoryRelated/InvoiceOtherDetail/EditInvoiceOtherDetail';
// Goods
import GoodsTable from './Components/Masters/Goods/GoodsTable';
import AddGoods from './Components/Masters/Goods/AddGoods';
import EditGoods from './Components/Masters/Goods/EditGoods';
// CapitalGoodsServices
import CapitalGoodsServicesTable from './Components/Masters/CapitalGoodsServices/CapitalGoodsServicesTable';
import AddCapitalGoodsServices from './Components/Masters/CapitalGoodsServices/AddCapitalGoodsServices';
import EditCapitalGoods from './Components/Masters/CapitalGoodsServices/EditCapitalGoodsServices';
// TDSSectionType
import TDSSectionTypeTable from './Components/Masters/TDSRelated/TDSSectionType/TDSSectionTypeTable';
import AddTdsSectionType from './Components/Masters/TDSRelated/TDSSectionType/AddTDSSectionType';
import EditSectionType from './Components/Masters/TDSRelated/TDSSectionType/EditTDSSectionType';
// TdsParameter
import TdsParameterTable from './Components/Masters/TDSRelated/TDSParameter/TDSParameterTable';
import AddTdsParameter from './Components/Masters/TDSRelated/TDSParameter/AddTDSParameter';
import EditTdsParameter from './Components/Masters/TDSRelated/TDSParameter/EditTDSParameter';
// Party_wise_tds
import Party_wise_tdsTable from './Components/Masters/TDSRelated/PartyWiseTDSPercentage/PartyWiseTdsTable';
import AddPartywiseTds from './Components/Masters/TDSRelated/PartyWiseTDSPercentage/AddPartyWiseTDSPercentage';
import EditPartyWiseTds from './Components/Masters/TDSRelated/PartyWiseTDSPercentage/EditPartyWiseTDSPercentage';
// document-series
import DocumentSeriesTable from './Components/Masters/OtherMasters/DocumentSeries/DocumentSeriesTable';
import AddDocumentSeries from './Components/Masters/OtherMasters/DocumentSeries/AddDocumentSeries';
import EditDocumentSeries from './Components/Masters/OtherMasters/DocumentSeries/EditDocumentSeries';
// VoucherNaration
import VoucherNarationTable from './Components/Masters/OtherMasters/VoucherNarration/VoucherNarrationTable';
import AddVoucherNarration from './Components/Masters/OtherMasters/VoucherNarration/AddVoucherNarration';
import EditVoucherNarration from './Components/Masters/OtherMasters/VoucherNarration/EditVoucherNarration';
// Expenses
import ExpensesTable from './Components/Masters/AadhatWorkRelated/Expenses/ExpensesTable';
import AddExpenses from './Components/Masters/AadhatWorkRelated/Expenses/AddExpenses';
import EditExpenses from './Components/Masters/AadhatWorkRelated/Expenses/EditExpenses';
import SellerSlipExpensesTable from './Components/Masters/AadhatWorkRelated/SellerSlipExpensesProfile/SellerSlipExpensesProfileTable';
import AddSellerslipExpenses from './Components/Masters/AadhatWorkRelated/SellerSlipExpensesProfile/AddSellerSlipExpensesProfile';
import EditSellerSlip from './Components/Masters/AadhatWorkRelated/SellerSlipExpensesProfile/EditSellerSlipExpensesProfile';
import CheckAuth from './APIHelper/CheckAuth';
import NotFound from './404/NotFound';
import NoBack from './CommonComponents/useClearSessionOnRouteChange';
import OpeningStockTable from './Components/Masters/InventoryRelated/OpeningStock/OpeningStockTable';
import OpeningStockAdd from './Components/Masters/InventoryRelated/OpeningStock/OpeningStockAdd';
import OpeningStockEdit from './Components/Masters/InventoryRelated/OpeningStock/OpeningStockEdit';

const MasterRoute = () =>{  
  return (
    <>  <Routes>
      <Route path='*' element={<NotFound/>}/>
      <Route path="/" element={ <HomePage /> } />
      <Route path="/city" element={ <CityTable /> } />
      <Route path="/city/add" element={ <AddCity /> } />
      <Route path="/city/edit/:id" element={ <Editcity /> } />
      
      <Route path="/group" element={ <GroupTable /> } />
      <Route path="/group/add" element={ <AddGroup /> } />
      <Route path="/group/edit/:id" element={ <EditGroup /> } />
      {/* Zone */ }
      <Route path='/zone' element={ <ZoneTable /> } />
      <Route path='/zone/add' element={ <Addzone /> } />
      <Route path='/zone/edit/:id' element={ <Editzone /> } />
      {/* <Route path='/zone/edit/:encryptedId' element={<Editzone/>}/> */ }
      {/* Accounts */ }
      <Route path='/accounts' element={ <AccountTable /> } />
      <Route path='/accounts/add' element={ <AddAccounts /> } />
      <Route path='/accounts/edit/:id' element={ <EditAccount /> } />
      {/* GST */}
      <Route path='/gst' element={ <GstTable /> } />
      <Route path='/gst/add' element={ <AddGst /> } />
      <Route path='/gst/edit/:id' element={ <EditGst /> } />
      {/* Cess */}
      <Route path='/cess' element={ <CessTable /> } />
      <Route path='/cess/add' element={ <AddCess /> } />
      <Route path='/cess/edit/:id' element={ <EditCess /> } />
      {/* HSN-SAC */}
      <Route path='/hsn-sac' element={ <HsnTable /> } />
      <Route path='/hsn-sac/add' element={ <AddHsn /> } />
      <Route path='/hsn-sac/edit/:id' element={ <EditHsn /> } />
      {/* Packing */}
      <Route path='/packing' element={ <PackingTable /> } />
      <Route path='/packing/add' element={ <AddPacking /> } />
      <Route path='/packing/edit/:id' element={ <EditPacking /> } />
      {/* Manufacturer */}
      <Route path='/manufacturer' element={ <ManufacturerTable /> } />
      <Route path='/manufacturer/add' element={ <AddManufacturer /> } />
      <Route path='/manufacturer/edit/:id' element={ <EditManufacturer /> } />
      {/* Stock Group */}
      <Route path='/stock-group' element={ <StockGroupTable /> } />
      <Route path='/stock-group/add' element={ <AddStockGroup /> } />
      <Route path='/stock-group/edit/:id' element={ <EditStockGroup /> } />
      {/* Stock Sub Group */}
      <Route path='/stock-sub-group' element={ <StockSubGroupTable /> } />
      <Route path='/stock-sub-group/add' element={ <AddStockSubGroup /> } />
      <Route path='/stock-sub-group/edit/:id' element={ <EditStockSubGroup /> } />
      {/*Godown */}
      <Route path='/Godown' element={ <GodownTable /> } />
      <Route path='/Godown/add' element={ <AddGodown /> } />
      <Route path='/Godown/edit/:id' element={ <EditGodown /> } />
      {/*broker */}
      <Route path='/broker' element={ <BrokerTable /> } />
      <Route path='/broker/add' element={ <AddBroker /> } />
      <Route path='/broker/edit/:id' element={ <EditBroker /> } />
      {/*Transport */}
      <Route path='/transport' element={ <TransportTable /> } />
      <Route path='/transport/add' element={ <AddTransport /> } />
      <Route path='/transport/edit/:id' element={ <EditTransport /> } />
      {/*Batchlot */}
      <Route path='/batchlot' element={ <GoodsBatchLotTable /> } />
      <Route path='/batchlot/add' element={ <Addbatchlot /> } />
      <Route path='/batchlot/edit/:id' element={ <EditGoodsBatchLot /> } />
      {/*Invice Other Detail */}
      <Route path='/invoice' element={ <InvoiceTable /> } />
      <Route path='/invoice/add' element={ <AddInviceDetail /> } />
      <Route path='/invoice/edit/:id' element={ <EditInviceDetail /> } />
      {/*Goods */}
      <Route path='/goods' element={ <GoodsTable /> } />
      <Route path='/goods/add' element={ <AddGoods /> } />
      <Route path='/goods/edit/:id' element={ <EditGoods /> } />
      {/*capital-goods-services */}
      <Route path='/capital-goods-services' element={ <CapitalGoodsServicesTable /> } />
      <Route path='/capital-goods-services/add' element={ <AddCapitalGoodsServices /> } />
      <Route path='/capital-goods-services/edit/:id' element={ <EditCapitalGoods /> } />
      {/*TDS Section Code */}
      <Route path='/tds-section-type' element={ <TDSSectionTypeTable /> } />
      <Route path='/tds-section-type/add' element={ <AddTdsSectionType /> } />
      <Route path='/tds-section-type/edit/:id' element={ <EditSectionType /> } />
      {/*TDS Parameter*/}
      <Route path='/tds-parameter' element={ <TdsParameterTable /> } />
      <Route path='/tds-parameter/add' element={ <AddTdsParameter /> } />
      <Route path='/tds-parameter/edit/:id' element={ <EditTdsParameter /> } />
      {/*party-wise-tds-percentage*/}
      <Route path='/party-wise-tds-percentage' element={ <Party_wise_tdsTable /> } />
      <Route path='/party-wise-tds-percentage/add' element={ <AddPartywiseTds /> } />
      <Route path='/party-wise-tds-percentage/edit/:id' element={ <EditPartyWiseTds /> } />
      {/*document-series*/}
      <Route path='/document-series' element={ <DocumentSeriesTable /> } />
      <Route path='/document-series/add' element={ <AddDocumentSeries /> } />
      <Route path='/document-series/edit/:id' element={ <EditDocumentSeries /> } />
      {/*voucher-narration*/}
      <Route path='/voucher-narration' element={ <VoucherNarationTable /> } />
      <Route path='/voucher-narration/add' element={ <AddVoucherNarration /> } />
      <Route path='/voucher-narration/edit/:id' element={ <EditVoucherNarration /> } />
      {/*expenses*/}
      <Route path='/expenses' element={ <ExpensesTable /> } />
      <Route path='/expenses/add' element={ <AddExpenses /> } />
      <Route path='/expenses/edit/:id' element={ <EditExpenses /> } />
      {/*expenses*/}
      <Route path='/seller-slip-expenses-profile' element={ <SellerSlipExpensesTable /> } />
      <Route path='/seller-slip-expenses-profile/add' element={ <AddSellerslipExpenses /> } />
      <Route path='/seller-slip-expenses-profile/edit/:id' element={ <EditSellerSlip /> } />
      {/* Opening Stock */}
      <Route path="opening-stock" element={<OpeningStockTable/> } />
      <Route path="opening-stock/add" element={<OpeningStockAdd/> } />
      <Route path='opening-stock/edit/:id' element={<OpeningStockEdit/>}/>
      {/* <Route path='opening-stock/edit/:id' element={<OpeningStockEdit/>}/> */}
    </Routes>
    {/* <NoBack/> */}
    </>
  

  );
};

export default MasterRoute;
