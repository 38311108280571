import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import Select, { components } from 'react-select';
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import customStyles from '../../../../validations/customStyles';
import { OnlyNumber, SelectCheck, removeLeadingZero, threeDecimal, Pincoode, emtySelect, maxlength, validInput, Star, Editicon } from '../../../../validations/validations';

const AddExpenses = ( e ) =>
{
    const toast = useRef( null );
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, setFocus, splitLastFocus } = useLinkManagement();
    const [ name, SetName ] = useState( '' ); const nameRef = useRef();
    const [ pname, SetPname ] = useState( '' ); const pnameRef = useRef();
    const [ account, SetAccount ] = useState( '' ); const AccountRef = useRef(); const [ AccountOpt, setAccountOpt ] = useState( [] );
    const [ tds_aply, SetTdsAply ] = useState( '1' ); const tds_aplyRef = useRef(); const [ tdsDis, settdsDis ] = useState( false );
    const [ code, SetCode ] = useState( '' ); const codeRef = useRef(); const [ sectioncodeotp, setseccodeopt ] = useState( [] );
    const [ error, setError ] = useState( '' );
    //Set Focuse
    useEffect( () =>
    {
        const setFocusFromStorage = JSON.parse( sessionStorage.getItem( 'focusme' ) );
        if ( setFocusFromStorage && setFocusFromStorage.length > 0 )
        {
            const focusElement = setFocusFromStorage[ setFocusFromStorage.length - 1 ];
            setTimeout( () =>
            {
                if ( focusElement === 'AccountRef' )
                {
                    AccountRef.current.focus();
                    splitLastFocus();
                }
                else if ( focusElement === 'tds_aplyRef' )
                {
                    codeRef.current.focus();
                    splitLastFocus();
                }
            }, 10 ); // 10 milliseconds delay
        }
    }, [] );
    useEffect( () => //Fetch Accounts
    {
        const fetchAccounts = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }/vaccountopt`, { mode: 'cors' } );
                const data = response.data;
                const mappedOptions = data.map( item => ( { value: item.id, label: item.accName } ) );
                setAccountOpt( mappedOptions );
            }
            catch ( error ) { console.log( 'Error fetching Accounts.', error ); }
        };
        fetchAccounts();
    }, [] );
    useEffect( () => //fetch section Code's
    {
        const fetsectioncode = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }/sectioncode`, { mode: 'cors' } );
                const data = response.data;
                const mappedOptions = data.map( item => ( { value: item.id, label: item.sectionCode } ) );
                setseccodeopt( mappedOptions );
            }
            catch ( error ) { console.log( 'Error fetching Section Code Opt.', error ); }
        };
        fetsectioncode();
    }, [] );
    const ExpensesData = () => ( {
        name: name.trim(),
        printname: pname.trim(),
        account: account,
        tdsaply: tds_aply,
        sectioncode: code,
    } );
    function SubmitForm( e )
    {
        e.preventDefault();
        setError();
        const namee = maxlength( name.trim(), 'name' );
        const pnamee = maxlength( pname.trim(), 'print name' );
        const Accountcheck = SelectCheck( account, 'posting A/c' );
        const codee = SelectCheck( code, 'posting A/c' );
        if ( namee !== true ) { setError( namee ); foCoUs( nameRef ); return false; }
        if ( pnamee !== true ) { setError( pnamee ); foCoUs( pnameRef ); return false; }
        if ( Accountcheck !== true ) { setError( Accountcheck ); AccountRef.current.focus(); return false; }
        if ( tds_aply === '1' && codee !== true ) { setError( codee ); codeRef.current.focus(); return false; }
        if ( tds_aply === '0' && code !== '' ) { setError( `Section code entry not valid  ${ code }!` ); foCoUs( tds_aplyRef ); return false; }
        saverequest();
    }
    const saverequest = () =>
    {
        axios
            .post( `${ BaseUrl }expenses`, ExpensesData(), { mode: 'cors' } )
            .then( function ( response )
            {
                const data = response.data;
                if (data.success === true) {
                    // Remove 'dbexpenses' from session storage after successful operation
                    sessionStorage.removeItem('dbexpenses');
                    // Get the last link from session storage
                    const cityLastLink = getLastLinkInSession();
                    if (cityLastLink === 'add_expenses_m') {  
                        // Clear form fields and set focus on 'nameRef'
                        setError(''); SetName(''); SetPname(''); SetTdsAply('1'); SetCode('');SetAccount('');
                        nameRef.current.focus();
                        // Show success toast message
                        toast.current.show({severity: 'success',summary: 'Success', detail: 'Add Expenses Success',life: 2000, });
                    } else {
                        // Handle other scenarios
                        handleSplitLastLink();
                        // Get the last link from session storage again
                        const LastLinkk = getLastLinkInSession();
                        let lasttlinkk = LastLinkk;
                        // Split the last link into parts
                        const linkParts = LastLinkk.split(',');
                        if (linkParts.length === 2) {
                            // Extract the link and editId from linkParts
                            const link = linkParts[0].trim();
                            const editId = linkParts[1].trim();
                            lasttlinkk = link;
                        }
                        //Add Expenses on Add Edit SellerSlipExpensesProfile
                        if (lasttlinkk === 'add_sellerslipexpensesprofile_m' || lasttlinkk === 'add_sellerslipexpensesprofile' || lasttlinkk === 'edit_sellerslipexpensesprofile') {
                            // Retrieve 'sellerslipexpense' from session storage
                            const selerslipexpense = sessionStorage.getItem('sellerslipexpensesprofile');
                
                            // Parse 'Expensesfocus' from session storage
                            const indexid = JSON.parse(sessionStorage.getItem('Expensesfocus'));
                
                            if (selerslipexpense) {
                                // Parse 'sellerslipexpense' JSON or initialize an empty object
                                const d = JSON.parse(selerslipexpense);
                                // Retrieve 'expensesArray' from the parsed data
                                const expensesArray = d.expenses;
                                // Define the index you want to modify
                                const indexToModify = indexid;
                                // Define the new name value
                                const newNameValue = { 'label': name, 'value': data.Newid };
                                if (indexToModify >= 0 && indexToModify < expensesArray.length) {
                                    // Modify the 'name' property of the selected expense
                                    expensesArray[indexToModify].name = newNameValue;
                                }
                                // Update 'expensesArray' in the data object
                                d.expenses = expensesArray;
                                // Store the updated data back in session storage
                                sessionStorage.setItem('sellerslipexpensesprofile', JSON.stringify(d));
                            }
                        }
                        // Navigate to the last link
                        navigateelast();
                    }
                }
                
            } )
            .catch( function ( error )
            {
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors ); foCoUs( nameRef );
            } );
    }
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };

    const chnageValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'name' ) { var valuee = validInput( value ); SetName( valuee ); SetPname( valuee ); removeinvalid( nameRef ); }
        if ( name === 'pname' ) { var valuee = validInput( value ); SetPname( valuee ); removeinvalid( pnameRef ); }
        if ( name === 'tds_apply' ) { SetTdsAply( value ); if ( value === '0' ) { SetCode( '' ); settdsDis( true ); } else { settdsDis( false ); } }
    };

    const Ac_change = selectedOption =>
    {
        if ( selectedOption !== '' ) { SetAccount( selectedOption ); }
    };
    const addAccountform = () => 
    {
        sessionStorage.setItem( 'dbexpenses',
            JSON.stringify( { name, pname, account, tds_aply, code } ) );
        addLink( "addaccount" );
        setFocus( 'AccountRef' );
        navigateelast();
    };

    const accountBtn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <spna
                    onClick={ addAccountform }
                    className="btn btn-xsm  btn-primary btn-block w-100"
                > Add </spna>
            </components.MenuList>
        );
    };


    const code_change = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'Code Type empty' ); }
        else { SetCode( selectedOption ); }
    };
    const addsectionform = () =>
    {
        sessionStorage.setItem( 'dbexpenses', JSON.stringify( { name, pname, account, tds_aply, code } ) );
        addLink( "add_tdsST" );
        setFocus( 'tds_aplyRef' );
        navigateelast();
    }
    const sectionBtn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <spna
                    onClick={ addsectionform }
                    className="btn btn-xsm  btn-primary btn-block w-100"
                > Add </spna>
            </components.MenuList>
        );
    };

    const accept = () =>
    {
        toast.current.show( { severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000, } );
        sessionStorage.removeItem( 'dbexpenses' );
        handleSplitLastLink();
        navigateelast();
        // Navigate after Click Cancel Btn 
        // if ( currentUrl === '/expenses/add' ) { navigate( '/expenses' ); }                                      
        // if ( currentUrl === AddSellerSlip ) { navigate( '/seller-slip-expenses-profile/add' ); }    // Go back Add Seller slip 
        // if ( currentUrl === EditSellerSlip ){                                                       // Go back Edit Seller slip 
        //     const sD = sessionStorage.getItem( 'sellerslipexpensesprofile' );                       // get ID on 'sellerslipexpensesprofile' sessionStorage
        //     if ( sD ) {  const pD = JSON.parse( sD );
        //         let pidcheck = pD.id;
        //         navigate( '/seller-slip-expenses-profile/edit/'+pidcheck+'' );                       // navigate to  Edit Seller slip page
        //     }

        // }
    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };

    //get data on session storage
    useEffect( () =>
    {
        const savedData = sessionStorage.getItem( 'dbexpenses' );
        if ( savedData )
        {
            const data = JSON.parse( savedData );
            SetName( data.name );
            SetPname( data.pname );
            SetAccount( data.account );
            SetTdsAply( data.tds_aply );
            if ( data.tds_aply === '0' )
            {
                settdsDis( true );
                SetCode( '' );
            }
            SetCode( data.code );
        }
    }, [] );
    //Edit Account
    const EditPartyform = ( value ) =>
    {
        sessionStorage.removeItem( 'dbexpenses' );
        sessionStorage.setItem( 'dbexpenses', JSON.stringify( { name, pname, account, tds_aply, code } ) );
        addLink( "edit_account," + value );
        setFocus( 'AccountRef' );
        navigateelast();
    };
    const EditParty = ( props ) =>
    {
        return (
            <>
                <div
                    onClick={ () => EditPartyform( props.selectProps.value.value ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const EditPartyfrom = ( { children, ...props } ) =>
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditParty { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };

    //Edit Section Code 
    const editSectionCodeForm = ( value ) =>
    {
        sessionStorage.removeItem( 'dbexpenses' );
        sessionStorage.setItem( 'dbexpenses', JSON.stringify( { name, pname, account, tds_aply, code } ) );
        addLink( "edit_tdsST," + value );
        setFocus( 'tds_aplyRef' );
        navigateelast();
    };
    const EditSectionCode = ( props ) =>
    {
        return (
            <>
                <div
                    onClick={ () => editSectionCodeForm( props.selectProps.value.value ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <Editicon />
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const IndicatorsContainer = ( { children, ...props } ) =>
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditSectionCode { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     { if ( event.key === 'Escape' ) { confirm( 'top' ); } };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () => { document.removeEventListener( 'keydown', handleKeyDown ); };
    // }, [] );
    return (
        <>
            <div className="p-2 mt-2 main"> <DynamicTitleComponent newTitle={ `Add Expenses` } />
                <div className="row">
                    <div className="col-md-6">

                        <div className='card'>
                            <div className="card-header">
                                <h4>Add Expenses</h4>
                            </div>
                            <form className="form-horizontal" onSubmit={ SubmitForm } autoComplete='off'>
                                <div className="card-body   ">
                                    <div className="row">
                                        {/* left column */ }
                                        <div>
                                            <span className='float-end' style={ { color: 'red' } }> { error }</span>
                                        </div>
                                        <div className="col-md-12 pb-1 ">
                                            <div className=" row">
                                                <div className="col-md-6 col-sm-6 ">
                                                    <label htmlFor="name" >Name </label><Star />
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        name="name"
                                                        placeholder="Name"
                                                        onChange={ chnageValue }
                                                        value={ name }
                                                        ref={ nameRef }
                                                        autoFocus
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-6 ">
                                                    <label htmlFor="pname" >Print Name </label><Star />
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm "
                                                        name="pname"
                                                        placeholder="Print Name"
                                                        onChange={ chnageValue }
                                                        value={ pname }
                                                        ref={ pnameRef }
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-8">
                                                    <label htmlFor="CityName" > Posting A/c</label><Star />
                                                    <Select
                                                        options={ AccountOpt }
                                                        value={ account }
                                                        onChange={ Ac_change }
                                                        styles={ customStyles }
                                                        components={ { MenuList: accountBtn, IndicatorsContainer: EditPartyfrom } }
                                                        isClearable
                                                        isSearchable
                                                        ref={ AccountRef }
                                                    />
                                                </div>
                                                <div className="col-md-2 col-sm-12 ">
                                                    <label htmlFor="tds_aply" > TDS Apply </label>
                                                    <select className='form-control form-control-sm' ref={ tds_aplyRef } name='tds_apply' onChange={ chnageValue } value={ tds_aply }>
                                                        <option value={ '1' }>Yes</option>
                                                        <option value={ '0' }>No</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-10 col-sm-12 ">
                                                    <label htmlFor="sectioncode" > Section Code </label>
                                                    <Select
                                                        options={ sectioncodeotp }
                                                        value={ code }
                                                        onChange={ code_change }
                                                        styles={ customStyles }
                                                        components={ { MenuList: sectionBtn, IndicatorsContainer: IndicatorsContainer } }
                                                        isClearable
                                                        isSearchable
                                                        ref={ codeRef }
                                                        isDisabled={ tdsDis }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitForm }>Save</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <Toast ref={ toast } />
                <ConfirmDialog />
            </div>
        </>
    )
}

export default AddExpenses;