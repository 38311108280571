import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
// import { Toast } from 'primereact/toast';
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import { maxlength, validInput ,Star } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../.././BaseUrl';
import axios from 'axios';
import useLinkManagement from '../../../../Routes//useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import CheckAuth from '../../../../APIHelper/CheckAuth';
import { toast } from 'react-toastify';
import {Loaderr} from '../../../../CommonComponents/Loader';
const AddZone = ( e ) =>
{
    // const toast = useRef( null );
    const {  handleSplitLastLink, getLastLinkInSession,  navigateelast } = useLinkManagement();
    const { handleLogout, setLoggedIn, isLoggedInToken, isLoading, setIsLoading } = CheckAuth();
    const location = useLocation();
    const [ zone_name, set_Zone_name ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const zoneName = useRef( null );
    const ZoneData = () => ( {
        zonename: zone_name.trim()
    } );
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    function OnSubmit( e )
    {
        e.preventDefault();
        const trimmedName = maxlength( zone_name.trim(), 'City Name', 50 );
        if ( trimmedName !== true )
        {
            setError( trimmedName ); foCoUs( zoneName )
            return false;
        }
        else
        {
            const Token = localStorage.getItem( "token" );
            setIsLoading( true );
            axios
            .post( `${ BaseUrl }zone`, ZoneData(), {
                    headers: {'Authorization': `Bearer ${Token}`, // Attach the token to the "Authorization" header
                    },
                    mode: 'cors',
                  } )
                .then( function ( response )
                {
                    const data = response.data;
                    if ( data.success === true )
                    {
                        const LastLink = getLastLinkInSession();
                        if ( LastLink === 'addzone_m' )
                        {
                            set_Zone_name( '' );
                            
        setIsLoading(false);
                    toast.success( ' Add Zone Success' );
                            // toast.current.show( {
                            //     severity: 'success',
                            //     summary: 'Success',
                            //     detail: 'Add Zone Success',
                            //     life: 2000,
                            // } );
                            zoneName.current.focus();
                        } else
                        {
                            handleSplitLastLink();
                            navigateelast();
                        }
                        

                    }
                    if (data.status === false) {
                        if (data.statusCode == '401') {
                          alert(data.message);
                          handleLogout();
                          setLoggedIn(false);
                        }
                        setIsLoading( false ); // call logout user
                      }
                } )
                .catch( function ( error )
                {
                    var errrr = error.response;
                    const errors = GlobalError( errrr );
                    setError( errors );
                    toast.error( ' '+errors );
                    foCoUs( zoneName );
                    setIsLoading(false);
                } );
        }
    }
    const ChangeValue = ( e ) =>
    {
        const { name, value } = e.target;
        if ( name === 'zone_name' ) { var valuee = validInput( value ); set_Zone_name( valuee ); setError( '' ); } else { setError( '' ); }
    };
    const accept = () =>
    {
        handleSplitLastLink();
        navigateelast();
        // toast.current.show( { severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 } );
        // if ( currentUrl == '/zone/add' )
        // {
        //     navigate( '/zone/' );
        // }
    }
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to Leaves this Page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept
        } );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) => { if ( event.key === 'Escape' ) { confirm( 'top' ); } };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () => { document.removeEventListener( 'keydown', handleKeyDown ); };
    // }, [] );
    return (

        <>

{ isLoading ? ( <Loaderr/> ) : ( '' ) }
            <div className="p-2 mt-2 main"><DynamicTitleComponent newTitle={`Add Zone `}/>
                <div className="row">
                    <div className="col-md-6">
                        <div className=" card">

                            <div className='pt-2  card-header'><h4>Add Zone</h4></div>
                            <form className="form-horizontal" autoComplete='off' >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label htmlFor="zone_name" className=" form-label ">Zone</label><Star/> <span className='float-end' style={ { color: 'red' } }> { error }</span>
                                            <input type="text" className="form-control form-control-sm "
                                                value={ zone_name } name="zone_name" placeholder="Zone"
                                                onChange={ ChangeValue } autoFocus autoComplete="off" ref={ zoneName } />

                                          
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ OnSubmit }>Save</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6">

                    </div>
                </div>
                {/* <Toast ref={ toast } /> */}
                <ConfirmDialog />
            </div>
        </>
    );
}
export default AddZone