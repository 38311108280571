// import React, { useState } from 'react';

// function InputValidation() {
//   const [inputValue, setInputValue] = useState('');
//   const [error, setError] = useState('');

//   const handleChange = (e) => {
//     const value = e.target.value;

//     // Check if the input is a number between 0 and 100
//     if (/^(100(\.0{1,2})?|[0-9]?[0-9](\.\d{1,2})?)$/.test(value)) {
//       setError('');
//       setInputValue(value);
//     } else {
//       setError('Invalid input. Please enter a number between 0 and 100.');
//     }
//   };

//   return (
//     <div>
//       <label htmlFor="inputField">Enter a number (0 to 100):</label>
//       <input
//         type="text"
//         id="inputField"
//         value={inputValue}
//         onChange={handleChange}
//       />
//       {error && <p style={{ color: 'red' }}>{error}</p>}
//     </div>
//   );
// }

// export default InputValidation;
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import axios from 'axios';
import Select, { components } from 'react-select';
import customStyles from '../../../../validations/customStyles';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent';
import { GlobalArray, GlobalError } from '../../../../validations/globalArray';
import CheckAuth from '../../../../APIHelper/CheckAuth';
import { Loaderr } from '../../../../CommonComponents/Loader';
import { Star, Editicon, AfterDecimalThreeValue, AfterDecimalTowValue, emtySelect } from '../../../../validations/validations';
import CommonFunctions, { fetchOptionsFrom } from '../../../../Common/CommonFunction';
const OpeningStockAdd = () =>
{
    const { isLoggedInToken, handleLogout, setLoggedIn, isLoading, setIsLoading } = CheckAuth();
    const { AddChangeData, EditChangeData, setInWhitchData, setInWhitchFrome, RemoveSSFild, splitLastInWitchD, splitLastInWitchF } = CommonFunctions();
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, splitLastFocus, setFocus } = useLinkManagement();

    //Goods
    const [ Goods, SetGoods ] = useState( '' );
    const [ GoodsOpt, setGoodsOpt ] = useState( [] ); const GoodsRef = useRef( [] );
    //Account
    const [ Account, SetAccount ] = useState( '' );
    const [ AccountOpt, setAccountOpt ] = useState( [] ); const AccountRef = useRef( [] );
    //Packing
    const [ PackingOpt, setPackingOpt ] = useState( '' );
    const [ PackingDisable, setPackingDisable ] = useState( false );

    //Batch/Lot No.
    const [ BatchLotNoOpt, setBatchLotNoOpt ] = useState( [] );
    const [ BatchDisable, setBatchDisable ] = useState( false );
    //Godown
    const refs2 = useRef( [] );
    const [ GodownOpt, setGodownOpt ] = useState( '' );
    const [ GodownDisable, setGodownDisable ] = useState( false );

    const [ error, setError ] = useState( '' );
    const refsbatch = useRef( [] );
    const refsgodown = useRef( [] );
    const refspacking = useRef( [] );
    const [ expenses, setExpenses ] = useState( [
        { sn: '1', packing: '', batchlotno: '', godown: '', unit: '', qunit: '', netqty: '', rate: '', rt: '1', amount: '', BatchLotNoOpt: [] }
    ] );
    //Total 
    const [ totalUnit, setTotalUnit ] = useState( 0 );
    const [ totalNetQty, setTotalNetQty ] = useState( 0 );
    const [ totalAmount, setTotalAmount ] = useState( 0 );

    // Common function to set 'openingstock' in sessionStorage
    const setOpeningStockData = ( frome = null ) =>
    {
        RemoveSSFild( 'openingstock' );
        setInWhitchData( 'openingstock' );
        setInWhitchFrome( frome );      //In Whitch Value Update on Date
        sessionStorage.setItem( 'openingstock', JSON.stringify( { Goods, Account, expenses, totalUnit, totalNetQty, totalAmount } ) );
    };
    // Get data from session storage and set values
    useEffect( () =>
    {
      const fetchData = async () =>
      {
        const editID = sessionStorage.getItem('OpeningStockeditID');
        const Token = localStorage.getItem( 'token' );
          setIsLoading( true );
          try
          {
              const response = await axios.get( `${ BaseUrl }openingstock/${ editID }`, { headers: { Authorization: `Bearer ${ Token }` }, mode: 'cors' } );
              const openingstockData = response.data;
              SetGoods( openingstockData.openingstockrowdata[0].goods);
              GetPackingOptionsonly(openingstockData.openingstockrowdata[0].goods.value);
              setExpenses( openingstockData.openingstockrowdata);
              setTotalUnit(openingstockData.totalunit);
              setTotalNetQty(openingstockData.totalqty);
              setTotalAmount(openingstockData.totalamount);
              setIsLoading( false );
          } catch ( error )
          {
              setIsLoading( false );
              console.error( 'Error:', error );
              // navigate( '/' );

          }
      };
      fetchData();
        // Initialize with 3 default rows
        const defaultRows = Array.from( { length: 3 }, ( _, index ) => ( {
            sn: ( index + 1 ).toString(), packing: '', batchlotno: '', godown: '', unit: '', qunit: '', netqty: '', rate: '', rt: '1', amount: '', BatchLotNoOpt: []
        } ) );
        setExpenses( defaultRows );
        calculateTotals();
        //get and set options
        setTimeout( () => { fetchGoodsOpt(); }, 200 );
        fetchOptionsFrom( 'vaccountopt', 'id', 'accName' ).then( mappedOptions => { setAccountOpt( mappedOptions ); } );
        fetchOptionsFrom( 'godownopt', 'id', 'name' ).then( mappedOptions => { setGodownOpt( mappedOptions ); } );

        // Get and Set Session storage data .
        const savedData = sessionStorage.getItem( 'openingstock' );
        if ( savedData )
        {
            const data = JSON.parse( savedData );
            const { Goods, Account, expenses, totalUnit, totalNetQty, totalAmount } = data;

            if ( Goods )
            {
                SetGoods( Goods );
                setTimeout( () => { fetchGoodsOpt( Goods.value ); }, 200 );
            }
            if ( Account ) { SetAccount( Account ); }
            setExpenses( expenses );
            if ( totalNetQty ) { setTotalNetQty( totalNetQty ); }
            if ( totalUnit ) { setTotalUnit( totalUnit ); }
            if ( totalAmount ) { setTotalAmount( totalAmount ); }
            // setTimeout( () => { if ( Goods ) { fetchGoodsOpt( Goods.value ); GetPackingOptions( Goods.value ); } }, 200 );
        }
    }, [] );


    const GetPackingOptionsonly = ( selectedOptionid ) =>
    {
        // Assuming fetchOptionsFrom returns a promise
        fetchOptionsFrom( `goodspackingopt/${ selectedOptionid }`, 'packingId', 'packingName', 'goodsId' )
            .then( ( mappedOptions ) =>
            {
                setPackingOpt( mappedOptions );
            } )
            .catch( ( error ) =>
            {
                console.log( error );
                console.error( "Error:", error );
            } );
    };
    const GetbatchOptionsonly = ( id ) =>
    {
        // Assuming fetchOptionsFrom returns a promise
        fetchOptionsFrom( `batchlotopt/${ id }/0`, 'id', 'batchNo' )
            .then( ( mappedOptions ) =>
            {
              const updatedExpenses = expenses.map( ( item ) =>
              {
                      return { ...item, BatchLotNoOpt: mappedOptions };
              } );
              setExpenses( updatedExpenses );
            } )
            .catch( ( error ) =>
            {
                console.log( error );
            } );
    };
    const GetPackingOptions = ( selectedOptionid ) =>
    {
        // Assuming fetchOptionsFrom returns a promise
        fetchOptionsFrom( `goodspackingopt/${ selectedOptionid }`, 'packingId', 'packingName', 'goodsId' )
            .then( ( mappedOptions ) =>
            {
                setPackingOpt( mappedOptions );
                updatePackingData( mappedOptions );
            } )
            .catch( ( error ) =>
            {
                console.log( error );
                console.error( "Error:", error );
            } );
    };
    // Check this goods packing options in selected packind 
    // const updatePackingData = async ( options ) =>
    // {
    //     console.log(expenses);
    //     const batchoptions = await fetchOptionsFrom( `batchlotopt/${ options.goodsId }/0`, 'id', 'batchNo' );
    //     expenses.map( item =>
    //     {
    //         const matchingPacking = options.find( opt => opt.value === (item.packing ? item.packing.value : null) );
    //         if ( matchingPacking )
    //         {
    //             item.packing = matchingPacking;
    //         } else
    //         {
    //             item.packing = '';
    //             item.batchlotno = '';
    //             item.BatchLotNoOpt = batchoptions;
    //         }
    //          console.log(expenses);
    //          setExpenses( expenses );
    //     } );
    // };
    const updatePackingData = async ( options ) =>
    {
        try
        {
            const batchoptions = await fetchOptionsFrom( `batchlotopt/${ options.goodsId }/0`, 'id', 'batchNo' );
            const updatedExpenses = expenses.map( ( item ) =>
            {
                const matchingPacking = options.find( ( opt ) => opt.value === ( item.packing ? item.packing.value : null ) );
                if ( matchingPacking )
                {
                    return { ...item, packing: matchingPacking };
                } else
                {
                    return { ...item, packing: '', batchlotno: '', BatchLotNoOpt: batchoptions };
                }
            } );
            setExpenses( updatedExpenses );
        } catch ( error )
        {
            console.error( 'An error occurred while updating packing data:', error );
        }
    };

    const GetPackingOptions2 = ( selectedOptionid ) =>
    {
        // Assuming fetchOptionsFrom returns a promise
        fetchOptionsFrom( `goodspackingopt/${ selectedOptionid }`, 'packingId', 'packingName', 'goodsId' )
            .then( ( mappedOptions ) =>
            {
                setPackingOpt( mappedOptions );
                updatePackingData2( mappedOptions );
            } )
            .catch( ( error ) =>
            {
                console.log( error );
                console.error( "Error:", error );
            } );
    };
    const updatePackingData2 = async ( options ) =>
    {
        try
        {
            const batchoptions = await fetchOptionsFrom( `batchlotopt/${ options.goodsId }/0`, 'id', 'batchNo' );
            const savedData = sessionStorage.getItem( 'openingstock' );
            const parsedData = JSON.parse( savedData );
            const { expenses } = parsedData;
            const updatedExpenses = expenses.map( ( item ) =>
            {
                const matchingPacking = options.find( ( opt ) => opt.value === ( item.packing ? item.packing.value : null ) );
                if ( matchingPacking )
                {
                    return { ...item, packing: matchingPacking };
                } else
                {
                    return { ...item, packing: '', batchlotno: '', BatchLotNoOpt: batchoptions };
                }
            } );
            setExpenses( updatedExpenses );
        } catch ( error )
        {
            console.error( 'An error occurred while updating packing data:', error );
        }
    };

    const fetchGoodsOpt = async ( check = null ) =>
    {
        try
        {
            const response = await axios.get( `${ BaseUrl }goodsopt`, { mode: 'cors' } );
            const data = response.data;
            const mappedOptions = data.map( item => ( {
                value: item.goodsId,
                label: item.name,
                godownmaintain: item.isGodownMaintain,
                packingmaintain: item.isPackingMaintain,
                batchmaintain: item.isBatchMaintain,
            } ) );
            setGoodsOpt( mappedOptions );
            if ( check !== null )
            {
                mappedOptions.map( ( item ) =>
                {
                    if ( check == item.value )
                    {
                        const selectedoption = { value: item.value, label: item.label, godownmaintain: item.godownmaintain, packingmaintain: item.packingmaintain, batchmaintain: item.batchmaintain }
                        SetGoods( selectedoption );
                        checkMentain( selectedoption );
                    }
                } );
            }

            // if ( check !== null )
            // {
            //     const batchoptions = await fetchOptionsFrom( `batchlotopt/${ check }/0`, 'id', 'batchNo' );
            //     let packingMentaions = '';
            //     let batchlotMentaions = '';
            //     let godownMentaions = '';
            //     mappedOptions.map( ( item ) =>
            //     {
            //         if ( check === item.value )
            //         {
            //             packingMentaions = item.packingmaintain;
            //             batchlotMentaions = item.batchmaintain;
            //             godownMentaions = item.godownmaintain;
            //             const selectedoption = { value: item.value, label: item.label, godownmaintain: item.godownmaintain, packingmaintain: item.packingmaintain, batchmaintain: item.batchmaintain }
            //             SetGoods( selectedoption );
            //         }
            //     } );
            //     if ( packingMentaions !== '' && packingMentaions == 0 )
            //     {
            //         setPackingDisable( true );
            //         const savedData = sessionStorage.getItem( 'openingstock' );
            //         if ( savedData )
            //         {
            //             const data = JSON.parse( savedData );
            //             const { expenses } = data;
            //             expenses.map( ( iteem ) => { iteem.packing = ''; iteem.BatchLotNoOpt = batchoptions; } );
            //             setExpenses( expenses );
            //         }
            //     }
            //     else { setPackingDisable( false ); }

            //     if ( batchlotMentaions !== '' && batchlotMentaions == 0 )
            //     {
            //         setBatchDisable( true );
            //         const savedData = sessionStorage.getItem( 'openingstock' );
            //         if ( savedData )
            //         {
            //             const data = JSON.parse( savedData );
            //             const { expenses } = data;
            //             expenses.map( ( iteem ) => { iteem.batchlotno = ''; } );
            //             setExpenses( expenses );
            //         }
            //     } else
            //     {
            //         setBatchDisable( false );
            //     }

            //     if ( godownMentaions !== '' && godownMentaions == 0 )
            //     {
            //         setGodownDisable( true );
            //         const savedData = sessionStorage.getItem( 'openingstock' );
            //         if ( savedData )
            //         {
            //             const data = JSON.parse( savedData );
            //             const { expenses } = data;
            //             expenses.map( ( iteem ) => { iteem.godown = ''; } );
            //             setExpenses( expenses );
            //         }
            //     } else
            //     {
            //         setGodownDisable( false );
            //     }
            // }
        } catch ( error )
        {
            console.error( 'Error fetching states:', error );
        }
    };


    const checkMentain = async ( goodsid = null, goods_id = null ) =>
    {
        if ( goodsid !== null )
        {
            // Determine if the good maintains packing.
            if ( goodsid.packingmaintain == '0' )
            {
                // Disable packing input field.
                setPackingDisable( true );
                // Clear packing values from expenses.
                setRowDataOnSS( 'packing', null );
            } else if ( goodsid.packingmaintain == '1' )
            {
                // Enable packing input field.
                setPackingDisable( false );

                // Get packing options for the selected good.
                setTimeout( () => { GetPackingOptions2( goodsid.value ); }, 200 );

            }

            // Determine if the good maintains batch/lot.
            if ( goodsid.batchmaintain == '0' )
            {
                // Disable batch/lot input field.
                setBatchDisable( true );
                // Clear batch/lot values from expenses.
                setRowDataOnSS( 'batchlotno', null );
            } else if ( goodsid.batchmaintain == '1' )
            {
                // Enable batch/lot input field.
                setBatchDisable( false );
                // Fetch batch lot options for the selected good.

                fetchOptionsFrom( `batchlotopt/${ goodsid.value }/0`, 'id', 'batchNo', 'goodsId' )
                    .then( ( mappedOptions ) =>
                    {
                        // Set batch lot options for expenses.
                        setRowDataOnSS( 'BatchLotNoOpt', mappedOptions );
                    } );

            }

            // Determine if the good maintains godown.
            if ( goodsid.godownmaintain == '0' )
            {
                // Disable godown input field.
                setGodownDisable( true );
                // Clear godown values from expenses.
                setRowDataOnSS( 'godown', null );
            } else if ( goodsid.godownmaintain == '1' )
            {
                // Enable godown input field.
                setGodownDisable( false );
            }
        }
        else if ( goods_id !== null )
        {
            // Determine if the good maintains packing.
            if ( goods_id.packingmaintain == '0' )
            {
                // Disable packing input field.
                setPackingDisable( true );
                // Clear packing values from expenses.
                setRowData( 'packing', null );
            } else if ( goods_id.packingmaintain == '1' )
            {
                // Enable packing input field.
                setPackingDisable( false );
                // Get packing options for the selected good.
                // GetPackingOptions( goods_id.value );
            }
            // Determine if the good maintains batch/lot.
            if ( goods_id.batchmaintain == '0' )
            {
                // Disable batch/lot input field.
                setBatchDisable( true );
                // Clear batch/lot values from expenses.
                setRowData( 'batchlotno', null );
            } else if ( goods_id.batchmaintain == '1' )
            {
                // Enable batch/lot input field.
                setBatchDisable( false );
                // Fetch batch lot options for the selected good.
                fetchOptionsFrom( `batchlotopt/${ goods_id.value }/0`, 'id', 'batchNo', 'goodsId' )
                    .then( ( mappedOptions ) =>
                    {
                        // Set batch lot options for expenses.
                        setRowData( 'BatchLotNoOpt', mappedOptions );
                    } );
            }
            // Determine if the good maintains godown.
            if ( goods_id.godownmaintain == '0' )
            {
                // Disable godown input field.
                setGodownDisable( true );
                // Clear godown values from expenses.
                setRowData( 'godown', null );
            } else if ( goods_id.godownmaintain == '1' )
            {
                // Enable godown input field.
                setGodownDisable( false );
            }

        }
        else
        {
            setPackingDisable( true );
            setGodownDisable( true );
            setBatchDisable( true );
        }

    }


    const setRowData = ( field, newData ) =>
    {
        expenses.map( ( item ) =>
        {
            item[ field ] = newData;
        } );
        setExpenses( expenses );
    }
    const setRowDataOnSS = ( field, newData ) =>
    {
        const savedData = sessionStorage.getItem( 'openingstock' );
        const parsedData = JSON.parse( savedData );
        const { expenses } = parsedData;

        expenses.map( ( item ) =>
        {
            item[ field ] = newData;
        } );
        setExpenses( expenses );
    }

    const GoodsValueChange = async ( selectedOption ) =>
    {
        if ( selectedOption !== '' && selectedOption !== null && selectedOption !== undefined )
        {
            // Set Goods Value on goods input.
            SetGoods( selectedOption );
            GetPackingOptions( selectedOption.value )
            checkMentain( null, selectedOption );
            setRowData( 'batchlotno', null );
            //   // Clear previous expenses batch lot numbers and options.
            //   expenses.map((item) => {
            //     item.batchlotno = '';
            //     item.BatchLotNoOpt = [];
            //   });
            //   setExpenses(expenses);

            //   // Fetch batch lot options for the selected good.
            //   const mappedOptions = await fetchOptionsFrom(`batchlotopt/${selectedOption.value}/0`, 'id', 'batchNo', 'goodsId');

            //   // Set batch lot options for expenses.
            //   expenses.map((item) => {
            //     item.BatchLotNoOpt = mappedOptions;
            //   });
            //   setExpenses(expenses);

            //   // Determine if the good maintains packing.
            //   if (selectedOption.packingmaintain == '0') {
            //     // Disable packing input field.
            //     setPackingDisable(true);

            //     // Clear packing values from expenses.
            //     expenses.map((item) => {
            //       item.packing = '';
            //     });
            //     setExpenses(expenses);
            //   } else if (selectedOption.packingmaintain == '1') {
            //     // Enable packing input field.
            //     setPackingDisable(false);

            //     // Get packing options for the selected good.
            //     GetPackingOptions(selectedOption.value);
            //   }

            //   // Determine if the good maintains batch/lot.
            //   if (selectedOption.batchmaintain == '0') {
            //     // Disable batch/lot input field.
            //     setBatchDisable(true);

            //     // Clear batch/lot values from expenses.
            //     expenses.map((item) => {
            //       item.batchlotno = '';
            //       item.BatchLotNoOpt = [];
            //     });
            //     setExpenses(expenses);
            //   } else if (selectedOption.batchmaintain == '1') {
            //     // Enable batch/lot input field.
            //     setBatchDisable(false);
            //   }

            //   // Determine if the good maintains godown.
            //   if (selectedOption.godownmaintain == '0') {
            //     // Disable godown input field.
            //     setGodownDisable(true);

            //     // Clear godown values from expenses.
            //     expenses.map((item) => {
            //       item.godown = '';
            //     });
            //     setExpenses(expenses);
            //   } else if (selectedOption.godownmaintain == '1') {
            //     // Enable godown input field.
            //     setGodownDisable(false);
            //   }
        }
    };
    // Add New Goods 
    const GoodsForm = () =>
    {
        RemoveSSFild( 'openingstock' );
        setInWhitchData( 'openingstock' );
        setInWhitchFrome( 'Goods' );      //In Whitch Value Update on Date
        const defaultRows = Array.from( { length: 3 }, ( _, index ) => ( {
            sn: ( index + 1 ).toString(), packing: '', batchlotno: '', godown: '', unit: '', qunit: '', netqty: '', rate: '', rt: '1', amount: '', BatchLotNoOpt: []
        } ) );
        sessionStorage.setItem( 'openingstock', JSON.stringify( { Goods: '', expenses: defaultRows } ) );
        addLink( 'addgoods' );
        navigateelast();
    };
    const GoodsAddBtn = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <span onClick={ () => GoodsForm() } className="btn btn-xsm btn-primary w-100">Add</span>
            </components.MenuList>
        );
    };
    //Edit Selected Goods
    const GoodsEditForm = ( value ) =>//Edit Selected Goods Form
    {
        setOpeningStockData( 'Goods' );
        addLink( `edit_goods,${ value }` );
        navigateelast();
    };
    const GetSelectedGoodId = ( props ) => //Edit Goods Button
    {
        return (
            <>
                <div
                    onClick={ () => GoodsEditForm( props.selectProps.value.value ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    {/* {{if(props ){
                <Editicon />
               }else{''}}}  */}
                    { props.selectProps.value.value ? <Editicon /> : null }
                    {/* <Editicon /> */ }
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const GoodsEdit = ( { children, ...props } ) => //Edit Goods Create or make icon
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <GetSelectedGoodId { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };





    // Add Row
    const handleAddRow = async () =>
    {
        const batchoptions = await fetchOptionsFrom( `batchlotopt/${ Goods.value }/0`, 'id', 'batchNo' );
        const newSN = ( expenses.length + 1 ).toString();
        setExpenses( [ ...expenses, { sn: newSN, packing: '', batchlotno: '', godown: '', unit: '', qunit: '', netqty: '', rate: '', rt: '1', amount: '', BatchLotNoOpt: batchoptions } ] );
        calculateTotals();
    };
    // Delete Row
    const handleDeleteRow = ( index ) =>
    {
        const updatedExpenses = [ ...expenses ];
        updatedExpenses.splice( index, 1 );
        setExpenses( updatedExpenses );

        const totals = calculateTotals1( updatedExpenses );
        setTotalUnit( totals.totalUnit );
        setTotalNetQty( totals.totalNetQty );
        setTotalAmount( totals.totalAmount );
    };
    const calculateTotals1 = ( expenses ) =>
    {
        let totalUnitValue = 0;
        let totalNetQtyValue = 0;
        let totalAmountValue = 0;

        for ( const expense of expenses )
        {
            totalUnitValue += Number( expense.unit ) || 0;
            totalNetQtyValue += Number( expense.netqty ) || 0;
            totalAmountValue += Number( expense.amount ) || 0;
        }

        return {
            totalUnit: totalUnitValue.toFixed( 2 ),
            totalNetQty: totalNetQtyValue.toFixed( 3 ),
            totalAmount: totalAmountValue.toFixed( 2 ),
        };
    };
    const ChangePacking = async ( index, selectedOption ) =>
    {
        if ( selectedOption !== null )
        {
            try
            {
                const batchoptions = await fetchOptionsFrom( `batchlotopt/${ Goods.value }/${ selectedOption.value }`, 'id', 'batchNo' );
                const updatedExpenses = [ ...expenses ];
                updatedExpenses[ index ].packing = selectedOption;
                updatedExpenses[ index ].batchlotno = '';
                updatedExpenses[ index ].BatchLotNoOpt = batchoptions;
                setExpenses( updatedExpenses );
            } catch ( error )
            {
                console.error( 'Error fetching batch options:', error );
            }
        } else
        {
            const batchoptions = await fetchOptionsFrom( `batchlotopt/${ Goods.value }/0`, 'id', 'batchNo' );
            const updatedExpenses = [ ...expenses ];
            updatedExpenses[ index ].packing = selectedOption;
            updatedExpenses[ index ].batchlotno = '';
            updatedExpenses[ index ].BatchLotNoOpt = batchoptions;
            setExpenses( updatedExpenses );
        }
    };


    //Edit Packing
    const EditPackingForm = ( props ) => //Edit Packing Form
    {
        const value = props.selectProps.value.value;
        const { index } = props;
        sessionStorage.getItem( 'OSPackingfocus' ); //remove all Expenses foces
        sessionStorage.setItem( 'OSPackingfocus', JSON.stringify( index ) );//Add  Expenses foces
        sessionStorage.removeItem( 'openingstock' );
        sessionStorage.setItem( 'openingstock', JSON.stringify( { Goods, Account, expenses, totalUnit, totalNetQty, totalAmount } ) );
        addLink( `edit_packing,${ value }` );
        navigateelast();
    };
    const EditPacking = ( props ) =>//Edit Packing Button
    {
        return (
            <>
                <div
                    onClick={ () => EditPackingForm( props ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    { props.selectProps.value.value ? <Editicon /> : null }
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const PackingEdit = ( { children, ...props } ) => // Get Selected id then pass the id on Edit Packing Button
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditPacking { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };

    // Select BatchLoat No Value
    const ChangeBatchLoatNo = ( index, selectedOption ) =>
    {
        const updatedExpenses = [ ...expenses ];
        updatedExpenses[ index ].batchlotno = selectedOption;
        setExpenses( updatedExpenses );
    };
    //Add New Batch Loat No
    const BatchLoatNoForm = ( props ) => //Add Batch Loat No form
    {
        const { index } = props;
        if ( expenses[ index ].packing !== undefined && expenses[ index ].packing !== null && expenses[ index ].packing !== '' )
        {
            sessionStorage.setItem( 'batchlotpackingid', JSON.stringify( expenses[ index ].packing ) );
        }
        if ( Goods !== null && Goods !== undefined && Goods !== '' )
        {
            sessionStorage.setItem( 'batchlotgoodsid', JSON.stringify( Goods ) );
        }
        setOpeningStockData( `expenses[${ index }].batchlotno` );                 //Call Goods Input Field Data
        sessionStorage.setItem( 'OSBatchLoatNofocus', JSON.stringify( index ) );
        addLink( "add_batchlot" );
        navigateelast();
    };


    const AppBatchLoatNo = ( props ) => //Add new Batch Loat No Button
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <spna
                    onClick={ () => { BatchLoatNoForm( props ) } }
                    className="btn btn-xsm  btn-primary  w-100 "
                > Add </spna>
            </components.MenuList>
        );
    };
    //Edit Batch Loat No
    const EditBatchLoatNoForm = ( props ) => //Edit Batch Loat No Form
    {
        const value = props.selectProps.value.value;
        const { index } = props;
        setOpeningStockData( `expenses[${ index }].batchlotno` );                 //Call Goods Input Field Data
        sessionStorage.setItem( 'OSBatchLoatNofocus', JSON.stringify( index ) );//Add  Batch Loat No foces
        addLink( `edit_batchlot,${ value }` );
        navigateelast();
    };
    const EditBatchLoatNo = ( props ) => //Edit Batch Loat No Button
    {
        return (
            <>
                <div
                    onClick={ () => EditBatchLoatNoForm( props ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    { props.selectProps.value.value ? <Editicon /> : null }
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const BatchLoatNoEdit = ( { children, ...props } ) => // Get Selected id then pass the id on Edit Batch Loat No Button
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditBatchLoatNo { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };


    // Select Godown Value
    const ChangeGodown = ( index, selectedOption ) =>
    {
        const updatedExpenses = [ ...expenses ];
        updatedExpenses[ index ].godown = selectedOption;
        setExpenses( updatedExpenses );
    };
    //Add New Godown
    const GodownForm = ( props ) => //Add Godown form
    {
        const { index } = props;
        setOpeningStockData( `expenses[${ index }].batchlotno` );                 //Call Goods Input Field Data
        sessionStorage.setItem( 'OSGodownfocus', JSON.stringify( index ) );
        addLink( "add_godown" );
        navigateelast();
    };
    const AppGodown = ( props ) => //Add new Godown Button
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <spna
                    onClick={ () => { GodownForm( props ) } }
                    className="btn btn-xsm  btn-primary  w-100 "
                > Add </spna>
            </components.MenuList>
        );
    };
    //Edit Godown
    const EditGodownForm = ( props ) => //Edit Godown Form
    {
        const value = props.selectProps.value.value;
        const { index } = props;

        setOpeningStockData( `expenses[${ index }].batchlotno` );
        sessionStorage.setItem( 'OSGodownfocus', JSON.stringify( index ) );//Add  Expenses foces
        addLink( `edit_godown,${ value }` );
        navigateelast();
    };
    const EditGodown = ( props ) => //Edit Godown Button
    {
        return (
            <>
                <div
                    onClick={ () => EditGodownForm( props ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    { props.selectProps.value.value ? <Editicon /> : null }
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const GodownEdit = ( { children, ...props } ) => // Get Selected id then pass the id on Edit Godown Button
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditGodown { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };


    const roundToDecimal = ( value, decimalPlaces ) =>
    {
        const factor = 10 ** decimalPlaces;
        return Math.round( value * factor ) / factor;
    };

    // Change Unit Value
    const handleChangeUnit = ( index, value ) =>
    {
        const isNonNegativeFloat = AfterDecimalTowValue( value );
        if ( isNonNegativeFloat !== null )
        {
            const updatedExpenses = [ ...expenses ];
            updatedExpenses[ index ].unit = value; // Parse the input as a float
            setExpenses( updatedExpenses );
            calculateAmount( index );
            calculateNetQty( index );
        }
    };

    // Change Qunit Value
    const handleChangeQUnit = ( index, value ) =>
    {
        const isNonNegativeFloat = AfterDecimalTowValue( value );
        if ( isNonNegativeFloat !== null )
        {
            const updatedExpenses = [ ...expenses ];
            updatedExpenses[ index ].qunit = value;
            setExpenses( updatedExpenses );
            calculateAmount( index );
            calculateNetQty( index );
        }
    };

    // Change Rate Value
    const handleChangeRate = ( index, value ) =>
    {
        const isNonNegativeFloat = AfterDecimalTowValue( value );

        if ( isNonNegativeFloat !== null )
        {
            const updatedExpenses = [ ...expenses ];
            updatedExpenses[ index ].rate = value;
            setExpenses( updatedExpenses );
            calculateAmount( index );
            // calculateNetQty( index );
        }
    };

    // calculate NetQty Value
    const calculateNetQty = ( index ) =>
    {
        const updatedExpenses = [ ...expenses ];
        const unit = updatedExpenses[ index ].unit;
        const qunit = updatedExpenses[ index ].qunit;
        updatedExpenses[ index ].netqty = roundToDecimal( unit * qunit, 3 ); // Round to 3 decimal places
        setExpenses( updatedExpenses );
        calculateTotals(); // Recalculate totals when Net Qty changes
    };

    // calculate Amount Value
    const calculateAmount = ( index ) =>
    {
        const updatedExpenses = [ ...expenses ];
        const rt = updatedExpenses[ index ].rt;
        const rate = updatedExpenses[ index ].rate;
        if ( rt === '1' )
        {
            const unit = updatedExpenses[ index ].unit;
            updatedExpenses[ index ].amount = roundToDecimal( unit * rate, 2 ); // Round to 2 decimal places
        } else
        {
            const netqty = updatedExpenses[ index ].netqty;
            updatedExpenses[ index ].amount = roundToDecimal( netqty * rate, 2 ); // Round to 2 decimal places
        }
        setExpenses( updatedExpenses );
        calculateTotals(); // Recalculate totals when Amount changes
    };

    // Change R/T Value
    const handleChangeRT = ( index, value ) =>
    {
        const updatedExpenses = [ ...expenses ];
        updatedExpenses[ index ].rt = value;
        setExpenses( updatedExpenses );
        calculateAmount( index );
        // calculateNetQty( index );
    };

    // Change Amount Value
    const handleChangeamount = ( index, value ) =>
    {
        const isNonNegativeFloat = AfterDecimalTowValue( value );
        if ( isNonNegativeFloat !== null )
        {
            const updatedExpenses = [ ...expenses ];
            updatedExpenses[ index ].amount = value;
            setExpenses( updatedExpenses );
            calculateTotals();
        }
    };

    // Change Qty Value
    const handleChangenetqty = ( index, value ) =>
    {
        const isNonNegativeFloat = AfterDecimalThreeValue( value );
        if ( isNonNegativeFloat !== null )
        {
            const updatedExpenses = [ ...expenses ];
            updatedExpenses[ index ].netqty = value;
            setExpenses( updatedExpenses );
            calculateAmount( index );
            calculateTotals();
        }

    };

    const calculateTotals = () =>
    {
        let totalUnitValue = 0;
        let totalNetQtyValue = 0;
        let totalAmountValue = 0;

        for ( const expense of expenses )
        {
            totalUnitValue += Number( expense.unit ) || 0;
            totalNetQtyValue += Number( expense.netqty ) || 0;
            totalAmountValue += Number( expense.amount ) || 0;
        }

        totalUnitValue = totalUnitValue.toFixed( 2 );
        totalNetQtyValue = totalNetQtyValue.toFixed( 3 );
        totalAmountValue = totalAmountValue.toFixed( 2 );

        setTotalUnit( totalUnitValue );
        setTotalNetQty( totalNetQtyValue );
        setTotalAmount( totalAmountValue );
    };


   
    const save = async () =>
    {

        // e.preventDefault();
        //set Validations 
        setError();
        const selectedgoods = emtySelect( Goods, 'Goods' );
        if ( selectedgoods !== true ) { setError( selectedgoods ); GoodsRef.current.focus(); return false; }
        const Token = localStorage.getItem( 'token' );
        const editID = sessionStorage.getItem('OpeningStockeditID');
        setIsLoading( true );
        axios
            // .post( `${ BaseUrl }openingstock`, OpeningStockData(), { headers: {'Authorization': `Bearer ${ Token }`,mode: 'cors' } })
            .patch( `${ BaseUrl }openingstock/${editID}`, OpeningStockData(), {
                headers: {
                    'Authorization': `Bearer ${ Token }`,
                },
                mode: 'cors',
            } )
            .then( function ( response )
            {
                const data = response.data;
                if ( data.success === true )
                {
                    toast.success( 'Update Opening Stock Success' );
                    sessionStorage.removeItem('OpeningStockeditID');
                    sessionStorage.removeItem( 'openingstock' );
                    const LastLink = getLastLinkInSession();
                    if ( LastLink === 'add_goods_m' )
                    {
                        setError( '' ); SetGoods( '' );
                        GoodsRef.current.focus();
                    } else
                    {
                        handleSplitLastLink();
                        //AddChangeData( goods_name, data.Newid ); // Change Last Open Popup Data or fild
                        navigateelast();
                    }
                }
                setIsLoading( false );
            } )
            .catch( function ( error )
            {
                var errrr = error.response;
                const errors = GlobalError( errrr );
                setError( errors );
                // foCoUs( GoodsRef );
                setIsLoading( false );
            } );





        const nonEmptyExpenses = expenses.filter( ( expense ) =>
        {
            return (
                ( expense.packing !== '' && expense.packing !== null && expense.packing !== undefined ) ||
                ( expense.batchlotno !== '' && expense.batchlotno !== null && expense.batchlotno !== undefined ) ||
                ( expense.godown !== '' && expense.godown !== null && expense.godown !== undefined ) ||
                ( expense.unit !== '' && expense.unit !== null && expense.unit !== undefined ) ||
                ( expense.qunit !== '' && expense.qunit !== null && expense.qunit !== undefined ) ||
                ( expense.netqty !== '' && expense.netqty !== null && expense.netqty !== undefined ) ||
                ( expense.rate !== '' && expense.rate !== null && expense.rate !== undefined ) ||
                ( expense.rt !== '' && expense.rt !== null && expense.rt !== undefined ) ||
                ( expense.amount !== '' && expense.amount !== null && expense.amount !== undefined )
            );
        } );
    }
    const partyWiseData = ( nonEmptyExpenses ) => ( {
        city: Goods,
        expenses: nonEmptyExpenses,
    } );
    const validateInputs = () =>
    {
        setError();
        const selectedgoods = emtySelect( Goods, 'Goods' );
        if ( selectedgoods !== true ) { setError( selectedgoods ); GoodsRef.current.focus(); return false; }
        let validRowwd = 0;
        let isvalid = 0;
        const errorsCopy = {};
        for ( const [ key, value ] of Object.entries( expenses ) )
        {
            setError( '' );
            const sn = value.sn;
            const thispacking = value.packing;
            const thisbatchlotno = value.batchlotno;
            const thisgodown = value.godown;
            const thisunit = value.unit;
            const thisrate = value.rate;
            const thisquty = value.qunit;
            const goodspackingmantain = Goods.packingmaintain;
            const goodsbatchgmantain = Goods.batchmaintain;
            const goodsgodowngmantain = Goods.godownmaintain;
            if ( goodspackingmantain != 0 && thispacking == null || thispacking == '' )
            {
                validRowwd += 1;
                errorsCopy[ sn ] = { ...errorsCopy[ sn ], calculateOn: 'Please select Packing ' + sn + '' }
                isvalid += 1;
            }
            if ( goodsbatchgmantain != 0 )
            {
                validRowwd += 1;
                errorsCopy[ sn ] = { ...errorsCopy[ sn ], calculateOn: 'Please select BatchLot on ' + sn + '' }
                isvalid += 1;
            }
            if ( goodsgodowngmantain != 0 )
            {
                validRowwd += 1;
                errorsCopy[ sn ] = { ...errorsCopy[ sn ], calculateOn: 'Please select Godown on ' + sn + '' }
                isvalid += 1;
            }
        }
        const displayErrors = ( errors ) =>
        {
            for ( const sn in errors )
            {
                const errorMessages = errors[ sn ];
                for ( const key in errorMessages )
                {
                    const errorMessage = errorMessages[ key ];
                    setError( ` ${ errorMessage }` );
                }
            }
        };
        displayErrors( errorsCopy );
        if ( validRowwd === 0 )
        {
            setError( 'There should be at least one expense row with %!' );
            refs2.current[ 0 ].focus();
            return false;
        }
        if ( isvalid === 0 )
        {
            const nonEmptyExpenses = expenses.filter( ( expense ) =>
            {
                return (
                    ( expense.packingmaintain !== '' && expense.packingmaintain !== null && expense.packingmaintain !== undefined ) ||
                    ( expense.batchmaintain !== '' && expense.batchmaintain !== null && expense.batchmaintain !== undefined ) ||
                    ( expense.godownmaintain !== '' && expense.godownmaintain !== null && expense.godownmaintain !== undefined )
                );
            } );
            partyWiseData( nonEmptyExpenses );
            return true;
        }
        else { return false; }

    }
    const OpeningStockData = () => ( {
        goods_id: Goods.value,
        formtype: 'openingstock',
        row: expenses
    } )
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    const accept = () =>
    {
        sessionStorage.removeItem( 'openingstock' );
        sessionStorage.removeItem('OpeningStockeditID');
        handleSplitLastLink();
        navigateelast();
    };
    //Set Focuse 
    useEffect( () =>
    {
        setTimeout( () =>
        {
            const getFocus = JSON.parse( sessionStorage.getItem( 'focusme' ) );
            if ( getFocus && getFocus.length > 0 )
            {
                const setfocus = getFocus[ getFocus.length - 1 ];
                setTimeout( () =>
                {
                    if ( setfocus === 'AccountRef' )
                    { AccountRef.current.focus(); splitLastFocus(); }
                }, 5 ); // 10 milliseconds delay
            }
            const focusOnPacking = JSON.parse( sessionStorage.getItem( 'OSPackingfocus' ) );
            if ( focusOnPacking == 0 ) { refspacking.current[ 0 ].focus(); } setTimeout( () =>
            {
                if ( focusOnPacking ) { refspacking.current[ focusOnPacking ].focus(); }
                sessionStorage.removeItem( 'OSPackingfocus' ); // Removes the 'Expensesfocus' item from sessionStorage
            }, 5 );
            const focusOnGodown = JSON.parse( sessionStorage.getItem( 'OSGodownfocus' ) );
            if ( focusOnGodown == 0 ) { refsgodown.current[ 0 ].focus(); } setTimeout( () =>
            {
                if ( focusOnGodown ) { refsgodown.current[ focusOnGodown ].focus(); }
                sessionStorage.removeItem( 'OSGodownfocus' ); // Removes the 'Expensesfocus' item from sessionStorage
            }, 5 );
            const focusOnBatchLot = JSON.parse( sessionStorage.getItem( 'OSBatchLoatNofocus' ) );
            if ( focusOnBatchLot == 0 ) { refsbatch.current[ 0 ].focus(); } setTimeout( () =>
            {
                if ( focusOnBatchLot ) { refsbatch.current[ focusOnBatchLot ].focus(); }
                sessionStorage.removeItem( 'OSBatchLoatNofocus' ); // Removes the 'Expensesfocus' item from sessionStorage
            }, 5 );
        }, 500 );

        // calculateTotals(); // Calculate initial totals
        // setIsLoading( false );

    }, [] );
    return ( <>
        { isLoading ? ( <Loaderr /> ) : ( '' ) }
        <ConfirmDialog />
        <DynamicTitleComponent newTitle={ `Opening Stock` } />
        <div className="p-2 mt-2 main">
            <div className='row'>
                <div className='col-md-12'>
                    <div className='card'>
                        <div className='card-header'>
                            <h4>Update Opening Stock</h4>
                        </div>
                        <form className="form-horizontal" autoComplete='off'>
                            <div className='card-body'>
                                <div className='row'>
                                    <span className='' style={ { color: 'red' } }> { error }</span>
                                    <div className='col-md-3'>
                                        <label htmlFor="Goods" >Goods</label>
                                        <Select
                                            options={ GoodsOpt }
                                            value={ Goods }
                                            onChange={ GoodsValueChange }
                                            styles={ customStyles }
                                            components={ { MenuList: GoodsAddBtn, IndicatorsContainer: GoodsEdit } }
                                            isSearchable
                                            autoFocus
                                            ref={ GoodsRef }
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <label htmlFor="CityName" >Account of</label>
                                        <Select
                                            styles={ customStyles }
                                            placeholder={ 'Selfe A/c' }
                                            isDisabled={ true }
                                        />
                                    </div>
                                </div>
                                <div className=' mt-3'>
                                    <div className='table-container'>
                                        <table className='table table-responsive table-bordered table-sm table-secondary fixed_header' id='table'>
                                            <thead className='pb-2'>
                                                <tr className='text-center'>
                                                    <th className='text-center' style={ { width: "45px" } }>SN</th>
                                                    <th className='selest-w'>Packing</th>
                                                    <th className='selest-w'>Batch/Lot No.</th>
                                                    <th className='selest-w'>Godown</th>
                                                    <th className='w-120'>Unit</th>
                                                    <th className='w-120'>Q/Unit</th>
                                                    <th className='w-120'>Net Qty.</th>
                                                    <th className='w-120'>Rate</th>
                                                    <th className='w-120'>R/T</th>
                                                    <th className='w-120'>Amount</th>
                                                    <th className='w-50'></th> {/* Column for Delete button */ }
                                                </tr>
                                            </thead>
                                            <tbody >
                                                { expenses.map( ( expense, index ) => (
                                                    <tr key={ index } className="">
                                                        <td className='text-center p-0 m-0'>{ index + 1 }</td>
                                                        <td className='p-0 m-0 '>
                                                            <Select
                                                                options={ PackingOpt }
                                                                value={ expense.packing }
                                                                styles={ customStyles }
                                                                components={ {
                                                                    // MenuList: ( props ) => <AppPacking { ...props } index={ index } />,
                                                                    IndicatorsContainer: ( props ) => <PackingEdit { ...props } index={ index } />
                                                                } }
                                                                isDisabled={ PackingDisable }
                                                                isClearable
                                                                isSearchable
                                                                className='selest-w'
                                                                ref={ ( el ) => ( refspacking.current[ index ] = el ) }
                                                                onChange={ ( selectedOption ) => ChangePacking( index, selectedOption ) }
                                                            />
                                                        </td>
                                                        <td className='p-0 m-0'>
                                                            <Select
                                                                options={ expense.BatchLotNoOpt }
                                                                value={ expense.batchlotno }
                                                                styles={ customStyles }
                                                                components={ {
                                                                    MenuList: ( props ) => <AppBatchLoatNo { ...props } index={ index } />,
                                                                    IndicatorsContainer: ( props ) => <BatchLoatNoEdit { ...props } index={ index } />
                                                                } }
                                                                isClearable
                                                                isSearchable
                                                                isDisabled={ BatchDisable }
                                                                className='selest-w'
                                                                ref={ ( el ) => ( refsbatch.current[ index ] = el ) }
                                                                onChange={ ( selectedOption ) => ChangeBatchLoatNo( index, selectedOption ) }
                                                            />
                                                        </td>
                                                        <td className='p-0 m-0'>
                                                            <Select
                                                                options={ GodownOpt }
                                                                value={ expense.godown }
                                                                styles={ customStyles }
                                                                components={ { MenuList: ( props ) => <AppGodown { ...props } index={ index } />, IndicatorsContainer: ( props ) => <GodownEdit { ...props } index={ index } /> } }
                                                                isClearable
                                                                isSearchable
                                                                isDisabled={ GodownDisable }
                                                                className='selest-w'
                                                                ref={ ( el ) => ( refsgodown.current[ index ] = el ) }
                                                                onChange={ ( selectedOption ) => ChangeGodown( index, selectedOption ) }
                                                            />
                                                        </td>

                                                        <td className='p-0 m-0'>
                                                            <input
                                                                type='text'
                                                                className='text-end'
                                                                value={ expense.unit }
                                                                onChange={ ( e ) => handleChangeUnit( index, e.target.value ) }
                                                            />
                                                        </td>
                                                        <td className='p-0 m-0'>
                                                            <input
                                                                type='text'
                                                                className='text-end'
                                                                value={ expense.qunit }
                                                                onChange={ ( e ) => handleChangeQUnit( index, e.target.value ) }
                                                            />
                                                        </td>
                                                        <td className='p-0 m-0'>
                                                            <input
                                                                type='text'
                                                                className='text-end'
                                                                value={ expense.netqty }
                                                                onChange={ ( e ) => handleChangenetqty( index, e.target.value ) }
                                                            />
                                                        </td>
                                                        <td className='p-0 m-0'>
                                                            <input
                                                                type='text'
                                                                className='text-end'
                                                                value={ expense.rate }
                                                                onChange={ ( e ) => handleChangeRate( index, e.target.value ) }
                                                            />
                                                        </td>
                                                        <td className='p-0 m-0 w-120'>
                                                            <select
                                                                className='form-select'
                                                                onChange={ ( e ) => handleChangeRT( index, e.target.value ) }
                                                                value={ expense.rt }
                                                            >
                                                                <option value='1'>Unit</option>
                                                                <option value='2'>Qty.</option>
                                                            </select>
                                                        </td>
                                                        <td className='p-0 m-0'>
                                                            <input
                                                                type='text'
                                                                className='text-end'
                                                                value={ expense.amount }
                                                                onChange={ ( e ) => handleChangeamount( index, e.target.value ) }
                                                            />
                                                        </td>
                                                        <td className='p-0 m-0 text-center w-50'>
                                                            <button type='button' onClick={ () => handleDeleteRow( index ) } className='btn btn-sm rounded-circle btn-danger mx-1 '>X</button>
                                                        </td>
                                                    </tr>
                                                ) ) }

                                            </tbody>
                                            <tfoot>
                                                {/* total Outputs */ }
                                                <tr className=" bg-white border-0">
                                                    <td className=' p-0 m-0 bg-white border-0'></td>
                                                    <td className='p-0 m-0 bg-white border-0 pt-5'>
                                                        <button onClick={ handleAddRow } type='button' className='btn  btn-sm btn-primary mt-2  px-4' > Add</button>
                                                    </td>
                                                    <td className='p-0 m-0 bg-white border-0'>

                                                    </td>
                                                    <td className='p-0 m-0 bg-white border-0'>

                                                    </td>
                                                    <td className='p-0 m-0 pt-5 bg-white border-0'>
                                                        <p className='bg-white text-dark disable p-readonly'>{ totalUnit }</p>
                                                    </td>
                                                    <td className='p-0 m-0 bg-white border-0'>
                                                    </td>
                                                    <td className='p-0 m-0 pt-5 bg-white border-0'>
                                                        <p className='bg-white text-dark disable p-readonly'>{ totalNetQty }</p>
                                                    </td>
                                                    <td className='p-0 m-0 bg-white border-0'>
                                                    </td>
                                                    <td className='p-0 m-0 bg-white border-0'>
                                                    </td>
                                                    <td className='p-0 m-0 pt-5 bg-white border-0'>
                                                        <p className='bg-white text-dark disable p-readonly'>{ totalAmount }</p>
                                                    </td>
                                                    <td className='p-0 m-0 bg-white border-0'>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>

                                    </div>
                                </div>
                            </div>
                            <div className='card-footer formBtn text-center'>
                                <button className="btn px-4  me-2" type='button' onClick={ save } >Update</button>
                                <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) } >Back/Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>

    </> )
}

export default OpeningStockAdd;

