import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import Select, { components } from 'react-select';
import { BrowserRouter as Router, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { GlobalError } from '../../../../validations/globalArray';
import { emtySelect, maxlength, Pincoode, OnlyNumber } from '../../../../validations/validations'
import customStyles from '../../../../validations/customStyles';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import CryptoJS from 'crypto-js';
import CheckAuth from '../../../../APIHelper/CheckAuth';
import { toast } from 'react-toastify';
import CommonFunctions, { fetchOptionsFrom } from '../../../../Common/CommonFunction';
import {Loaderr} from '../../../../CommonComponents/Loader';
const Editcity = () =>
{
    // const { id } = useParams();
    const [id ,SetId]= useState();
    const { handleLogout, setLoggedIn, isLoggedInToken, isLoading, setIsLoading } = CheckAuth();
    // const toast = useRef( null );
    const navigate = useNavigate();
    // const [ isLoading, setIsLoading ] = useState( false );
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast } = useLinkManagement(); const { AddChangeData, EditChangeData, setInWhitchData, setInWhitchFrome, RemoveSSFild, setHSNSaCType, splitLastInWitchD, splitLastInWitchF } = CommonFunctions();
    const [ isDisabled, setIsDisabled ] = useState( true );
    const [ name, set_city_name ] = useState( '' );
    const [ pincode, set_pin_code ] = useState( '' );
    const [ state, set_state_name ] = useState( '' );
    const [ stateopt, setStates ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const cityname = useRef();
    const citypincode = useRef();
    const citystate = useRef();
    const [ userId, setUserId ] = useState( '' );
    // useEffect(() => {
    //     // Check if the user is logged in by looking at localStorage
    //     const storedLoggedIn = localStorage.getItem('loggedIn');
    //     const storedUserId = localStorage.getItem('userId');

    //     if (storedLoggedIn === 'true' && storedUserId) {
    //       setUserId(storedUserId);
    //     }
    //   }, []);

    const fetchStates = async () =>
    {
        fetchOptionsFrom( 'states', 'id', 'name' ).then( mappedOptions => { setStates( mappedOptions ); } );
    };
    useEffect( () =>
    {
        fetchStates();
        let editID = sessionStorage.getItem( 'editcityID' );
        SetId( editID);
        if ( !editID ){ editID = id; }
        const fetchData = async () =>
        {
            if ( !isLoggedInToken )
            {
                handleLogout();
                setLoggedIn( false );
                return;
            }
            setIsLoading( true );
            try
            {
                const response = await axios.get( `${ BaseUrl }city/${ editID }`, { headers: { Authorization: `Bearer ${ isLoggedInToken }` } } );
                const data = response.data;

                if ( data.status === false )
                {
                    if ( data.statusCode == '401' )
                    {
                        alert( data.message );
                        handleLogout();
                        setLoggedIn( false );
                    }
                    if ( data.statuscode == '406' )
                    {
                        toast.error( data.message );
                    }
                    // if (data.statuscode == '404' ) {
                    //     toast.error(data.message);
                    // }
                    toast.error( data.message );
                    setIsLoading( false );
                }
                const editGroupData = response.data[ 0 ];
                set_city_name( editGroupData.cityName );
                set_pin_code( editGroupData.pinCode );
                set_state_name( { value: editGroupData.statevalue, label: editGroupData.statelabel } );
                setIsLoading( false );
            } catch ( error )
            {
                // if(error){
                //     const errormessage = error.response.data.messages.error;
                //     if(errormessage){
                //         toast.error( errormessage);
                //     }
                // }            
                //  console.error('Error:', error.response.data.messages.error);
                setIsLoading( false );
            }
        };

        if ( isLoggedInToken )
        {
            fetchData();
        }
    }, [ id, isLoggedInToken ] );
    // useEffect( () =>
    // {
    //   // Check if the user is logged in by looking at localStorage
    //   const storedLoggedIn = localStorage.getItem( 'loggedIn' );
    //   const storedUserId = localStorage.getItem( 'userId' );
    //   if ( storedLoggedIn === 'true' && storedUserId )
    //   {
    //     setUserId( storedUserId );
    //   }
    // }, [] );
    // useEffect( () =>
    // {
    //     const fetchData = async () =>
    //     {
    //         try
    //         {
    //             const response = await axios.get( `${ BaseUrl }city/${ id }/${ userId }` );
    //             const editGroupData = response.data[ 0 ];
    //             set_city_name( editGroupData.cityName );
    //             set_pin_code( editGroupData.pinCode );
    //             set_state_name( { value: editGroupData.statevalue, label: editGroupData.statelabel } );
    //         } catch ( error )
    //         {
    //             console.error( 'Error:', error );
    //         }
    //     };
    //     fetchData();
    // }, [] );
    const CityData = () => ( {
        city_name: name.trim(),
        pin_code: pincode.trim(),
        state_name: state.value,
        uid: userId
    } );
    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    function OnSubmit( e )
    {
        e.preventDefault();
        setError( '' );
        const trimmedName = maxlength( name.trim(), 'City Name', 50 );
        const trimmedPincode = Pincoode( pincode.trim(), 'Pincode ', 6 );
        const trimmedValue = emtySelect( state, 'State' );
        if ( trimmedName !== true ) { setError( trimmedName ); foCoUs( cityname ); return false; }
        else if ( trimmedPincode !== true ) { setError( trimmedPincode ); foCoUs( citypincode ); return false; }
        else if ( trimmedValue !== true ) { setError( trimmedValue ); citystate.current.focus(); return false; }
        else
        {
            axios
                .patch( `${ BaseUrl }city/${ id }`, CityData(), { headers: { 'Authorization': `Bearer ${ isLoggedInToken }` } }, { mode: 'cors' } )
                .then( function ( response )
                {
                    const data = response.data;
                    if ( data.success === true )
                    {
                        toast.success( `Update City Success` );
                        // toast.current.show( { severity: 'success',  summary: 'Success',
                        //     detail: 'Update City Success',
                        //     life: 2000,
                        // } );
                        if ( data.status === false )
                        {
                            if ( data.statusCode == '401' )
                            {
                                alert( data.message );
                                toast.error( data.message );
                                handleLogout();
                                setLoggedIn( false );
                            }
                        }

                        const cityLastLink = getLastLinkInSession();
                        const godown_data = sessionStorage.getItem( 'godown_data' );
                        const Selerslip_SS = sessionStorage.getItem( 'sellerslipexpensesprofile' );
                        if ( Selerslip_SS !== '' && Selerslip_SS !== null )
                        {
                            const d = Selerslip_SS ? JSON.parse( Selerslip_SS ) : {};
                            const value = d.CityName.value;
                            const label = name;
                            d.CityName = { value, label };
                            sessionStorage.setItem( 'sellerslipexpensesprofile', JSON.stringify( d ) );
                        } //Add Godown an edit City
                        // if ( godown_data !== '' && godown_data !== null )
                        // {
                        //     const data = godown_data ? JSON.parse( godown_data ) : {};
                        //     const value = data.CityName.value;
                        //     const label = name;
                        //     data.CityName = { value, label };
                        //     sessionStorage.setItem( 'godown_data', JSON.stringify( data ) );
                        // }
                        handleSplitLastLink();
                        AddChangeData( name, data.Newid );
                        navigateelast();
                        sessionStorage.removeItem( 'editcityID' );
                    }


                } )
                .catch( function ( error )
                {
                    var errrr = error.response;
                    const errors = GlobalError( errrr );
                    setError( errors );
                    toast.error( errors );

                } );
        }
    }
    useEffect( () =>
    {
       
        // const fetchStates = async () =>
        // {
        //     try
        //     {
        //         const response = await axios.get( `${ BaseUrl }/states`, { mode: 'cors' } );
        //         const data = response.data;
        //         const mappedOptions = data.map( item => ( { value: item.id, label: item.name } ) );
        //         setStates( mappedOptions );
        //     } catch ( error )
        //     {
        //         console.error( 'Error fetching states:', error );
        //     }
        // };

        //fetchStates();
    }, [] );
    // useEffect( () =>
    // {
    //     const godown_url = '/city/edit/' + id + ''; // Godown Add time edit City
    //     if ( currentUrl == godown_url || currentUrl == edit_godown_url ) { setIsDisabled( true ); }
    // }, [] );
    const StateChange = selectedOption =>
    {
        set_state_name( selectedOption );
    };
    const cancleUpdate = async () =>
    {
        try
        {
            const response = await axios.get( `${ BaseUrl }cancleupdate/${ id }`, { headers: { 'Authorization': `Bearer ${ isLoggedInToken }` } }, { mode: 'cors' } );
            const editGroupData = response.data[ 0 ];
        } catch ( error )
        {
            console.error( 'Error:', error );
            toast.error( error );
        }
    };
    const accept = () =>
    {
        // toast.current.show( {
        //     severity: 'info',
        //     summary: 'Confirmed',
        //     detail: 'You have accepted',
        //     life: 3000,
        // } );
        // toast.error('Confirmed');
        cancleUpdate();
        splitLastInWitchD();
        splitLastInWitchF();
        handleSplitLastLink();
        navigateelast();
        sessionStorage.removeItem( 'editcityID' );

    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    const ChangeValue = ( e ) =>
    {
        // cityname.current.classList.remove( 'is-invalid' );
        const { name, value } = e.target;
        if ( name === 'city_name' ) { set_city_name( value ); setError( '' ); cityname.current.classList.remove( 'is-invalid' ); }
        if ( name === 'pin_code' ) { var pincodeno = OnlyNumber( value ); set_pin_code( pincodeno ); setError( '' ); citypincode.current.classList.remove( 'is-invalid' ); }
    }
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     {
    //         if ( event.key === 'Escape' )
    //         {
    //             confirm( 'top' );
    //         }
    //     };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () =>
    //     {
    //         document.removeEventListener( 'keydown', handleKeyDown );
    //     };
    // }, [] );

    return (
        <>

            { isLoading ? ( <div id="loader-background">
                <div id="loader-content">
                    {/* <div id="loader-content"> */ }
                    <div class="loader">
                        <div class="bubble"></div>
                        <div class="bubble"></div>
                        <div class="bubble"></div>
                    </div>
                </div>
            </div> ) : ( '' ) }
            <div class=" p-2 mt-2 main"><DynamicTitleComponent newTitle={ `Edit City ` } />
                <div className="row">
                    <div className="col-md-6">
                        <div className='card '>
                            <div className="pt-2 card-header">  <h4> Edit City</h4> </div>
                            <form className="form-horizontal" onSubmit={ OnSubmit } autoComplete="off">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="city_name" className=" modal-label ">City Name</label><span className="text-danger"><sup>*</sup> </span><span className='float-end' style={ { color: 'red' } }> { error }</span>
                                                    <input type="text" className="form-control form-control-sm  " id="city_name" name="city_name" placeholder="City Name" autoFocus onChange={ ChangeValue } value={ name } ref={ cityname } maxLength={ 50 } />
                                                </div>
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="pin_code" className=" form-label ">Pin Code</label> <span className="text-danger" id="pin_code_error" />
                                                    <input type="text" className="form-control form-control-sm  " id="pin_code" name="pin_code" placeholder="Pin Code" maxLength={ 6 } minLength={ 6 } onChange={ ChangeValue } value={ pincode } ref={ citypincode } />
                                                </div>
                                                <div className="col-md-12 col-sm-12 ">
                                                    <label htmlFor="state" >State Name</label>
                                                    <Select
                                                        id="godown_state"
                                                        options={ stateopt }
                                                        value={ state }
                                                        onChange={ StateChange }
                                                        styles={ customStyles }
                                                        isClearable
                                                        isSearchable
                                                        isDisabled={ isDisabled }
                                                        ref={ citystate }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer formBtn">
                                    <button className="btn px-4  me-2" type='submit' onClick={ OnSubmit } disabled={ isLoading }>{ isLoading ? (
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    ) : (
                                        'Update'
                                    ) }</button>
                                    {/* <button className="btn px-4  me-2" type='submit' onClick={ OnSubmit }>Update</button> */ }
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <Toast ref={ toast } /> */ }
                <ConfirmDialog />
            </div>
        </>
    );
}

export default Editcity;

