const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#ced4da',
    minHeight: '30px',
    height: '30px',
  }),
  
  valueContainer: (provided, state) => ({
    ...provided,
    height: '30px',
    padding: '0 6px',
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '30px',
  }),
 

};
export const customStyless ={
  control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#ced4da',
      minHeight: '30px',
      height: '30px',
     width:'100%'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px',
    }),
  
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
  
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
}
export default customStyles;
