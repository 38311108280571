import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
// import { Toast } from 'primereact/toast';
import Select, { components } from 'react-select';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { GlobalError } from '../../../validations/globalArray';
import { emtySelect, maxlength, Pincoode, OnlyNumber } from '../../../validations/validations'
import customStyles from '../../../validations/customStyles';
import axios from 'axios';
import useLinkManagement from '../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../CommonComponents/DynamicTitleComponent';
import { Loaderr } from '../../../CommonComponents/Loader';
import CheckAuth from '../../../APIHelper/CheckAuth';
import { toast } from 'react-toastify';
import CommonFunctions from '../../../Common/CommonFunction';
function AddAccounts( props )
{
    // const toast = useRef( null );
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast } = useLinkManagement();
    const { isLoggedInToken, handleLogout, setLoggedIn, isLoading, setIsLoading } = CheckAuth();
    const {AddChangeData, EditChangeData, setInWhitchData, setInWhitchFrome, RemoveSSFild, setHSNSaCType , RCM,splitLastInWitchD, splitLastInWitchF } = CommonFunctions();
    const dealert_option = [ { value: 'GSTIN', label: 'GSTIN' }, { value: 'NON GSTIN', label: 'NON GSTIN' } ]
    const location = useLocation();
    const [ error, setError ] = useState( '' );
    const currentUrl = location.pathname;
    const [ ac_gstin_holder, setAc_gstin_holder ] = useState( '' );
    const [ group_name, setAc_group_name ] = useState( '' );
    const [ ac_ledger_type, setAc_ledger_type ] = useState( '' );
    const [ ac_main_account, setAc_main_account ] = useState( '' );
    const [ ac_account_name, setAc_account_name ] = useState( '' );
    const [ ac_bank_short_name, setAc_bank_short_name ] = useState( '' );
    const [ ac_bill_by_bill_ent, setAc_bill_by_bill_ent ] = useState( '' );
    const [ ac_pan_no, setAc_pan_no ] = useState( '' );
    const [ ac_pan_type, setAc_pan_type ] = useState( '' );
    const [ ac_legal_name, setAc_legal_name ] = useState( '' );
    const [ ac_add_account_gstin, setAc_add_account_gstin ] = useState( '' );
    const [ ac_dealer_type, setAc_dealer_type ] = useState( '' );
    const [ ac_tan_no, setAc_tan_no ] = useState( '' );
    const [ ac_aadhar_no, setAc_aadhar_no ] = useState( '' );
    const [ ac_opening_balance, setAc_opening_balance ] = useState( '' );
    const [ ac_opening_balance_type, setAc_opening_balance_type ] = useState( '' );
    const [ ac_balance_diff, setAc_balance_diff ] = useState( '' );
    const [ ac_devit_limit, setAc_devit_limit ] = useState( '' );
    const [ ac_credit_limit, setAc_credit_limit ] = useState( '' );
    const [ ac_address1, setAc_address1 ] = useState( '' );
    const [ ac_street, setAc_street ] = useState( '' );
    const [ ac_state, setAc_state ] = useState( '' );
    const [ ac_city, setAc_city ] = useState( '' );
    const [ ac_pin_code, setAc_pin_code ] = useState( '' );
    const [ ac_email, setAc_email ] = useState( '' );
    const [ ac_mobile, setAc_mobile ] = useState( '' );
    const [ ac_phoneno, setAc_phoneno ] = useState( '' );
    const [ ac_ifsc_code1, setAc_ifsc_code1 ] = useState( '' );
    const [ ac_account_no1, setAc_account_no1 ] = useState( '' );
    const [ ac_bank_name1, setAc_bank_name1 ] = useState( '' );
    const [ ac_branch_name1, setAc_branch_name1 ] = useState( '' );
    const [ ac_ifsc_code2, setAc_ifsc_code2 ] = useState( '' );
    const [ ac_account_no2, setAc_account_no2 ] = useState( '' );
    const [ ac_bank_name2, setAc_bank_name2 ] = useState( '' );
    const [ ac_branch_name2, setAc_branch_name2 ] = useState( '' );
    const [ ac_license_name, setAc_license_name ] = useState( '' );
    const [ ac_license_no, setAc_license_no ] = useState( '' );

    const [ GroupOptions, setGroupOptions ] = useState( [] );
    const navigate = useNavigate();
    const add_group = () =>
    {
        sessionStorage.setItem( 'account_form',
            JSON.stringify( {
                ac_gstin_holder, ac_account_name, ac_main_account, ac_ledger_type, group_name, ac_bank_short_name, ac_bill_by_bill_ent, ac_pan_no, ac_pan_type, ac_legal_name, ac_add_account_gstin, ac_dealer_type, ac_tan_no, ac_aadhar_no, ac_opening_balance, ac_opening_balance_type, ac_balance_diff, ac_devit_limit, ac_credit_limit, ac_address1, ac_street, ac_state, ac_city, ac_pin_code, ac_email, ac_mobile, ac_phoneno, ac_ifsc_code1, ac_account_no1, ac_bank_name1, ac_branch_name1, ac_ifsc_code2, ac_account_no2, ac_bank_name2, ac_branch_name2, ac_license_name, ac_license_no
            } ) );
        addLink( "addgroup" );
        navigateelast();


    };
    useEffect( () =>
    {
        const savedData = sessionStorage.getItem( 'account_form' );
        console.log( savedData );
        if ( savedData )
        {
            const parsedData = JSON.parse( savedData );
            // console.log( parsedData );
            setAc_gstin_holder( parsedData.ac_gstin_holder );
            setAc_group_name( parsedData.group_name );
            setAc_ledger_type( parsedData.ac_ledger_type );
            setAc_main_account( parsedData.ac_main_account );
            setAc_account_name( parsedData.ac_account_name );
            setAc_bank_short_name( parsedData.ac_bank_short_name );
            setAc_bill_by_bill_ent( parsedData.ac_bill_by_bill_ent );
            setAc_pan_no( parsedData.ac_pan_no );
            setAc_pan_type( parsedData.ac_pan_type );
            setAc_legal_name( parsedData.ac_legal_name );
            setAc_add_account_gstin( parsedData.ac_add_account_gstin );
            setAc_dealer_type( parsedData.ac_dealer_type );
            setAc_tan_no( parsedData.ac_tan_no );
            setAc_aadhar_no( parsedData.ac_aadhar_no );
            setAc_opening_balance( parsedData.ac_opening_balance );
            setAc_opening_balance_type( parsedData.ac_opening_balance_type );
            setAc_balance_diff( parsedData.ac_balance_diff );
            setAc_devit_limit( parsedData.ac_devit_limit );
            setAc_credit_limit( parsedData.ac_credit_limit );
            setAc_address1( parsedData.ac_address1 );
            setAc_street( parsedData.ac_street );
            setAc_state( parsedData.ac_state );
            setAc_city( parsedData.ac_city );
            setAc_pin_code( parsedData.ac_pin_code );
            setAc_email( parsedData.ac_email );
            setAc_mobile( parsedData.ac_mobile );
            setAc_phoneno( parsedData.ac_phoneno );
            setAc_ifsc_code1( parsedData.ac_ifsc_code1 );
            setAc_account_no1( parsedData.ac_account_no1 );
            setAc_bank_name1( parsedData.ac_bank_name1 );
            setAc_branch_name1( parsedData.ac_branch_name1 );
            setAc_ifsc_code2( parsedData.ac_ifsc_code2 );
            setAc_account_no2( parsedData.ac_account_no2 );
            setAc_bank_name2( parsedData.ac_bank_name2 );
            setAc_branch_name2( parsedData.ac_branch_name2 );
            setAc_license_name( parsedData.ac_license_name );
            setAc_license_no( parsedData.ac_license_no );
        }
    }, [] );
    const handleChange = ( e ) =>
    {
        const { name, value } = e.target;
        if ( name === 'ac_gstin_holder' )
        {
            setAc_gstin_holder( value );
        } else if ( name === 'ac_account_name' )
        {
            setAc_account_name( value );
        } else if ( name === 'ac_bank_short_name' )
        {
            setAc_bank_short_name( value );
        } else if ( name === 'ac_bill_by_bill_ent' )
        {
            setAc_bill_by_bill_ent( value );
        } else if ( name === 'ac_pan_no' )
        {
            setAc_pan_no( value );
        } else if ( name === 'ac_pan_type' )
        {
            setAc_pan_type( value );
        } else if ( name === 'ac_legal_name' )
        {
            setAc_legal_name( value );
        } else if ( name === 'ac_add_account_gstin' )
        {
            setAc_add_account_gstin( value );
        } else if ( name === 'ac_dealer_type' )
        {
            setAc_dealer_type( value );
        } else if ( name === 'ac_tan_no' ) { setAc_tan_no( value ); }
        else if ( name === 'ac_aadhar_no' ) { setAc_aadhar_no( value ); }
        else if ( name === 'ac_opening_balance' ) { setAc_opening_balance( value ); }
        else if ( name === 'ac_opening_balance_type' ) { setAc_opening_balance_type( value ); }
        else if ( name === 'ac_balance_diff' ) { setAc_balance_diff( value ); }
        else if ( name === 'ac_devit_limit' ) { setAc_devit_limit( value ); }
        else if ( name === 'ac_credit_limit' ) { setAc_credit_limit( value ); }
        else if ( name === 'ac_address1' ) { setAc_address1( value ); }
        else if ( name === 'ac_street' ) { setAc_street( value ); }
        else if ( name === 'ac_state' ) { setAc_state( value ); }
        else if ( name === 'ac_city' ) { setAc_city( value ); }
        else if ( name === 'ac_pin_code' ) { setAc_pin_code( value ); }
        else if ( name === 'ac_email' ) { setAc_email( value ); }
        else if ( name === 'ac_mobile' ) { setAc_mobile( value ); }
        else if ( name === 'ac_phoneno' ) { setAc_phoneno( value ); }
        else if ( name === 'ac_ifsc_code1' ) { setAc_ifsc_code1( value ); }
        else if ( name === 'ac_account_no1' ) { setAc_account_no1( value ); }
        else if ( name === 'ac_bank_name1' ) { setAc_bank_name1( value ); }
        else if ( name === 'ac_branch_name1' ) { setAc_branch_name1( value ); }
        else if ( name === 'ac_ifsc_code2' ) { setAc_ifsc_code2( value ); }
        else if ( name === 'ac_account_no2' ) { setAc_account_no2( value ); }
        else if ( name === 'ac_bank_name2' ) { setAc_bank_name2( value ); }
        else if ( name === 'ac_branch_name2' ) { setAc_branch_name2( value ); }
        else if ( name === 'ac_license_name' ) { setAc_license_name( value ); }
        else if ( name === 'ac_license_no' ) { setAc_license_no( value ); }
    };
    useEffect( () =>
    {

        const Token = localStorage.getItem( "token" );
        // Fetch Group options from the API endpoint
        fetch( BaseUrl + 'group', { headers: { Authorization: `Bearer ${ Token }` } } )
            .then( ( response ) => response.json() )
            .then( ( data ) =>
            {
                if ( data.status === false )    // server return -> status false
                {
                    if ( data.statusCode == '401' ) // server error unauthorized access or token is invalid
                    {
                        toast.error( data.message ); // show error message in toast
                        handleLogout();              // user logged out . Auto
                    }
                    toast.error( data.message );
                }
                const mappedOptions = data.map( ( item ) => ( {
                    value: item.id,
                    label: item.groupName,
                    systemid: item.system,
                } ) );
                setGroupOptions( mappedOptions );
            } )
            .catch( ( error ) =>
            {
                console.error( 'Error fetching options:', error );
            } );
    }, [] );
    const SelectMenuButton = ( props ) =>
    {
        return (
            <components.MenuList { ...props }>
                { props.children }
                <span
                    // to={ '/accounts/add-accounts/group' }
                    onClick={ add_group }
                    className="btn btn-xsm  btn-primary btn-block w-100"
                >
                    Add
                </span>
            </components.MenuList>
        );
    };
    const SavegroupValue = ( selected ) =>
    {
        setAc_group_name( selected );
    };

    const LedgerOpt = [
        { 'value': 'Main', 'label': 'Main Ledger' },
        { 'value': 'Farmer', 'label': 'Sale Ledger' },
        { 'value': 'Child', 'label': 'Child Ledger' },
        { 'value': 'RefBy', 'label': 'Ref By Ledger' },
    ]
    const ledger_change = selectedOption =>
    {
        if ( selectedOption === '' ) { console.log( 'Document Type empty' ); }
        else { setAc_ledger_type( selectedOption ); }
    };

    const PanOpt = function Option()
    {
        //const [ PanOptions, setPanOptions ] = useState( [] );
        const PanOptions = [
            { value: 'C', label: 'Company' },
            { value: 'P', label: 'Person' },
            { value: 'H', label: 'Hindu Undivided Family (HUF)' },
            { value: 'F', label: 'Firm' },
            { value: 'A', label: 'Association of Persons (AOP)' },
            { value: 'T', label: 'AOP (Trust)' },
            { value: 'B', label: 'Body of Individuals (BOI)' },
            { value: 'L', label: 'Local Authority' },
            { value: 'J', label: 'Artificial Juridical Person' },
            { value: 'G', label: 'Government' }
        ];
        const SavePanValue = ( selected ) =>
        {
            setAc_pan_type( selected );
        };
        return (
            <>
                <Select options={ PanOptions } styles={ customStyles } isClearable isSearchable name="ac_pan_type" id="ac_pan_type" value={ ac_pan_type } onChange={ SavePanValue } />

            </>
        );
    }
    const AccountData = () => ( {
        group_name: group_name,
        ledger_type: ac_ledger_type,
        account_name: ac_account_name,
        bank_short_name: ac_bank_short_name
    } );
    const SubmitForm = () =>
    {
        // console.log( AccountData() );
        saverequest();
    }
    const saverequest = () =>
    {
        setIsLoading( true );
        const Token = localStorage.getItem( "token" );
        axios
            .post( `${ BaseUrl }account`, AccountData(), {
                headers: {
                    'Authorization': `Bearer ${ Token }`, // Attach the token to the "Authorization" header
                },
                mode: 'cors',
            } )
            .then( function ( response )
            {setError('');
                const data = response.data;
                if ( data.success === true )
                {
                    setIsLoading( false );
                    sessionStorage.removeItem( 'account_form' );
                    toast.success( 'Add Accounts Success' );
                    const LastLink = getLastLinkInSession();
                    if ( LastLink === 'addaccount_m' )
                    {
                        setAc_group_name( '' ); setAc_account_name( '' );
                        // toast.current.show( { severity: 'success', summary: 'Success', detail: 'Add Accounts Success', life: 2000, } );
                        group_name.current.focus();
                    }
                    else
                    {
                        handleSplitLastLink();
                        const LastLinkk = getLastLinkInSession();
                        let lasttlinkk = LastLinkk;
                        const linkParts = LastLinkk.split( ',' );
                        if ( linkParts.length === 2 )
                        {
                            const link = linkParts[ 0 ].trim();
                            const editId = linkParts[ 1 ].trim();
                            lasttlinkk = link;
                        }
                        if ( lasttlinkk === 'add_tdsST_m' || lasttlinkk === 'add_tdsST' || lasttlinkk === "edit_tdsST" )
                        {
                            const TdsSC = sessionStorage.getItem( 'tdssectioncode' );
                            if ( TdsSC !== '' || TdsSC !== null || TdsSC !== undefined )
                            {
                                const d = TdsSC ? JSON.parse( TdsSC ) : {};
                                if ( group_name.value === '6' || group_name.value === '7' || group_name.value === '16' )
                                {
                                    d.TDSCA = '';
                                    sessionStorage.setItem( 'tdssectioncode', JSON.stringify( d ) );
                                } else
                                {
                                    const value = data.Newid;
                                    const label = ac_account_name;
                                    d.TDSCA = { value, label };
                                    sessionStorage.setItem( 'tdssectioncode', JSON.stringify( d ) );
                                }
                            }
                        }
                        if ( lasttlinkk === 'add_tdspartywise_m' || lasttlinkk === 'add_tdspartywise' || lasttlinkk === "edit_tdspartywise" )
                        {
                            const TdsSC = sessionStorage.getItem( 'party_wise_parameters' );
                            if ( TdsSC !== '' || TdsSC !== null || TdsSC !== undefined )
                            {
                                const d = TdsSC ? JSON.parse( TdsSC ) : {};
                                if ( group_name.value === '6' || group_name.value === '7' || group_name.value === '16' )
                                {
                                    d.account = '';
                                    sessionStorage.setItem( 'party_wise_parameters', JSON.stringify( d ) );
                                } else
                                {
                                    const value = data.Newid;
                                    const label = ac_account_name;
                                    d.account = { value, label };
                                    sessionStorage.setItem( 'party_wise_parameters', JSON.stringify( d ) );
                                }

                            }
                        }
                        if ( lasttlinkk === 'add_expenses_m' || lasttlinkk === 'add_expenses' || lasttlinkk === "edit_expenses" )
                        {
                            const TdsSC = sessionStorage.getItem( 'dbexpenses' );
                            if ( TdsSC !== '' || TdsSC !== null || TdsSC !== undefined )
                            {toast.error( 'Group name : Bank Accounts, Cash In Hand, And Bank OCC/AC not use ' );
                                const d = TdsSC ? JSON.parse( TdsSC ) : {};
                                if ( group_name.value === '6' || group_name.value === '7' || group_name.value === '16' )
                                {
                                    d.account = '';
                                    sessionStorage.setItem( 'dbexpenses', JSON.stringify( d ) );
                                } else
                                {
                                    const value = data.Newid;
                                    const label = ac_account_name;
                                    d.account = { value, label };
                                    sessionStorage.setItem( 'dbexpenses', JSON.stringify( d ) );
                                }

                            }
                        }
                        if (LastLinkk === 'add_opening_stock_m' || LastLinkk === 'add_opening_stock' || LastLinkk === "edit_opening_stock"){
                            const openingstockdata = sessionStorage.getItem('openingstock' );
                            if ( openingstockdata !== '' || openingstockdata !== null || openingstockdata !== undefined )
                            {
                                const d = openingstockdata ? JSON.parse( openingstockdata ) : {};
                                if ( group_name.value === '6' || group_name.value === '7' || group_name.value === '16' )
                                { toast.error( 'Group name : Bank Accounts, Cash In Hand, And Bank OCC/AC not use ' );
                                    d.Account = '';
                                    sessionStorage.setItem( 'openingstock', JSON.stringify( d ) );
                                } else
                                {
                                    const value = data.Newid;
                                    const label = ac_account_name;
                                    d.Account = { value, label };
                                    sessionStorage.setItem( 'openingstock', JSON.stringify( d ) );
                                }

                            }
                        }
                        // if (LastLinkk === 'add_goods_m' || LastLinkk === 'addgoods' || LastLinkk === "edit_goods"){
                        //     const goodsData = sessionStorage.getItem('goods_data' );
                        //     if ( goodsData !== '' || goodsData !== null || goodsData !== undefined )
                        //     {
                        //         const d = goodsData ? JSON.parse( goodsData ) : {};
                        //         if ( group_name.value === '6' || group_name.value === '7' || group_name.value === '16' )
                        //         { toast.error( 'Group name : Bank Accounts, Cash In Hand, And Bank OCC/AC not use ' );
                        //             d.group_trading_ac = '';
                        //             sessionStorage.setItem( 'goods_data', JSON.stringify( d ) );
                        //         } else
                        //         {
                        //             const value = data.Newid;
                        //             const label = ac_account_name;
                        //             d.group_trading_ac = { value, label };
                        //             sessionStorage.setItem( 'goods_data', JSON.stringify( d ) );
                        //         }

                        //     }
                        // }
                        AddChangeData( ac_account_name, data.Newid );
                        navigateelast();

                        // switch ( lasttlinkk )
                        // {
                        //     case 'add_tdspartywise_m': case 'add_tdspartywise': case 'edit_tdspartywise':
                        //         const TdsSC = sessionStorage.getItem( 'party_wise_parameters' );
                        //         const d = TdsSC ? JSON.parse( TdsSC ) : {};
                        //         if ( group_name.value === '6' || group_name.value === '7' || group_name.value === '16' )
                        //         {
                        //             d.account = '';
                        //             sessionStorage.setItem( 'party_wise_parameters', JSON.stringify( d ) );
                        //         } else
                        //         {


                        //         }


                        //         break;
                        // }

                    }

                }
                if ( data.status === false )
                {
                    if ( data.statusCode == '401' )
                    {
                        //   alert(data.message);
                        toast.error( data.message );
                        handleLogout();
                        setLoggedIn( false );
                    }
                    setIsLoading( false ); // call logout user
                }

            } )
            .catch( function ( error )
            {
                
                var errrr = error.response;
                const errors = GlobalError( errrr );
                if( errors != null){
                    setError(errors[0]);
                }
                
                // toast.error( errors[0] );
                setIsLoading( false );
            } );
    }
    const saveDealerType = ( selected ) =>
    {
        setAc_dealer_type( selected );
    };
    const Dealertypeopt = () => (
        <Select options={ dealert_option } name="ac_dealer_type" styles={ customStyles } isClearable isSearchable value={ ac_dealer_type } onChange={ saveDealerType } />
    )
    const accept = () =>
    {
        sessionStorage.removeItem( 'account_form' );
        const LastLink = getLastLinkInSession();
        if ( LastLink === 'addaccount_m' ) { splitLastInWitchD( 'f' ); splitLastInWitchF( 'f' ); }
        splitLastInWitchD();
        splitLastInWitchF();
        handleSplitLastLink();
        navigateelast();
        // toast.current.show( {
        //     severity: 'info',
        //     summary: 'Info',
        //     detail: 'please click back Button',
        //     life: 3000,
        // } );

    };
    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };
    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     { if ( event.key === 'Escape' ) { confirm( 'top' ); } };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () => { document.removeEventListener( 'keydown', handleKeyDown ); };
    // }, [] );

    //Edit Group 
    const editGroupForm = ( value, systemid = null ) =>
    {
        const Token = localStorage.getItem( 'token' );
        const checkeditid = async () =>
        {
            try
            {
                const response = await axios.get( `${ BaseUrl }checkgroup/${ value }`, {
                    headers: { Authorization: `Bearer ${ Token }` },
                    mode: 'cors'
                } );
                const returndata = response.data;
                if ( returndata.status === false )
                {
                    if ( returndata.statusCode === '401' )
                    {
                        toast.error( returndata.message );
                        handleLogout();
                        setLoggedIn( false );
                    }
                    if ( returndata.code == '1' )
                    {
                        toast.error( returndata.message );
                        setGroupOptions( prevData => prevData.filter( row => row.id !== value ) );
                    }
                    else { toast.error( returndata.message ); }
                }
                if ( returndata.status === true )
                {
                    if ( systemid === '1' && value === '1' )
                    {
                        sessionStorage.removeItem( 'account_form' );
                        sessionStorage.setItem( 'account_form', JSON.stringify( { ac_account_name, group_name, ac_bank_short_name } ) );
                        addLink( "edit_group," + value );
                        sessionStorage.setItem( 'editgroupID', value );
                        navigateelast();
                    } else
                    {
                        sessionStorage.removeItem( 'account_form' );
                        sessionStorage.setItem( 'account_form', JSON.stringify( { ac_account_name, group_name, ac_bank_short_name } ) );
                        addLink( "edit_group," + value );
                        sessionStorage.setItem( 'editgroupID', value );
                        navigateelast();

                    }
                }
            }
            catch ( error )
            {
                toast.error( 'Error:', error );
                console.error( 'Error:', error );
            }
        }
        if (value !== '1' && systemid === '1' )
        {
            toast.error( `Entry can't be Update due to entry is system defined.` );
            return false;
        } else
        {
            checkeditid();
        }


        // if ( systemid === '1' && value === '1' )
        // {
        //     sessionStorage.removeItem( 'account_form' );
        //     sessionStorage.setItem( 'account_form', JSON.stringify( { ac_account_name, group_name, ac_bank_short_name } ) );
        //     addLink( "edit_group," + value );
        //     navigateelast();
        // }
        // else if ( systemid === '1' )
        // {
        //     toast.current.show( { severity: 'error', summary: 'Rejected', detail: 'This Group System Defined', life: 3000 } );
        //     return false;
        // }
        // else 
        // {
        //     sessionStorage.removeItem( 'account_form' );
        //     sessionStorage.setItem( 'account_form', JSON.stringify( { ac_account_name, group_name, ac_bank_short_name } ) );
        //     addLink( "edit_group," + value );
        //     navigateelast();
        // }

    };
    const EditGroup = ( props ) =>
    {
        return (
            <>
                <div
                    onClick={ () => editGroupForm( props.selectProps.value.value, props.selectProps.value.systemid ) } className="css-1xc3v61-indicatorContainer" aria-hidden="true">
                    <svg viewBox="0 0 50 50" width="19px" height="17px" className="css-tj5bde-Svg" aria-hidden="false" focusable="false" >
                        <path d="M 43.125 2 C 41.878906 2 40.636719 2.488281 39.6875 3.4375 L 38.875 4.25 L 45.75 11.125 C 45.746094 11.128906 46.5625 10.3125 46.5625 10.3125 C 48.464844 8.410156 48.460938 5.335938 46.5625 3.4375 C 45.609375 2.488281 44.371094 2 43.125 2 Z M 37.34375 6.03125 C 37.117188 6.0625 36.90625 6.175781 36.75 6.34375 L 4.3125 38.8125 C 4.183594 38.929688 4.085938 39.082031 4.03125 39.25 L 2.03125 46.75 C 1.941406 47.09375 2.042969 47.457031 2.292969 47.707031 C 2.542969 47.957031 2.90625 48.058594 3.25 47.96875 L 10.75 45.96875 C 10.917969 45.914063 11.070313 45.816406 11.1875 45.6875 L 43.65625 13.25 C 44.054688 12.863281 44.058594 12.226563 43.671875 11.828125 C 43.285156 11.429688 42.648438 11.425781 42.25 11.8125 L 9.96875 44.09375 L 5.90625 40.03125 L 38.1875 7.75 C 38.488281 7.460938 38.578125 7.011719 38.410156 6.628906 C 38.242188 6.246094 37.855469 6.007813 37.4375 6.03125 C 37.40625 6.03125 37.375 6.03125 37.34375 6.03125 Z"></path>
                    </svg>
                </div>
                <span className="css-1u9des2-indicatorSeparator" />
            </>
        );

    };
    const editGroup = ( { children, ...props } ) =>
    {
        return (
            <components.IndicatorsContainer { ...props }>
                { props.selectProps.value ? <EditGroup { ...props } /> : null }
                { children }
            </components.IndicatorsContainer>
        );
    };
    return (
        <>
            <div className=" mt-2 main">
                { isLoading ? ( <Loaderr /> ) : ( '' ) }
                <ConfirmDialog />
                <DynamicTitleComponent newTitle="Add Accounts" />
                <div className='card' >
                    <div className=" card-header">
                        <h4 className="pt-2"> Add Accounts</h4>
                    </div>

                    <form  >
                        <div className='card-body'>
                            <div className="form-group row"><span className='float-end' style={ { color: 'red' } }> { error }</span>
                                <div className="col-md-4 col-sm-6 ">
                                    <label htmlFor="group_name" className=" ">Group<span className="text-danger"><sup>*</sup></span></label>
                                    {/* <EditGroup /> */ }
                                    {/* <GroupOpt /> */ }
                                    <Select
                                        options={ GroupOptions }
                                        components={ { MenuList: SelectMenuButton, IndicatorsContainer: editGroup } }
                                        styles={ customStyles }
                                        isClearable
                                        autoFocus
                                        isSearchable
                                        name="group_name"
                                        value={ group_name }
                                        onChange={ SavegroupValue }
                                    />
                                </div>
                                <div className="col-md-4 col-sm-6 ">
                                    <label htmlFor="ac_ledger_type" className=" ">Ledger Type<span className="text-danger"><sup>*</sup></span></label>
                                    <Select options={ LedgerOpt } value={ ac_ledger_type } onChange={ ledger_change } styles={ customStyles } isClearable isSearchable />
                                </div>
                                <div className="col-md-4 col-sm-6 ">
                                    <label htmlFor="ac_main_account" className="  ">Main Account<span className="text-danger"><sup>*</sup></span></label>
                                    <Select options={ LedgerOpt } styles={ customStyles } isClearable isSearchable isDisabled={ true } />
                                </div>
                                <div className="col-md-4 col-sm-6 ">
                                    <label htmlFor="ac_account_name" className=" ">Account Name<span className="text-danger"><sup>*</sup></span></label>
                                    <input type="text" className="form-control form-control-sm  " id="ac_account_name" name="ac_account_name" placeholder="Account Name" value={ ac_account_name } onChange={ handleChange } />
                                </div>
                                <div className="col-md-4 col-sm-6 ">
                                    <label htmlFor="ac_bank_short_name" className="">Bank Short Name</label>
                                    <input type="text" className="form-control form-control-sm  " id="ac_bank_short_name" name="ac_bank_short_name" placeholder="Short Name" value={ ac_bank_short_name } onChange={ handleChange } />
                                </div>
                                <div className="col-md-4 col-sm-4 ">
                                    <label htmlFor="ac_bill_by_bill_ent" className="  ">Bill By Bill Entery</label>
                                    <select className="form-control form-control-sm " name="ac_bill_by_bill_ent" id="ac_bill_by_bill_ent" value={ ac_bill_by_bill_ent } onChange={ handleChange }>
                                        <option value={ 0 }>No</option>
                                        <option value={ 1 }>Yes</option>
                                    </select>
                                </div>
                                <div className="col-md-4 col-sm-6 ">
                                    <label htmlFor="ac_pan_no" className="">Pan No.</label>
                                    <input type="text" className="form-control   form-control-sm " id="ac_pan_no" name="ac_pan_no" placeholder="Pan No." maxLength={ 10 } minLength={ 10 } value={ ac_pan_no } onChange={ handleChange } />
                                </div>
                                <div className="col-md-4 col-sm-3">
                                    <label htmlFor="ac_pan_type" className="">Pan Type</label>

                                    <PanOpt />
                                </div>
                                <div className="col-md-4 col-sm-6 ">
                                    <label htmlFor="ac_legal_name" className=" ">Legal Name</label>
                                    <input type="text" className="form-control  form-control-sm  " id="ac_legal_name" name="ac_legal_name" placeholder="Legal Name" value={ ac_legal_name } onChange={ handleChange } />
                                </div>
                                <div className="col-md-4 col-sm-6 ">
                                    <label htmlFor="ac_add_account_gstin" className=" ">GSTIN</label>
                                    <input type="text" className="form-control  form-control-sm  " id="ac_add_account_gstin" name="ac_add_account_gstin" placeholder="GSTIN" onkeyup="this.value = this.value.toUpperCase();" maxLength={ 15 } minLength={ 15 } value={ ac_add_account_gstin } onChange={ handleChange } />
                                </div>
                                <div className="col-md-4 col-sm-3 ">
                                    <label htmlFor="ac_dealer_type" className="">Dealer Type</label>
                                    {/* <select className="form-control  form-control-sm select2" name="ac_dealer_type" id="ac_dealer_type" data-placeholder="Type" style={ { width: '100%' } } value={ ac_dealer_type } onChange={ handleChange }>
                                                        <option value />
                                                        <option value={ 0 }>GSTIN</option>
                                                        <option value={ 1 }>NON GSTIN</option>
                                                    </select> */}
                                    <Dealertypeopt />
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <label htmlFor="ac_tan_no" className="">Tan No.</label>
                                    <input type="text" className="form-control  form-control-sm  " id="ac_tan_no" name="ac_tan_no" placeholder="Tan No." maxLength={ 10 } minLength={ 10 } value={ ac_tan_no } onChange={ handleChange } />
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <label htmlFor="ac_aadhar_no" className=" ">Aadhar No.</label>
                                    <input type="number" className="form-control form-control-sm  " id="ac_aadhar_no" name="ac_aadhar_no" placeholder="Aadhar No." value={ ac_aadhar_no } onChange={ handleChange } />
                                </div>
                                <div className="col-md-3 col-sm-2">
                                    <label htmlFor="ac_opening_balance" className=" ">Opening Balance</label>
                                    <input type="number" className="form-control  form-control-sm  " id="ac_opening_balance" name="ac_opening_balance" placeholder="Opening Balance" value={ ac_opening_balance } onChange={ handleChange } />
                                </div>
                                <div className="col-md-1 col-sm-2">
                                    <label htmlFor="ac_opening_balance" className=" " style={ { marginTop: '1.9rem !important' } } />
                                    <select className="form-control  form-control-sm 	" name="ac_opening_balance_type" id="ac_opening_balance_type" data-placeholder="Type" style={ { width: '100%', marginTop: '7px' } } value={ ac_opening_balance_type } onChange={ handleChange }>
                                        <option value="dr" selected>Dr.</option>
                                        <option value="cr">Cr.</option>
                                    </select>
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <label htmlFor="ac_balance_diff" className=" ">Balance Difference</label>
                                    <input type="number" className="form-control   form-control-sm " id="ac_balance_diff" name="ac_balance_diff" placeholder="Balance Diff" value={ ac_balance_diff } onChange={ handleChange } />
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <label htmlFor="ac_devit_limit" className=" ">Debit Limit</label>

                                    <input type="number" className="form-control form-control-sm" id="ac_devit_limit" name="ac_devit_limit" placeholder="Debit Limit" value={ ac_devit_limit } onChange={ handleChange } />
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <label htmlFor="ac_credit_limit" className=" ">Credit Limit</label>
                                    <input type="number" className="form-control form-control-sm  " id="ac_credit_limit" name="ac_credit_limit" placeholder="Credit Limit" value={ ac_credit_limit } onChange={ handleChange } />
                                </div>
                            </div>
                        </div>
                        <div className="formBtn card-footer">
                            <button className="btn px-4  me-2" type='button' onClick={ SubmitForm }>Save</button>
                            <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                        </div>
                    </form>
                </div>
                {/* <Toast ref={ toast } /> */ }

            </div>
        </>
    );
}
export default AddAccounts