import React, { useState, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { BrowserRouter as Router, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { addtwoDecimal, addthreeDecimal, removeLeadingZero, threeDecimal, twoDecimal, emtySelect, maxlength, validInput, Star } from '../../../../validations/validations';
import { GlobalError } from '../../../../validations/globalArray';
import BaseUrl from '../../../BaseUrl';
import useLinkManagement from '../../../../Routes/useLinkManagement';
import DynamicTitleComponent from '../../../../CommonComponents/DynamicTitleComponent'
import { toast } from 'react-toastify';
import CommonFunctions from '../../../../Common/CommonFunction';
const EditCess = ( e ) =>
{
    const { id } = useParams();
    const { links, splitLast, addLink, handleSplitLastLink, getLastLinkInSession, clearSessionStorage, navigateelast, navigatefirstlink } = useLinkManagement();
    const { AddChangeData, EditChangeData, setInWhitchData, splitLastInWitchD, splitLastInWitchF, RemoveHSNSaCType } = CommonFunctions();
    const toastt = useRef( null );
    const [ cessName, setCessName ] = useState( '' );
    const [ cessApplyOn, setCessApplyOn ] = useState( 'Amount' );
    const [ cessValue, setCessValue ] = useState( '' );
    const [ cess_dec_val, set_dec_val ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const cessNameRef = useRef();
    const cessApplyOnRef = useRef();
    const cessValueRef = useRef();

    useEffect( () =>
    {
        fetchData();
        
    }, [] );
    const fetchData = async () =>
    {
        try
        {
            const response = await axios.get( `${ BaseUrl }cess/${ id }` );
            const editCessData = response.data;
            setCessName( editCessData.name );
            setCessApplyOn( editCessData.cessCalculateOn );
            setCessValue( editCessData.igstPer );
            set_dec_val( editCessData.igstPer );
        } catch ( error )
        {
            console.error( 'Error:', error );
        }
    };
    const CessData = () => ( {
        name: cessName.trim(),
        applyon: cessApplyOn.trim(),
        value: cess_dec_val.trim(),
    } );

    const foCoUs = ( foc ) =>
    {
        foc.current.focus();
        foc.current.classList.add( 'is-invalid' );
    };
    const removeinvalid = ( foc ) =>
    {
        foc.current.classList.remove( 'is-invalid' );
    };

    function SubmitForm( e )
    {
        e.preventDefault();
        setError( '' );
        const trimmedName = maxlength( cessName.trim(), 'cess name', 50 );
        const trimmedApplyOn = emtySelect( cessApplyOn.trim(), 'apply on' );
        const trimmedValue = maxlength( cessValue.trim(), 'Value should be greater than 0 ' );
        const Type = JSON.parse( sessionStorage.getItem( 'InWhitchHsnSac' ) );
        if ( trimmedName !== true ) { setError( trimmedName ); foCoUs( cessNameRef ); return false; }
        else if ( trimmedApplyOn !== true ) { setError( trimmedApplyOn ); foCoUs( cessApplyOnRef ); return false; }
        else if ( trimmedValue !== true ) { setError( trimmedValue ); foCoUs( cessValueRef ); return false; }
        else if ( cessValue.trim() === '0' || cessValue.trim() === '00' || cessValue.trim() === '.0' ) { setError( 'Value should be greater than 0' ); foCoUs( cessValueRef ); return false; }
        else if ( cessApplyOn === 'Amount' && Type === 'Qty' )
        {
            toast.error( "Amount Entry Not Allowed!" );
            return false;
        }
        else if ( cessApplyOn === 'Qty' && Type === 'Amount' )
        {
            toast.error( "Qty Entry Not Allowed!" );
            return false;
        }
        else
        {
            axios
                .patch( `${ BaseUrl }cess/${ id }`, CessData(), { mode: 'cors' } )
                .then( function ( response )
                {
                    const data = response.data;
                    if ( data.success === true )
                    {
                        toast.success( 'Cess Update Selected' );
                        const LastLink = getLastLinkInSession();

                        if ( LastLink === 'edit_hsnsac' )
                        {
                            handleSplitLastLink();
                            navigateelast();
                        } else
                        {
                            const Type = JSON.parse( sessionStorage.getItem( 'InWhitchHsnSac' ) );
                            if ( data.type === 'Amount' && Type === 'Qty' )
                            {
                                toast.error( "Amount Entry Not Allowed!" );
                                return false;
                            }
                            if ( data.type === 'Qty' && Type === 'Amount' )
                            {
                                toast.error( "Qty Entry Not Allowed!" );
                                return false;
                            } else
                            {
                                handleSplitLastLink();
                                EditChangeData( cessName, data.Newid ); // Change Last Open Popup Data or field
                                RemoveHSNSaCType();
                                navigateelast();
                            }

                        }

                        // if(currentUrl===edit_url){
                        //     navigate('/cess');
                        // }
                    }
                } )
                .catch( function ( error )
                {
                    var errrr = error.response;
                    const errors = GlobalError( errrr );
                    setError( errors ); foCoUs( cessNameRef );
                } );
        }
    }


    const changeValue = ( e ) =>
    {
        setError( '' );
        const { name, value } = e.target;
        if ( name === 'cess_name' ) { var valuee = validInput( value ); setCessName( valuee ); removeinvalid( cessNameRef ); }
        if ( name === 'cess_apply_on' ) { var valuee = validInput( value ); setCessApplyOn( valuee ); removeinvalid( cessApplyOnRef ); }
        if ( name === 'cess_value' )
        {
            const processedValue = removeLeadingZero( value );
            const validationError = twoDecimal( processedValue );
            if ( validationError !== null )
            {
                setCessValue( validationError );
                const twoValue = addtwoDecimal( validationError );
                set_dec_val( twoValue );
            }
            removeinvalid( cessValueRef );
        }
    };

    const accept = () =>
    {
        splitLastInWitchD();
        splitLastInWitchF();
        RemoveHSNSaCType();
        handleSplitLastLink();
        navigateelast();
        // if ( currentUrl === '/cess/edit/'+id+'' )
        // {
        //     navigate( '/cess' );
        // } 
    };

    const confirm = ( position ) =>
    {
        confirmDialog( {
            message: 'Are you sure you want to leave this page?',
            header: 'Leave Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
        } );
    };

    // useEffect( () =>
    // {
    //     const handleKeyDown = ( event ) =>
    //     {
    //         if ( event.key === 'Escape' )
    //         {
    //             confirm( 'top' );
    //         }
    //     };
    //     document.addEventListener( 'keydown', handleKeyDown );
    //     return () =>
    //     {
    //         document.removeEventListener( 'keydown', handleKeyDown );
    //     };
    // }, [] );

    return (
        <>
            <div className="px-2">
                <div className="row">
                    <div className="col-md-6">
                        <DynamicTitleComponent newTitle={ `Edit Cess ` } />
                        <div className='card'>
                            <div className="card-header">
                                <h4>Edit Cess</h4>
                            </div>
                            <form className="form-horizontal">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <label htmlFor="cess_name" className="modal-label">
                                                        Cess Name
                                                    </label><Star /><span className='float-end' style={ { color: 'red' } }> { error }</span>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        id="cess_name"
                                                        name="cess_name"
                                                        placeholder="Cess Name"
                                                        ref={ cessNameRef }
                                                        value={ cessName }
                                                        autoFocus
                                                        onChange={ changeValue }
                                                    />
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <label htmlFor="cess_apply_on" className="" >Apply on{ ' ' }</label><Star />
                                                    <select
                                                        className="form-control form-control-sm"
                                                        style={ { width: '100%' } }
                                                        aria-invalid="false"
                                                        name="cess_apply_on"
                                                        id="cess_apply_on"
                                                        value={ cessApplyOn }
                                                        onChange={ changeValue }
                                                        ref={ cessApplyOnRef }
                                                    >
                                                        <option value="Amount">
                                                            Advol (Amount)
                                                        </option>
                                                        <option value="Qty">
                                                            Non Advol (Qty.)
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <label htmlFor="cess_value" className="">
                                                        Value{ ' ' }
                                                    </label><Star />
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        id="cess_value"
                                                        name="cess_value"
                                                        placeholder="Value"
                                                        ref={ cessValueRef }
                                                        value={ cessValue }
                                                        onChange={ changeValue }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBtn card-footer">
                                    <button className="btn px-4  me-2" type='submit' onClick={ SubmitForm }>Update</button>
                                    <button className="btn  " type="reset" onClick={ () => confirm( 'top' ) }>Back/Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6"></div>
                </div>
                <Toast ref={ toastt } />
                <ConfirmDialog />
            </div>
        </>
    );
}

export default EditCess;

