// import React, { useState, useEffect } from 'react';
// import Loader from './Loader';
// import axios from 'axios'; // Import Axios
// import BaseUrl from '../BaseUrl';
// import { Link } from 'react-router-dom';
// import { toast } from 'react-hot-toast';
// const Login = ( { onLogin } ) =>
// {
//     const [ error, setError ] = useState( '' );
//     const [ email, setEmail ] = useState( '' );
//     const [ password, setPassword ] = useState( '' );
//     const [ isLoading, setIsLoading ] = useState( false );
//     const userDate = { email: email, password: password };
//     const validateEmail = () =>
//     {
//         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         if ( !emailRegex.test( email ) )
//         {
//             setError( 'Invalid email format' );
//             return false;
//         }
//         return true;
//     };
//     const handleSubmit = ( e ) =>
//     {
//         e.preventDefault();// Validate email before making the API request
//         localStorage.clear();
//         sessionStorage.clear();
//         if ( !validateEmail() ) { return; }
//         setIsLoading( true );
//         axios
//             .post( `http://localhost/FactBook/userApi/public/login`, userDate, { mode: 'cors' } )
//             .then( function ( response )
//             {
//                 const data = response.data;
//                 if ( data.status === true )
//                 {
//                     toast.success('Login success');
//                     const loginTime = new Date().getTime();
//                     localStorage.setItem( 'token', data.token );
//                     localStorage.setItem( 'loginTime', loginTime );
//                     localStorage.setItem( "loggedIn", true );
//                     onLogin( data.token );
//                     console.log( 'User logged in successfully.' );
//                     //  window.location.reload();
//                 } 
//                 else{ setError( 'invalid User Password' ); } // Handle other possible conditions
//                 setIsLoading( false );
//             } )
//             .catch( function ( error )
//             {
//                 var errrr = error.response;
//                 console.error( errrr );
//                 setError( 'invalid user Password' );
//                 setIsLoading( false );
//             } );
//     };
//     return (
//         <div className="container mt-5">
//             <div className="row justify-content-center">
//                 <div className="col-md-6">
//                     <div className="card">
//                         <div className="card-header">Login</div>

//                         <form className="" onSubmit={ handleSubmit } autoComplete="off">
//                             <div className="card-body">
//                                 { error && (
//                                     <div className="alert alert-sm py-1 alert-danger" role="alert">
//                                         { error }
//                                     </div>
//                                 ) }
//                                 <div className="mb-3">
//                                     <label htmlFor="userId" className="form-label">
//                                         User Email:
//                                     </label>
//                                     <input
//                                     autoFocus
//                                         type="email"
//                                         className="form-control"
//                                         id="userId"
//                                         name="userId"
//                                         required
//                                         value={ email }
//                                         onChange={ ( e ) => setEmail( e.target.value ) }
//                                     />
//                                 </div>
//                                 <div className="mb-3">
//                                     <label htmlFor="password" className="form-label">
//                                         Password:
//                                     </label>
//                                     <input
//                                         type="password"
//                                         className="form-control"
//                                         id="password"
//                                         name="password"
//                                         required
//                                         value={ password }
//                                         onChange={ ( e ) => setPassword( e.target.value ) }
//                                     />
//                                 </div>
//                             </div>
//                             <div className="card-footer text-center">
//                                 <button type="submit" className={ `btn btn-primary px-3 rounded-3 ${ isLoading ? 'disabled' : '' }` } disabled={ isLoading } >
//                                     { isLoading ? (
//                                         <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
//                                     ) : (
//                                         'Login'
//                                     ) }
//                                 </button>
//                                  {/* <Link to="/register">Register</Link> */}

//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Login;


import React, { useState } from 'react';
import Loader from './Loader';
import axios from 'axios';
import BaseUrl from '../BaseUrl';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import zxcvbn from 'zxcvbn'; // Import zxcvbn library for password strength checking

const Login = ( { onLogin } ) =>
{
    const [ error, setError ] = useState( '' );
    const [ email, setEmail ] = useState( '' );
    const [ password, setPassword ] = useState( '' );
    const [ isLoading, setIsLoading ] = useState( false );
    const [ passwordStrength, setPasswordStrength ] = useState( null ); // Password strength score
    // const history = useHistory();

    const validateEmail = ( value ) =>
    {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test( value );
    };

    const handlePasswordChange = ( value ) =>
    {
        setPassword( value );
        // const result = zxcvbn( value );
        // setPasswordStrength( result.score ); // Get the password strength score
    };

    const handleSubmit = async ( e ) =>
    {
        e.preventDefault();

        if ( !validateEmail( email ) )
        {
            setError( 'Invalid email format' );
            return;
        }

        if ( !email || !password )
        {
            setError( 'Email and password are required' );
            return;
        }

        // if ( passwordStrength < 3 )
        // {
        //     setError( 'Password is too weak. Please choose a stronger password.' );
        //     return;
        // }

        setIsLoading( true );

        try
        {
            const response = await axios.post( `${BaseUrl}login`, {
                email,
                password,
            } );

            const data = response.data;
            if ( data.status === true )
            {
                toast.success( 'Login success' );
                const loginTime = new Date().getTime();
                localStorage.setItem( 'token', data.token );
                localStorage.setItem( 'loginTime', loginTime );
                localStorage.setItem( 'loggedIn', true );
                onLogin( data.token );
                // window.location.href = 'http://fkcrm.uelsoftwares.com';
                // window.location.href = 'http://localhost:3000';
                // history.push( '/' ); // Redirect to the desired page
            } else
            {
                setError( 'Invalid User Password' );
            }
        } catch ( error )
        {
            console.error( error.response );
            setError( 'Invalid User Password' );
        } finally
        {
            setIsLoading( false );
        }
    };

    return (
        <div className="container mt-5">
            { isLoading ? ( <div id="loader-background">
              <div id="loader-content">
                {/* <div id="loader-content"> */ }
                <div class="loader">
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                </div>
              </div>
            </div> ) : ('') }
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <h4 className="card-header text-center">Login</h4>
                        <form className="" onSubmit={ handleSubmit } autoComplete="off">
                            <div className="card-body">
                                { error && (
                                    <div className="alert alert-sm py-1 alert-danger" role="alert">
                                        { error }
                                    </div>
                                ) }
                                <div className="mb-3">
                                    <label htmlFor="userId" className="form-label">
                                        User Email:
                                    </label>
                                    <input
                                        autoFocus
                                        type="email"
                                        className={ `form-control ${ error ? 'is-invalid' : '' }` }
                                        id="userId"
                                        name="userId"
                                        value={ email }
                                        onChange={ ( e ) => setEmail( e.target.value ) }
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">
                                        Password:
                                    </label>
                                    <input
                                        type="password"
                                        className={ `form-control ${ error ? 'is-invalid' : '' }` }
                                        id="password"
                                        name="password"
                                        value={ password }
                                        onChange={ ( e ) => handlePasswordChange( e.target.value ) }
                                    />
                                   
                                </div>
                            </div>
                            <div className="card-footer text-center">
                                <button
                                    type="submit"
                                    className={ `btn btn-primary px-3 rounded-3 ${ isLoading ? 'disabled' : '' }` }
                                    disabled={ isLoading }
                                >
                                    { isLoading ? (
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    ) : (
                                        'Login'
                                    ) }
                                </button>
                                <Link className='ps-3' to="/register">Register</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;







// export default Login;
// import React, { useState } from 'react';
// import Loader from './Loader';


// const Login = ( { onLogin } ) =>
// {
//     const [ error, setError ] = useState( '' );
//     const [ email, setEmail ] = useState( '' );
//     const [ password, setpassword ] = useState( '' );
//     const [isLoading, setIsLoading] = useState(false);
//     // const handleSubmit = ( values, { setSubmitting, setFieldError } ) =>
//     // {
//     //     // Find the user in the user data
//     //     const user = userData.users.find( ( user ) => user.id === values.userId );

//     //     if ( user && user.password === values.password )
//     //     {
//     //         onLogin( user.ud ); // Call the login callback with the user ID
//     //     } else
//     //     {
//     //         setError( 'Invalid ID or password' );
//     //     }

//     //     setSubmitting( false );
//     // };
//     const userDate = () => ( {
//         email: email,
//         password: password
//     } );

// const Validations = ()=>{

// }
//     const LoginUser = () =>
//     {
//         axios.post( `${ BaseUrl }/login`, userDate() { mode: 'cors' } )

//             .then( function ( response )
//             {
//                 setIsLoading(true);
//                 const data = response.data;
//                 if ( data.success === true )
//                 {
//                     onLogin( data.uid );
//                     console.log( 'User logged out successfully.' );
//                 } else
//                 {
//                     console.log( 'User logout unsuccessful.' ); // Handle other possible conditions
//                 }
//             } )
//             .catch( function ( error )
//             {
//                 var errrr = error.response;
//                 console.error( errrr );
//             } );
//     }

//     return (
//         <div className="container mt-5">
//             <div className="row justify-content-center">
//                 <div className="col-md-6">
//                     <div className="card">
//                         <div className="card-header">Login</div>
//                         { error && (
//                             <div className="alert alert-sm py-1 alert-danger" role="alert">
//                                 { error }
//                             </div>
//                         ) }
//                         <form className="form" onSubmit={ } autoComplete='off'>
//                             <div className="card-bodr">
//                                 <div className="mb-3">
//                                     <label htmlFor="userId" className="form-label">User Email:</label>
//                                     <input type="email" className="form-control" id="userId" name="userId" required value={ email } onChange={ setEmail( e.target.value ) } />

//                                 </div>
//                                 <div className="mb-3">
//                                     <label htmlFor="password" className="form-label">User Email:</label>
//                                     <input type="password" className="form-control" id="password" name="password" required value={ password } onChange={ setpassword( e.target.value ) } />

//                                 </div>
//                             </div>
//                             <div className="card-footer text-center">
//                                 <button type="submit" className="btn btn-primary px-3 rounded-3" >  {isLoading ? 'Login':<Loader />} </button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Login;


// const fetchDataIfLoggedIn = () =>
// {
//     if ( loggedIn )
//     {
//         const token = localStorage.getItem( 'token' );
//         const apiUrl = 'http://localhost/FactBook/userApi/public/user';

//         fetch( apiUrl, {
//             method: 'GET',
//             headers: {
//                 'Authorization': `Bearer ${token}`, // Attach the token to the "Authorization" header
//               },
//             // headers: { Authorization: token },
//         } )
//             // .then( ( response ) => response.json() )
//             .then((response) => {
//                 if (!response.ok) {
//                     // Handle HTTP error status codes (e.g., 401 for Unauthorized)
//                     if (response.status === 401) {
//                         // Token expired or unauthorized, trigger logout
//                         handleLogout();
//                     } else {
//                         // Handle other error cases and provide feedback to the user
//                         throw new Error('Request failed with status: ' + response.status);
//                     }
//                 }
//                 return response.json();
//             })
//             .then( ( data ) =>
//             {

//                 localStorage.setItem( 'userId', data.useid );
//                 localStorage.setItem( 'username', data.username );
//                 onLogin( token );
//             } )
//             .catch( ( error ) =>
//             {
//                 console.error( 'Error fetching data:', error );
//                 handleLogout();
//             } );
//     }
// };
// const handleLogout = () =>
// {
//     // Clear login status and user-related data in localStorage
//     localStorage.removeItem( 'token' );
//     localStorage.removeItem( 'userId' );
//     localStorage.removeItem( 'username' );
//     setLoggedIn( false );
// };





