import { useState, useEffect } from "react";
import axios from 'axios';
import { toast } from 'react-hot-toast';
import BaseUrl from "../BaseUrl";
const CheckAuth = () =>
{
  const [ loggedIn, setLoggedIn ] = useState( false );
  const [ isLoggedInToken, setIsLoggedInToken ] = useState( null );
  const [ isLoading, setIsLoading ] = useState( false );
  useEffect( () =>
  {
    checkLoggedInUser();
  }, [ loggedIn, isLoggedInToken ] );
  //Check Login User 
  const checkLoggedInUser = () =>
  {
    const storedLoggedIn = localStorage.getItem( "loggedIn" ) === "true";
    const storedToken = localStorage.getItem( "token" );

    if ( storedToken !== null )
    {
      setIsLoggedInToken( storedToken );
    }

    if ( !loggedIn && storedLoggedIn )
    {
      setLoggedIn( true );
    }

    // console.log( loggedIn + ' - - - - ' + isLoggedInToken );
  };
  // User log out handleLogout 
  const handleLogout = async () =>
  {
    try
    {
      const isLoggedInToken = localStorage.getItem( "token" );

      if ( !isLoggedInToken )
      {
        // No token, user is not logged in
        alert( "Please Login Again" );
        toast.error( "Please Login Again" );
      } else
      { // Token exists, attempt to log out
        const response = await axios.post( `${ BaseUrl }logout`, null, { headers: { Authorization: `Bearer ${ isLoggedInToken }` }, } );
        const data = response.data;
        if ( data.status === true ) { toast.success( "User logged out successfully." ); }
        else { toast.error( "User logout unsuccessful." ); } // Logout unsuccessful
      }
    } catch ( error )
    {
      toast.error( "Error during logout:", error ); // Display an error message to the user
    } finally
    {// Clear local and session storage and setLoggedIn to false
      localStorage.clear(); sessionStorage.clear(); setLoggedIn( false );
      // Redirect to the login page
      window.location.href = 'http://fkcrm.uelsoftwares.com';
      // window.location.href = 'http://localhost:3000';
    }
  };
  const handleLogin = ( token ) => { setLoggedIn( true ); };
  // Function to update the user's session
  const updateSession = async () =>
  {
    try
    {
      const isLoggedInToken = localStorage.getItem( "token" );

      if ( !isLoggedInToken )
      {
        alert( "Please Login Again" );
        toast.error( "Please Login Again" );
      } else
      {
        const response = await axios.post(
          `${ BaseUrl }newtoken`,
          null,
          {
            headers: { Authorization: `Bearer ${ isLoggedInToken }` },
            mode: "cors",
          }
        );
        const data = response.data;

        if ( data.status === false && data.statusCode === 401 )
        {
          window.alert( data.message );
          handleLogout();
          setLoggedIn( false );
        }

        if ( data.status === true )
        {
          const loginTime = new Date().getTime();
          localStorage.setItem( "token", data.token );
          localStorage.setItem( "loginTime", loginTime );
          localStorage.setItem( "loggedIn", true );
        }
      }
    } catch ( error )
    {
      toast.error( "Error during session update:", error );
    }
  };
  // Constants for Time Intervals (in milliseconds)
  const FIFTY_MINUTES = 50 * 60 * 1000;
  const THREE_MINUTES_TWENTY_SECONDS = 2 * 60 * 1000;

  // Function to retrieve login time from localStorage
  const getLoginTimeFromLocalStorage = () =>
  {
    const loginTime = localStorage.getItem( "loginTime" );
    return loginTime ? parseInt( loginTime ) : null;
  };

  // Function to schedule a session update after 50 minutes
  const scheduleSessionUpdate = () =>
  {
    const loginTime = getLoginTimeFromLocalStorage();
    if ( loginTime )
    {
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - loginTime;
      if ( timeDifference >= FIFTY_MINUTES )
      {
        updateSession();
      }
    }
  };
  useEffect( () =>
  {
    scheduleSessionUpdate();
    const intervalId = setInterval( () =>
    {
      scheduleSessionUpdate();
      checkLoggedInUser();
    }, THREE_MINUTES_TWENTY_SECONDS ); // Check every 3 minutes and 20 seconds for session updates

    // Clean up the interval when the component unmounts
    return () =>
    {
      clearInterval( intervalId );
    };
  }, [] );

  const Token =()=>{
   const GetToken = sessionStorage.getItem('token');
   return (GetToken);
  }
  return {
    loggedIn,
    isLoggedInToken,
    isLoading,
    setIsLoading,
    setLoggedIn,
    handleLogout,
    checkLoggedInUser,
    handleLogin,
    updateSession
  };
};
export default CheckAuth;

