import React, { useEffect, useState } from 'react';

const TypingAnimation = () => {
  const text = 'Welcome to the FactBook App';
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const typingInterval = setInterval(() => {
      if (currentIndex < text.length) {
        setDisplayText(prevText => prevText + text[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      } else {
        clearInterval(typingInterval);
      }
    }, 100); // Adjust the typing speed (in milliseconds) as needed

    return () => {
      clearInterval(typingInterval);
    };
  }, [currentIndex, text]);

  return (
    <div className="animation-container">
      <div className="animation-text">{displayText}</div>
    </div>
  );
};

export default TypingAnimation;


